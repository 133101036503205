import Vue from "vue";

import values from 'lodash/values'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import size from "lodash/size";

const STORE_NAME = 'newProducts'

const state = {
    classMaster: {
        sizes: [],
        colors: [],
    },
    quantity: 1,
    details: [],
    selectedProduct: {},
    items: [],
    item: {
        id: '',
        product_name: '',
        product_class_type_id1: '',
        product_class_type_id2: '',
        tax_rate: '',
        header: '',
        description: '',
        body: '',
        footer: '',
        attention: '',
        unit_name: '',
        contained: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        detail_image_paths: '',
        detail_thumbnail_image_paths: '',
        main_image: null,
        detail_image1: null,
        detail_image2: null,
        detail_image3: null,
        detail_image4: null,
        detail_image5: null,
        quantity: 1,
        is_pickup: Boolean,
        is_regular: Boolean,
        is_sales_at_retail_price: Boolean,
        is_cosmetic: Boolean,
        is_kogen: Boolean,
        is_mebius: Boolean,
        is_chaga: Boolean,
        is_inconsistent: Boolean,
        is_broken: Boolean,
        is_not_exists_detail: Boolean,
        is_enable_value_id1: 0,
        is_enable_value_id2: 0,
        has_campaign: false,
        selected_value_id1: '',
        selected_value_id2: '',
        selected_product_code: '',
        for_front: Boolean,
        started_at: '',
        ended_at: '',
        default_price: {},
        details: {
            id: Number,
            ratio: Number,
            product_id: Number,
            product_code: String,
            product_class_value_id1: Number,
            product_class_value_id2: Number,
            original_price: Number,
            original_price_tax: Number,
            retail_price: Number,
            retail_price_tax: Number,
            sales_price: Number,
            sales_price_tax: Number,
            is_out_of_stock: 0,
        },
        list: [],
        campaign: {
            id: Number,
            is_present: Boolean,
            product_codes: JSON,
            product_codes_for_present: JSON,
            name: String,
            title: String,
            description: String,
            header: String,
            body: String,
            footer: String,
            discount_rule: String,
            required_quantity: String,
            required_amount: String,
            present_quantity: String,
            discount_of_agency: Number,
            discount_of_dealer: Number,
            discount_of_customer: Number,
            discount_of_general: Number,
            started_at: Number,
            ended_at: Number,
            quantity: Number,
        },
        relative_products: [],
    },
    oldProduct: {
        id: '',
        product_name: '',
        product_class_type_id1: '',
        product_class_type_id2: '',
        tax_rate: '',
        header: '',
        description: '',
        body: '',
        footer: '',
        attention: '',
        unit_name: '',
        contained: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        detail_image_paths: '',
        detail_thumbnail_image_paths: '',
        main_image: null,
        detail_image1: null,
        detail_image2: null,
        detail_image3: null,
        detail_image4: null,
        detail_image5: null,
        is_pickup: Boolean,
        is_regular: Boolean,
        is_sales_at_retail_price: Boolean,
        is_cosmetic: Boolean,
        is_kogen: Boolean,
        is_mebius: Boolean,
        is_iga: Boolean,
        is_inconsistent: Boolean,
        is_broken: Boolean,
        is_not_exists_detail: Boolean,
        is_enable_value_id1: 0,
        is_enable_value_id2: 0,
        has_campaign: false,
        selected_value_id1: '',
        selected_value_id2: '',
        selected_product_code: '',
        for_front: Boolean,
        started_at: '',
        ended_at: '',
        quantity: Number,
        customer_ratio: Number,
        grade: Number,
        default_price: {},
        details: {
            id: Number,
            ratio: Number,
            product_id: Number,
            product_code: String,
            product_class_value_id1: Number,
            product_class_value_id2: Number,
            original_price: Number,
            original_price_tax: Number,
            retail_price: Number,
            retail_price_tax: Number,
            sales_price: Number,
            sales_price_tax: Number,
            is_out_of_stock: 0,
        },
        list: [],
        campaign: {
            id: Number,
            is_present: Boolean,
            product_codes: JSON,
            product_codes_for_present: JSON,
            name: String,
            title: String,
            description: String,
            header: String,
            body: String,
            footer: String,
            discount_rule: String,
            required_quantity: String,
            required_amount: String,
            present_quantity: String,
            discount_of_agency: Number,
            discount_of_dealer: Number,
            discount_of_customer: Number,
            discount_of_general: Number,
            started_at: Number,
            ended_at: Number,
        },
        relative_products: [],
    },
    selectedValueId1: '',
    selectedValueId2: '',
    newProducts: [],
    importProductsHistories: [],
};

const initialState = {
    classMaster: {
        sizes: [],
        colors: [],
    },
    quantity: 1,
    details: [],
    selectedProduct: {},
    items: [],
    item: {
        id: '',
        product_name: '',
        product_class_type_id1: '',
        product_class_type_id2: '',
        tax_rate: '',
        header: '',
        description: '',
        body: '',
        footer: '',
        attention: '',
        unit_name: '',
        contained: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        detail_image_paths: '',
        detail_thumbnail_image_paths: '',
        main_image: null,
        detail_image1: null,
        detail_image2: null,
        detail_image3: null,
        detail_image4: null,
        detail_image5: null,
        is_pickup: Boolean,
        is_regular: Boolean,
        is_sales_at_retail_price: Boolean,
        is_cosmetic: Boolean,
        is_kogen: Boolean,
        is_mebius: Boolean,
        is_chaga: Boolean,
        is_inconsistent: Boolean,
        is_broken: Boolean,
        is_not_exists_detail: Boolean,
        is_enable_value_id1: 0,
        is_enable_value_id2: 0,
        has_campaign: false,
        selected_value_id1: '',
        selected_value_id2: '',
        selected_product_code: '',
        for_front: Boolean,
        started_at: '',
        ended_at: '',
        customer_ratio: Number,
        grade: Number,
        master: [],
        default_price: {},
        details: {
            id: Number,
            product_id: Number,
            product_code: String,
            product_class_value_id1: Number,
            product_class_value_id2: Number,
            original_price: Number,
            original_price_tax: Number,
            retail_price: Number,
            retail_price_tax: Number,
            sales_price: Number,
            sales_price_tax: Number,
            is_out_of_stock: 0,
        },
        classes: [],
        relative_products: [],
        campaign: {
          price: {
              campaign_price: Number,
              campaign_price_tax: Number,
          }
        },
    },
    productClassTypes: [],
    productClassValues: [],
    errors: [],
    suggestList: [],
    oldProduct: {
        id: '',
        product_name: '',
        product_class_type_id1: '',
        product_class_type_id2: '',
        tax_rate: '',
        header: '',
        description: '',
        body: '',
        footer: '',
        attention: '',
        unit_name: '',
        contained: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        detail_image_paths: '',
        detail_thumbnail_image_paths: '',
        main_image: null,
        detail_image1: null,
        detail_image2: null,
        detail_image3: null,
        detail_image4: null,
        detail_image5: null,
        is_pickup: Boolean,
        is_regular: Boolean,
        is_sales_at_retail_price: Boolean,
        is_cosmetic: Boolean,
        is_kogen: Boolean,
        is_mebius: Boolean,
        is_chaga: Boolean,
        is_inconsistent: Boolean,
        is_broken: Boolean,
        is_not_exists_detail: Boolean,
        is_enable_value_id1: 0,
        is_enable_value_id2: 0,
        has_campaign: false,
        selected_value_id1: '',
        selected_value_id2: '',
        selected_product_code: '',
        for_front: Boolean,
        started_at: '',
        ended_at: '',
        default_price: {},
        details: {
            id: Number,
            ratio: Number,
            product_id: Number,
            product_code: String,
            product_class_value_id1: Number,
            product_class_value_id2: Number,
            original_price: Number,
            original_price_tax: Number,
            retail_price: Number,
            retail_price_tax: Number,
            sales_price: Number,
            sales_price_tax: Number,
            is_out_of_stock: 0,
        },
        list: [],
        campaign: {
            id: Number,
            is_present: Boolean,
            product_codes: JSON,
            product_codes_for_present: JSON,
            name: String,
            title: String,
            description: String,
            header: String,
            body: String,
            footer: String,
            discount_rule: String,
            required_quantity: String,
            required_amount: String,
            present_quantity: String,
            discount_of_agency: Number,
            discount_of_dealer: Number,
            discount_of_customer: Number,
            discount_of_general: Number,
            started_at: Number,
            ended_at: Number,
            price: {
                campaign_price: Number,
                campaign_price_tax: Number,
            },
        },
        relative_products: [],
    },
    selectedValueId1: '',
    selectedValueId2: '',
    newProducts: [],
    importProductsHistories: [],
};

const mutations = {
    setClassMaster(state, classMaster) {
        state.classMaster = classMaster
    },
    setProductClassTypes(state, productClassTypes) {
        state.productClassTypes = productClassTypes;
    },
    setProductClassValues(state, productClassValues) {
        state.productClassValues = productClassValues;
    },
    setDetails(state, details) {
        state.details = details
    },
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setImportProductsHistories(state, histories) {
        state.importProductsHistories = histories;
    },
    setOldProduct(state, oldProduct) {
        state.oldProduct = oldProduct
    },
    setSuggestList(state, items) {
        Vue.set(state, 'suggestList', items);
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.items.splice(index, 1);
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    setProductCode(state, productCode) {
        state.item.selected_product_code = productCode
    },
    changeSelectedValueId1(state, value) {
        console.log("ValueId1", value, typeof value)
        state.item.selected_value_id1 = value
    },
    changeSelectedValueId2(state, value) {
        state.item.selected_value_id2 = value
    },
    setNewProducts(state, newProducts) {
        state.newProducts = newProducts
    },
};

const getters = {
    quantity(state) {
        return state.quantity;
    },
    products(state) {
        return state.items;
    },
    product(state) {
        return state.item;
    },
    oldProduct(state) {
        return state.oldProduct;
    },
    details(state) {
        return state.details;
    },
    productClassTypes(state) {
        return state.productClassTypes;
    },
    productClassValues(state) {
        return state.productClassValues;
    },
    importProductsHistories(state) {
        return state.importProductsHistories;
    },
    getClassMaster(state) {
        return state.classMaster;
    },
    campaign(state) {
        if(state.item.campaign) {
            return state.item.campaign
        } else {
            return false
        }
    },
    price(state) {
        const index = findIndex(state.item.details, { product_code: getters.selectedProductCode(state) });
        if(index < 0) {
            return state.item.default_price
        } else {
            return state.item.details[index].price;
        }
    },
    retailPrice(state) {
        const price = getters.price(state)
        return price.retail_price
    },
    retailPriceTax(state) {
        const price = getters.price(state)
        return price.retail_price_tax
    },
    salesPrice(state) {
        const price = getters.price(state)
        return price.sales_price
    },
    salesPriceTax(state) {
        const price = getters.price(state)
        return price.sales_price_tax
    },
    campaignPrice(state) {
        const price = getters.price(state)
        return price.campaign_price
    },
    campaignPriceTax(state) {
        const price = getters.price(state)
        console.log(price)
        return price.campaign_price_tax
    },
    selectedValueId1(state) {
        return state.item.selected_value_id1
    },
    selectedValueId2(state) {
        return state.item.selected_value_id2
    },
    selectedProductCode(state) {
        return state.item.selected_product_code
    },
    newProducts(state) {
        return state.newProducts;
    },
    countQuantity(state) {
        let initialValue = 0
        console.log(state.item.details)
        if(size(state.item.details) > 0) {
            return state.item.details.reduce(
                (previousValue, item) => previousValue + parseInt(item.quantity),
                initialValue)
        } else {
            return 0
        }
    }
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/products', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);

            console.log(STORE_NAME + '.fetch', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetch.error', error)
        });
    },
    async fetchWithClasses({state, commit, rootActions}, params) {
        await window.axios.get('/products/with/classes', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setClassLabel', 1, values(res.data.classLabel1))
            commit('setClassLabel', 2, values(res.data.classLabel2))
            commit('setSearch', params);

            console.log(STORE_NAME + '.fetchWithClasses', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchWithClasses.error', error)
        });
    },
    async fetchSuggest({state, commit}) {
        await window.axios.get('/products/suggest-list').then(res => {
            commit('setSuggestList', res.data);

            console.log(STORE_NAME + '.fetchSuggest', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchSuggest.error', error)
        });
    },
    async fetchOne({dispatch, commit}, id) {
        await window.axios.get('/products/' + id).then(res => {
            commit('setItem', res.data.product);
            commit('setOldProduct', res.data.oldProduct);
            console.log(res.data.product)

            console.log(STORE_NAME + '.fetchOne')
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOne.error', error)
        });
    },
    async fetchOneForFront({state, dispatch, commit}, id) {
        await window.axios.get('/products/' + id + '/detail').then(res => {
            commit('setItem', res.data.product);
            commit('setOldProduct', res.data.oldProduct);

            console.log(STORE_NAME + '.fetchOneForFront')
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOneForFront.error', error)
        });
    },
    async fetchClassMaster({state, commit}) {
        await window.axios.get('/products/class-master').then(res => {
            commit('setClassMaster', res.data);
            console.log(STORE_NAME + '.fetchClassMaster', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchClassMaster.error', error)
        });
    },
    async fetchProductClassValues({commit}) {
        await window.axios.get('/products/class/values').then(res => {
            commit('setProductClassValues', res.data);

            console.log(STORE_NAME + '.fetchProductClassValues', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchProductClassValues.error', error)
        });
    },
    async fetchProductClassTypes({commit}) {
        await window.axios.get('/products/class/types').then(res => {
            commit('setProductClassTypes', res.data);

            console.log(STORE_NAME + '.fetchProductClassTypes', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchProductClassTypes.error', error)
        });
    },
    async fetchByCategoryId({commit}, id) {
        await window.axios.get('/products/search/category/' + id).then(res => {
            commit('setItems', res.data.products);

            console.log(STORE_NAME + '.fetchByCategoryId', res)

            return true
        }).catch(error => {
            console.log(STORE_NAME + '.fetchByCategoryId.error', error)
        });
    },
    async fetchByProductName({commit}, productName) {
        await window.axios.get('/products/search/name/' + productName).then(res => {
            commit('setItems', res.data.products);

            console.log(STORE_NAME + '.fetchByProductName', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchByProductName.error', error)
        });
    },
    async fetchProductCode({commit}, { product_id: productId, value_id1: valueId1, value_id2: valueId2}) {
        return await window.axios.post('/products/search/product-code', { product_id: productId, value_id1: valueId1, value_id2: valueId2 }).then(res => {
            commit('setProductCode', res.data.product_code);

            console.log(STORE_NAME + '.fetchProductCode', res)

            console.log(res.data.product_code)
            return res.data.product_code
        }).catch(error => {
            console.log(STORE_NAME + '.fetchProductCode.error', error)
        });
    },
    async fetchNewProducts({commit}) {
        await window.axios.get('/new-products').then(res => {
            commit('setNewProducts', res.data);

            console.log(STORE_NAME + '.fetchNewProducts', res)

            return true
        }).catch(error => {
            console.log(STORE_NAME + '.fetchNewProducts.error', error)
        });
    },
    async importProducts({state, commit}, formData) {
        return await window.axios.post('/products/import', formData).then(res => {
            console.log(STORE_NAME + '.importProducts', res)

            return true
        }).catch(error => {
            console.log(STORE_NAME + '.importProducts.error', error)
        });
    },
    async fetchImportProductsHistories({state, commit}) {
        return await window.axios.get('/products/import/histories').then(res => {
            commit('setImportProductsHistories', res.data);
            console.log(STORE_NAME + '.importProducts', res)

            return true
        }).catch(error => {
            console.log(STORE_NAME + '.importProducts.error', error)
        });
    },
    resetState({commit}) {
        commit('resetState');
        console.log(STORE_NAME + '.resetState')
    },
    async getPrice(productCode) {
        return await actions.getDetail(productCode)
    },
    async getDetail(productCode) {
        const index = findIndex(state.details, { product_code: productCode })
        return state.detail[index]
    },
    async setItem({commit}, product) {
          commit('setItem', product)
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};