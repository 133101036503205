<template>
  <div>
    <div class="py-4">
      <div class="container">
        <div class="row">
          <div class="contents mt-3 mb-3 col-md-12 d-flex justify-content-center">
            <h1 class="text-white copy16" style="font-family: &quot;M PLUS 1p&quot;, sans-serif; font-weight: 700;">パネル買い取りについて</h1>
          </div>
          <div class="contents col-md-12 text-center d-flex justify-content-center">
            <h2 class="copy23 pb-4 mb-5 font-weight-bold">どこよりも早く、<br class="pc_hid">どこよりも高く</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-9 bg-light">
            <p class="mb-0 p-2 copy12">
              モバイルリペアセンターではガラスが割れたフロントパネルを買取しています。<br>
              国内の工場で、すぐに検査をし最短3日でお支払が可能です。<br>
              個人のご依頼も大歓迎。<br>
              割れパネルがありましたら、ぜひモバイルリペアセンターにお売りください。
            </p>
          </div>
        </div>
        <!-- contact button -->
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 col-lg-6 mt-5">
            <router-link :to="{ name: 'ContactPanelPurchase' }" class="btn_contact">お申し込み</router-link>
          </div>
        </div>

        <!-- title01 -->
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="d-flex justify-content-center text-center contents">
            <h3 class="copy14 font-weight-bold">買取の流れ</h3>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contents01 -->
            <div>
              <div class="mb-4">
                <h4 class="copy12"><span>01</span>お客様情報のご入力</h4>
                <p>お送りいただく前に、申込フォームを入力頂きお申し込みください。</p>
              </div>
              <div class="p-4 border rounded mb-5">
                <h5 class="pb-2 border-bottom border-danger">お問い合わせ番号に関して</h5>
                <p>※お問い合わせ番号が発送後でなければ発行されない場合、買取希望のパネルを発送後に申込フォームをご記入くださいますようお願いいたします。</p>
              </div>
            </div>

            <!-- contents02 -->
            <div>
              <div class="mb-4">
                <h4 class="copy12"><span>02</span>梱包・発送</h4>
                <p>端末をお送りの際は、<span class="marker">緩衝材で端末を保護し「ワレモノ扱い」</span>で<span class="text-red">元払い</span>にてお送りください。 <br>また、使用済みバッテリーも回収可能です。同梱の上発送ください。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  梱包方法
                </div>
                <div class="card-body">
                  <div class="justify-content-center row">
                    <div class="col-6 px-0 text-center">
                      <img class="img-fruid px-2" width="70%" src="images/contact/panel_konpou_ok.png"/>
                    </div>
                    <div class="col-6 px-0 text-center">
                      <img class="img-fruid px-2" width="70%" src="images/contact/panel_konpou_ng.png"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  送り先
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row">
                    <p class="mb-0 p-4">※送り先が変わりました※<br />
                      〒169-0075<br />
                      東京都新宿区高田馬場1丁目33-16 KRビル 1F
                      モバイルリペアセンター宛<br />
                      ※送料はお客様負担とさせて頂きます。
                    </p>
                  </div>
                </div>
              </div>
              <div class="p-4 border rounded mb-5">
                <h5 class="pb-2 border-bottom border-danger">発送前のご注意</h5>
                <p class="mb-0">※配送料は<span class="text-red">お客様ご負担</span>とさせていただいております。<span class="text-red">元払い</span>にてお送りください。<br>
                  着払いでお送りいただいた場合には買取価格より差し引かせて頂きますので、ご了承くださいませ。<br>
                  ※<span class="text-red">不良品は同梱しないよう</span>お願い致します。<br>
                  不良品は<span class="marker">渋谷事務所宛</span>にお送りお願い致します。</p>
              </div>
            </div>

            <!-- contents03 -->
            <div>
              <div class="mb-4">
                <h4 class="copy12"><span>03</span>検品・査定・入金</h4>
                <p>弊社にパネルが到着後、最短5日でご指定の口座にご入金が可能。 <br />※混雑状況やご依頼の枚数によって前後する場合がございます。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  ご新規様でNGパネルをお送りいただく場合
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row p-4">
                    <p class="mb-0 px-2">ご新規様でNGパネル（Apple純正品ではないパネル)を多数お送りいただく場合は発送前にご連絡下さい。<br>
                      振込手数料を下回る買取価格になる可能性が有る為、お断りさせていただく場合がございます。<br>
                      ※パネル廃棄のご相談承りしております。<br>
                      <br />
                      弊社の割れパネル買取に初めてお申し込み頂く場合、ご依頼無くパネルが到着した際は受取不可とさせて頂いております。<br>
                      また、受付対応が出来ないご内容に関しても着払いにてご返送させていただきます。<br>
                      何卒ご理解のほど宜しくお願い致します。
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- contact button -->
            <div class="row justify-content-center">
              <div class="col-11 col-md-9 col-lg-6 mt-3">
                <router-link :to="{ name: 'ContactPanelPurchase' }" class="btn_contact">お申し込み</router-link>
              </div>
            </div>

          </div>
        </div>

        <!-- title02 -->
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="text-center contents">
            <h3 class="copy14 font-weight-bold">対象パネル</h3>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contents01 -->
            <div>
              <div class="p-4 border rounded mb-3">
                <ul class="list-group copy14">
                  <li class="list-group-item border-0"><i class="far fa-check-square text-primary"></i> Apple純正品</li>
                  <li class="list-group-item border-0"><i class="far fa-check-square text-primary"></i> 液晶の画面表示に問題がないもの</li>
                  <li class="list-group-item border-0"><i class="far fa-check-square text-primary"></i> ケーブル部分に著しい損傷がないもの</li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- title03 -->
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="text-center contents">
            <h3 class="copy14 font-weight-bold">注意事項</h3>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contents01 -->
            <div>
              <div class="p-4 border rounded mb-3">
                <ul class="list-group copy10 font-weight-bold">
                  <li class="list-group-item border-0">※混雑状況やご依頼の枚数によって前後する場合がございます。</li>
                  <li class="list-group-item border-0">※ご入金は<span class="text-danger">振込手数料（176～275円）を差し引いた金額</span>となります。</li>
                  <li class="list-group-item border-0">※タッチ操作に問題がないパネルであっても、発光チェックの際、色を正常に表示できないものは<span class="text-danger">難有り品価格での買取</span>となります。</li>
                  <li class="list-group-item border-0">※表示・タッチ操作に異常があるもの、Apple純正品でないものは<span class="text-danger">NG品価格での買取</span>となります。</li>
                  <li class="list-group-item border-0">※検品の際、ガラスは剥がして液晶の検品を行います。</li>
                  <li class="list-group-item border-0">お送り頂いたパネルの返送対応は出来かねます。予めご了承下さいませ。</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- contact button -->
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 col-lg-6 mt-3 mb-5">
            <router-link :to="{ name: 'ContactPanelPurchase' }" class="btn_contact">お申し込み</router-link>
          </div>
        </div>

        <!-- title04 -->
        <div class="row d-flex justify-content-center">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contents01 -->
            <div>
              <div class="p-4 border rounded mb-3 bg-lightgray">
                <h5 class="font-weight-bold">
                  梱包方法
                </h5>
                <p class="mb-4">
                  梱包には衝撃緩衝材を 使用してください。<br>割れパネルの機種・枚数が識別できるよう梱包してください。<br>また、不良品同梱の際は別途内容記載をお願い致します。<br>
                  ご協力よろしくお願い致します。
                </p>
                <h5 class="font-weight-bold">
                  送料について
                </h5>
                <p class="mb-4">
                  配送料はお客様ご負担とさせていただいております。元払いにてお送りください。<br>
                  ※着払いでお送りいただいた場合には買取価格より差し引かせて頂きますので、ご了承くださいませ。
                </p>
                <h5 class="font-weight-bold">
                  割れパネルお送り先
                </h5>
                <p class="mb-0">
                  〒169-0075<br>
                  東京都新宿区高田馬場1-33−16 KRビル 1F モバイルリペアセンター宛<br><br>
                  ※送料はお客様負担とさせて頂きます。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center"
                     style="">
                  <i class="fas fa-thumbs-up fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">技術に自信があります</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">基板修理復旧率９０％</span><br><br>
                    <span class="font-weight-bold">iPhone修理復旧率９７％</span><br><br>
                    過去10数年の修理経験がある当社にまずはお任せください<br><br>
                  </p>
                </div>
              </div>
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <i class="fas fa-exclamation-triangle fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">
                    修理ミス</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">
                      ・バッテリー交換<br />
                      ・タッチパネル交換<br />
                      ・水没<br />
                      ・リンゴループ<br />
                      ・データ吸い出し<br />
                      ・基板修理<br />
                      ・ゲーム機の修理<br />
                    </span>
                    <br />

                    どのような修理でもまずはお問い合わせください。<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4 mt-3">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="col-md-12 pb-2 text-center">
          <p class="font-weight-bold">
            即日修理・即日郵送をモットーとしております。お気軽にご相談ください。
          </p>
          <!-- contact button -->
          <div class="row justify-content-center">
            <div class="col-11 col-md-9 col-lg-6 mt-3">
              <router-link :to="{ name: 'Contact' }" class="btn_inquiry">お問い合わせはこちらから</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>

<script>
export default {
  name: 'ContactPanelPurchaseDetail',
  async beforeRouteEnter(to, from, next) {
    return next();
  },
}
</script>