<template>
  <div class="col-md-12 p-md-4">
    <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :on-cancel="this.onCancel"
        color="#007bff"></loading>
    <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="form-group form-row py-2 border-top border-light mb-0">
          <div class="pt-1 col-md-4">
            <label>法人名</label>
          </div>
          <div class="col-md-8 mb-0">
            <Input type="text" class="mb-2 mb-md-0" v-model="customerDelivery.company_name" name="法人名" rules=""></Input>
          </div>
        </div>
        <div class="form-group form-row py-2 border-top border-light mb-0">
          <div class="pt-1 col-md-4">
            <label>店舗名</label>
          </div>
          <div class="col-md-8 mb-0">
            <Input type="text" class="mb-2 mb-md-0" v-model="customerDelivery.company_division" name="店舗名" rules=""></Input>
          </div>
        </div>
        <div class="form-row border-top py-2 border-light">
          <div class="pt-1 col-md-4">
            <label>お名前</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <Input type="text" class="mb-2 mb-md-0" v-model="customerDelivery.name" name="お名前" rules="required"></Input>
          </div>
        </div>
        <div class="form-row border-top py-2 border-light">
          <div class="pt-1 col-md-4">
            <label>お名前（カナ）</label>
          </div>
          <div class="form-group col-md-8 mb-0">
            <Input type="text" class="mb-2 mb-md-0" v-model="customerDelivery.name_kana" name="フリガナ" rules="required|kana"></Input>
          </div>
        </div>

        <div class="form-row py-2 border-top border-light">
          <div class="pt-1 col-md-4">
            <label>ご住所</label>
          </div>
          <div class="form-group mb-0 col-md-8">
            <div class="mb-2" style="">
              <Input type="text" class="mb-2 mb-md-0" prepend="〒" v-model="customerDelivery.zip" name="郵便番号" rules="required"></Input>
              <button @click.prevent="findAddress" class="btn btn-primary ml-2 rounded-0 float-right">住所検索</button>
              <br>
            </div>
            <div class="px-0 col-lg-12">
              <div class="form-group">
                <ValidationProvider
                    name="都道府県"
                    mode="aggressive"
                    rules="required|min:1"
                    v-slot="validationContext"
                >
                  <b-form-select
                      class="w-50 rounded-0"
                      v-model="customerDelivery.pref"
                      :options="prefs"
                      value-field="value"
                      text-field="description"
                      :state="getValidationState(validationContext)"
                  >
                    <template #first>
                      <b-form-select-option value="">都道府県を選択</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback class="mb-3">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group px-0">
              <Input type="text" class="mb-2 mb-md-0" v-model="customerDelivery.city" name="市区町村名（例：京都市中京区）" rules="required"></Input>
            </div>
            <div class="form-group px-0">
              <Input type="text" class="rounded-0 mb-2" v-model="customerDelivery.address1" name="番地・ビル名（例：○○町○丁目○-○）" rules="required"></Input>
            </div>
          </div>
        </div>
        <div class="form-group form-row py-2 border-light mb-0 border-top" style="">
          <div class="pt-1 col-md-4">
            <label>電話番号</label>
          </div>
          <div class="col-md-8 mb-0">
            <Input type="number" class="rounded-0" placeholder="00011112222" name="電話番号" v-model="customerDelivery.phone" rules="required|numeric|min:10|max:11"></Input>
          </div>
        </div>
        <div class="py-3 text-center" style="">
          <p v-show="invalid" class="fade show alert alert-danger rounded-0 mb-3">
            未入力の項目があるか、内容が適切でない項目があります。
          </p>
          <button :disabled="invalid" type="submit" class="btn btn-secondary rounded-0 py-2 px-4">
            お届け先を
            <template v-if="customerDelivery.id > 0">
              更新する
            </template>
            <template v-else>
              登録する
            </template>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import store from "@/store";
import Input from "@/components/Form/Input"
import Toast from "@/components/Toast"

export default {
  name: 'MyPage',
  components: { Input, Toast },
  data() {
    return {
      spinner: false,
      validationFailed: false,
      active: '',
      spinnerFindAddress: false,
      isLoading: false,
    }
  },
  props: {
    customerCode: {
      type: String,
      required: true
    },
  },
  methods: {
    ...mapActions('myPage', [
      'fetchCustomer',
      'fetchZip',
      'storeDelivery',
      'updateDelivery',
    ]),
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    async onSubmit() {
      this.isLoading = true
      this.validationFailed = false

      this.customerDelivery.customer_code = this.customerCode

      if(this.customerDelivery.id > 0) {
        this.updateDelivery(this.customerDelivery).then(res => {
          if (res === true) {
            this.$refs.toast.makeToast('success', 'お届け先', '更新しました')
          }
        });
      } else {
        this.storeDelivery(this.customerDelivery).then(res => {
          if (res === true) {
            this.$refs.toast.makeToast('success', 'お届け先', '登録しました')
          }
        });
      }

      // 後処理
      this.$emit('addDeliveryComplete', true)
      this.$bvModal.hide('add-delivery-modal')
      this.isLoading = false
    },
    async findAddress() {
      this.spinnerFindAddress = true
      await this.fetchZip(this.customerDelivery.zip);

      const prefName = this.customerDelivery.pref_name;

      const targetPref = Object.values(this.enums['pref']).filter((value, index) => {
        if(value.description === prefName) {
          return value.value
        }
      })

      console.log(targetPref)

      this.customerDelivery.pref = targetPref[0].value
      this.spinnerFindAddress = false
    },
  },
  computed: {
    ...mapState({
      prefs: state => state.enums.items['pref'],
    }),
    ...mapGetters('myPage', [
      'customerDelivery',
    ]),
    ...mapGetters('enums', [
       'enums',
    ]),
  },
  mounted: async function () {
    await store.commit('setLoading', true)

    await store.dispatch('enums/fetch');

    await store.commit('setLoading', false)
  },
}
</script>