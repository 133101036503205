<template>
  <div>
    <div class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="mb-0 copy08"><a href="/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1"></i>会員登録手続き完了</p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 border-bottom" style="">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="top_ttl mb-0 copy22 text">Registration Complete<span class="copy14 ml-0 ml-md-2 mt-2 mt-md-0 text">会員登録手続き完了</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="py-5" style="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="mb-5">
              <h2 class="copy14 pb-3 border-bottom">会員登録手続きが完了しました。</h2>
              <p class="mt-3 mb-4" style="">会員登録いただき、誠にありがとうございます。<br>
                これから当サイト運営スタッフによる「<span class="font-weight-bold">会員登録の承認作業</span>」を実施させていただきます。<br>
                <br>
                承認作業が終わると、ログイン / ご注文いただけるようになります。<br>
                また、承認作業の完了までは通常２～３営業日ほど頂戴しております。<br>
                <br>
                承認作業が完了次第、運営スタッフより会員登録されたメールアドレス宛にご連絡致します。 <br>
                大変恐れいりますが、今しばらくお待ち下さい。
              </p>
              <div class="">
                <a class="btn btn-outline-primary rounded-0 px-3 btn-sm" href="/">トップページに戻る</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
  name: 'Order',
  data() {
    return {
      spinner: false,
      currentStep: 0,
    }
  },
  computed: {
    ...mapGetters('myPage', [
      'customer',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  async beforeRouteEnter(to, from, next) {

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
