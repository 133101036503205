import Vue from "vue";

const state = {
    item: {
        id: '',
        email: '',
        code: '',
        expired_at: '',
    }
};

const initialState = {
    item: {
        id: '',
        email: '',
        code: '',
        expired_at: '',
    },
};

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    emailVerification: state => {
        return state.item;
    }
};

const actions = {
    async delete({state, commit}, item) {
        const index = state.items.indexOf(item);

        return await window.axios.post('/email-verification/delete', item)
            .then(res => {
                commit('delete', index);
                return true;
            }).catch(error => {
                return error;
            });
    },
    async register({state, commit}, item) {
        return await window.axios.post('/email-verification/register', item)
            .then(res => {
                commit('setItem', res.data);
                return res.data;
            }).catch(error => {
                return error;
            });
    },
    async reSend({state, commit}, item) {
        return await window.axios.post('/email-verification/re-send', item)
            .then(res => {
                commit('setItem', res.data);
                return res.data;
            }).catch(error => {
                return error;
            });
    },
    async validateCode({state, commit}, item) {
        return await window.axios.post('/email-verification/validate-code', item)
            .then(res => {
                commit('setItem', res.data);

                return true;
            }).catch(error => {
                return error;
            });
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};