import Vue from "vue";
import customers from './customers'
import axios from "axios";

const STORE_NAME = 'myPage'

const state = {
    ratio: 0,
    items: [],
    customer: {
        detail: {
            customer_code: '',
            parent_customer_code: '',
            grade: 1,
            payment_timing: 1,
            ratio: 0,
            is_vip: false,
            is_trained: false,
            is_leaders_fee2: false,
            is_supervisor: false,
            is_friendship_kenbikai: false,
            is_fixed_bonus: false,
            is_ifa: false,
            has_leaders_fee_0_or_more: false,
            has_leaders_fee_1_or_more: false,
            has_pc_bonus_0_or_more: false,
            has_pc_bonus_1_or_more: false,
            has_masters_club_bonus_0_or_more: false,
            has_masters_club_bonus_1_or_more: false,
            has_grand_masters_club_bonus_0_or_more: false,
            has_grand_masters_club_bonus_1_or_more: false,
            applied_from: '',
            applied_to: '',
        },
        id: '',
        is_enabled: true,
        is_enable_credit_receivable: false,
        customer_code: '',
        first_name: '',
        last_name: '',
        name: '',
        first_name_kana: '',
        last_name_kana: '',
        name_kana: '',
        pca_customer_name1: '',
        pca_customer_name2: '',
        pca_index_kana: '',
        email: '',
        email_verified_at: '',
        password: '',
        password_confirm: '',
        login_failed: '',
        logged_in_at: '',
        blocked_at: '',
        zip: '',
        pref: 1,
        city: '',
        address1: '',
        address2: '',
        phone: '',
        fax: '',
        cell_phone: '',
        company_zip: '',
        company_pref: 1,
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_name: '',
        company_name_kana: '',
        company_division: '',
        company_position: '',
        company_title: '',
        company_staff_title: '',
        company_email: '',
        company_phone: '',
        company_fax: '',
        company_cell_phone: '',
        pca_customer_division1: 0,
        pca_customer_division2: 0,
        pca_customer_division3: 0,
        pca_customer_division4: 0,
        pca_customer_division5: 0,
        pca_main_staff_code: 0,
        pca_applied_sales_price_number: 0,
        pca_ratio: 100.0,
        pca_managed_results: 0,
        pca_print_sales_date: 0,
        pca_tax_rule: 0,
        pca_sales_amount_rule: 0,
        pca_company_shop_code: '',
        pca_type_code: '',
        pca_voucher_division: '',
        pca_business_partner_code: '',
        pca_statement_of_delivery_paper: 1,
        pca_statement_of_delivery_company: 0,
        pca_billing_code: 0,
        pca_settlement_day: 99,
        pca_collect_type1: 0,
        pca_type_boundary_value: 0,
        pca_collect_type2: 0,
        pca_collect_date: 0,
        pca_collect_rule: 0,
        pca_credit_limit: 0,
        pca_opening_balance: 0,
        pca_fraction_amount: 0,
        pca_tax_amount: 0,
        pca_tax_print_rule: 2,
        pca_print_paper_type: 1,
        pca_print_company_name: 0,
        pca_print_company_type: 0,
        pca_company_code1: '',
        pca_company_code2: '',
        pca_bank_account_code: '',
        pca_print_invoice_type: 1,
        pca_holiday_type: 0,
        pca_holiday_calender_code: '',
        pca_comment: '',
        pca_company_number: '',
        is_tester: false,
    },
    customerDelivery: {
        id: null,
        is_default: false,
        customer_code: '',
        name: '',
        name_kana: '',
        company_name: '',
        company_division: '',
        zip: '',
        pref: 1,
        pref_name: '',
        city: '',
        address1: '',
        address2: '',
        phone: '',
        cell_phone: '',
        created_at: '',
        updated_at: '',
        deleted_at: '',
    },
    orders: [],
    errors: [],
    registeredCards: [],
    cardEditUrl: null,
};

const initialState = {
    ratio: 0,
    items: [],
    customer: {
        detail: {
            customer_code: '',
            parent_customer_code: '',
            grade: 1,
            payment_timing: 1,
            ratio: 0,
            is_vip: false,
            is_trained: false,
            is_leaders_fee2: false,
            is_supervisor: false,
            is_friendship_kenbikai: false,
            is_fixed_bonus: false,
            is_ifa: false,
            has_leaders_fee_0_or_more: false,
            has_leaders_fee_1_or_more: false,
            has_pc_bonus_0_or_more: false,
            has_pc_bonus_1_or_more: false,
            has_masters_club_bonus_0_or_more: false,
            has_masters_club_bonus_1_or_more: false,
            has_grand_masters_club_bonus_0_or_more: false,
            has_grand_masters_club_bonus_1_or_more: false,
            applied_from: '',
            applied_to: '',
        },
        // customer
        id: '',
        is_enabled: true,
        is_enable_credit_receivable: false,
        customer_code: '',
        first_name: '',
        last_name: '',
        name: '',
        first_name_kana: '',
        last_name_kana: '',
        name_kana: '',
        pca_customer_name1: '',
        pca_customer_name2: '',
        pca_index_kana: '',
        email: '',
        email_verified_at: '',
        password: '',
        password_confirm: '',
        login_failed: '',
        logged_in_at: '',
        blocked_at: '',
        zip: '',
        pref: 1,
        city: '',
        address1: '',
        address2: '',
        phone: '',
        fax: '',
        cell_phone: '',
        company_zip: '',
        company_pref: 1,
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_name: '',
        company_name_kana: '',
        company_division: '',
        company_position: '',
        company_title: '',
        company_staff_title: '',
        company_email: '',
        company_phone: '',
        company_fax: '',
        company_cell_phone: '',
        pca_customer_division1: 0,
        pca_customer_division2: 0,
        pca_customer_division3: 0,
        pca_customer_division4: 0,
        pca_customer_division5: 0,
        pca_main_staff_code: 0,
        pca_applied_sales_price_number: 0,
        pca_ratio: 100.0,
        pca_managed_results: 0,
        pca_print_sales_date: 0,
        pca_tax_rule: 0,
        pca_sales_amount_rule: 0,
        pca_company_shop_code: '',
        pca_type_code: '',
        pca_voucher_division: '',
        pca_business_partner_code: '',
        pca_statement_of_delivery_paper: 1,
        pca_statement_of_delivery_company: 0,
        pca_billing_code: 0,
        pca_settlement_day: 99,
        pca_collect_type1: 0,
        pca_type_boundary_value: 0,
        pca_collect_type2: 0,
        pca_collect_date: 0,
        pca_collect_rule: 0,
        pca_credit_limit: 0,
        pca_opening_balance: 0,
        pca_fraction_amount: 0,
        pca_tax_amount: 0,
        pca_tax_print_rule: 2,
        pca_print_paper_type: 1,
        pca_print_company_name: 0,
        pca_print_company_type: 0,
        pca_company_code1: '',
        pca_company_code2: '',
        pca_bank_account_code: '',
        pca_print_invoice_type: 1,
        pca_holiday_type: 0,
        pca_holiday_calender_code: '',
        pca_comment: '',
        pca_company_number: '',
        is_tester: false,
    },
    customerDelivery: {
        id: null,
        is_default: false,
        customer_code: '',
        name: '',
        name_kana: '',
        company_name: '',
        company_division: '',
        zip: '',
        pref: 1,
        pref_name: '',
        city: '',
        address1: '',
        address2: '',
        phone: '',
        cell_phone: '',
        created_at: '',
        updated_at: '',
        deleted_at: '',
    },
    orders: [],
    errors: [],
    registeredCards: [],
    cardEditUrl: null,
};

const mutations = {
    setRatio(state, ratio) {
        state.ratio = ratio
    },
    setGrade(state, grade) {
        state.grade = grade
    },
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setCustomer(state, customer) {
        state.customer = customer
    },
    setCustomerDelivery(state, customerDelivery) {
        state.customerDelivery = customerDelivery
    },
    unsetCustomerDelivery(state) {
        state.customerDelivery = {
            id: null,
            is_default: false,
            customer_code: '',
            name: '',
            name_kana: '',
            company_name: '',
            company_division: '',
            zip: '',
            pref: 1,
            pref_name: '',
            city: '',
            address1: '',
            address2: '',
            phone: '',
            cell_phone: '',
            created_at: '',
            updated_at: '',
            deleted_at: '',
        };
    },
    setAddress(state, item) {
        state.customerDelivery.pref_name = item.pref
        state.customerDelivery.city = item.city
        state.customerDelivery.address1 = item.town
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrderStatus(state, { orderId, statusId }) {
        let order = state.orders.find((row, index) => row.id == orderId)
        console.log("ORDER", order)
        order.status = statusId
    },
    setCardEditUrl(state, url) {
        state.cardEditUrl = url
    },
    setRegisteredCards(state, cards) {
        if(cards) {
            for(const key in cards) {
                console.log(cards[key])
                let expireYear = parseInt(cards[key].expire.substring(0, 2)) + 2000 + '年';
                let expireMonth = cards[key].expire.substring(2, 4) + '月';
                cards[key].expire = expireYear + expireMonth
            }
        }
        state.registeredCards = cards
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    updateCustomer(state, {customer, newCustomer}) {
        Object.assign(customer, newCustomer)
    },
    store(state, item) {
        state.item = item;
    },
    storeCustomer(state, customer) {
        state.customer = customer;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = {}
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    orders(state) {
        return state.orders;
    },
    order(state) {
        return state.item;
    },
    customer(state) {
        return state.customer;
    },
    customerDelivery(state) {
        return state.customerDelivery;
    },
    registeredCards(state) {
        return state.registeredCards
    },
    cardEditUrl(state) {
        return state.cardEditUrl;
    },
    qrUrl(state) {
        return process.env.VUE_APP_API_URL + '/images/qr-code/' + state.customerCode + '.png'
    },
    ratio(state) {
        if(state.ratio === 0) {
            return 1
        } else {
            return state.ratio
        }
    },
    grade(state) {
        if(state.grade === 0) {
            return 0
        } else {
            return state.grade
        }
    }
};

const actions = {
    async fetchCustomer({commit}) {
        await window.axios.get('/my-page/customer').then(res => {
            commit('setCustomer', res.data);
            console.log(STORE_NAME + '.fetchCustomer', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchCustomer.error', error)
        });
    },
    async fetchRatio({commit}) {
        await window.axios.get('/my-page/customer/ratio').then(res => {
            window.localStorage.setItem('ratio', res.data.ratio);

            commit('setRatio', res.data.ratio);
            console.log(STORE_NAME + '.fetchRatio', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchRatio.error', error)
        });
    },
    async fetchGrade({commit}) {
        await window.axios.get('/my-page/customer/grade').then(res => {
            window.localStorage.setItem('grade', res.data.grade);

            commit('setGrade', res.data.grade);
            console.log(STORE_NAME + '.fetchGrade', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchGrade.error', error)
        });
    },
    async fetchOrders({commit}) {
        await window.axios.get('/my-page/orders').then(res => {
            commit('setOrders', res.data);
            console.log(STORE_NAME + '.fetchOrders', res)
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOrders.error', error)
        });
    },
    async fetchOrder({commit}, id) {
        await window.axios.get('/my-page/order/' + id).then(res => {
            commit('setItem', res.data);
            console.log(STORE_NAME + '.fetchOrder')
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOrder.error', error)
        });
    },
    async fetchZip({commit}, zip) {
        await axios.get('https://api.zipaddress.net/?zipcode=' + zip).then(res => {
            commit('setAddress', res.data.data);
            commit('unsetErrors')
            console.log('customers.fetchZip', res.data.data)

            return true
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchZip.error', error)

            return false
        });
    },
    async store({commit}, item) {
        return await window.axios.post('/my-page/store', item)
            .then(res => {
                console.log(STORE_NAME + '.store', res, item)
            }).catch(error => {
                console.log(STORE_NAME + '.store.error', error, item)
            });
    },
    async profileUpdate({state, commit}, newItem) {
        return await window.axios.post('/my-page/profile/update', newItem)
            .then(res => {
                commit('setItem', res.data)
                console.log(STORE_NAME + '.profileUpdate', res, newItem)

                return true
            }).catch(error => {
                console.log(STORE_NAME + '.profileUpdate.error', error)
            });
    },
    async passwordUpdate({state, commit}, newItem) {
        return await window.axios.post('/my-page/password/update', newItem)
            .then(res => {
                commit('setItem', res.data)
                console.log(STORE_NAME + '.passwordUpdate', res, newItem)

                return true
            }).catch(error => {
                console.log(STORE_NAME + '.passwordUpdate.error', error)
            });
    },
    async passwordChange({state, commit}, user) {
        return await window.axios.post('/my-page/password-change', user)
            .then(res => {

                return true
            }).catch(error => {
                console.log(STORE_NAME + '.passwordUpdate.error', error)
            });
    },
    async fetchRegisteredCards({commit}) {
        return await window.axios.get('/payment/registered-cards').then(res => {
            commit('setRegisteredCards', res.data);
            console.log(STORE_NAME + '.fetchRegisteredCards')

            return res.data;
        }).catch(error => {
            console.log(STORE_NAME + '.fetchRegisteredCards.error', error)
        });
    },
    async fetchCardEditUrl({commit}) {
        return await window.axios.get('/payment/card-edit/from/my-page').then(res => {
            commit('setCardEditUrl', res.data.url)
            console.log(STORE_NAME + '.fetchCardEditUrl', res.data)

            return true
        }).catch(error => {
            console.log(STORE_NAME + '.fetchCardEditUrl.error', error)
            return false
        });
    },
    async storeDelivery({state, commit}, item) {
        return await window.axios.post('/my-page/customer-deliveries/store', item)
            .then(res => {
                commit('unsetErrors')
                commit('unsetCustomerDelivery')
                console.log(STORE_NAME + '.store', res, item)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log(STORE_NAME + '.store.error', error, item)
            });
    },
    async updateDelivery({commit}, item) {
        return await window.axios.post('/my-page/customer-deliveries/update', item)
            .then(res => {
                commit('unsetErrors')
                commit('unsetCustomerDelivery')
                console.log(STORE_NAME + '.store', res, item)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log(STORE_NAME + '.store.error', error, item)
            });
    },
    async deleteDelivery({commit}, item) {
        return await window.axios.post('/my-page/customer-deliveries/delete', item)
            .then(res => {
                commit('unsetErrors')
                console.log(STORE_NAME + '.store', res, item)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log(STORE_NAME + '.store.error', error, item)
            });
    },
    async orderCancel({state, commit}, orderId) {
        return await window.axios.get('/my-page/order-cancel/' + orderId)
            .then(res => {

                commit('setOrderStatus', { orderId: orderId, statusId: 5 })

                return true
            }).catch(error => {
                console.log('orders.orderCancel: failed', error)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log(STORE_NAME + '.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};