<template>
  <div class="form-group form-row">
    <div class="col-md-3">{{ label }}</div>
    <div class="col-md-9">
      <select class="form-control is-valid" v-model="order.payment_status">
        <option>選択してください</option>

      </select>
    </div>
  </div>
</template>

<script>
// TODO: 実装中
export default {
  name: 'ASelect',
  props: {
    label: {
      type: String,
      required: true,
    },
    defaultOptionLabel: {
      type: String,
      default: '選択してください'
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>