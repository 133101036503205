import Vue from "vue";

const state = {
    items: [],
    search: {
        per_page: 25,
        page: 1,
        grades: [],
        customer_code: '',
        name: '',
        phone: '',
        pref: '',
        address: '',
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
};

const initialState = {
    items: [],
    search: {
        per_page: 25,
        page: 1,
        customer_code: '',
        name: '',
        email: '',
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
};

const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setSearch(state, search) {
        state.search = search
    },
    setPagination(state, items) {
        state.pagination = items
    },
    resetState() {
        for (let f in state) {
            console.log(f)
            Vue.set(state, f, initialState[f]);
        }
    },
    resetSearchState() {
        state.search = {
            per_page: 25,
            page: 1,
            grades: [],
            customer_code: '',
            name: '',
            phone: '',
            pref: '',
            address: '',
        }
    },
};


const getters = {
    histories(state) {
        return state.items;
    },
    pagination(state) {
        return state.pagination;
    },
    search(state) {
        return state.search;
    },
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/customer-login-histories', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            console.log('customer-login-histories.fetch', res)
        }).catch(error => {
            console.log('customer-login-histories.fetch.error', error)
        });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('customer-login-histories.resetState')
    },
    resetSearchState({commit}) {
        commit('resetSearchState')
        console.log('customer-login-histories.resetSearchState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};