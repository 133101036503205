import Vue from 'vue'
import Vuex from 'vuex'
import { version } from '../../package.json'

import axios from 'axios'
import VueAxios from 'vue-axios'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";

import auth from './modules/auth'
import enums from './modules/enums'
import users from './modules/users'

import payments from './modules/payments'
import categories from './modules/categories'
import series from './modules/series'
import news from './modules/news'
import pages from './modules/pages'
import campaigns from './modules/campaigns'
import campaignProducts from './modules/campaignProducts'
import campaignPresents from './modules/campaignPresents'

import products from './modules/products'
import relativeProducts from './modules/relativeProducts'
import newProducts from './modules/newProducts'
import newItems from './modules/newItems'
import pickupProducts from './modules/pickupProducts'
import customers from './modules/customers'
import customerLoginHistories from './modules/customerLoginHistories'
import orders from './modules/orders'
import aggregateHistories from './modules/aggregateHistories'
import islandFees from './modules/islandFees'
import shippingFees from './modules/shippingFees'
import configurations from './modules/configurations'

let ls = new SecureLS();

Vue.use(Vuex)
Vue.use(VueAxios, axios);


const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    isLoading: false,
    fullPage: true,
    version: version,
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    isFullPage(state) {
        return state.fullPage;
    },
    getVersion(state) {
        return version
    },
    hasNewVersion(state, clientSideVersion) {
        return (clientSideVersion === version)
    }
}

const actions = {}

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading
    },
    setFullPage(state, fullPage) {
        state.fullPage = fullPage
    },
    setVersion(state, version) {
        state.version = version
    },
}

if (module.hot) {
    module.hot.accept([
        './modules/auth',
        './modules/enums',
        './modules/users',
        './modules/payments',
        './modules/categories',
        './modules/series',
        './modules/news',
        './modules/pages',
        './modules/campaigns',
        './modules/campaignProducts',
        './modules/campaignPresents',
        './modules/products',
        './modules/relativeProducts',
        './modules/newProducts',
        './modules/newItems',
        './modules/pickupProducts',
        './modules/customers',
        './modules/customerLoginHistories',
        './modules/orders',
        './modules/aggregateHistories',
        './modules/shippingFees',
        './modules/islandFees',
        './modules/configurations',
    ], () => {
        store.hotUpdate({
            auth: require('./modules/auth'),
            enums: require('./modules/enums'),
            users: require('./modules/users'),
            payments: require('./modules/payments'),
            categories: require('./modules/categories'),
            series: require('./modules/series'),
            news: require('./modules/news'),
            pages: require('./modules/pages'),
            campaigns: require('./modules/campaigns'),
            campaignProducts: require('./modules/campaignProducts'),
            campaignPresents: require('./modules/campaignPresents'),
            products: require('./modules/products'),
            relativeProducts: require('./modules/relativeProducts'),
            newProducts: require('./modules/newProducts'),
            newItems: require('./modules/newItems'),
            pickupProducts: require('./modules/pickupProducts'),
            customers: require('./modules/customers'),
            customerLoginHistories: require('./modules/customerLoginHistories'),
            orders: require('./modules/orders'),
            aggregateHistories: require('./modules/aggregateHistories'),
            shippingFees: require('./modules/shippingFees'),
            islandFees: require('./modules/islandFees'),
            configurations: require('./modules/configurations'),
        })
    })
}


/**
 * Storage functions
 */
import router from '@/router'
const getItem = function (key) {
    try {
        ls.get(key);
    } catch(e) {
        console.log("LS error detected: ", e)
        window.localStorage.clear();
        console.log("Local storage has been cleared.")
        router.push('/login')
    }
}

const setItem = function (key, value) {
    try {
        ls.set(key, value);
    } catch(e) {
        console.log("LS error detected: ", e)
        window.localStorage.clear();
        console.log("Local storage has been cleared.")
        router.push('/login')
    }
}

const removeItem = function (key, value) {
    try {
        ls.remove(key);
    } catch(e) {
        console.log("LS error detected: ", e)
        window.localStorage.clear();
        console.log("Local storage has been cleared.")
        router.push('/login')
    }
}

/**
 * /Storage functions
 */


export default new Vuex.Store({
    modules: {
        auth: auth,
        enums: enums,
        users: users,
        payments: payments,
        categories: categories,
        series: series,
        news: news,
        pages: pages,
        campaigns: campaigns,
        campaignProducts: campaignProducts,
        campaignPresents: campaignPresents,
        products: products,
        relativeProducts: relativeProducts,
        newProducts: newProducts,
        newItems: newItems,
        pickupProducts: pickupProducts,
        customers: customers,
        customerLoginHistories: customerLoginHistories,
        orders: orders,
        aggregateHistories: aggregateHistories,
        shippingFees: shippingFees,
        islandFees: islandFees,
        configurations: configurations,
    },
    state,
    getters,
    actions,
    mutations,
    // @see https://www.npmjs.com/package/vuex-extensions
    mixins: {
        mutations: {
            changeState: function (state, changed) {
                Object.entries(changed)
                    .forEach(([name, value]) => {
                        state[name] = value
                    })
            }
        }
    },
    plugins: [
        createPersistedState({
            key: process.env.VUE_APP_NAME,
            paths: ['auth'],
            storage: {
                getItem,
                setItem,
                removeItem,
            },
        }),
    ],
})
