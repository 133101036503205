import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import './validation';
import "regenerator-runtime/runtime"

// Vue Loaders
import VueLoaders from 'vue-loaders'
import 'vue-loaders/dist/vue-loaders.css';
Vue.use(VueLoaders);

// Font Awesome5
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Global Components
import Toast from '@/components/Toast'
Vue.component('Toast', Toast)

import AddCart from '@/components/Form/AddCart'
Vue.component('AddCart', AddCart)

import TimerLink from '@/components/Timer/Link'
Vue.component('TimerLink', TimerLink)

import TimerBlock from '@/components/Timer/Block'
Vue.component('TimerBlock', TimerBlock)

/**
 * Versionが更新された場合、ローカルストレージをクリアする
 */
const { version } = require('../package.json');

console.log("CURRENT_VERSION", window.localStorage.getItem('front_app_version'))
console.log("NEXT_VERSION", version)
if (!window.localStorage.getItem('front_app_version')) {
  window.localStorage.setItem('front_app_version', version);
}
setTimeout(() => {
  if (window.localStorage.getItem('front_app_version') !== version) {
    // Refresh the page and clear the front_app_version, so it can be set again with the latest version
    window.localStorage.removeItem('front_app_version');

    // Should log null
    console.log(window.localStorage.getItem('front_app_version'))

    // Fire a refresh
    window.location.reload();
  }
}, 3000)

// Base Components (webpack)
// 指定したディレクトリに存在するコンポーネントを自動で呼び出し、Vueのインスタンスに登録する
const requireComponent = require.context(
    // コンポーネントフォルダの相対パス
    './components/NextArcadia',
    // サブフォルダ内を調べるかどうか
    false,
    // 基底コンポーネントのファイル名に一致させるのに使う正規表現
    /NA[A-Z]\w+\.(vue|js)$/
)

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
requireComponent.keys().forEach(fileName => {
  // コンポーネント設定を取得する
  const componentConfig = requireComponent(fileName)

  // コンポーネント名をパスカルケース (PascalCase) で取得する
  const componentName = upperFirst(
      camelCase(
          // フォルダの深さに関わらずファイル名を取得する
          fileName
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')
      )
  )

  // コンポーネントをグローバル登録する
  Vue.component(
      componentName,
      // `export default` を使ってコンポーネントがエクスポートされた場合に存在する
      // `.default` でコンポーネントオプションを期待していて
      // 存在しない場合にはモジュールのルートにフォールバックします。
      componentConfig.default || componentConfig
  )
})

// Form
const formComponent = require.context(
    // コンポーネントフォルダの相対パス
    './components/Form',
    // サブフォルダ内を調べるかどうか
    false,
    // 基底コンポーネントのファイル名に一致させるのに使う正規表現
    /NA[A-Z]\w+\.(vue|js)$/
)
formComponent.keys().forEach(fileName => {
  // コンポーネント設定を取得する
  const componentConfig = requireComponent(fileName)

  // コンポーネント名をパスカルケース (PascalCase) で取得する
  const componentName = upperFirst(
      camelCase(
          // フォルダの深さに関わらずファイル名を取得する
          fileName
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')
      )
  )

  // コンポーネントをグローバル登録する
  Vue.component(
      componentName,
      // `export default` を使ってコンポーネントがエクスポートされた場合に存在する
      // `.default` でコンポーネントオプションを期待していて
      // 存在しない場合にはモジュールのルートにフォールバックします。
      componentConfig.default || componentConfig
  )
})

// Global Filters
import * as filters from './js/filters/filters.js';

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// Loading Overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading', Loading)
Vue.prototype.isLoading = false
Vue.prototype.fullPage = true
Vue.prototype.onCancel = function() {
  console.log('User cancelled the loader.')
}

// VeeValidate & Bootstrap Vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
  localize
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";
import {  } from 'vee-validate';

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("ja", ja);

// custom rules
const validateKana = {
  params: ['str'],
  message: '{_field_}は全角カタカナで入力してください',
  validate(value, {str}) {
    // eslint-disable-next-line no-irregular-whitespace
    return value.match(/^[ァ-ヶー　 ]+$/);
  }
};
extend('kana', validateKana);


extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'パスワードとパスワード（確認用）が一致しません'
});

// Install VeeValidate components globally
setInteractionMode('lazy');

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Lazy
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/images/error_50.jpg',
  loading: '/images/lazy-spinner.png',
  attempt: 1,
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  listenEvents: [ 'scroll' ]
})

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

window.axios = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
})

const token = window.localStorage.getItem('front_api_token')
if(token) {
  window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

window.axios.interceptors.request.use(async function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// 401が帰ってきた場合、ログイン画面に転送
window.axios.interceptors.response.use(async function (response) {
  return response
}, async function (error) {
  if ('response' in error && 401 === error.response.status) {
    await router.push('/login')
  } else {

    console.log("axios.interceptors.response.use: [Reject] ", error)
    return Promise.reject(error);
  }
})

// VueLazyLoad
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)


// Vue
Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)
Vue.config.productionTip = false;

// Global Variables
Vue.prototype.VUE_APP_API_URL = process.env.VUE_APP_API_URL
Vue.prototype.VUE_APP_API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT
Vue.prototype.VUE_APP_FRONT_URL = process.env.VUE_APP_FRONT_URL
Vue.prototype.VUE_APP_VERSION = version


// Mixins
//import Auth from '@/mixins/Auth.js'

Vue.mixin({
  methods: {
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App,
    VueAxios,
  },
  data: {

  }
})
