<template>
  <div id="toasts">
    <CToaster :autohide="3000">
      <template v-for="toast in linkSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          親会員を紐付けました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in removeLinkSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          親子関係の紐付けを解除しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in saveSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          保存しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in saveFailed">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="danger"
            class="text-center"
        >
          保存に失敗しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in cloneSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="info"
            class="text-center"
        >
          複製しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in cloneFailed">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="danger"
            class="text-center"
        >
          複製に失敗しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in deleteSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="info"
            class="text-center"
        >
          削除しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in deleteFailed">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="danger"
            class="text-center"
        >
          削除に失敗しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in addSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          作成しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="3000">
      <template v-for="toast in addFailed">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="danger"
            class="text-center"
        >
          作成に失敗しました
        </CToast>
      </template>
    </CToaster>
    <CToaster :autohide="5000">
      <template v-for="toast in validationFailed">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="danger"
            class="text-center"
        >
          入力されていないか、入力内容が間違っている必須項目があります。 <br />
          必須項目を入力しなおし、再度保存ボタンを押してください。
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="3000">
      <template v-for="toast in addCancel">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="info"
            class="text-center"
        >
          キャンセルしました
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="6000">
      <template v-for="toast in seriesHasChildren">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="danger"
            class="text-center"
        >
          削除しようとしているシリーズに商品データが紐付いているため、削除できませんでした。 <br>
          商品編集画面よりシリーズの紐付けを解除し、再度実行してください。
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="6000">
      <template v-for="toast in setCustomerSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          会員情報を読み込みました。
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="6000">
      <template v-for="toast in addProductSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          受注明細に商品を追加しました。
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="6000">
      <template v-for="toast in addOrderSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          請求書出力対象に受注データを追加しました。
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="6000">
      <template v-for="toast in productImportSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          商品データ一括更新を予約しました。<br>
          処理結果は画面下部に表示されます。<br>
          しばらくお待ち下さい。
        </CToast>
      </template>
    </CToaster>

    <CToaster :autohide="6000">
      <template v-for="toast in sendmailSuccess">
        <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            class="text-center"
        >
          メール送信処理を実行しました。
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      linkSuccess: 0,
      removeLinkSuccess: 0,
      addSuccess: 0,
      addFailed: 0,
      saveSuccess: 0,
      saveFailed: 0,
      cloneSuccess: 0,
      cloneFailed: 0,
      deleteSuccess: 0,
      deleteFailed: 0,
      validationFailed: 0,
      addCancel: 0,
      cancelSuccess: 0,
      seriesHasChildren: 0,
      setCustomerSuccess: 0,
      addProductSuccess: 0,
      addOrderSuccess: 0,
      productName: '',
      productImportSuccess: 0,
      sendmailSuccess: 0,
    }
  },
  methods: {
    showLinkSuccess() {
      this.linkSuccess++
    },
    showRemoveLinkSuccess() {
      this.removeLinkSuccess++
    },
    showAddSuccess() {
      this.addSuccess++
    },
    showAddFailed() {
      this.addFailed++
    },
    showSaveSuccess() {
      this.saveSuccess++
    },
    showSaveFailed() {
      this.saveFailed++
    },
    showCloneSuccess() {
      this.cloneSuccess++
    },
    showCloneFailed() {
      this.cloneFailed++
    },
    showDeleteSuccess() {
      this.deleteSuccess++
    },
    showDeleteFailed() {
      this.deleteFailed++
    },
    showValidationFailed() {
      this.validationFailed++
    },
    showCancelSuccess() {
      this.cancelSuccess++
    },
    showSeriesHasChildren() {
      this.seriesHasChildren++
    },
    showSetCustomerSuccess() {
      this.setCustomerSuccess++
    },
    showAddProductSuccess() {
      this.addProductSuccess++
    },
    showAddOrderSuccess() {
      this.addOrderSuccess++
    },
    showProductImportSuccess() {
      this.productImportSuccess++
    },
    showSendmailSuccess() {
      this.sendmailSuccess++
    }
  }
}
</script>