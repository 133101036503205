<template>
  <div>
    <nav class="bg-white navbar navbar-expand-lg navbar-light py-3 py-lg-0">
      <!-- SP -->
      <Burger></Burger>
      <Sidebar>
        <div class="py-0 px-0">
          <div class="container">
            <div class="row">
              <div class="px-0">
                <div class="list-group">
                  <router-link v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true"
                               :to="{ name: 'MyPage' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action active rounded-0 d-flex justify-content-between align-items-center px-3"
                  >
                    <p class="mb-0"><i class="fas fa-user fa-lg mr-2 text-white"></i>マイページ</p><i
                      class="fas fa-angle-right fa-lg text-white"></i>
                  </router-link>

                  <router-link v-if="computedIsLoggedIn === 'false' || computedIsLoggedIn === false"
                               :to="{ name: 'Login' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action active rounded-0 d-flex justify-content-between align-items-center px-3"
                  >
                    <p class="mb-0"><i class="fas fa-sign-in-alt fa-lg mr-2 text-white"></i>新規登録／ログイン</p><i
                      class="fas fa-angle-right fa-lg text-white"></i>
                  </router-link>

                  <router-link :to="{ name: 'Cart' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light px-3"
                  >
                    <p class="mb-0"><i class="fas fa-shopping-cart fa-lg mr-2 text-primary"></i>お買い物かご</p><i
                      class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>

                  <router-link :to="{ name: 'Home' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center px-3 border-bottom"
                  >
                    <p class="mb-0">ホーム</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>

                  <router-link :to="{ name: 'Category' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center px-3 border-bottom"
                  >
                    <p class="mb-0">カテゴリーから探す</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>

                  <template v-for="(category) in categories">
                    <router-link
                        class="list-group-item list-group-item-action rounded-0 d-flex justify-content-between align-items-center px-3 py-1 border-bottom-0"
                        :key="category.id"
                        :to="{ name: 'CategoryById', params: { id: category.id }}"
                        @click.native="$store.dispatch('toggleNav')"
                    >
                      <p class="ml-3 mr-3 mb-0">{{ category.name }}</p><i
                        class="fas fa-angle-right fa-lg text-orange"></i>
                    </router-link>
                  </template>

                  <router-link :to="{ name: 'News' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-top border-bottom-0 px-3"
                  >
                    <p class="mb-0">お知らせ</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>

                  <template v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true">
                    <router-link v-if="!isMirror"
                                 :to="{ name: 'ContactPanelPurchaseDetail' }"
                                 @click.native="$store.dispatch('toggleNav')"
                                 class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-top border-bottom-0 px-3"
                    >
                      <p class="mb-0">パネル買取りについて</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                    </router-link>

                    <router-link v-if="!isMirror"
                                 :to="{ name: 'ContactRepairBoardDetail' }"
                                 @click.native="$store.dispatch('toggleNav')"
                                 class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-top border-bottom-0 px-3"
                    >
                      <p class="mb-0">基板修理について</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                    </router-link>

                    <router-link v-if="!isMirror"
                                 :to="{ name: 'ContactRepairInsteadDetail' }"
                                 @click.native="$store.dispatch('toggleNav')"
                                 class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-top border-bottom-0 px-3"
                    >
                      <p class="mb-0">代行修理について</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                    </router-link>

                    <router-link :to="{ name: 'ContactProduct' }"
                                 @click.native="$store.dispatch('toggleNav')"
                                 class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-top border-bottom-0 px-3"
                    >
                      <p class="mb-0">不良品に関するお問い合わせ</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                    </router-link>
                  </template>

                  <a href="https://line.me/R/ti/p/@648oaqay"
                     target="_blank"
                     @click.native="$store.dispatch('toggleNav')"
                     class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-top border-bottom px-3"
                  >
                    <p class="mb-0">LINEでお問い合わせ</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </a>

                  <router-link :to="{ name: 'Contact' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-bottom px-3"
                  >
                    <p class="mb-0">お問い合わせ</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>



                  <!--
                  <router-link :to="{ name: 'Campaign' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-bottom px-3"
                  >
                    <p class="mb-0">キャンペーン情報</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>
                  -->

                  <!--router-link :to="{ name: 'Contact' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center border-bottom px-3"
                  >
                    <p class="mb-0">お問い合わせ</p><i class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link-->

                  <router-link :to="{ name: 'Guide' }"
                               @click.native="this.store.commit('setNavOpen', false)"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light px-3 rounded-0"
                  >
                    <p class="mb-0"><i class="far fa-question-circle fa-lg mr-2 text-primary"></i>ご利用ガイド</p><i
                      class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>

                  <router-link v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true"
                               :to="{ name: 'Logout' }"
                               @click.native="$store.dispatch('toggleNav')"
                               class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light px-3 rounded-0"
                  >
                    <p class="mb-0"><i class="fas fa-sign-out-alt fa-lg mr-2 text-primary"></i>ログアウト</p><i
                      class="fas fa-angle-right fa-lg text-orange"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>

      <!-- PC -->
      <div class="container">
        <a class="navbar-brand size_lg" href="/#/"><img src="images/common/logo.png"/></a>

        <div class="collapse navbar-collapse " id="navbarNavDropdown-22">
          <ul class="ml-auto navbar-nav">
            <li v-if="!computedIsLoggedIn === 'true' || !computedIsLoggedIn === true">
              <router-link :to="{ name: 'Login' }" class="nav-link px-lg-2 py-lg-4 mb-0">新規登録／ログイン</router-link>
            </li>
            <li v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true">
              <router-link :to="{ name: 'Logout' }"
                           class="nav-link px-lg-2 py-lg-4 mb-0"
              >
                ログアウト
              </router-link>
            </li>
            <li>
              <p class="nav-link px-lg-2 py-lg-4 mb-0">
                <router-link :to="{ name: 'Guide' }" class="text-body">ご利用案内</router-link>
              </p>
            </li>
            <li v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true" class="d-flex align-items-center">
              <router-link :to="{ name: 'MyPage' }"
              >
                <i class="fa-solid fa-circle-user text-primary fa-lg pl-3 pr-2"></i>
              </router-link>
            </li>
            <li class="d-flex align-items-center">
              <router-link :to="{ name: 'Cart' }"
              ><i class="fa-solid fa-cart-shopping text-primary fa-lg px-2"></i></router-link>
            </li>
          </ul>
          <!--search-->
          <!-- 動作してないのでコメントアウト -->
          <div id="search-wrap" class="d-none d-lg-block py-1 pr-0">
            <form role="search" method="get" action="">
              <input type="text" value="" name="" id="search-text"/>
            </form>
          </div>
          <!--search end-->
        </div>
      </div>
    </nav>

    <!-- PC / SP ナビ -->
    <nav class="d-none d-md-block d-lg-block bg-primary navbar navbar-expand-lg navbar-dark py-1 py-lg-0">
      <div class="container flex-wrap">

        <div class="collapse navbar-collapse w-100 justify-content-center" id="navbarNavDropdown-7">
          <ul class="navbar-nav ">
            <li class="nav-item dropdown">
              <div class="nav-link px-lg-4 py-lg-2 dropdown-toggle" id="navbarDropdownMenuLink" role="button"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">カテゴリー</div>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="top: 2.8rem;">
                <template v-for="(category) in categories">
                  <router-link
                      class="dropdown-item"
                      :key="category.id"
                      :to="{ name: 'CategoryById', params: { id: category.id }}"
                  >
                    {{ category.name }}
                  </router-link>
                  <div class="dropdown-divider"></div>
                </template>
              </div>
            </li>
            <template v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true">
              <li v-if="!isMirror" class="nav-item"><a class="nav-link px-lg-4 py-lg-2" href="/#/contact/panel-purchase-detail">パネル買取り</a>
              </li>
              <li v-if="!isMirror" class="nav-item"><a class="nav-link px-lg-4 py-lg-2" href="/#/contact/repair-board-detail">基板修理</a>
              </li>
              <li v-if="!isMirror" class="nav-item"><a class="nav-link px-lg-4 py-lg-2" href="/#/contact/repair-instead-detail">代行修理</a>
              </li>
              <li v-if="!isMirror" class="nav-item"><a class="nav-link px-lg-4 py-lg-2" href="/#/agency-recruitment">代理店募集</a>
              </li>
            </template>
            <li class="nav-item dropdown">
              <div class="nav-link px-lg-3 py-lg-2 dropdown-toggle" id="navbarDropdownMenuLink2" role="button"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">お問い合わせ</div>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2" style="top: 2.8rem;">
                <router-link
                    class="dropdown-item"
                    :to="{ name: 'Contact' }"
                >
                  お問い合わせ
                </router-link>
                <template v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true">
                  <div class="dropdown-divider"></div>
                  <router-link
                      class="dropdown-item"
                      :to="{ name: 'ContactProduct' }"
                  >
                    不良品に関するお問い合わせ
                  </router-link>
                  <div v-if="!isMirror" class="dropdown-divider"></div>
                  <router-link
                      v-if="!isMirror"
                      class="dropdown-item"
                      :to="{ name: 'ContactRepairBoard' }"
                  >
                    基板修理のご依頼
                  </router-link>
                  <div v-if="!isMirror" class="dropdown-divider"></div>
                  <router-link
                      v-if="!isMirror"
                      class="dropdown-item"
                      :to="{ name: 'ContactRepairInstead' }"
                  >
                    修理代行のご依頼
                  </router-link>
                  <div v-if="!isMirror" class="dropdown-divider"></div>
                  <router-link
                      v-if="!isMirror"
                      class="dropdown-item"
                      :to="{ name: 'ContactPanelPurchase' }"
                  >
                    パネル買い取りのご依頼
                  </router-link>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "../../../admin/src/store";
import Burger from "@/components/Sidebar/Burger.vue"
import Sidebar from "@/components/Sidebar/Sidebar.vue"

export default {
  name: 'Navbar',
  components: {Burger, Sidebar},
  data() {
    return {
      spinner: false,
      productName: '',
      visible: false,
      isMirror: (process.env.NODE_ENV === 'production-mirror' || process.env.NODE_ENV === 'local-mirror'),
      nodeEnv: process.env.NODE_ENV,
    }
  },
  created() {
    this.fetchForFront()
    console.log("isMirror", this.isMirror)
    console.log("nodeEnv", this.nodeEnv)

  },
  methods: {
    ...mapActions('products', [
      'fetchByProductName',
    ]),
    ...mapActions('categories', [
      'fetchForFront'
    ]),
    // 保存
    async searchByProductName() {
      this.setLoading(true)

      if (this.productName === '') {
        await this.$router.push(
            {
              path: '/products/list'
            }
        )
      }

      let path = '/products/search/name/' + this.productName;
      if (this.$route.path === path) {
        return false
      }

      await this.$router.push(
          {
            path: path
          }
      )
    },
    setLoading(value) {
      store.commit('setLoading', value)
    },
    toggleNav() {
      console.log(store.state.isNavOpen);
      store.dispatch('toggleNav');
    }
  },
  computed: {
    ...mapGetters('categories', [
      'categories',
    ]),
    ...mapGetters('authCustomer', [
      'isLoggedIn',
    ]),
    ...mapGetters('cart', [
      'cartItemCount',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
    computedIsLoggedIn() {
      console.log(this.$store.state.authCustomer.isLoggedIn)
      return this.$store.state.authCustomer.isLoggedIn
    },
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
