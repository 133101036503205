import Vue from "vue";

const state = {
};

const initialState = {
};

const getters = {
};


const mutations = {
    setError(state, error) {
        state.errors.push(error);
    },
    resetError(state) {
        state.errors = {}
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const actions = {
    async sendmail({commit}, contact) {
        return await window.axios.post("/contact/sendmail", contact).then(res => {
            console.log('contacts.sendmail', contact)

            return true
        }).catch(error => {
            commit('setError', error)
            console.log('contacts.sendmail.error', error)

            return false
        });
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};