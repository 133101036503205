<template>
  <div>
    <form>
      <label for="todo">TODO: </label>
      <input id="todo" type="text" size="40" v-model="todo" /> <br />
      <input type="button" value="add" v-on:click="onAdd" />
      <input type="button" value="remove" v-on:click="onRemove" />

    </form>
    <transition-group tag="ul">
      <li v-for="item in items" v-bind:key="item">{{ item }}</li>
    </transition-group>
  </div>
</template>

<style scoped>
/* 追加, 削除時 */
.v-enter-active, .v-leave-active, .v-move {
  transition: transform 1s;
}

/* 追加前, 削除後の位置 */
.v-enter, .v-leave-to {
  transform: translateX(80%);
}

.v-leave-active {
  opacity: 0.2;
  position: absolute;
}
</style>

<script>
export default {
  name: 'NATransitionList',
  props: {

  },
  data() {
    return {
      todo: '',
      items: [
        "テスト書籍1",
        "テスト書籍2",
        "テスト書籍3",
      ],

    }
  },
  methods: {
    onAdd: function() {
      this.items.unshift(this.todo)
      this.todo = ''
    },
    onRemove: function() {
      let that = this;
      this.items = this.items.filter(function(value) {
        return value !== that.todo
      })
      this.todo = ''
    }
  }
}
</script>