import Vue from "vue";
import findIndex from 'lodash/findIndex'
import size from 'lodash/size'

// _.merge(object, [sources])
import merge from 'lodash/merge'

import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import values from 'lodash/values'
import unset from 'lodash/unset'
import forEach from "lodash/forEach";

const state = {
    items: [],
    item: {
        id: '',
        usage_point: 0,
        earned_point: 0,
        point: 0,
        point_rate: 0.01,
        parent_order_id: '',
        regular_count: 0,
        is_pre_order: '',
        type: 1,
        delivery_id: null,
        customer_code: '',
        status: 1,
        deliver_id: 1,
        payment_id: 1,
        payment_name: '',
        payment_timing: '',
        payment_status: 1,
        sex: '',
        birth: '',
        first_name: '',
        last_name: '',
        first_name_kana: '',
        last_name_kana: '',
        phone: '',
        cell_phone: '',
        fax: '',
        email: '',
        zip: '',
        pref: 1,
        city: '',
        address1: '',
        address2: '',
        company_name: '',
        company_name_kana: '',
        company_position: '',
        company_division: '',
        company_pref: 1,
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_phone: '',
        company_fax: '',
        company_email: '',
        delivery_date: '',
        delivery_time: '',
        delivery_name: '',
        delivery_name_kana: '',
        delivery_company_name: '',
        delivery_company_division: '',
        delivery_zip: '',
        delivery_pref: 1,
        delivery_city: '',
        delivery_address1: '',
        delivery_address2: '',
        delivery_phone: '',
        delivery_fax: '',
        delivery_email: '',
        subtotal: 0,
        tax: 0,
        total: 0,
        discount: 0,
        delivery_fee: 0,
        charge: 0,
        construction_cost: 0,
        is_free_shipping: false,
        is_free_charge: false,
        is_island: false,
        adjustment_amount: 0,
        payment_total: 0,
        pca_order_id1: '',
        pca_order_id2: '',
        pca_customer_code: '',
        pca_customer_name: '',
        pca_staff_name: '',
        pca_staff_code: '',
        pca_division_code: '',
        canceled_at: '',
        committed_at: '',
        delivered_at: '',
        paid_at: '',
        sold_at: '',
        billed_at: '',
        order_mailed_at: '',
        shipping_mailed_at: '',
        canceled_mailed_at: '',
        created_at: '',
        updated_at: '',
        details: [],
        campaigns: [],
        customer_detail: {},
        send_order_mail: true,
    },
    campaigns: [],
    calculated: {
        subtotal: Number,
        total: Number,
        tax: Number,
        charge: Number,
        shippingFee: Number,
        paymentTotal: Number,
        adjustmentAmount: Number,
    },
    details: [],
    presentList: {},
    presentLimit: [],
    presentLimitCount: [],
    presentFlat: [],
    presentCondition: [],
    islandShippingFee: 0,
    validatePresent: false,
    registeredCards: [],
    cardEditUrl: null,
    customerDeliveries: [],
    shippingFees: [],
};

const initialState = {
    items: [],
    item: {
        id: '',
        usage_point: 0,
        earned_point: 0,
        point: 0,
        point_rate: 0.01,
        parent_order_id: '',
        regular_count: 0,
        is_pre_order: '',
        type: 1,
        delivery_id: null,
        customer_code: '',
        status: 1,
        deliver_id: 1,
        payment_id: 1,
        payment_name: '',
        payment_timing: '',
        payment_status: 1,
        sex: '',
        birth: '',
        first_name: '',
        last_name: '',
        first_name_kana: '',
        last_name_kana: '',
        phone: '',
        cell_phone: '',
        fax: '',        email: '',
        zip: '',
        pref: 1,
        city: '',
        address1: '',
        address2: '',
        company_name: '',
        company_name_kana: '',
        company_position: '',
        company_division: '',
        company_pref: 1,
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_phone: '',
        company_fax: '',
        company_email: '',
        delivery_date: '',
        delivery_time: '',
        delivery_name: '',
        delivery_name_kana: '',
        delivery_company_name: '',
        delivery_company_division: '',
        delivery_zip: '',
        delivery_pref: 1,
        delivery_city: '',
        delivery_address1: '',
        delivery_address2: '',
        delivery_phone: '',
        delivery_fax: '',
        delivery_email: '',
        subtotal: 0,
        tax: 0,
        total: 0,
        discount: 0,
        delivery_fee: 0,
        charge: 0,
        construction_cost: 0,
        adjustment_amount: 0,
        payment_total: 0,
        pca_order_id1: '',
        pca_order_id2: '',
        pca_customer_code: '',
        pca_customer_name: '',
        pca_staff_name: '',
        pca_staff_code: '',
        pca_division_code: '',
        canceled_at: '',
        committed_at: '',
        delivered_at: '',
        paid_at: '',
        sold_at: '',
        billed_at: '',
        order_mailed_at: '',
        shipping_mailed_at: '',
        canceled_mailed_at: '',
        created_at: '',
        updated_at: '',
        details: [],
        campaigns: [],
        customer_detail: {},
        send_order_mail: true,
    },
    details: [],
    campaigns: [],
    calculated: {
        subtotal: Number,
        total: Number,
        tax: Number,
        charge: Number,
        shippingFee: Number,
        paymentTotal: Number,
        adjustmentAmount: Number,
    },
    presentList: {},
    presentLimit: [],
    presentLimitCount: [],
    presentFlat: [],
    presentCondition: [],
    // プレゼント選択数
    presentQuantity: 0,
    islandShippingFee: 0,
    selectedPresentList: [],
    validatePresent: false,
    registeredCards: [],
    cardEditUrl: null,
    customerDeliveries: [],
    shippingFees: [],
};


const getters = {
    order(state) {
        return state.item;
    },
    orderDetails(state) {
        return state.items;
    },
    campaigns(state) {
        return state.campaigns;
    },
    itemCount(state) {
        if(size(state.items) > 0) {
            return size(state.items)
        } else {
            return 0;
        }
    },
    orderTotal(state) {
        let initialValue = 0
        if(size(state.items) > 0) {
            return state.items.reduce(
                (previousValue, item) => previousValue + Math.floor((item.detail.price.sales_price * item.quantity) * (1 + item.detail.tax_rate)),
                initialValue)
        } else {
            return 0;
        }
    },
    orderSubtotal(state) {
        let initialValue = 0
        if(size(state.items) > 0) {
            return state.items.reduce(
                (previousValue, item) => previousValue + item.detail.price.sales_price * item.quantity,
                initialValue)
        } else {
            return 0
        }
    },
    registeredCards(state) {
        return state.registeredCards
    },
    cardEditUrl(state) {
        return state.cardEditUrl;
    },
    islandShippingFee(state) {
        return state.islandShippingFee;
    },
    presentList(state) {
        return state.presentList
    },
    presentLimit(state) {
        return state.presentLimit;
    },
    presentLimitCount(state) {
        return state.presentLimitCount;
    },
    presentCondition(state) {
        return state.presentCondition;
    },
    presentFlat(state) {
        return state.presentFlat;
    },
    presentQuantityTotal(state) {
        return state.presentQuantityTotal;
    },
    notValidated(state) {
        return !state.validatePresent
    },
    selectableLimit(campaignId) {
        return state.presentLimit
    },
    targetPresentList(campaignId) {
       return Object.entries(state.presentList).find(function(row) {
          if(row[0] === campaignId) {
              return row;
          }
       });
    },
    paymentId(state) {
        return state.payment_id
    },
    customerDeliveries(state) {
        return state.customerDeliveries;
    },
    shippingFees(state) {
        return state.shippingFees;
    }
};

const mutations = {
    checkout(state) {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    setItems(state, items) {
        Vue.set(state, "items", items);
        //state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setRegisteredCards(state, cards) {
        //console.log(cards)
        if(cards) {
            for(const key in cards) {
                console.log(cards[key])
                let expireYear = parseInt(cards[key].expire.substring(0, 2)) + 2000 + '年';
                let expireMonth = cards[key].expire.substring(2, 4) + '月';
                cards[key].expire = expireYear + expireMonth
            }
        }
        state.registeredCards = cards
    },
    setCardEditUrl(state, url) {
        state.cardEditUrl = url
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    deleteDetail(state, index) {
        state.item.details.splice(index, 1);
    },
    addProduct(state, detail) {
        state.details.push(detail)
    },
    addCampaign(state, campaign) {
        Object.push(state.campaigns, campaign)
    },
    setCustomer(state, customer) {
        const newCustomer = { ...state.item, ...customer }

        state.item = newCustomer
    },
    setCustomerDelivery(state, customerDelivery) {
        state.item.delivery_id = customerDelivery.id
        state.item.delivery_name = customerDelivery.name
        state.item.delivery_name_kana = customerDelivery.name_kana
        state.item.delivery_company_name = customerDelivery.company_name
        state.item.delivery_company_division = customerDelivery.company_division
        state.item.delivery_zip = customerDelivery.zip
        state.item.delivery_pref = customerDelivery.pref
        state.item.delivery_city = customerDelivery.city
        state.item.delivery_address1 = customerDelivery.address1
        state.item.delivery_address2 = customerDelivery.address2
        state.item.delivery_phone = customerDelivery.phone
        state.item.delivery_fax = customerDelivery.fax
        state.item.delivery_email = customerDelivery.email
    },
    changeQuantity(state, item) {
        const cartItem = state.items.find(row => row.detail.product_code === item.detail.product_code)
        cartItem.quantity = item.quantity
    },
    setPresentList(state, presentList) {
        state.presentList = presentList
    },
    setPresentLimit(state, presentLimit) {
        state.presentLimit = presentLimit
    },
    setPresentLimitCount(state, presentLimitCount) {
        state.presentLimitCount = presentLimitCount
    },
    setPresentCondition(state, presentCondition) {
        state.presentCondition = presentCondition
    },
    setPresentFlat(state, presentFlat) {
        state.presentFlat = presentFlat
    },
    setIslandShippingFee(state, islandShippingFee) {
        state.islandShippingFee = islandShippingFee
    },
    setSelectedPresentList(state, orderDetail) {
        const index = findIndex(state.items, { product_code: orderDetail.product_code });
        state.items[index] = orderDetail
    },
    validatePresent(state) {
        let length = Object.keys(state.presentList).length
        if(length === 0) {
            state.validatePresent = true
        }

        let count = 0
        state.items.forEach(function(row) {
            console.log(row)
            if(row.detail.present_product_id === undefined) {
                count++
            }
        });
        console.log(count, length)

        if(count !== length) {
            state.validatePresent = false
        } else {
            state.validatePresent = true
        }
    },
    setPresent(state, present) {
        const itemIndex = findIndex(state.presentList[present.campaign_id], { target_product_id: present.target_product_id, product_id: present.product_id })

        console.log(state.presentList[present.campaign_id]);
        console.log("INDEX", itemIndex);
    },
    getSelectedQuantity(state, present) {
        const itemIndex = findIndex(state.presentList[present.campaign_id], { target_product_id: present.target_product_id, product_id: present.product_id })
        return state.presentList[present.campaign_id][itemIndex].selected_quantity
    },
    setShippingFees(state, shippingFees) {
        state.shippingFees = shippingFees
    },
};

const actions = {
    async fetchCustomer({commit}) {
        await window.axios.get('/my-page/customer').then(res => {
            if(res.data) {
                commit('setCustomer', res.data);
                console.log('my-page.fetchCustomer', res)
            }
        }).catch(error => {
            console.log('my-page.fetchCustomer.error', error)
        });
    },
    async fetchDelivery({state, commit}) {
        const params = {
            'delivery_id': state.item.delivery_id,
            'customer_code': state.item.customer_code,
        }
        await window.axios.post('/my-page/customer-delivery', params).then(res => {
            if(res.data) {
                commit('setCustomerDelivery', res.data);
            }
        }).catch(error => {
            console.log('my-page.fetchDefaultDelivery.error', error)
        });
    },
    async fetchShippingFees({state, commit}) {
        await window.axios.get('/shipping-fees').then(res => {
            commit('setShippingFees', res.data)

            return true;
        }).catch(error => {
            console.log('my-page.fetchDefaultDelivery.error', error)
        });
    },
    async fetchRegisteredCards({commit}) {
        return await window.axios.get('/payment/registered-cards').then(res => {
            commit('setRegisteredCards', res.data);
            console.log('newCart.fetchRegisteredCards')

            return res.data;
        }).catch(error => {
            console.log('newCart.fetchRegisteredCards.error', error)
        });
    },
    async fetchCardEditUrl({commit}) {
        return await window.axios.get('/payment/card-edit').then(res => {
            commit('setCardEditUrl', res.data.url)
            console.log('newCart.fetchCardEditUrl', res.data)

            return true
        }).catch(error => {
            console.log('newCart.fetchCardEditUrl.error', error)
            return false
        });
    },
    async deleteDetail({state, commit}, detail) {
        const index = state.item.details.indexOf(detail)

        console.log("INDEX", index)

        commit('deleteDetail', index)
        console.log('order.deleteDetail', state.item.details[index], index)
    },
    async complete({commit}, id) {
        const param = { cart_id: window.localStorage.getItem('cart_id'), order_id: window.localStorage.getItem('order_id') }

        return await window.axios.post('/orders/complete', param)
            .then(res => {
                commit('resetState')
                console.log('orders.complete', res, id)

                return res.data
            }).catch(error => {
                console.log('orders.complete.error', error, id)
            });
    },
    async storeFront({state, commit}, item) {
        const param = {
            order: state.item,
            details: state.items,
            presentList: state.presentList
        }
        return await window.axios.post('/cart/pre-order', param)
            .then(res => {
                if(res.data.error !== '') {
                    return false;
                }

                console.log("storeFront Executed.")
                console.log('orders.storeFront', res, item)
                window.localStorage.setItem('order_id', res.data.order.id)
                console.log(res.data.order)

                return res.data.order.id
            }).catch(error => {
                console.log('orders.storeFront.error', error, item)

                return error;
            });
    },
    async storeFrontByCredit({state, commit}, item) {
        const param = {
            order: state.item,
            details: state.items,
            presentList: state.presentList
        }

        return await window.axios.post('/payment/checkout', param).then(res => {
            console.log("storeFrontByCredit Executed.", res.data.order_id)
            window.localStorage.setItem('order_id', res.data.order_id)

            return res.data.url
        }).catch(error => {
            console.log('orders.storeFrontByCredit.error', error)
        });
    },
    async changeQuantity({commit}, item) {
        const param = { cart_id: window.localStorage.getItem('cart_id'), product: item }
        return await window.axios.post('/cart/change-quantity', param)
            .then(res => {
                commit('changeQuantity', res.data.product)
                console.log('newCart.changeQuantity', res)

                return res.data.product
            }).catch(error => {
                console.log('newCart.changeQuantity.error', error, item)
            });
        },
    async addDetail({commit}, detail) {
        commit('addDetail', detail)
    },
    resetState({commit}) {
        commit('resetState');
        console.log('orders.resetState')
    },
    resetSearchState({commit}) {
        commit('resetSearchState')
        console.log('orders.resetSearchState')
    },
    async getCartId({commit}) {
        const cartId = window.localStorage.getItem('cart_id');
        return await window.axios.get('/cart/get/cart/id/' + cartId)
            .then(res => {
                window.localStorage.setItem('cart_id', res.data);
                console.log("getCartId", res);

                return res.data
            }).catch(error => {
                console.log('orders.getCart.error', error)
            });
    },
    async getCart({dispatch, commit}) {

        await dispatch('getCartId');
        await dispatch('getPresentList');
        const cartId = window.localStorage.getItem('cart_id');
        return await window.axios.get('/cart/get/' + cartId)
            .then(res => {
                commit('setItems', res.data)

                return res.data
            }).catch(error => {
                console.log('orders.getCart.error', error)
            });
    },
    async deleteCartItem({commit}, item) {
        return await window.axios.get('/cart/delete/' + item.id)
            .then(res => {
                return true
            }).catch(error => {
                console.log('orders.getCart.error', error)
            });
    },
    async addProduct({commit}, product, quantity) {
        let cartId = window.localStorage.getItem('cart_id');
        if(cartId === null || cartId === '' || cartId === 'undefined' || cartId === undefined || cartId === 'null') {
            cartId = 'new'
        }

        console.log("cart_id: ", window.localStorage.getItem('cart_id'));

        const param = { product: product, quantity: quantity, cart_id: cartId }

        return await window.axios.post('/cart/add', param)
            .then(res => {
                console.log(res.data)
                console.log("cart_id: ", res.data.cart_id);
                window.localStorage.setItem('cart_id', res.data.cart_id);
                console.log("cart_id: ", res.data.cart_id);
                console.log("addProduct: " , res.data.cart);
                commit('setItems', res.data.cart)

                return true
            }).catch(error => {
                console.log('newCart.addProduct.error', error)
            });
    },
    async checkout({dispatch, commit}, param) {
        return await window.axios.post('/cart/checkout', param)
            .then(res => {
                commit('checkout', res.data)

                return res.data
            }).catch(error => {
                console.log('orders.storeFront.error', error)

                return false;
            });
    },
    async getPresentList({dispatch, commit}) {
        // await dispatch('checkout')
        const param = { cart_id: window.localStorage.getItem('cart_id') }
        return await window.axios.post('/cart/present-list', param)
            .then(res => {
                if(size(res.data) > 0 && size(res.data.presentList) > 0) {
                    console.log("sizeCount", res.data.presentList)
                    commit("setPresentList", res.data.presentList)
                    commit("setPresentLimit", res.data.presentLimit)
                    commit("setPresentLimitCount", res.data.presentLimitCount)
                    commit("setPresentFlat", res.data.presentFlat)
                    commit("setPresentCondition", res.data.presentCondition)
                } else {
                    commit("setPresentList", [])
                    commit("setPresentLimit", 0)
                    commit("setPresentLimitCount", 0)
                    commit("setPresentFlat", [])
                    commit("setPresentCondition", [])
                }
            }).catch(error => {
                console.log(error)
                return false;
            });
    },
    async getIslandShippingFee({state, commit}) {
        return await window.axios.get('/cart/island-shipping-fee/' + state.item.zip)
            .then(res => {
                commit('setIslandShippingFee', res.data.island_shipping_fee)
            }).catch(error => {
                return false;
            });
    },
    async changePresentQuantity({state, commit}) {
        commit('')
    },
    async setCustomerDelivery({state, commit}, delivery) {
        commit('setCustomerDelivery', delivery);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};