<template>
  <div>
    <div class="py-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt-4">
            <h1 class="top_ttl mb-0 copy22">Other Services<span class="copy14 ml-2">お問い合わせ</span>
            </h1>
          </div>
          <div class="col-md-12 mt-3">
            <p>必須項目をご入力のうえ、画面下部の「送信ボタン」を押してください。</p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="nodeEnv === 'development'">
      <div>
        <button @click.prevent="setTestData">テストデータ</button>
      </div>
    </template>
    <div class="container mt-3 d-flex justify-content-center">
      <div class="col-md-9">
        <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(onSubmit)">


            <!-- 名前 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">お名前<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="日本花子" name="お名前"
                         v-model="contact.name" rules="required|max:50"></Input>
                </div>
              </div>
            </div>

            <!-- 名前フリガナ -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">フリガナ<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="ニホンハナコ" name="フリガナ"
                         v-model="contact.name_kana" rules="required|max:50|kana"></Input>
                </div>
              </div>
            </div>

            <!-- メールアドレス -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">メールアドレス<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="example@lingrise.jp" name="メールアドレス"
                         v-model="contact.email" rules="required|email"></Input>
                </div>
              </div>
            </div>

            <!-- お問い合わせ内容 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">お問い合わせ内容<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <validation-provider
                      name="お問い合わせ内容"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                  >
                    <b-form-textarea class="rounded-0"
                                     v-model="contact.message"
                                     :state="getValidationState(validationContext)"
                                     placeholder="お問い合わせ内容"
                                     aria-describedby="input-1-live-feedback"
                                     rows="8"
                    ></b-form-textarea>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!--利用規約-->
            <div class="form-group">
              <div class="copy12 border-bottom mt-3 pb-2 mb-2">個人情報保護方針</div>
              <div class="copy09" style="height: 13rem; overflow:scroll;">
                ＜個人情報の取扱に関する方針＞<br/>
                当サイトを運営する株式会社リングライズでは、お客様のプライバシーを尊重し、個人情報に対して十分な配慮を行うと共に、お客様の個人情報を大切に保護し、適正な管理を行うことに努めています。<br/>
                <br/>
                【個人情報利用目的】<br/>
                お客様の個人情報は、原則として、当社のサービスに関する情報を提供する目的や当社に対するご意見、ご要望に対する今後の改善、および、ご依頼、お問い合わせに関する業務のために利用致します。<br/>
                上記以外の目的で利用する場合は、個人情報をご提供いただく際に、予め目的を明示しておりますので、ご確認ください。<br/>
                <br/>
                【第三者への情報提供】<br/>
                お客様の個人情報は、以下の場合を除き、第三者に開示、提供、譲渡することは致しません。<br/>
                ・法的拘束力がある第三者機関からの開示要求がある場合<br/>
                ・お客様本人の同意があった場合<br/>
                <br/>
              </div>
            </div>
            <div class="form-group pb-4">
              <div class="custom-control custom-checkbox text-center">
                <validation-provider
                    name="個人情報保護方針"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                  <b-form-checkbox
                      id="agreement"
                      v-model="contact.agreement"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      placeholder="個人情報保護方針"
                      name="agreement"
                      value="true"
                  >
                    上記の個人情報保護方針に同意し、送信する
                  </b-form-checkbox>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>
            <div class="py-1 text-center mb-5" style="">
              <p v-show="invalid" class="fade show alert alert-danger rounded-0 mb-3">
                未入力の項目があるか、内容が適切でない項目があります。
              </p>
              <button :disabled="!handleSubmit" type="submit" class="btn btn-primary rounded-0 py-2 px-4">送信する</button>
            </div>
            <!--/利用規約-->
          </form>
        </ValidationObserver>
      </div>
    </div><!-- /container -->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {localize} from 'vee-validate';
import Input from "@/components/Form/Input"

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'
import en from 'vee-validate/dist/locale/en.json';
import ja from 'vee-validate/dist/locale/ja.json';
import store from "@/store";
import moment from "moment/moment";

localize({
  en,
  ja
});
export default {
  name: 'Contact',
  props: {},
  components: {
    Input
  },
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      selected: [],
      spinner: false,
      contact: {
        mail_type: 'Contact',
        name: '',
        name_kana: '',
        email: '',
        message: '',
      },
      contactTest: {
        mail_type: 'Contact',
        name: 'テスト太郎',
        name_kana: 'テストタロウ',
        email: 'test@test.com',
        message: 'テストメッセージ',
        agreement: '',
        sendmail_flag: true,
        created_at: '',
        updated_at: '',
      },
      validationFailed: false,
    }
  },
  computed: {
    ...mapState({
      prefs: state => state.enums.items['pref'],
    }),
    ...mapGetters('enums', [
      'enums',
    ]),
  },
  methods: {
    ...mapActions('customers', [
      'fetchZipData',
    ]),
    ...mapActions('contacts', [
      'sendmail',
    ]),
    setTestData() {
      this.contact = this.contactTest
    },
    async findAddress() {
      this.spinnerFindAddress = true
      const target = await this.fetchZipData(this.contact.zip);
      console.log(target)

      this.contact.pref = target.pref;
      this.contact.city = target.city;
      this.contact.address1 = target.town;

      this.spinnerFindAddress = false
    },
    getDeliveryDateMin() {
      const now = moment();
      //console.log(now.format('YYYY-MM-DD'))
      return now.format('YYYY-MM-DD');
    },
    validateEmail() {
      if (this.contact.email === "") return 'メールアドレス'
      return this.contact.email
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        food: null
      };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    // 保存
    onSubmit() {
      this.handleSendmail(this.contact)
    },
    handleSendmail(contact) {
      this.validationFailed = false
      this.sendmail(contact).then(res => {
        if (res === true) {
          this.$router.push({path: '/contact/complete'})
          window.scrollTo(0, 0);
        }
      });
    },
  },
  async created() {
    await store.dispatch('enums/fetch')
  },
  async beforeRouteUpdate(to, from, next) {
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    return next();
  },
  async beforeRouteLeave(to, from, next) {
    next();
  },
}
</script>