import Vue from "vue";
// _.findIndex(array, [predicate=_.identity], [fromIndex=0])
import findIndex from 'lodash/findIndex'

// _.merge(object, [sources])
import merge from 'lodash/merge'

import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import values from 'lodash/values'
import unset from 'lodash/unset'
import size from "lodash/size";

const state = {
    items: [],
    item: {
        id: '',
        parent_order_id: '',
        order_division_id: 5,
        regular_count: 0,
        is_pre_order: '',
        is_shipped: false,
        type: 1,
        delivery_id: 1,
        customer_code: '',
        status: 1,
        deliver_id: 1,
        payment_id: 2,
        payment_name: '',
        payment_timing: '',
        payment_status: 1,
        sex: '',
        birth: '',
        first_name: '',
        last_name: '',
        first_name_kana: '',
        last_name_kana: '',
        phone: '',
        cell_phone: '',
        fax: '',
        email: '',
        zip: '',
        pref: '',
        city: '',
        address1: '',
        address2: '',
        company_name: '',
        company_name_kana: '',
        company_position: '',
        company_division: '',
        company_pref: '',
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_phone: '',
        company_fax: '',
        company_email: '',
        shipping_number: '',
        delivery_date: '',
        delivery_time: '',
        delivery_name: '',
        delivery_name_kana: '',
        delivery_company_name: '',
        delivery_company_division: '',
        delivery_pref: 1,
        delivery_city: '',
        delivery_address1: '',
        delivery_address2: '',
        delivery_phone: '',
        delivery_fax: '',
        delivery_email: '',
        subtotal: 0,
        tax: 0,
        total: 0,
        discount: 0,
        delivery_fee: 0,
        charge: 0,
        construction_cost: 0,
        adjustment_amount: 0,
        payment_total: 0,
        pca_order_id1: '',
        pca_order_id2: '',
        pca_customer_code: '',
        pca_customer_name: '',
        pca_staff_name: '',
        pca_staff_code: '',
        pca_division_code: '',
        message: '',
        canceled_at: '',
        committed_at: '',
        estimate_date: '',
        delivered_at: '',
        paid_at: '',
        sold_at: '',
        billed_at: '',
        order_mailed_at: '',
        shipping_mailed_at: '',
        canceled_mailed_at: '',
        is_exported: false,
        created_at: '',
        updated_at: '',
        details: [],
        campaigns: [],
        customer_detail: {},
        point_rate: 0.01,
        earned_point: 0,
        usage_point: 0,
    },
    campaigns: [],
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    // 管理画面 検索条件
    search: {
        per_page: 25,
        page: 1,
        name: '',
        company_name: '',
        company_division: '',
        phone: '',
        total_from: '',
        total_to: '',
        created_at_from: '',
        created_at_to: '',
        statuses: [],
        payment_statuses: [],
        payment_methods: [],
        product_name: '',
        product_code: '',
        model_name: '',
        category_id: '',
        series_id: '',
        grades: [],
    },
    productCodeList: [],
    registeredCards: [],
    outputOrders: [],
};

const initialState = {
    items: [],
    item: {
        id: '',
        parent_order_id: '',
        is_pre_order: '',
        is_shipped: false,
        order_division_id: 5,
        regular_count: 0,
        type: 1,
        delivery_id: 1,
        customer_code: '',
        status: 1,
        deliver_id: 1,
        payment_id: 2,
        payment_name: '',
        payment_timing: '',
        payment_status: 1,
        sex: '',
        birth: '',
        first_name: '',
        last_name: '',
        first_name_kana: '',
        last_name_kana: '',
        phone: '',
        cell_phone: '',
        fax: '',
        email: '',
        zip: '',
        pref: '',
        city: '',
        address1: '',
        address2: '',
        company_name: '',
        company_name_kana: '',
        company_position: '',
        company_division: '',
        company_pref: '',
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_phone: '',
        company_fax: '',
        company_email: '',
        shipping_number: '',
        delivery_date: '',
        delivery_time: '',
        delivery_name: '',
        delivery_name_kana: '',
        delivery_company_name: '',
        delivery_company_division: '',
        delivery_pref: 1,
        delivery_city: '',
        delivery_address1: '',
        delivery_address2: '',
        delivery_phone: '',
        delivery_fax: '',
        delivery_email: '',
        subtotal: 0,
        tax: 0,
        total: 0,
        discount: 0,
        delivery_fee: 0,
        charge: 0,
        construction_cost: 0,
        adjustment_amount: 0,
        payment_total: 0,
        pca_order_id1: '',
        pca_order_id2: '',
        pca_customer_code: '',
        pca_customer_name: '',
        pca_staff_name: '',
        pca_staff_code: '',
        pca_division_code: '',
        canceled_at: '',
        committed_at: '',
        estimate_date: '',
        message: '',
        delivered_at: '',
        paid_at: '',
        sold_at: '',
        billed_at: '',
        order_mailed_at: '',
        shipping_mailed_at: '',
        canceled_mailed_at: '',
        is_exported: false,
        created_at: '',
        updated_at: '',
        details: [],
        campaigns: [],
        customer_detail: {},
        clone: {},
        point_rate: 0.01,
        earned_point: 0,
        usage_point: 0,
    },
    campaigns: [],
    // 管理画面 検索条件
    search: {
        per_page: 25,
        page: 1,
        company_name: '',
        company_division: '',
        name: '',
        phone: '',
        total_from: '',
        total_to: '',
        created_at_from: '',
        created_at_to: '',
        statuses: [],
        payment_statuses: [],
        payment_methods: [],
        product_name: '',
        product_code: '',
        category_id: '',
        series_id: '',
        grades: [],
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    calculated: {
        subtotal: Number,
        total: Number,
        tax: Number,
        charge: Number,
        shippingFee: Number,
        paymentTotal: Number,
        adjustmentAmount: Number,
    },
    productCodeList: [],
    registeredCards: [],
    outputOrders: [],
};
const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setDetails(state, details) {
        state.item.details = details;
    },
    setRegisteredCards(state, cards) {
        state.registeredCards = cards;
    },
    setClone(state, item) {
        state.item.clone = item.order
    },
    setPagination(state, items) {
        Vue.set(state, 'pagination', items);
    },
    setSearch(state, search) {
        state.search = search
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.pagination.data.splice(index, 1);
    },
    deleteDetail(state, index) {
        state.item.details.splice(index, 1);

        mutations.calculateOrder(state)
    },
    recoverDetail(state, index) {
        let detail = state.items.orderDetails[index]
            detail.deleted_at = ''
    },
    deleteCampaign(state, index) {
        let campaign = state.items.orderCampaigns[index]
        if(campaign.id === '') {
            state.items.splice(index, 1);
        } else {
            campaign.deleted_at = Date.now()
        }
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    resetSearchState() {
        state.search.per_page = 25;
        state.search.page = 1;
        state.search.email = '';
        state.search.name = '';
        state.search.company_name = '';
        state.search.company_division = '';

        state.search.customer_code = '';
        state.search.phone = '';
        state.search.fax = '';
        state.search.total_from = '';
        state.search.total_to = '';
        state.search.created_at_from = '';
        state.search.created_at_to = '';
        state.search.statuses = [];
        state.search.payment_statuses = [];
        state.search.payment_methods = [];
        state.search.product_name = '';
        state.search.product_code = '';
        state.search.category_id = '';
        state.search.series_id = '';
        state.search.grades = [];
    },
    newAddProduct(state, product) {
        // カートの中に商品が存在するか
        const orderDetailIndex = findIndex(state.item.details, { product_code: product.product_code })

        // カートに存在する場合、個数を1足す
        let orderDetail = {}
        if(orderDetailIndex > 0 && !product.is_shipping_fee && !product.is_charge) {
            orderDetail = state.item.details[orderDetailIndex]
            orderDetail.quantity++

            state.item.details[orderDetailIndex] = getters.calculateDetail(state, orderDetail)

            console.log(orderDetailIndex);
            console.log(state.item.details[orderDetailIndex]);
        // カートに存在しない場合、明細を追加する
        } else {
            orderDetail = getters.newProductToDetail(state, product)
            orderDetail = getters.calculateDetail(state, orderDetail)
            state.item.details.push(orderDetail)

            console.log('orderDetail: ', orderDetail)
        }

        // 再計算
        mutations.calculateOrder(state)
    },
    addProduct(state, product) {
        // 商品コード
        console.log("PRO", product)
        const targetProductCode = (product.selected_product_code) ? product.selected_product_code : null
        console.log("DETAILS", product['details'])

        // 商品コードに紐づく行を取得
        const productDetailIndex = findIndex(product['details'], { product_code: targetProductCode});
        const productDetail = product['details'][productDetailIndex]
        console.log("SELECTED DETAIL", productDetail)

        console.log("orders.addProduct", targetProductCode, productDetail)
        console.log("orders.addProduct.product", product)

        // カートの中に商品が存在するか
        const orderDetailIndex = findIndex(state.item.details, { product_code: productDetail.product_code })

        // カートに存在する場合、個数を1足す
        let orderDetail = {}
        if(orderDetailIndex > 0 && !productDetail.is_shipping_fee && !productDetail.is_charge) {
            orderDetail = state.item.details[orderDetailIndex]
            orderDetail.quantity++

            state.item.details[orderDetailIndex] = getters.calculateDetail(state, orderDetail)

            console.log(orderDetailIndex);
            console.log(state.item.details[orderDetailIndex]);
        // カートに存在しない場合、明細を追加する
        } else {
            orderDetail = getters.productToDetail(state, product, productDetail)
            orderDetail = getters.calculateDetail(state, orderDetail)
            state.item.details.push(orderDetail)

            console.log('orderDetail: ', orderDetail)
        }

        // 再計算
        mutations.calculateOrder(state)
    },
    reCalculateDetail(state, orderDetail) {
        const orderDetailIndex = findIndex(state.item.details, { product_code: orderDetail.product_code })
        console.log('reCalculateDetail [Before]', state.item.details[orderDetailIndex])
        state.item.details[orderDetailIndex] = getters.calculateDetail(state, orderDetail)
        console.log('reCalculateDetail [After]', state.item.details[orderDetailIndex])

        mutations.calculateOrder(state)
    },
    addShippingFee(state) {
        let product = {
            id: null,
            selected_product_code: '4450',
            product_name: '送料',
            tax_rate: 0.1,
            details: {
                product_id: '1000001',
                export_status: '1',
                product_code: '4450',
                original_price: 600,
                retail_price: 600,
                quantity: 1,
                ratio: 1,
                discount: 0,
                is_shipping_fee: 1,
                is_negative: 0,
            },
        }

        // orderDetailを生成しつつ金額を計算する
        let orderDetail = getters.productToDetail(state, product, product.details)
        orderDetail = getters.calculateDetail(state, orderDetail)

        // 計算結果を追加
        state.item.details.push(orderDetail)

        // 再計算
        mutations.calculateOrder(state)
    },
    addIslandShippingFee(state) {
        let product = {
            id: null,
            selected_product_code: '4400',
            product_name: '離島送料',
            tax_rate: 0.1,
            details: {
                product_id: '1000002',
                export_status: '1',
                product_code: '4400',
                original_price: 1500,
                retail_price: 1500,
                quantity: 1,
                ratio: 1,
                discount: 0,
                is_shipping_fee: 1,
                is_negative: 0,
            },
        }

        // orderDetailを生成しつつ金額を計算する
        let orderDetail = getters.productToDetail(state, product, product.details)
        orderDetail = getters.calculateDetail(state, orderDetail)

        // 計算結果を追加
        state.item.details.push(orderDetail)

        // 再計算
        mutations.calculateOrder(state)
    },
    addCharge(state) {
        let product = {
            id: null,
            selected_product_code: '4970',
            product_name: '手数料',
            tax_rate: 0.1,
            details: {
                product_id: '1000003',
                export_status: '1',
                product_code: '4970',
                original_price: 300,
                retail_price: 300,
                quantity: 1,
                ratio: 1,
                discount: 0,
                is_charge: 1,
                is_negative: 0,
            },
        }

        // orderDetailを生成しつつ金額を計算する
        let orderDetail = getters.productToDetail(state, product, product.details)
        orderDetail = getters.calculateDetail(state, orderDetail)

        // 計算結果を追加
        state.item.details.push(orderDetail)

        // 再計算
        mutations.calculateOrder(state)
    },
    addConstructionCost(state) {
        let product = {
            id: null,
            selected_product_code: '4960',
            product_name: '工事費',
            tax_rate: 0.1,
            details: {
                product_id: '1000003',
                export_status: '1',
                product_code: '4960',
                original_price: 5000,
                retail_price: 5000,
                quantity: 1,
                ratio: 1,
                discount: 0,
                is_construction_cost: 1,
                is_negative: 0,
            },
        }

        // orderDetailを生成しつつ金額を計算する
        let orderDetail = getters.productToDetail(state, product, product.details)
        orderDetail = getters.calculateDetail(state, orderDetail)

        // 計算結果を追加
        state.item.details.push(orderDetail)

        // 再計算
        mutations.calculateOrder(state)
    },
    addNegative(state) {
        let product = {
            id: null,
            selected_product_code: '00000000',
            product_name: '赤伝雛形',
            tax_rate: -0.1,
            details: {
                product_id: '1000003',
                export_status: '1',
                product_code: '00000000',
                original_price: 10000,
                retail_price: 10000,
                amount: 10000,
                quantity: -1,
                ratio: 1,
                discount: 0,
                is_construction_cost: 0,
                is_negative: 1,
            }
        }

        // orderDetailを生成しつつ金額を計算する
        let orderDetail = getters.productToDetail(state, product, product.details)
        orderDetail = getters.calculateDetail(state, orderDetail)

        // 計算結果を追加
        state.item.details.push(orderDetail)

        // 再計算
        mutations.calculateOrder(state)
    },
    calculateOrder(state) {
        let subtotal = 0
        let total = 0
        let taxTotal = 0
        let shippingFee = 0
        let charge = 0
        let constructionCost = 0
        let discount = 0
        let paymentTotal = 0

        state.item.details.map(function(row) {
            // 値引き額
            discount += parseInt(row.discount * 1)

            // 小計(税抜)
            subtotal += parseInt(row.subtotal)

            // 合計(税抜)
            total += parseInt(row.total)

            // 消費税加算は小計が0以上のときのみ
            if(subtotal > 0) {
                taxTotal += parseInt(row.tax)
            } else {
                taxTotal = 0;
            }

            if(has(row, 'is_shipping_fee') && row.is_shipping_fee > 0) {
                shippingFee += parseInt(row.total)
            }
            if(has(row, 'is_charge') && row.is_charge > 0) {
                charge += parseInt(row.total)
            }
            if(has(row, 'is_construction_cost') && row.is_construction_cost > 0) {
                constructionCost += parseInt(row.total)
            }

            console.log("subtotal", row.subtotal, 'tax', row.tax)
            paymentTotal += parseInt(row.subtotal) + parseInt(row.tax)
        });

        state.item.subtotal = subtotal
        state.item.total = total
        state.item.tax = taxTotal
        state.item.discount = discount
        state.item.payment_total = paymentTotal
        state.item.delivery_fee = shippingFee
        state.item.charge = charge
        state.item.construction_cost = constructionCost

        console.log(state.item);
    },
    setCustomer(state, customer) {
        const customerDetail = cloneDeep(customer.detail)
        unset(customer, 'detail')
        customer.id = null

        console.log("CUST", customer)

        merge(state.item, customer)
        merge(state.item.customer_detail, customerDetail)
    },
    setProductCodeList(state, list) {
        state.productCodeList = list
    },
    addOutputOrder(state, order) {
        state.outputOrders.push(order)
    },
    setDeliveryAddress(state, customerDelivery) {
        state.item.delivery_id = customerDelivery.id
        state.item.delivery_phone = customerDelivery.phone
        state.item.delivery_zip = customerDelivery.zip
        state.item.delivery_pref = customerDelivery.pref
        state.item.delivery_city = customerDelivery.city
        state.item.delivery_address1 = customerDelivery.address1
        state.item.delivery_address2 = customerDelivery.address2
        state.item.delivery_name = customerDelivery.name
        state.item.delivery_name_kana = customerDelivery.name_kana
        state.item.delivery_company_name = customerDelivery.company_name
        state.item.delivery_company_division = customerDelivery.company_division
    },
    setOrderMailedAt(state, date) {
        state.item.order_mailed_at = date
    },
    setShippingMailedAt(state, date) {
        state.item.shipping_mailed_at = date
    },
    setCancelMailedAt(state, date) {
        state.item.cancel_mailed_at = date
    }
};

const getters = {
    orders(state) {
        return state.items;
    },
    order(state) {
        return state.item;
    },
    campaigns(state) {
        return state.campaigns;
    },
    pagination(state) {
        return state.pagination;
    },
    search(state) {
        return state.search;
    },
    productCodeList(state) {
        return state.productCodeList
    },
    registeredCards(state) {
        return state.registeredCards;
    },
    hasRegisteredCards(state) {
        if(values(state.registeredCards).length > 0) {
            return true;
        } else {
            return false;
        }
    },
    hasShippingFee(state) {
        if(values(state.item.details).length > 0) {
            const res = state.item.details.filter((row) => row.is_shipping_fee > 0)

            return (res.length > 0)
        } else {
            return false
        }
    },
    hasCharge(state) {
        if(values(state.item.details).length > 0) {
            const res = state.item.details.filter((row) => row.is_charge > 0)

            return (res.length > 0)
        } else {
            return false
        }
    },
    hasConstructionCost(state) {
        if(values(state.item.details).length > 0) {
            const res = state.item.details.filter((row) => row.is_construction_cost > 0)


            console.log(state.item.details)

            return (res.length > 0)
        } else {
            return false
        }
    },
    getRatio(state, product) {
        const productRatio = product.ratio
        const orderRatio = state.item.ratio
        const customerRatio = state.item.customer_detail.ratio

        console.log("getRatio.productRatio", productRatio)
        console.log("getRatio.orderRatio", orderRatio)
        console.log("getRatio.customerRatio", customerRatio)

        if(productRatio > 0) {
            return productRatio
        } else if(orderRatio > 0) {
            return orderRatio
        } else if(customerRatio > 0) {
            return customerRatio
        } else {
            return 1
        }
    },
    newProductToDetail(state, product) {
        const order = state.item
        let ratio = 1
        if(product.is_shipping_fee || product.is_charge || product.is_construction_cost) {
            ratio = 1
        } else {
            ratio = getters.getRatio(state, product)
        }

        return {
            id: null,
            order_id: order.id,
            customer_code: order.customer_code,
            export_status: '1',
            is_negative: product.is_negative,
            category_id: product.category_id,
            series_id: product.series_id,
            product_id: product.product_id,
            product_name: product.product_name,
            product_code: product.product_code,
            model_name: product.model_name,
            product_class_type1: product.product_class_type1,
            product_class_type2: product.product_class_type2,
            product_class_value1: product.product_class_value1,
            product_class_value_id1: product.product_class_value_id1,
            product_class_value2: product.product_class_value2,
            product_class_value_id2: product.product_class_value_id2,
            is_sales_at_retail_price: product.is_sales_at_retail_price ? product.is_sales_at_retail_price : 0,
            original_price: product.original_price,
            retail_price: product.retail_price,
            sales_price: product.sales_price,
            amount: product.amount,
            contained: product.contained,
            quantity: 1,
            ratio: ratio,
            discount: (product.discount) ? product.discount : 0,
            subtotal: product.subtotal,
            total: product.total,
            tax: product.tax,
            tax_rate: (product.tax_rate) ? product.tax_rate : 0.1,
            is_cosmetic: (product.is_cosmetic) ? product.is_cosmetic : 0,
            is_kogen: (product.is_kogen) ? product.is_kogen : 0,
            is_mebius: (product.is_mebius) ? product.is_mebius : 0,
            is_chaga: (product.is_chaga) ? product.is_chaga : 0,
            is_invisible: (product.is_invisible) ? product.is_invisible : 0,
            is_shipping_fee: (product.is_shipping_fee) ? product.is_shipping_fee : 0,
            is_charge: (product.is_charge) ? product.is_charge : 0,
            is_construction_cost: (product.is_construction_cost) ? product.is_construction_cost : 0,
            is_present: (product.is_present) ? product.is_present : 0,
        }
    },
    productToDetail(state, product, productDetail) {
        console.log("productDetail", productDetail)
        const order = state.item
        let ratio = 1
        if(productDetail.is_shipping_fee || productDetail.is_charge || productDetail.is_construction_cost) {
            ratio = 1
        } else {
            ratio = getters.getRatio(state, product)
        }

        console.log('getRatio', getters.getRatio(state, product))
        console.log('detailRatio', ratio)
        console.log('taxRate', product.tax_rate)

            console.log("ProductTToDetail.quantity:",product.quantity, productDetail.quantity)
        if(productDetail.quantity === undefined) {
            if(product.is_negative) {
                productDetail.quantity = -1
            } else {
                productDetail.quantity = 1
            }
        }

        return {
            id: null,
            order_id: order.id,
            customer_code: order.customer_code,
            export_status: (productDetail.export_status > 0) ? productDetail.export_status : 1,
            is_negative: productDetail.is_negative,
            category_id: product.category_id,
            series_id: product.series_id,
            product_id: productDetail.product_id,
            product_name: product.product_name,
            product_code: productDetail.product_code,
            product_class_type1: productDetail.product_class_type1,
            product_class_type2: productDetail.product_class_type2,
            product_class_value1: productDetail.product_class_value1,
            product_class_value2: productDetail.product_class_value2,
            is_sales_at_retail_price: productDetail.is_sales_at_retail_price ? productDetail.is_sales_at_retail_price : 0,
            original_price: productDetail.original_price,
            retail_price: productDetail.retail_price,
            sales_price: productDetail.sales_price,
            amount: productDetail.amount,
            contained: productDetail.contained,
            quantity: productDetail.quantity,
            ratio: ratio,
            discount: (productDetail.discount) ? productDetail.discount : 0,
            subtotal: productDetail.subtotal,
            total: productDetail.total,
            tax: productDetail.tax,
            tax_rate: (product.tax_rate) ? product.tax_rate : 0.1,
            is_cosmetic: (product.is_cosmetic) ? product.is_cosmetic : 0,
            is_kogen: (product.is_kogen) ? product.is_kogen : 0,
            is_mebius: (product.is_mebius) ? product.is_mebius : 0,
            is_chaga: (product.is_chaga) ? product.is_chaga : 0,
            is_invisible: (productDetail.is_invisible) ? productDetail.is_invisible : 0,
            is_shipping_fee: (productDetail.is_shipping_fee) ? productDetail.is_shipping_fee : 0,
            is_charge: (productDetail.is_charge) ? productDetail.is_charge : 0,
            is_construction_cost: (productDetail.is_construction_cost) ? productDetail.is_construction_cost : 0,
            is_present: (productDetail.is_present) ? productDetail.is_present : 0,
        }
    },
    // 明細を受け取り金額系項目を計算して、明細を返す
    calculateDetail(state, orderDetail) {
        // 利用する変数
        const taxRate = orderDetail.tax_rate
        const retailPrice = orderDetail.retail_price
        const quantity = orderDetail.quantity;
        console.log('calculateDetail.quantity:', quantity)

        const ratio = orderDetail.ratio
        const isSalesAtRetailPrice = orderDetail.is_sales_at_retail_price

        // 値引き金額
        const discount = orderDetail.discount

        // 計算結果
        let salesPrice = 0
        if(orderDetail.is_negative) {
            salesPrice = orderDetail.amount
        } else {
            salesPrice = retailPrice
        }

        const amount = salesPrice
        const subtotal = (amount * quantity) - discount

        // 消費税計算, 単価 * 数量 が値引きと等しい場合、消費税を0にする
        let tax = getters.getTax(state, amount, taxRate) * quantity
        if((amount * quantity) - discount === 0) {
            tax = 0;
        }

        let taxTotal = tax * quantity
        if(orderDetail.is_negative) {
            tax = tax * -1.0
        }
        const total = (amount * quantity) - discount

        // 結果
        const prices = {
            // 原価
            original_price: parseInt(orderDetail.original_price),
            // 上代
            retail_price: parseInt(retailPrice),
            // 小売価格
            sales_price: salesPrice,
            // 単価
            amount: amount,
            // 値引き金額
            discount: discount,
            // 商品1個あたりの消費税
            tax: tax,
            // 消費税合計 (消費税 * 数量)
            tax_total: taxTotal,
            // 小計 (単価 * 数量) - 値引き金額
            subtotal: subtotal,
            // 合計 (小計 + 消費税)
            total: total,
        }

        console.log("prices", prices)

        // orderDetailをpricesで更新して返す
        return merge(orderDetail, prices)
    },
    // 単価を計算して返す
    getAmount(state, retailPrice, ratio, isSalesAtRetailPrice = false) {
        // 上代価格で販売フラグが立っている場合、そのまま返す
        if(isSalesAtRetailPrice) {
            return retailPrice
        }

        return Math.floor(retailPrice * ratio)

    },
    // 消費税を計算して返す
    getTax(state, amount, taxRate) {
        return Math.floor(amount * taxRate)
    },
    outputOrders(state) {
        return state.outputOrders;
    },
    charge(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let charge = 0

            charge = state.item.details.reduce(
                function(previousValue, detail) {
                    if(detail.is_charge) {
                        return previousValue + Math.floor((detail.sales_price * detail.quantity) * (1 + detail.tax_rate))
                    }
                    return previousValue
                },
                initialValue)

            state.item.charge = charge

            return charge
        } else {
            return 0;
        }
    },
    deliveryFee(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let deliveryFee = 0

            deliveryFee = state.item.details.reduce(
                function(previousValue, detail) {
                    if(detail.is_shipping_fee) {
                        return previousValue + Math.floor((detail.sales_price * detail.quantity) * (1 + detail.tax_rate))
                    }
                    return previousValue;
                },
                initialValue)

            state.item.delivery_fee = deliveryFee

            return deliveryFee
        } else {
            return 0;
        }
    },
    orderPaymentTotal(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let orderPaymentTotal = 0

            orderPaymentTotal = state.item.details.reduce(
                function(previousValue, detail) {
                    return previousValue + Math.floor((detail.sales_price * detail.quantity) * (1 + detail.tax_rate))
                },
                initialValue)

            orderPaymentTotal -= state.item.usage_point;
            state.item.payment_total = orderPaymentTotal

            return orderPaymentTotal
        } else {
            return 0;
        }
    },
    orderTotal(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let orderTotal = 0

            orderTotal = state.item.details.reduce(
                function(previousValue, detail) {
                    if(!detail.is_charge && !detail.is_shipping_fee && !detail.is_construction_cost) {
                        return previousValue + Math.floor((detail.sales_price * detail.quantity) * (1 + detail.tax_rate))
                    } else {
                        return previousValue;
                    }
                },
                initialValue)

            state.item.payment_total = orderTotal

            return orderTotal
        } else {
            return 0;
        }
    },
    orderSubtotal(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let orderSubtotal = 0

            orderSubtotal = state.item.details.reduce(
                function(previousValue, detail) {
                    if(!detail.is_charge && !detail.is_shipping_fee && !detail.is_construction_cost) {
                        return previousValue + Math.floor((detail.sales_price * detail.quantity))
                    } else {
                        return previousValue;
                    }
                },
                initialValue)

            state.item.subtotal = orderSubtotal

            return orderSubtotal
        } else {
            return 0
        }
    },
    orderTax(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let orderTax = 0

            orderTax = state.item.details.reduce(
                function(previousValue, detail) {
                    if(!detail.is_charge && !detail.is_shipping_fee && !detail.is_construction_cost) {
                        return previousValue + Math.floor((detail.sales_price * detail.quantity) * detail.tax_rate)
                    } else {
                        return previousValue;
                    }
                },
                initialValue)

            state.item.tax = orderTax

            return orderTax
        } else {
            return 0
        }
    },
    earnedPoint(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            const usagePoint = state.item.usage_point;
            let earnedPoint = 0
            let total = 0 // 送料, 手数料除く税込み商品購入金額

            total = state.item.details.reduce(
                function(previousValue, detail) {
                    return previousValue + Math.floor(((detail.sales_price * detail.quantity) * (1 + detail.tax_rate)))
                },
                initialValue)

            console.log(total)

            earnedPoint = Math.floor((total - usagePoint) * state.item.point_rate)

            state.item.earned_point = earnedPoint

            if(earnedPoint > 0) {
                return earnedPoint
            }

            return 0
        } else {
            return 0
        }
    },
    maxPointsAvailable(state) {
        let initialValue = 0
        if(size(state.item.details) > 0) {
            let maxPointsAvailable = 0

            maxPointsAvailable = state.item.details.reduce(
                function(previousValue, detail) {
                    if(detail.is_shipping_fee || detail.is_charge) {
                        return previousValue;
                    }

                    return previousValue + Math.floor(((detail.sales_price * detail.quantity) * (1 + detail.tax_rate)))
                },
                initialValue)

            state.item.max_points_available = maxPointsAvailable

            return maxPointsAvailable
        } else {
            return 0
        }
    },
};

const actions = {
    async fetch({commit}, params) {
        await window.axios.get('/orders', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('orders.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('orders.fetch.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/orders/' + id).then(res => {
            console.log(res.data)
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('orders.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('orders.fetchOne.error', error)
        });
    },
    async addProductByProductCode({commit}, productCode) {
        return await window.axios.get('/products/code/' + productCode).then(res => {
            if(res.data) {
                if(res.data.product_code == 1) {
                    res.data.is_shipping_fee = true
                } else if(res.data.product_code == 2) {
                    res.data.is_charge = true
                }

                commit('newAddProduct', res.data);
                commit('calculateOrder')
                commit('unsetErrors')
                console.log('orders.addProductByProductCode:true', res)

                return true
            } else {
                console.log('orders.addProductByProductCode:false', res)
                return false
            }
        }).catch(error => {
            commit('setErrors', error)
            console.log('orders.fetchByProductCode.error', error)
        });
    },
    async addOutputOrder({commit}, order) {
        commit('addOutputOrder', order);
        console.log('orders.addOutputOrder:true', order)

        return true
    },
    async fetchProductDetails({commit}) {
        await window.axios.get('/products/details').then(res => {
            commit('setProductCodeList', res.data);
            commit('unsetErrors')
            console.log('orders.fetchProductDetails')
        }).catch(error => {
            commit('setErrors', error)
            console.log('orders.fetchProductDetails.error', error)
        });
    },
    async new({commit}) {
        let item = cloneDeep(initialState.item)

        commit('setItem', item);
        console.log('orders.new')
    },
    async delete({state, commit}, item) {
        const index = state.pagination.data.indexOf(item);

        return await window.axios.post('/orders/delete', item)
            .then(res => {
                commit('delete', index);
                commit('unsetErrors')
                console.log('orders.delete', state.pagination.data[index])

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('orders.delete.error', error, item)
            });
    },
    async deleteStatementList({state, commit}, item) {
        const index = state.pagination.data.indexOf(item);
        commit('delete', index);

        return true;
    },
    async deleteDetail({state, commit}, detail) {
        const index = state.item.details.indexOf(detail)

        commit('deleteDetail', index)
        console.log('order.deleteDetail', state.item.details[index], index)
    },
    async deleteCampaign({state, commit}, campaign) {
        const index = state.items.indexOf(campaign)

        commit('deleteCampaign', index)
        console.log('order.deleteCampaign', state.items[index], index)
    },
    async store({commit}, item) {
        return await window.axios.post('/orders/store', item)
            .then(res => {
                commit('unsetErrors')
                console.log('orders.store', res, item)

                return res.data[0].order.id;
            }).catch(error => {
                commit('setErrors', error)
                console.log('orders.store.error', error, item)
            });
    },
    async storeClone({commit}, item) {
        return await window.axios.post('/orders/clone', item)
            .then(res => {
                commit('setClone', res.data);
                commit('unsetErrors')
                console.log('orders.storeClone', res, item)

                return res
            }).catch(error => {
                commit('setErrors', error)
                console.log('orders.storeClone.error', error, item)
            });
    },
    async update({state, commit}, newItem) {

        console.log(newItem.delivery_time)
        return await window.axios.post('/orders/update', newItem)
            .then(res => {
                commit('setItem', res.data)
                commit('unsetErrors')
                console.log('orders.update', res, newItem)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('orders.update.error', error, newItem)
            });
    },
    async updateStatus({state, commit}, newItem) {
        return await window.axios.post('/orders/update-status', newItem)
            .then(res => {
                commit('setItem', res.data)
                commit('unsetErrors')
                console.log('orders.updateStatusToCancel', res, newItem)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('orders.updateStatusToCancel.error', error, newItem)
            });
    },
    async updateExportStatus({state, commit}, newItem) {
        return await window.axios.post('/orders/export-status/update', newItem)
            .then(res => {
                commit('setDetails', res.data)
                commit('unsetErrors')
                console.log('orders.updateExportStatus', res, newItem)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('orders.updateExportStatus.error', error, newItem)
            });
    },
    async exportCommitCsv() {
        return await window.axios.get('/orders/export/commit')
            .then(res => {
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                let now = new Date();
                let Y = now.getFullYear();
                let M = (now.getMonth()+1).toString().padStart(2, '0');
                let D = now.getDate().toString().padStart(2, '0');
                let h = now.getHours().toString().padStart(2, '0');
                let m = now.getMinutes().toString().padStart(2, '0');
                let unixTime = new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16)
                fileLink.href = fileURL;
                fileLink.setAttribute('download', "orders_" + Y + M + D + "_" + h + m + "_" + unixTime + ".csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(error => {
                console.log('orders.exportCommitCsv: failed', error)
            });
    },
    async exportPrepaymentCSV() {
        return await window.axios.get('/orders/export/prepayment-csv')
            .then(res => {
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                let now = new Date();
                let Y = now.getFullYear();
                let M = (now.getMonth()+1).toString().padStart(2, '0');
                let D = now.getDate().toString().padStart(2, '0');
                let h = now.getHours().toString().padStart(2, '0');
                let m = now.getMinutes().toString().padStart(2, '0');
                let unixTime = new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16)
                fileLink.href = fileURL;
                fileLink.setAttribute('download', "元払いCSV_" + Y + M + D + "_" + h + m + "_" + unixTime + ".csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(error => {
                console.log('orders.exportPrepaymentCSV: failed', error)
            });
    },
    async exportCashOnDeliveryCSV() {
        return await window.axios.get('/orders/export/cash-on-delivery-csv')
            .then(res => {
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                let now = new Date();
                let Y = now.getFullYear();
                let M = (now.getMonth()+1).toString().padStart(2, '0');
                let D = now.getDate().toString().padStart(2, '0');
                let h = now.getHours().toString().padStart(2, '0');
                let m = now.getMinutes().toString().padStart(2, '0');
                let unixTime = new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16)
                fileLink.href = fileURL;
                fileLink.setAttribute('download', "代引きCSV_" + Y + M + D + "_" + h + m + "_" + unixTime + ".csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(error => {
                console.log('orders.exportCashOnDeliveryCSV: failed', error)
            });
    },
    async exportInventoryCountCSV() {
        return await window.axios.get('/orders/export/inventory-count-csv')
            .then(res => {
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                let now = new Date();
                let Y = now.getFullYear();
                let M = (now.getMonth()+1).toString().padStart(2, '0');
                let D = now.getDate().toString().padStart(2, '0');
                let h = now.getHours().toString().padStart(2, '0');
                let m = now.getMinutes().toString().padStart(2, '0');
                let unixTime = new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16)
                fileLink.href = fileURL;
                fileLink.setAttribute('download', "在庫消込CSV_" + Y + M + D + "_" + h + m + "_" + unixTime + ".csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(error => {
                console.log('orders.exportInventoryCountCSV: failed', error)
            });
    },
    async exportInvoiceExcel({state, commit}) {
        const params = {
            orders: state.pagination.data
        }

        return await window.axios.post('/orders/export/invoice-excel', params)
            .then(res => {
                let fileURL = res.data
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(error => {
                console.log('orders.exportInvoiceExcel: failed', error)
            });
    },
    async exportCommitComplete() {
        return await window.axios.get('/orders/export/commit-complete')
            .then(res => {
                return true;
            });
    },
    async exportProvisionalCsv() {
        return await window.axios.get('/orders/export/provisional')
            .then(res => {

                console.log("PROV")
                // 現在時刻
                const date = new Date();
                const currentDatetime = date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "_" +
                    date.getHours() + date.getMinutes() + date.getSeconds() ;

                // ファイル出力
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'orders_provisional_' + currentDatetime + '.csv');
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch(error => {
                console.log('orders.exportProvisionalCsv: failed', error)
            });
    },
    async exportProvisionalComplete() {
        return await window.axios.get('/orders/export/provisional-complete')
            .then(res => {
                return true;
            });
    },
    async sendOrderMail({state, commit}, order) {
        return await window.axios.get('/orders/sendmail/order-mail/' + order.id)
            .then(res => {
                commit('setOrderMailedAt', res.data)

                return true
            }).catch(error => {
                console.log('orders.sendOrderMail: failed', error)
            });
    },
    async sendShippingMail({state, commit}, order) {
        return await window.axios.get('/orders/sendmail/shipping-mail/' + order.id)
            .then(res => {
                commit('setShippingMailedAt', res.data)

                return true
            }).catch(error => {
                console.log('orders.sendOrderMail: failed', error)
            });
    },
    async sendCancelMail({state, commit}, order) {
        return await window.axios.get('/orders/sendmail/cancel-mail/' + order.id)
            .then(res => {
                commit('setCancelMailedAt', res.data)

                return true
            }).catch(error => {
                console.log('orders.sendOrderMail: failed', error)
            });
    },
    setDeliveryAddress({state, commit}) {
        const res = state.item.customer_deliveries.filter(function(row, index) {
            console.log(row.id, state.item.delivery_id)
            if(row.id === state.item.delivery_id) {
                return row;
            }
        })

        commit('setDeliveryAddress', res[0])
    },
    resetState({commit}) {
        commit('resetState');
        console.log('orders.resetState')
    },
    resetSearchState({commit}) {
        commit('resetSearchState')
        console.log('orders.resetSearchState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};