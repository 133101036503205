<template>
  <div class="col-md-3 pt-0 d-none d-md-block px-0">
    <!-- Left menu -->
    <div class="col-md-12">
      <div class="card rounded-0 mb-2">
        <div class="card-body">
          <h6 class="card-title pb-2 border-bottom"><i class="fa-solid fa-cart-shopping text-primary fa-2x"></i> ショッピングカート</h6>
          <p class="mb-0">{{ itemCount | numberFormat }}商品</p>
          <p class="mb-2">総額　{{ orderTotal | numberFormat }}円</p>
          <div class="float-right">
            <a href="/#/cart"><button type="button" class="btn btn-primary btn-sm">カートの詳細を見る</button></a>
          </div>
        </div>

        <div class="card-body border-top">
          <h6 class="card-title mb-0"><a href="https://line.me/R/ti/p/@648oaqay" target="_blank"><i class="fa-brands fa-line fa-2x" style="color: #06c755;"></i> LINEでお問い合わせ</a></h6>
        </div>
        <div class="card-body border-top">
          <h6 class="card-title mb-0"><a href="/#/contact"><i class="fa-solid fa-envelope fa-lg text-primary"></i>  メールでお問い合わせ</a></h6>
        </div>
      </div>
      <div class="card mb-3 rounded-0">
        <ul class="list-group list-group-flush border-top-0">
          <li class="list-group-item border-top py-0 bg-primary text-white rounded-0"><small>カテゴリー</small>
          </li>
          <template v-for="(category) in categories">
            <template v-if="category.children.length >0">
              <!-- 親 -->
              <router-link
                  :to="{ name: 'CategoryById', params: { id: category.id }}"
                  :key="category.id"
              >

                <li class="list-group-item list-group-item-menu py-1 border-top">
                  <template v-if="isSelected(category.id)">
                    <i class="fas fa-map-marker-alt text-primary"></i>
                  </template>
                  {{ category.name }}
                </li>
              </router-link>

              <!-- 子 -->
              <b-collapse :id="'collapse-category-' + category.id" class="mt-1 border-top" :visible="isVisible">
                <ul class="list-group list-group-flush">
                  <template v-for="(child) in category.children">
                      <router-link
                          :to="{ name: 'CategoryById', params: { id: child.id }}"
                      >
                    <li class="list-group-item list-group-item-menu pl-4 py-1" :key="child.id">
                        <template v-if="isSelected(child.id)">
                          <i class="fas fa-map-marker-alt text-primary"></i>
                        </template>
                        {{ child.name }}
                    </li>
                      </router-link>
                  </template>
                </ul>
              </b-collapse>
            </template>
            <template v-else>
              <router-link
                  :to="{ name: 'CategoryById', params: { id: category.id }}"
                  :key="category.id"
              >

                <li class="list-group-item list-group-item-menu py-1 border-top">
                  <template v-if="isSelected(category.id)">
                    <i class="fas fa-map-marker-alt text-primary"></i>
                  </template>
                  {{ category.name }}
                </li>
              </router-link>
            </template>

          </template>
        </ul>
      </div>
      <div class="card border-0 rounded-0 mb-4"><a href="#"><div class="card-body" style="background-color: #00784c;">
        <h5 class="card-subtitle mb-2 text-warning font-weight-bold">後払い決済サービス</h5>
        <p class="card-text text-white">モバイルリペアセンターなら、お支払い時に後払いが選べます！</p>
        <p class="card-text text-white copy08"><i class="fa-regular fa-square-check"></i> 手数料なし<br/>&nbsp;（商品代金以外は一切無料）<br/><i class="fa-regular fa-square-check"></i> 弊社基準の簡易審査あり※<br/><i class="fa-regular fa-square-check"></i> 個人事業主もご利用可！</p>
      </div></a>
        <img class="mt-3" src="images/common/credit.jpg"/>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import store from "../../../admin/src/store";

export default {
  name: 'CategoryLeftMenu',
  components: {},
  data() {
    return {
      spinner: false,
      currentCategoryId: null,
    }
  },
  methods: {
    ...mapActions('categories', [
      'fetchForFront'
    ]),
    isSelected(id) {
      return id === this.$route.params.id
    }
  },
  computed: {
    ...mapGetters('categories', [
      'categories',
    ]),
    ...mapGetters('newCart', [
      'orderTotal', 'itemCount'
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
    isVisible() {
      const currentCategoryId = this.$route.params.id

      let isParent = false
      const res = this.categories.filter(function(row) {
        isParent = row.children.filter(function(child) {
          return child.id === currentCategoryId
        })
      });

      return !!isParent
    },
  },
}
</script>
