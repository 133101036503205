<template>
  <div class="pt-4 pb-5">
    <div class="container">
      <div class="row col-12">
        <div class="copy09 mb-4 pb-1">
          <router-link
              :to="{ name: 'Home' }"
          >
            トップ
          </router-link>
          <template v-if="breadcrumb.parent_category_id > 0">
            >
            <router-link :to="{ name: 'CategoryById', params: { id: breadcrumb.parent_category_id }}">
              {{ breadcrumb.parent_category_name }}
            </router-link>
            > {{ breadcrumb.category_name }}
          </template>

          <template v-else>
              >
              {{ breadcrumb.category_name }}
          </template>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 px-3 px-md-2">
          <h1 class="top_ttl mb-0 copy22"><span class="text">Category</span><span class="copy14 ml-0 ml-md-2 text">カテゴリーから探す</span>
          </h1>
        </div>
      </div>
      <div class="row py-3">
        <CategoryLeftMenu></CategoryLeftMenu>

        <div class="col-12 col-md-9 px-3 d-md-none">
          <template v-for="child in getTargetCategoryChildren()">
            <div>
              <a :href="'/#/category/' + child.id"><p class="py-1 px-2 bg-light border mb-2"><i class="fas fa-arrow-right mr-1 text-gray"></i>{{ child.name }}</p></a>
            </div>
          </template>
        </div>

        <div class="col-md-9 order-md-2">
          <div class="row px-3 px-md-0">
            <!--{ 検索結果有り }-->
            <template v-if="products">
              <ComponentListProduct :items="products"></ComponentListProduct>
            </template>
            <!--{ 検索結果無し }-->
            <template v-else>
              条件にマッチする商品が見つかりませんでした
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import CategoryLeftMenu from "@/components/CategoryLeftMenu"
import ComponentListProduct from "@/components/List/Product"
export default {
  name: 'CategoryById',
  components: {
    CategoryLeftMenu,
    ComponentListProduct
  },
  data() {
    return {
      spinner: false,
      categoryId: 0,
      categoryName: '',
      parentCategoryId: 0,
      parentCategoryName: '',
      hasParent: false,
    }
  },
  methods: {
    getCategory(id) {
      if(this.list.length > 0) {
        let res = this.list.find(function (row) {
          return row.id == parseInt(id)
        })

        return res
      } else {
        return {
          'id': 0,
          'name': '',
        }
      }
    },
    getParentCategory(id) {
      if(this.list.length > 0) {
        let child = this.list.find(function(row) {
          return row.id == parseInt(id)
        })

        let res = this.list.find(function(row) {
          return row.id == parseInt(child.parent_id)
        })

        return res
      } else {
        return {
          'id': 0,
          'name': '',
        }
      }
    },
    getTargetCategoryChildren() {
      let res = this.categories.filter(row => row.id == this.$route.params.id)
      res = res[0]

      console.log("RES", res)

      if(res !== undefined) {
        return res.children
      }

      return null
    }
  },
  computed: {
    ...mapActions('newProducts', [
      'fetchByCategoryId',
    ]),
    ...mapGetters('newProducts', [
      'products',
    ]),
    ...mapGetters('categories', [
      'list', 'breadcrumb', 'categories'
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  mounted: async function() {
    await store.commit('setLoading', false)
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id !== undefined) {
      await store.dispatch(
          "newProducts/fetchByCategoryId",
          to.params.id
      );

      await store.dispatch(
          "categories/fetchList",
      );

      await store.dispatch(
          "categories/fetchBreadcrumb",
          to.params.id
      )
    }

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.id !== undefined) {
      await store.dispatch(
          "newProducts/fetchByCategoryId",
          to.params.id
      );

      await store.dispatch(
          "categories/fetchList",
      );

      await store.dispatch(
          "categories/fetchBreadcrumb",
          to.params.id
      )
    }

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
