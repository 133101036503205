<template>
  <div class="text-center m-5">
    {{ isLKF }}
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import Input from "@/components/Form/Input"
import router from "@/router";
import filter from "lodash/filter"


export default {
  name: 'LKF',
  data() {
    return {
      isLKF: window.localStorage.getItem('isLKF')
    }
  },
  methods: {

  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    // Common
    await store.commit('setLoading', false)

    window.localStorage.setItem('isLKF', true)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
}
</script>