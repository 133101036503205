<template>
  <section class="py-4 row px-3 news">
    <h2 class="mb-3 top-title col-lg-7 pl-2 bg-primary">NEWS <span class="copy12 ml-2">お知らせ</span></h2>
    <div style="width:100%;height:320;overflow-x:hidden;overflow-y:auto;">
      <table class="table">
        <tbody>
          <template v-for="(item, index) in news">
            <template v-if="index < 4">
              <div class="row my-3 border-bottom">
                <template v-if="item.redirect_url">
                  <tr class="col-12 border-top">
                    <td class="pr-3 border-0">{{ item.created_at | toDate }}</td>
                    <td class="p-3 border-0">
                      <p class="mb-1 font-weight-bold">{{ item.title }}</p>
                      <p v-html="item.description">
                      </p>
                    </td>
                  </tr>
                </template>
              </div>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import store from "../../../admin/src/store";

export default {
  name: 'News',
  components: {},
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    ...mapActions('news', [
      'fetchLatest', 'delete'
    ]),
  },
  computed: {
    ...mapGetters('news', [
      'news',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  created() {
    this.fetchLatest()
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(
        "news/fetchLatest",
    );

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>