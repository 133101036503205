<template>
  <div>
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="this.onCancel"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    <div v-show="isLoading" style="width: 100vh; height: 100vh;"></div>
    <div v-show="!isLoading">
      <div class="py-5 text-center align-items-center d-flex login-bg ball-pulse">
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import TransitionFade from "@/components/TransitionFade"
import PasswordReminder from "@/views/Login/PasswordReminder"
import EmailVerification from "@/views/Login/EmailVerification"
import store from "@/store";
import router from "@/router";
import Input from "@/components/Form/Input"

export default {
  name: "Logout",
  data() {
    return {}
  },
  mounted: async function() {
    await store.dispatch("authCustomer/Logout").then(() => {
      router.push('/').catch(function(err){ console.log(err) })
    })
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },

  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
    }),
  },
  methods: {
    ...mapActions('authCustomer', [
      'Logout'
    ]),
  },
};
</script>
