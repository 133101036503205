<template>
  <div>
    <CInput
        v-model="message"
        label="Input is valid"
        valid-feedback="Input is valid."
        invalid-feedback="Please provide at least 4 characters."
        value="Valid value"
        :is-valid="validator"
    />
  </div>
</template>
<script>
export default {
  props: {

  },
  methods: {
    validator(val) {
      return val ? val.length >= 4 : false
    }
  }
}
</script>