import Vue from "vue";

const state = {
    shippingFees: [],
    shippingFee: {
        id: '',
        pref: '',
        shipping_fee: '',
        tax: 0,
        isEdit: false,
        created_at: '',
        updated_at: '',
    }
};

const initialState = {
    shippingFees: [],
    shippingFee: {
        id: '',
        pref: '',
        shipping_fee: '',
        tax: 0,
        isEdit: false,
        created_at: '',
        updated_at: '',
    }
};


const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.items.unshift(item)
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);

        const index = state.items.indexOf(newItem)
        this.closeEdit(state.items[index])
    },
    delete(state, index) {
        state.items.splice(index, 1);
    },
    store(state, item) {
        state.items.unshift(item);
    },
    openEdit(state, item) {
        const index = state.items.indexOf(item);
        state.items[index].isEdit = true
    },
    closeEdit(state, item) {
        const index = state.items.indexOf(item);
        state.items[index].isEdit = false
    },
    closeAllEdit(state, item) {
        state.items.forEach(item => {
            item.isEdit = false
        })
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    shippingFees: state => {
        return state.items;
    },
    shippingFee(state) {
        return state.item;
    }
};

const actions = {
    async fetch({commit}) {
        await window.axios.get('/shipping-fees').then(res => {
            res.data.map((item) => {
                return {...item}
            });

            let listOfObjects = Object.keys(res.data).map((key) => {
                res.data[key].isEdit = false
                return res.data[key]
            })

            commit('setItems', listOfObjects);

            return listOfObjects;
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/shipping-fees/' + id).then(res => {
            commit('setItem', res.data);

            return res.data;
        });
    },
    async delete({state, commit}, item) {
        const index = state.items.indexOf(item);

        return await window.axios.post('/shipping-fees/delete', item)
            .then(res => {
                commit('delete', index);
                return true;
            }).catch(error => {
                return error;
            });
    },
    async store({state, commit}, item) {
        return await window.axios.post('/shipping-fees/store', item)
            .then(res => {
                commit('store', res.data);
                console.log("RES", res.data)
                return true;
            }).catch(error => {
                return error;
            });
    },
    async update({state, commit}, newItem) {
        const index = state.items.indexOf(newItem);
        const item = state.items[index];

        return await window.axios.post('/shipping-fees/update', newItem)
            .then(res => {
                commit('update', {item, newItem});
                return true;
            }).catch(error => {
                return error;
            });
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};