import Vue from "vue";

const state = {
    items: [],
    labels: [],
    lists: [],
};

const initialState = {
    items: [],
    labels: [],
    lists: [],
};

const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setLabels(state, labels) {
        state.labels = labels
    },
    setLists(state, lists) {
        state.lists = lists
    },
    setSelectOptions(state, selectOptions) {
        state.selectOptions = selectOptions
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    }
};

const getters = {
    enums(state) {
        return state.items;
    },
    pref(state) {
        return state.items['pref']
    },
    /*
    getEnumDescription(state, type, key) {
        return _filter(state.labels, function(v, k) {
            return (k === key && _has(v, key)) ? v[key] : console.debug('store.enums.getDescription: Enum type or key is not found.');
        });
    },
    getEnumList(state, type) {
        return _filter(state.lists, function(v, k) {
            return (k === key) ? v : console.debug('store.enums.getList: Enum type or key is not found.');
        });
    },
     */
};

const actions = {
    async fetch({state, commit}) {
        if(state.items.length > 0) {
            return true
        }

        await window.axios.get('/enums').then(res => {
            commit('setItems', res.data);
            console.log(res.data);

            return true;
        });
    },
    async fetchLists({commit}) {
        await window.axios.get('/enums/lists').then(res => {
            commit('setLists', res.data);

            return true;
        });
    },
    async fetchLabels({commit}) {
        await window.axios.get('/enums/labels').then(res => {
            commit('setLabels', res.data);

            return true;
        });
    },
    async fetchSelectOptions({commit}) {
        await window.axios.get('/enums/select-options').then(res => {
            commit('setSelectOptions', res.data);

            return true;
        });
    },
    resetState({commit}) {
        commit('resetState');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};