import Vue from "vue";

const state = {
    item: {
        id: 1,
        data: {
            "minimum_purchase_amount": 0,
            "required_for_free_shipping": 0,
            "required_for_free_charge": 0,
        }
    },
    errors: {},
};

const initialState = {
    item: {
        id: 1,
        data: {
            "minimum_purchase_amount": 0,
            "required_for_free_shipping": 0,
            "required_for_free_charge": 0,
        }
    },
    errors: {},
};
const mutations = {
    setItem(state, item) {
        state.item = item
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    configurations(state) {
        return state.item;
    },
};

const actions = {
    async fetchOne({commit}, id) {
        await window.axios.get('/configurations').then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('configurations.fetchOne', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('configurations.fetchOne.error', error)
        });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/configurations', newItem)
            .then(res => {
                commit('unsetErrors')
                console.log('configurations.update', res, newItem)
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('configurations.update.error', error, newItem)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('news.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};