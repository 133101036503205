<template>
  <div class="row w-100">
    <template v-for="(product) in items">
      <template v-if="viewMode === 'Product' || viewMode === 'CampaignProduct' || viewMode === 'CampaignPresent'">
        <div class="p-3 col-lg-4 col-md-6" :key="product.id">
          <!-- キャンペーン商品在庫切れ -->
          <template v-if="product.is_out_of_stock == 1">
            <img class="img-fluid d-block mb-2" v-lazy="product.main_image_path">
          </template>
          <!-- キャンペーン商品在庫あり -->
          <template v-else>
            <router-link
                :to="{ name: 'ProductDetail', params: { id: product.product_id } }"
            >
              <img class="img-fluid d-block mb-2" v-lazy="product.main_image_path">
            </router-link>
          </template>

          <!-- キャンペーン商品在庫切れ -->
          <template v-if="product.is_out_of_stock == 1">
            <span class="badge badge-info mt-1 mb-1 px-2">入荷待ち</span>
            <p class="mb-1">{{ product.product_name }}</p>
          </template>
          <!-- キャンペーン商品在庫あり -->
          <template v-else>
            <router-link
                :to="{ name: 'ProductDetail', params: { id: product.product_id } }"
            >
              <p class="mb-1">{{ product.product_name }}</p>
            </router-link>
          </template>


          <!-- 商品 -->
          <template v-if="viewMode === 'Product'">
            <p class="text-right">
              <span v-if="computedIsLoggedIn">販売価格: {{ product.price.sales_price_tax | numberFormat }}円 (税込)</span>
              <span v-else>販売価格: 非公開</span>
            </p>

            <template v-if="isLKF">
              <p class="text-right" style="color: indigo;">会員区分: {{ product.grade | numberFormat }}</p>
            </template>
          </template>

          <!-- キャンペーン対象商品 -->
          <template v-else-if="viewMode === 'CampaignProduct'">
            <p class="text-right text-small mb-1" v-if="product.price.retail_price_tax !== product.price.campaign_price_tax">上代: {{ product.price.retail_price_tax | numberFormat }}円 (税込)</p>
            <p class="text-right text-small mb-1" v-if="product.price.sales_price_tax > 0">通常価格: {{ product.price.sales_price_tax | numberFormat }}円 (税込)</p>
            <p class="text-right" v-if="product.price.campaign_price_tax > 0">
              <span class="text-danger" v-if="computedIsLoggedIn">キャンペーン価格: {{ product.price.campaign_price_tax | numberFormat }}円 (税込)</span>
              <span v-else>キャンペーン価格: 非公開</span>
            </p>

            <template v-if="isLKF">
              <p class="text-right" style="color: indigo;">ID: {{ product.id }}</p>
              <p class="text-right" style="color: indigo;">会員区分: {{ product.grade | numberFormat }}</p>
              <p class="text-right" style="color: indigo;">通常販売価格: {{ product.price.sales_price_tax | numberFormat }}円 (税込)</p>
              <p class="text-right" style="color: indigo;" v-if="product.price.campaign_price_tax > 0">キャンペーン価格: {{ product.price.campaign_price_tax | numberFormat }}円 (税込)</p>
              <p class="text-right" style="color: indigo;" v-if="product.required_quantity > 0">購入数: {{ product.required_quantity | numberFormat }}</p>
              <p class="text-right" style="color: indigo;" v-if="product.campaign_ratio > 0">掛率変更: {{ product.campaign_ratio | numberFormat }} ({{ product.campaign_ratio * 100 }}%)</p>
            </template>
          </template>

          <!-- プレゼント対象商品 -->
          <template v-else-if="viewMode === 'CampaignPresent'">
            <template v-if="isLKF">
              <p class="text-right" style="color: indigo;">{{ product.present_quantity }}個プレゼント</p>
            </template>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
  name: 'ComponentListProduct',
  props: {
    items: {
      required: true
    },
    ratio: {
      type: Number,
    },
    viewMode: {
      type: String,
      default: 'Product'
    },
  },
  data() {
    return {
      spinner: false,
      slide: 0,
      isDebug: process.env.VUE_APP_DEBUG,
      isLKF: window.localStorage.getItem('isLKF') ? window.localStorage.getItem('isLKF') : false,
    }
  },
  method: {
  },
  computed: {
    ...mapGetters('newProducts', [
      'products', 'getRetailPriceByProductCode'
    ]),
    ...mapGetters('authCustomer', [
      'isLoggedIn',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
    computedIsLoggedIn() {
      console.log(this.$store.state.authCustomer.isLoggedIn)
      return this.$store.state.authCustomer.isLoggedIn
    },
  },
}
</script>
