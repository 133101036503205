<template>
  <!-- Foorer -->
  <div class="bg-light pt-4 pb-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 py-3"> <a href="#" class="d-inline-block h2 mb-3 text-decoration-none text-uppercase"><img src="images/common/logo-gray.png"/></a>
          <div class="pl-3">
            <p class="mb-0 copy09">営業時間10:30～17:00（年中無休）</p>
            <div class="mb-4 copy18 font-weight-bold">
              <a href="tel:03-5285-8019" class="text-primary"><i class="fa-solid fa-phone-flip"></i> 03-5285-8019</a> <br/>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-4 py-3">
          <h2 class="font-weight-bold h5 mb-3 text-primary text-uppercase">MENU</h2>
          <ul class="list-unstyled">
            <li class="mb-2"> <a href="/#/about">会社概要</a>
            </li>
            <li class="mb-2"> <a href="/#/specified-commercial-law">特定商取引法に基づく表記</a>
            </li>
            <li class="mb-2"> <a href="/#/privacy-policy">プライバシーポリシー</a>
            </li>
            <li class="mb-2"> <a href="/#/contact">お問い合わせ</a>
            </li>
          </ul>
        </div>
        <div class="col-md-4 col-lg-4 py-3">
          <h2 class="font-weight-bold h5 mb-3 text-primary text-uppercase">service</h2>
          <ul class="list-unstyled">
            <li class="mb-2"> <a href="/#/repair/instead">修理代行について</a>
            </li>
            <li class="mb-2"> <a href="/#/repair/board">基板修理について</a>
            </li>
            <li class="mb-2">
              <a href="/#/agency-recruitment">代理店募集</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="py-2 small border-top">
        <div class="align-items-center row">
          <div class="col-md pb-2 pt-2">
            <p class="mb-0 text-center">Copyright &copy; モバイルリペアセンター All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>

export default {
  name: 'Footer',
  data() {
    return {}
  },
}
</script>