<template>
  <div>
    <div class="py-4" style="">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center d-flex justify-content-center" style="">
            <h1 class="border-bottom pb-3 text-primary" style="font-family: &quot;M PLUS 1p&quot;, sans-serif; font-weight: 700;">代理店募集</h1>
          </div>
        </div>
        <div class="row justify-content-center" style="">
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-9" style="">
            <p class="mb-0">
              iPhone修理・iPhoneカスタマイズ・関連製品販売などのサービスを行う代理店様・SOHO様を全国的に広く募集しております。<br/>
              <br/>
              昨今のiPhone人気は衰えるどころか益々の人気を博しており、それに伴い修理を必要とするお客様のニーズは比例して増えております。<br/>
              <br/>
              同時に修理業に目を向けられる方も増え、弊社にも多くのご相談をいただいております。弊社も強靭な代理店フローをご用意しており、新規ビジネスとしてスタートされる方々を手厚くサポートしております。<br/>
              <br/>
            </p>
          </div>
          <div class="col-md-9">
            <div class="h5">
              <span class="check-text">
                <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                <span class="check-text">これから修理業を営もうとしている方</span>
              </span>
            </div>
            <div class="h5">
              <span class="check-text">
                <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                <span class="check-text">修理用品・修理部品の販売のため仕入元を探している</span>
              </span>
            </div>
            <div class="h5">
              <span class="check-text">
                <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                <span class="check-text">修理業を営んでおり、仕入れ価格に不満を抱えている方</span>
              </span>
            </div>
            <div class="h5">
              <span class="check-text">
                <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                <span class="check-text">修理業を営んでおり、仕入れ商品の品質、納期に不満を抱えている方</span>
              </span>
            </div>
          </div>
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-9" style="">
            <p class="mb-0">
              <span class="font-weight-bold">
                この様な悩みを持っている方、 <br />
                是非当社にお問い合わせ、ご相談ください！
              </span>
            </p>
          </div>
          <div class="col-md-9 bg-light pt-2 pb-2 pr-3 pl-3">
            <div class="row">
              <!-- vision 01 -->
              <div class="col-md-4 bg-light pt-2 pb-2">
                <div class="row col-md-12 bg-white mx-auto vision-height">
                  <div class="row col-md-4 bg-gray mx-auto">
                    <span class="vision-text-small">VISION</span>
                    <span class="vision-text-large">01</span>
                  </div>
                  <div class="row col-md-12 d-flex justify-content-center text-center mx-auto p-0">
                  <span class="d-block mt-3">
                    総務省の推奨する <br />
                    「登録修理業者制度」<br />
                    に基づいた運営<br />
                    (適法な営業)<br />
                    <br />
                    <br />
                  </span>
                  </div>
                </div>
              </div>
              <!-- vision 02 -->
              <div class="col-md-4 bg-light pt-2 pb-2">
                <div class="row col-md-12 bg-white mx-auto vision-height">
                  <div class="row col-md-4 bg-gray mx-auto">
                    <span class="vision-text-small">VISION</span>
                    <span class="vision-text-large">02</span>
                  </div>
                  <div class="row col-md-12 d-flex justify-content-center text-center mx-auto p-0">
                  <span class="d-block mt-3">
                    毎日数回市場の価格を<br />
                    見ながら、価格調整を<br />
                    行うため低価格での提供を可能としています<br />
                    <br /><br />
                  </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 bg-light pt-2 pb-2">
                <div class="row col-md-12 bg-white mx-auto vision-height">
                  <div class="row col-md-4 bg-gray mx-auto">
                    <span class="vision-text-small">VISION</span>
                    <span class="vision-text-large">03</span>
                  </div>
                  <div class="row col-md-12 d-flex justify-content-center text-center mx-auto p-0">
                  <span class="d-block">
                    修理・店舗経営の専門スタッフが在籍しておりますのでどんな相談にも親身になって回答させていただきます <br />
                    <br />
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-4">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="py-0">
          <div class="container">
            <div class="row bg-light">
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center"
                     style="">
                  <i class="fa fa-building fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">主要代理店</h2>
                  <p class="mb-3">
                    <a href="https://buyerscorp.co.jp/" alt="">バイヤーズ株式会社（ダイワンテレコム）</a> <br/>
                    <a href="http://flash-agt.com/company/open_iphone-repairking_akasakatameikesannou/">フラッシュエージェント（TSUTAYA系列）</a> <br/>
                    <a href="http://沖縄スマホ修理.com/">株式会社イーグル（スマホエステ）</a><br/>
                    <br />
                    他多数 <br />
                    <small>※ 順不同、敬称略</small>

                  </p>
                </div>
              </div>
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <i class="fas fa-database fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">
                    ノウハウ</h2>
                  <p class="mb-3">
                    弊社では長年iPhone、iPad、Android、ゲーム機パーツの卸販売を行ってきました。　<br />
                    また同時にiPhone修理の店舗として10数年の実績があります<br />
                    販売店舗、修理ノウハウを持っておりますので多角的にサポートが可能です<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-4 mt-3">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="col-md-12 pb-2 text-center">
          <p class="font-weight-bold">iPhoneのパーツはもちろん、iPadやAndroidのパーツも扱っております。お気軽にご相談・お問い合わせくださいませ。</p>  <br />
          <router-link class="btn btn-secondary mt-2 py-2 px-2" to="/contact">
            <i class="fas fa-envelope pr-2"></i>お問い合わせはこちら
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>
<script>
export default {
  name: 'RepairFailed',
  async beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);

    return next();
  },
}
</script>