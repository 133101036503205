<template>
  <div class="py-5 text-center align-items-center d-flex login-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-10 bg-light p-3 p-lg-5 mx-auto border">

          <h1 class="top_read copy22 mb-3">新規会員登録</h1>
          <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit }">
            <form class="mb-4" @submit.prevent="handleSubmit(onSubmitRegister)">
              <div class="form-group">
                <p v-show="errorMessage" class="fade show alert alert-danger">{{ errorMessage }}</p>
                <Input name="メールアドレス" type="text" v-model="form.email_register" rules="required|email"/>
              </div>
              <button type="submit" class="btn btn-secondary px-4 rounded-0">送 信</button> <br>
              <button v-show="showValidationCodeModal" @click.prevent="$bvModal.show('modal-email-verification')" class="btn btn-success my-2 px-4 rounded-0">認証コードを入力する</button>
            </form>
          </ValidationObserver>
          <EmailVerification :email_register="form.email_register"></EmailVerification>

          <h1 class="top_read copy16 mb-2">ログイン</h1>
          <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmitLogin)">
              <p v-show="showError" class="fade show alert alert-danger">メールアドレスかパスワードが間違っています</p>
              <div class="form-group">
                <Input name="メールアドレス" type="text" v-model="form.email" rules="required|email" autocomplete="username"/>
              </div>
              <div class="form-group mb-3">
                <Input name="パスワード" type="password" v-model="form.password" rules="required" autocomplete="current-password"/>
                <small class="form-text text-muted text-right">
                  <PasswordReminder></PasswordReminder>
                </small>
              </div>
              <button type="submit" class="btn btn-secondary px-4 rounded-0">送 信</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import TransitionFade from "@/components/TransitionFade"
import PasswordReminder from "@/views/Login/PasswordReminder"
import EmailVerification from "@/views/Login/EmailVerification"
import store from "@/store";
import router from "@/router";
import Input from "@/components/Form/Input"

export default {
  name: "Login",
  components: { PasswordReminder, EmailVerification, Input },
  data() {
    return {
      fromCart: (this.$route.params.from_cart) ? this.$route.params.from_cart : false,
      form: {
        email: "",
        email_register: "",
        email_reminder: "",
        password: "",
      },
      errorMessage: '',
      showError: false,
      showRegisterError: false,
      showValidationCodeModal: false,
    };
  },
  created: async function() {
    await store.dispatch("categories/resetState");
    await store.dispatch("series/resetState");
    await store.dispatch("payments/resetState");

    await store.dispatch("news/resetState");
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('authCustomer/currentUser')

    return next();
  },

  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
    }),
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
  },
  methods: {
    ...mapActions('authCustomer', [
      'Login', 'Logout',
    ]),
    ...mapGetters('authCustomer', [
       'StateUser'
    ]),
    ...mapActions('emailVerification', [
      'register',
    ]),
    async onSubmitLogin() {
      await store.commit('setLoading', true)
      this.showError = false

      const User = new FormData();
      User.append("email", this.form.email);
      User.append("password", this.form.password);
      User.append("cart_id", window.localStorage.getItem('cart_id'))
      try {
        const res = await this.Login(User);
        console.log(res);

        // 一度リロードしないと、historyが壊れて戻る進むボタンが効かなくなる
        router.push({path: '/my-page'})
      } catch (error) {
        await store.commit('setLoading', false)
        this.showError = true

        console.log('login.error', error)
      }
    },
    async onSubmitRegister() {
      await store.commit('setLoading', true)
      this.showError = false

      const User = new FormData();
      User.append("email", this.form.email_register);
      try {
        const res = await this.register(User);

        console.log(res)

        if(res.error !== '') {
          this.errorMessage = res.error;
          this.showValidationCodeModal = false
        } else {
          this.showValidationCodeModal = true
          this.$bvModal.show('modal-email-verification');
        }
      } catch (error) {
        console.log(error);
        this.showValidationCodeModal = false
        this.showRegisterError = true
      }

      await store.commit('setLoading', false)
    },
    setLoading(value) {
      store.commit('setLoading', value)
    },
  },
};
</script>
