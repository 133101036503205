<template>
    <a v-if="isEnable()">
      <slot></slot>

      <template v-if="!isValid()">
        <span class="text-danger">(日付の形式が不正, from = {{ from }}, to = {{ to }})</span>
      </template>
    </a>
</template>

<script>
import moment from "moment";

export default {
  name: 'TimerLink',
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    isEnable() {
      // エラー発生時は強制出力
      if(!this.isValid()) {
        return true
      }

      const currentTime = moment(new Date())
      const from = moment(this.from)
      const to = moment(this.to)

      return currentTime.isSameOrAfter(from) && currentTime.isSameOrBefore(to)
    },
    isValid() {
      const from = moment(this.from).isValid()
      const to = moment(this.to).isValid()

      if(!from || !to) {
        return false
      }

      return true
    }
  },
};
</script>
