<template>
  <div class="py-3" style="">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
              <div class="row px-2">
                <div class="col-md-12 p-2 my-3">
                  <h3 class="mb-0 font-weight-bold text-primary">特定商取引法</h3>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <td width="20%" class="table-lightgray">販売業者</td>
                    <td style="">株式会社リングライズ</td>
                  </tr>
                  <tr>
                    <td width="20%" class="table-lightgray">運営責任者</td>
                    <td style="">宇都宮 直良</td>
                  </tr>
                  <tr>
                    <td width="20%" class="table-lightgray">住所</td>
                    <td style="">
                      〒169-0075 <br>
                      東京都新宿区高田馬場1丁目33-16 KRビル 1F
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">電話番号</td>
                    <td style="">03-5285-8019</td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">FAX</td>
                    <td style="">-</td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">メールアドレス</td>
                    <td style="">
                      <template v-if="isMirror">
                        <a href="mailto:support@store.mobile-repair-center.jp">support@store.mobile-repair-center.jp</a>
                      </template>
                      <template v-else>
                        <a href="mailto:support@mobile-repair-center.jp">support@mobile-repair-center.jp</a>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">URL</td>
                    <td>
                      <template v-if="isMirror">
                        <a href="https://store.mobile-repair-center.jp">https://store.mobile-repair-center.jp</a>
                      </template>
                      <template v-else>
                        <a href="https://mobile-repair-center.jp">https://mobile-repair-center.jp</a>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">商品以外の必要代金</td>
                    <td>
                      佐川急便でお届け致します。 <br>
                      ・送料(税別3万円以上で送料無料) <br>
                      　都内　　　 :　　810円 <br>
                      　北海道　　 :　1,300円 <br>
                      　東北　　 　:　　870円 <br>
                      　関東/信越　:　　870円 <br>
                      　北陸/東海　:　　870円 <br>
                      　近畿　　　 :　　970円 <br>
                      　中国/四国　:　1,100円 <br>
                      　九州　　　 :　1,300円 <br>
                      　沖縄　　　 :　1,350円 <br>
                      <br>
                      ・代金引換手数料 <br>
                      　～ 3万円 :330円 <br>
                      　～10万円 :550円 <br>
                      　～20万円 :880円 <br>
                      <br>
                      ・銀行振込手数料 <br>
                      <br>
                      ・店頭販売手数料 <br>
                      　通常送料 + 代金引換手数料 <br>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">注文方法</td>
                    <td>
                      ・会員登録後、商品をお選びください。 <br>
                      　商品ページの「カゴに入れる」をクリックして「購入手続きへ」に進みます。<br>
                      　　　　　　　　↓<br>
                      ・お支払い方法やお届け日をご指定頂き、ご注文完了です。<br>
                      　確定後、ご注文完了メールが自動送信されますので内容をご確認ください。<br>
                      <br>
                      ・最低注文価格は税抜き6,000円からご注文いただけます。<br>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">支払方法</td>
                    <td>
                      ・代金引換 <br>
                      　商品受取時、配送業者様に代金をお支払い下さい。 <br>
                      　ご注文完了後、すぐに発送準備に取りかからせて頂きます。 <br>
                      <br>
                      ・クレジットカード <br>
                      　各種クレジットカードご利用可能。 <br>
                      　決済完了後、すぐに発送準備に取りかからせて頂きます。 <br>
                      <br>
                      ・銀行振込(前払い) <br>
                      　ご注文完了後、請求書を添付したメールを別途お送り致します。 <br>
                      　請求書記載の期日までにお振込み頂き、 <br>
                      　ご入金完了後に発送準備に取りかからせて頂きます。 <br>
                      <br>
                      ・後払い <br>
                      　事前にお申込みが必要です。 <br>
                      　詳しくは「各種サービス」内の「後払い決済」をご確認ください。 <br>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray"><a>支払期限</a></td>
                    <td>
                      ・代金引換 <br>
                      　お客様が商品を受け取るとき <br>
                      <br>
                      ・クレジットカード決済 <br>
                      　お客様の契約に基づいた引き落とし日にお支払い <br>
                      <br>
                      ・銀行振込(前払い) <br>
                      　ご注文日より3営業日以内 <br>
                      (期限切れの場合はキャンセルさせて頂きます) <br>
                      <br>
                      ・後払い <br>
                      　お客様の契約に基づいた引き落とし日にお支払い <br>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray"><a>引渡し時期</a></td>
                    <td>
                      ・営業日の15時迄に頂いたご注文かつご入金確認完了で当日発送致します。 <br>
                      　(1～2営業日以内の到着予定) <br>
                      ・銀行振込は入金確認後に発送です。 <br>
                      ・ご注文の集中や交通機関の混雑状況によっては遅れる場合がございます。 <br>
                      ・在庫切れまたは入荷待ち商品の場合は入荷後に発送致します。 <br>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray"><a id="access">返品交換について</a></td>
                    <td>
                      【液晶パネル】 <br> <br>
                      永年保証とさせて頂いております。 <br>
                      お客様の端末に取り付けた後でも保証の対象です。 <br>
                      ※取り外しパネルにつきましては保証をお付けしておりません。 <br>
                      　ご購入前に販売ページをよくご確認ください。 <br>
                       <br>
                      【その他の部品】 <br>
                      商品到着後、90日間の保証期間を設けております。 <br>
                      端末への取り付け時に発生する初期不良のみ対象です。 <br>
                       <br>
                      -----下記に該当する場合は、保証対象外とさせて頂きます。----- <br>
                      ・端子、ケーブル、ガラスに物損があるもの <br>
                      ・弊社の印がないもの <br>
                      ・納品時の状態でないもの(箱や保護フィルム、納品時ラベルの欠損) <br>
                      ・返送頂いた商品の動作確認が出来ないもの <br>
                      ・修理の際の破損が見受けられるもの(修理店様での作業が原因と考えられるもの) <br>
                      ・故意過失による損傷があるもの <br>
                      ・ご注文時には発生していない将来的なiOSのアップデートにより発生した不具合 <br>
                      ・個人のお客様からのご購入とと見受けられる場合 <br>
                      ・iPad部品とiPhone4パネル <br>
                      　(在庫一掃商品の為保証はお付けしておりません) <br>
                       <br>
                      【返送方法】 <br>
                      出荷時の状態にお戻しし、不良内容を明記頂いたメモ等を同梱の上、 <br>
                      着払にて弊社にご返送ください。(配送業者の指定はございません) <br>
                      到着後当社にて検品を行い、商品代金分をポイント付与させていただきます。 <br>
                      ※商品ページ記載の現時点での販売金額分です。 <br>
                       <br>
                      【ご注意点】 <br>
                      ・保証対象外の部品をお送り頂いた場合は着払いでご返送させて頂きます。 <br>
                      ・不良品の返送は月1回までとさせていただきます。 <br>
                       <br>
                      【個人購入のお客様へ】 <br>
                      当店は会員制のスマホ部品卸売りサイトです。 <br>
                      会員登録は<修理事業関係者様>に限らせて頂きます。 <br>
                      業務用商品の取扱について基本的な知識や技術を <br>
                      お持ちの方を対象とさせて頂いておりますので、 <br>
                      一般の方のご登録やご購入はお受けしておりません。 <br>
                      また、個人のお客様と見受けられる場合には <br>
                      部品の不良が技術の面によるものなのか部品の問題なのか <br>
                      判断が出来兼ねる為、全ての商品の返品保証はお付けいたしておりません。 <br>
                       <br>
                      ご不明な点などございましたらお気軽にお問い合わせくださいませ。 <br>
                      　TEL:<a href="tel:03-5285-8019">03-5285-8019</a><br>
                      　営業時間:10:30～17:00(定休日:日曜) <br>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      isMirror: (process.env.NODE_ENV === 'production-mirror' || process.env.NODE_ENV === 'local-mirror'),
    }
  },
}
</script>