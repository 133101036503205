<template>
  <div>
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="this.onCancel"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    <div v-show="isLoading" style="width: 100vh; height: 100vh;"></div>
    <div v-show="!isLoading">
      <div class="col-md-12">
        <div class="container">
          <div class="row">
            <div class="col-md-12 py-2 px-3 mx-2 px-md-2">
              <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i>マイページ</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-3 px-3 px-md-2">
              <div class="bg-lightgreen col-lg-6 mt-3 mt-md-0">
                <h1 class="contents-ttl copy22 mb-4 p-1">My Page<span class="copy12 ml-2">マイページ</span></h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-0 pb-5">
        <div class="container">
          <div class="row pt-4 pb-3">
            <div class="col-md-12">
              <div>
                <b-tabs pills>
                  <!-- 注文履歴 -->
                  <b-tab title="注文履歴" class="px-04 py-2 nav-pills" active>
                    <div class="fade active show" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                      <div class="p-md-4 col-md-12">
                        <div class="alert alert-info mb-3">
                          こちらのページから「発送処理前のご注文」に限りキャンセルいただけます。 <br>
                          発送処理中、または出荷済みのご注文をキャンセルされる場合は、大変お手数ですが
                          「<router-link :to="{ name: 'Contact' }">お問い合わせフォーム</router-link>」からその旨ご連絡いただくか、<br>
                          「<a href="tel:03-5285-8019">お電話</a>」にてご連絡ください。
                        </div>
                        <h3 class="mb-3 top_ttl">注文履歴</h3>
                        <div class="history-detail">
                          <table class="table">
                            <thead>
                            <tr>
                              <td>
                                <div class="row">
                                  <div class="col-12 col-md-6">注文日時</div>
                                  <div class="col-md-2 col-6">注文番号</div>
                                  <div class="col-md-2 col-6 text-right">合計金額</div>
                                  <div class="col-12 text-right col-md-2"> </div>
                                </div>
                              </td>
                            </tr>
                            </thead>
                            <tbody class="border-bottom">
                            <template v-if="orders.length > 0">
                              <template v-for="(order, index) in orders">
                                <tr>
                                  <td>
                                    <div class="row">
                                      <div class="col-12 col-md-6">{{ order.created_at | toDatetimeJp }}</div>
                                      <div class="col-md-2 col-6">
                                        {{ order.id }}<br>

                                        <!-- 新規受注 & クレカ注文以外キャンセルできる -->
                                        <template v-if="order.status === '1' && order.payment_id !== '1'">
                                          <b-button class="btn rounded-0 btn-sm px-2 mt-2" variant="outline-warning" @click.prevent="handleOrderCancelModal(order.id)">注文をキャンセル</b-button><br>
                                        </template>
                                        <template v-else>
                                          <b-button class="btn rounded-0 btn-sm px-2 mt-2" variant="warning" disabled>注文キャンセル済み</b-button><br>
                                        </template>
                                      </div>
                                      <div class="col-md-2 col-6 text-right">{{ order.payment_total | numberFormat }}円</div>
                                      <div class="col-12 text-right col-md-2">
                                        <b-button v-b-toggle="'collapse-' + index" class="btn rounded-0 btn-sm px-2 mt-2" variant="outline-primary">詳細</b-button>
                                      </div>
                                      <div class="col-12">
                                        <b-collapse :id="'collapse-' + index" class="mt-2">
                                          <b-card class="border-0">
                                            <div class="">
                                              <table class="table">
                                                <thead>
                                                <tr>
                                                  <td class="d-none d-md-block">
                                                    <div class="row mx-0">
                                                      <div class="col-12 px-0 col-md-5">商品名</div>
                                                      <div class="col-md-2 col-6 text-center">モデル番号</div>
                                                      <div class="col-md-2 col-6 text-right">金額</div>
                                                      <div class="col-12 text-right col-md-1">個数</div>
                                                      <div class="col-12 text-right col-md-2"> </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                                </thead>
                                                <tbody class="border-bottom">
                                                <template v-for="detail in order.details">
                                                  <tr>
                                                    <td class="p-1">
                                                      <div class="row mx-0">
                                                        <div class="d-flex align-items-center col-md-3 py-2 py-md-0 col-9">
                                                          <div>
                                                            <p class="mb-0">{{ detail.product_name }}</p>
                                                            <p class="mb-0" v-if="detail.product_class_value1"><small>{{ detail.product_class_type1 }}: {{ detail.product_class_value1 }}</small></p>
                                                            <p class="mb-0" v-if="detail.product_class_value2"><small>{{ detail.product_class_type2 }}: {{ detail.product_class_value2 }}</small></p>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-2 px-0 align-items-center d-flex col-3">
                                                          <img src="images/product/product-01.jpg" class="invisible img-fluid">
                                                        </div>
                                                        <div class="col-md-2 d-flex align-items-center col-6 pb-2 pb-md-0 justify-content-center">
                                                          {{ detail.model_name }}
                                                        </div>
                                                        <div class="col-md-2 d-flex align-items-center justify-content-end col-6 pb-md-0 pb-2 px-0">{{ detail.sales_price_tax | numberFormat }}円</div>
                                                        <div class="d-flex align-items-center col-4 pb-2 pb-md-0 justify-content-end col-md-1">{{ detail.quantity | numberFormat }}個</div>
                                                        <div class="d-flex align-items-center col-8 pb-md-0 justify-content-end pb-2 px-0 col-md-2">
                                                          <template v-if="detail.product_id !== 1 && detail.product_id !== 2">
                                                            <a class="btn rounded-0 btn-sm btn-outline-primary px-2" :href="'/#/product/' + detail.product_id">再度購入する</a>
                                                          </template>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </template>
                                                </tbody>
                                              </table>
                                            </div>
                                            <b-button v-b-toggle="'collapse-' + index" class="float-right btn rounded-0 btn-sm px-2" variant="outline-primary">閉じる</b-button>
                                          </b-card>
                                        </b-collapse>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </template>
                            <template v-else>
                              <tr>
                                <td>
                                  <div class="row text-center">
                                    <b-col>
                                      注文履歴はありません
                                    </b-col>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- プロフィール管理 -->
                  <b-tab title="プロフィール管理">
                    <div class="fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div class="col-md-12 p-md-4">
                        <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit, invalid }">
                          <form @submit.prevent="handleSubmit(onSubmitProfile)">
                            <div class="form-row border-top py-3 border-light">
                              <div class="col-md-2 pt-1">
                                <label>会員ID</label>
                              </div>
                              <div class="form-group col-md-5 mb-0">
                                <input type="text" class="form-control rounded-0" v-model="customer.customer_code" disabled>
                              </div>
                            </div>
                            <div class="form-row border-top py-3 border-light">
                              <div class="col-md-2 pt-1">
                                <label>お名前</label>
                              </div>
                              <div class="form-group col-md-5 mb-0">
                                <Input type="text" class="mb-2 mb-md-0" v-model="customer.name" name="お名前" rules="required"></Input>
                              </div>
                            </div>
                            <div class="form-row border-top py-3 border-light">
                              <div class="col-md-2 pt-1">
                                <label>お名前（カナ）</label>
                              </div>
                              <div class="form-group col-md-5 mb-0">
                                <Input type="text" class="mb-2 mb-md-0" v-model="customer.name_kana" name="フリガナ" rules="required|kana"></Input>
                              </div>
                            </div>
                            <div class="form-group form-row py-3 border-top border-light mb-0">
                              <div class="pt-1 col-md-2">
                                <label>会社名</label>
                              </div>
                              <div class="col-md-10 mb-0">
                                <Input type="text" class="mb-2 mb-md-0" v-model="customer.company_name" name="会社名" rules=""></Input>
                              </div>
                            </div>
                            <div class="form-row py-3 border-top border-light">
                              <div class="pt-1 col-md-2">
                                <label>ご住所</label>
                              </div>
                              <div class="form-group mb-0 col-md-10">
                                <div class="form-inline mb-2" style="">
                                  <label class="mr-2">〒</label>
                                  <Input type="text" class="mb-2 mb-md-0" v-model="customer.zip" name="郵便番号" rules="required"></Input>
                                  <button @click.prevent="findAddress" class="btn btn-primary btn-sm ml-2 rounded-0">住所検索</button>
                                </div>
                                <div class="px-0 col-lg-12">
                                  <div class="form-group">
                                    <ValidationProvider
                                        name="都道府県"
                                        mode="aggressive"
                                        rules="required|min:1"
                                        v-slot="validationContext"
                                    >
                                      <b-form-select
                                          class="w-25 rounded-0"
                                          v-model="customer.pref"
                                          :options="prefs"
                                          value-field="value"
                                          text-field="description"
                                          :state="getValidationState(validationContext)"
                                      >
                                        <template #first>
                                          <b-form-select-option value="">都道府県を選択</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <b-form-invalid-feedback class="mb-3">{{
                                          validationContext.errors[0]
                                        }}
                                      </b-form-invalid-feedback>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="form-group px-0">
                                  <Input type="text" class="mb-2 mb-md-0" v-model="customer.city" name="市区町村名（例：京都市中京区）" rules="required"></Input>
                                </div>
                                <div class="form-group px-0">
                                  <Input type="text" class="rounded-0 mb-2" v-model="customer.address1" name="番地・ビル名（例：○○町○丁目○-○）" rules="required"></Input>
                                </div>
                              </div>
                            </div>
                            <div class="form-group form-row py-3 border-top border-light mb-0">
                              <div class="pt-1 col-md-2">
                                <label>メールアドレス</label>
                              </div>
                              <div class="col-md-10 mb-0">
                                <input type="text" class="form-control rounded-0" v-model="customer.email" disabled>
                              </div>
                            </div>
                            <div class="form-group form-row py-3 border-light mb-0 border-top" style="">
                              <div class="pt-1 col-md-2">
                                <label>電話番号</label>
                              </div>
                              <div class="col-md-10 mb-0">
                                <Input type="number" class="rounded-0" placeholder="00011112222" name="電話番号" v-model="customer.phone" rules="required|numeric|min:10|max:11"></Input>
                              </div>
                            </div>
                            <div class="form-group form-row py-3 border-light mb-0 border-top" style="">
                              <div class="pt-1 col-md-2">
                                <label>携帯電話番号</label>
                              </div>
                              <div class="col-md-10 mb-0">
                                <Input type="number" class="rounded-0" placeholder="00011112222" name="携帯電話番号" v-model="customer.cell_phone" rules="numeric|min:10|max:11"></Input>
                              </div>
                            </div>
                            <div class="py-3 text-center" style="">
                              <p v-show="invalid" class="fade show alert alert-danger rounded-0 mb-5">
                                未入力の項目があるか、内容が適切でない項目があります。
                              </p>
                              <button :disabled="invalid" type="submit" class="btn btn-secondary rounded-0 py-2 px-4">プロフィールを更新する</button>
                            </div>
                          </form>
                        </ValidationObserver>
                      </div>
                    </div>
                  </b-tab>

                  <!-- パスワード変更 -->
                  <b-tab title="パスワード変更">
                    <div class="tab-pane fade show" role="tabpanel">
                      <div class="p-md-4 col-md-12">
                        <h3 class="mb-3 top_ttl">パスワード変更</h3>
                        <p class="mb-4" >新しいパスワードを下のテキストボックスに半角英数字8～32文字以内で入力してください。</p>
                        <div class="row col-sm-6">
                          <ValidationObserver ref="validationObserver2" v-slot="{ handleSubmit, invalid }">
                            <form class="mb-4" @submit.prevent="handleSubmit(onSubmitPassword)">
                            <ValidationProvider
                                name="パスワード"
                                mode="aggressive"
                                rules="required|alpha_num|min:8|max:32|password:@confirm"
                                v-slot="validationContext"
                            >
                              <b-form-input
                                  class="rounded-0 mb-2"
                                  type="password"
                                  name="password"
                                  placeholder="半角英数字8～32文字"
                                  :state="getValidationState(validationContext)"
                                  v-model="customer.password"
                              ></b-form-input>
                              <b-form-invalid-feedback class="mb-3">{{
                                  validationContext.errors[0]
                                }}
                              </b-form-invalid-feedback>
                            </ValidationProvider >

                            <ValidationProvider
                                name="パスワード（確認）"
                                mode="aggressive"
                                rules="required|alpha_num|min:8|max:32"
                                vid="confirm"
                                v-slot="validationContext"
                            >
                              <b-form-input
                                  class="rounded-0 mb-2"
                                  type="password"
                                  name="password"
                                  placeholder="確認のためもう一度入力してください"
                                  :state="getValidationState(validationContext)"
                                  v-model="customer.password_confirm"
                              ></b-form-input>
                              <b-form-invalid-feedback class="mb-3">{{
                                  validationContext.errors[0]
                                }}
                              </b-form-invalid-feedback>
                              <div class="py-3 text-center">
                                <p v-show="onClickPasswordButton && invalid" class="fade show alert alert-danger">未入力の項目があるか、内容が適切でない項目があります。</p>
                                <button @click="onClickPasswordButton = true" :disabled="invalid && onClickPasswordButton" class="btn btn-secondary rounded-0 py-2 px-4">パスワードを更新する</button>
                              </div>
                            </ValidationProvider >
                          </form>
                          </ValidationObserver>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <!-- orders -->
              <div class="tab-content mt-2">
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref="toast"></Toast>
      <b-modal id="orderCancelModal" title="注文をキャンセルする" header-bg-variant="warning" centered>
        <p class="my-4">注文ID「{{ orderCancelTargetId }}」をキャンセルしてもよろしいですか？</p>
        <template #modal-footer="{ ok, cancel }">
          <b-button class="rounded-0" variant="lightgray" @click="cancel()">
            いいえ
          </b-button>
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button class="rounded-0" variant="primary" @click.prevent="handleOrderCancel(orderCancelTargetId)">
            はい
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import store from "@/store";
import Input from "@/components/Form/Input"
import Toast from "@/components/Toast"

export default {
  name: 'MyPage',
  components: { Input, Toast },
  data() {
    return {
      spinner: false,
      validationFailed: false,
      onClickPasswordButton: false,
      active: '',
      orderCancelTargetId: 0,
    }
  },
  methods: {
    ...mapActions('myPage', [
      'fetchCustomer',
      'fetchOrders',
      'fetchOrder',
      'storeCustomer',
      'profileUpdate',
      'passwordUpdate',
      'fetchRegisteredCards',
      'fetchCardEditUrl',
      'orderCancel',
    ]),
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    async onSubmitProfile() {
      this.validationFailed = false

      this.profileUpdate(this.customer).then(res => {
        if (res === true) {
          this.$refs.toast.makeToast('success', '会員情報', '更新しました')
        }
      });
    },
    async onSubmitPassword() {
      this.validationFailed = false

      this.passwordUpdate(this.customer).then(res => {
        if (res === true) {
          this.$refs.toast.makeToast('success', 'パスワード変更', 'パスワードを変更しました')
        }
      });
    },
    async handleOrderCancelModal(orderId) {
      this.orderCancelTargetId = orderId
      this.$bvModal.show('orderCancelModal')
    },
    async handleOrderCancel(orderId) {
      await this.orderCancel(orderId)
      this.$nextTick(() => {
        this.$bvModal.hide('orderCancelModal')
      })
      this.$refs.toast.makeToast('success', 'ご注文のキャンセル', 'ご注文をキャンセルしました')

    },
    async cardEditHandler() {
      let cardEditLink = document.createElement('a');

      cardEditLink.href = this.cardEditUrl;
      document.body.appendChild(cardEditLink);

      this.active = 'card-edit';

      cardEditLink.click();
    },
  },
  computed: {
    ...mapState({
      prefs: state => state.enums.items['pref'],
    }),
    ...mapGetters('myPage', [
      'customer',
      'orders',
      'qrUrl',
      // 'registeredCards',
      // 'cardEditUrl',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  mounted: async function () {
    await store.commit('setLoading', true)

    await this.fetchOrders();
    await this.fetchCustomer();
    this.customer.password = '';
    this.customer.password_confirm = '';
    await store.dispatch('enums/fetch')
    await store.dispatch('categories/fetchForFront')

    // await store.dispatch('myPage/fetchRegisteredCards');
    // await store.dispatch('myPage/fetchCardEditUrl');

    await store.commit('setLoading', false)

  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', true)
    await store.commit('myPage/resetState')

    // await store.dispatch('enums/fetch')
    // await store.dispatch('categories/fetchForFront')

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>