import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";

import authCustomer from './modules/authCustomer'
import contacts from './modules/contacts'
import emailVerification from './modules/emailVerification'
import myPage from './modules/myPage'
import enums from './modules/enums'
import users from './modules/users'

import payments from './modules/payments'
import categories from './modules/categories'
import series from './modules/series'
import news from './modules/news'
import pages from './modules/pages'
import campaigns from './modules/campaigns'
import newCampaigns from './modules/newCampaigns'
import newCart from './modules/newCart'

import products from './modules/products'
import pickupProducts from './modules/pickupProducts'
import newItems from './modules/newItems'
import newProducts from './modules/newProducts'
import relativeProducts from './modules/relativeProducts'
import customers from './modules/customers'
import aggregateHistories from './modules/aggregateHistories'
import islandFees from './modules/islandFees'
import shippingFees from './modules/shippingFees'
import configurations from './modules/configurations'

import Cookies from 'js-cookie';
import cookie from 'cookie';

let ls = new SecureLS();

Vue.use(Vuex)
Vue.use(VueAxios, axios);


const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    isLoading: false,
    fullPage: true,
    isNavOpen: false,
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    isFullPage(state) {
        return state.fullPage;
    },
}

const actions = {
    toggleNav({state, commit}) {
        commit('setNavOpen', !state.isNavOpen)
    },
    async toggleLoading({state, commit}) {
        commit("setLoading", !state.isLoading)
    },
}

const mutations = {
    setNavOpen(state, value) {
        state.isNavOpen = value;
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading
    },
    setFullPage(state, fullPage) {
        state.fullPage = fullPage
    },
}


/* LS */
const getItem = function (key) {
    try {
        ls.get(key);
    } catch(e) {
        console.log("LS error detected: ", e)
        window.localStorage.clear();
        console.log("Local storage has been cleared.")
        router.push('/login')
    }
}

const setItem = function (key, value) {
    try {
        ls.set(key, value);
    } catch(e) {
        console.log("LS error detected: ", e)
        window.localStorage.clear();
        console.log("Local storage has been cleared.")
        router.push('/login')
    }
}

const removeItem = function (key, value) {
    try {
        ls.remove(key);
    } catch(e) {
        console.log("LS error detected: ", e)
        window.localStorage.clear();
        console.log("Local storage has been cleared.")
        router.push('/login')
    }
}

/* Cookie */
const attributes = {
    path: '/',
    domain: process.env.NODE_ENV === 'production' ? '.mobile-repair-center.jp' : 'localhost',
    expires: 7, // days
    secure: true,
}

const getItemFromCookie = function (key) {
    try {
        return Cookies.get(key);
    } catch(e) {
        console.log("Cookies error detected: ", e)
        removeAllItemsFromCookie()
        console.log("Cookie has been cleared.")
        router.push('/login')
    }
}

const setItemFromCookie = function (key, value) {
    try {
        Cookies.set(key, value, attributes)
    } catch(e) {
        console.log("Cookies error detected: ", e)
        removeAllItemsFromCookie()
        console.log("Cookie has been cleared.")
        router.push('/login')
    }
}

const removeItemFromCookie = function (key) {
    try {
        Cookies.remove(key, attributes);
    } catch(e) {
        console.log("Cookies error detected: ", e)
        removeAllItemsFromCookie()
        console.log("Cookie has been cleared.")
        router.push('/login')
    }
}

const removeAllItemsFromCookie = function() {
    Object.keys(Cookies.get()).forEach(function(cookieName) {
        Cookies.remove(cookieName, attributes);
    });
}


if (module.hot) {
    module.hot.accept([
        './modules/authCustomer',
        './modules/contacts',
        './modules/emailVerification',
        './modules/myPage',
        './modules/enums',
        './modules/users',
        './modules/payments',
        './modules/categories',
        './modules/series',
        './modules/news',
        './modules/pages',
        './modules/campaigns',
        './modules/newCart',
        './modules/newCampaigns',
        './modules/products',
        './modules/newProducts',
        './modules/pickupProducts',
        './modules/newItems',
        './modules/relativeProducts',
        './modules/customers',
        './modules/aggregateHistories',
        './modules/shippingFees',
        './modules/islandFees',
        './modules/configurations',
    ], () => {
        store.hotUpdate({
            authCustomer: require('./modules/authCustomer'),
            contacts: require('./modules/contacts'),
            emailVerification: require('./modules/emailVerification'),
            myPage: require('./modules/myPage'),
            enums: require('./modules/enums'),
            users: require('./modules/users'),
            payments: require('./modules/payments'),
            categories: require('./modules/categories'),
            series: require('./modules/series'),
            news: require('./modules/news'),
            pages: require('./modules/pages'),
            campaigns: require('./modules/campaigns'),
            newCart: require('./modules/newCart'),
            newCampaigns: require('./modules/newCampaigns'),
            products: require('./modules/products'),
            pickupProducts: require('./modules/pickupProducts'),
            newItems: require('./modules/newItems'),
            newProducts: require('./modules/newProducts'),
            relativeProducts: require('./modules/relativeProducts'),
            customers: require('./modules/customers'),
            aggregateHistories: require('./modules/aggregateHistories'),
            shippingFees: require('./modules/shippingFees'),
            islandFees: require('./modules/islandFees'),
            configurations: require('./modules/configurations'),
        })
    })
}

/**
 * Storage functions
 */
import router from 'vue-router'

export default new Vuex.Store({
    modules: {
        authCustomer: authCustomer,
        contacts: contacts,
        emailVerification: emailVerification,
        myPage: myPage,
        enums: enums,
        users: users,
        payments: payments,
        categories: categories,
        series: series,
        news: news,
        pages: pages,
        campaigns: campaigns,
        newCampaigns: newCampaigns,
        newCart: newCart,
        products: products,
        newProducts: newProducts,
        pickupProducts: pickupProducts,
        newItems: newItems,
        relativeProducts: relativeProducts,
        customers: customers,
        aggregateHistories: aggregateHistories,
        shippingFees: shippingFees,
        islandFees: islandFees,
        configurations: configurations,
    },
    state,
    getters,
    actions,
    mutations,
    // TODO: CHECK
    // @see https://www.npmjs.com/package/vuex-extensions
    mixins: {
        mutations: {
            changeState: function (state, changed) {
                Object.entries(changed)
                    .forEach(([name, value]) => {
                        state[name] = value
                    })
            }
        }
    },
    plugins: [
        createPersistedState({
            key: 'BHCFront',
            paths: ['authCustomer'],
            storage: {
                // Cookie用
                // TODO: 動作はしてるっぽい
                // getItem: getItemFromCookie,
                // setItem: setItemFromCookie,
                // removeItem: removeItemFromCookie,
                // LS用
                getItem,
                setItem,
                removeItem,
            },
        }),
    ],
})
