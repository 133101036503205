<template>
  <div>
    <div class="py-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="mb-0 copy08"><a href="/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1"></i>ご注文手続き完了</p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 border-bottom" style="">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="top_ttl mb-0 copy22 text">Order Processing<span class="copy14 ml-0 ml-md-2 mt-2 mt-md-0 text">ご注文手続き</span>
            </h1>
          </div>
        </div>
        <div class="row bs-wizard justify-content-center py-5">
          <div class="col-xs-3 bs-wizard-step complete">
            <div class="text-center bs-wizard-stepnum text-primary">Step 1</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="/#/cart" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">カートの商品</div>
          </div>
          <div class="col-xs-3 bs-wizard-step complete">
            <!-- complete -->
            <div class="text-center bs-wizard-stepnum text-primary">Step 2</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="/#/login" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">ログイン</div>
          </div>
          <div class="col-xs-3 bs-wizard-step complete">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum text-primary">Step 3</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="/#/order/confirm" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">ご注文内容確認</div>
          </div>
          <div class="col-xs-3 bs-wizard-step complete">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum text-primary">Step 4</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="#" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">ご注文の完了</div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-5" style="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="mb-5">
              <h2 class="copy14 pb-3 border-bottom">ご注文が完了しました</h2>
              <p class="mt-3 mb-4" style="">この度はモバイルリペアセンターでのご購入、ありがとうございました。<br>受付完了後、５営業日以内に発送いたします。<br><br>またのご利用をお待ちしております。</p>
              <div class="">
                <a class="btn btn-outline-primary rounded-0 px-3 btn-sm" href="/">トップページに戻る</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
  name: 'Order',
  data() {
    return {
      spinner: false,
      currentStep: 0,
    }
  },
  methods: {
    ...mapActions('newCart', [
      'complete', 'checkout'
    ]),
  },
  computed: {
    ...mapGetters('myPage', [
      'customer',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  mounted: async function() {
    if(window.localStorage.getItem('order_id') > 0) {
      await store.dispatch(
          "newCart/complete",
          window.localStorage.getItem('order_id')
      );
      //this.checkout()
      window.localStorage.removeItem('order_id');
    }
  },
  async beforeRouteEnter(to, from, next) {

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
