<template>
  <div>
    <b-modal :hide-footer=true id="modal-email-verification" title="認証コードを送信しました" class="p-3">
      <p class="my-4 text-center alert alert-success">
        {{ email_register }} 宛に認証コードを送信しました。<br>
        メール内のリンクをクリックするか、<br>
        認証コードをフォームに入力してください
      </p>
      <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
        <b-form-row class="w-100 d-block mx-auto">
          <b-col>
            <form @submit.prevent="handleSubmit(onSubmit)">
              <b-row>
                <b-col sm="12" v-show="showError" >
                  <p class="fade show alert alert-danger">入力された認証コードが間違っています</p>
                </b-col>
                <b-col sm="8">
                  <Input name="認証コード" type="text" v-model="form.code" rules="required" />
                </b-col>
                <b-col sm="4">
                  <button type="submit" class="btn btn-secondary px-4 rounded-0">送 信</button>
                </b-col>
              </b-row>
            </form>
          </b-col>
        </b-form-row>
        <b-form-row class="mt-3">
          <b-col class="text-center">
            <form @submit.prevent="handleSubmit(onSubmitReSend)">
              <button type="submit" class="btn btn-success px-4 rounded-0">認証コードを再送</button>
            </form>
          </b-col>
        </b-form-row>
      </validation-observer>
    </b-modal>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import store from "@/store";
import router from "@/router";
import Input from "@/components/Form/Input"
export default {
  name: "EmailVerification",
  components: {
    Input,
  },
  props: {
    email_register: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      form: {
        code: '',
      },
      showError: false,
      showReSend: false,
    };
  },
  async beforeRouteEnter(to, from, next) {

    return next();
  },

  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
    }),
  },
  methods: {
    ...mapActions('emailVerification', [
      'validateCode', 'reSend', 'register'
    ]),
    async onSubmit() {
      this.setLoading(true)
      this.showError = false

      const User = new FormData();
      User.append("email", this.email_register);
      User.append("code", this.form.code);
      await this.validateCode(User).then(res => {
        if (res === true) {
          this.$router.push({path: '/register', params: { email: this.email_register, code: this.form.code }})
        } else {
          this.showError = true
        }
      });

      this.setLoading(false)
    },
    async onSubmitReSend() {
      this.setLoading(true)
      this.showError = false
      this.showReSend = false;

      const User = new FormData();
      User.append("email", this.email_register);

      try {
        const res = await this.reSend(User);

        this.showReSend = true;

      } catch (error) {
        this.setLoading(false)

        this.showError = true
      }
    },
    setLoading(value) {
      store.commit('setLoading', value)
    },
  },
};
</script>
