import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Root
import Index from '@/views/Index.vue'
import Campaign from '@/views/Campaign.vue'
import CampaignDetail from '@/views/Campaign/Detail.vue'
import Cart from '@/views/Cart.vue'
import Category from '@/views/Category.vue'
import CategoryList from '@/views/CategoryList.vue'
import CategoryById from '@/views/CategoryById.vue'
import Guide from '@/views/Guide.vue'
import Law from '@/views/Law.vue'
import News from '@/views/News.vue'
import Commerce from '@/views/Commerce.vue'
import Privacy from '@/views/Privacy.vue'
import Register from '@/views/Register.vue'
import RegisterFromInvite from '@/views/RegisterFromInvite.vue'

// Contact
import Contact from '@/views/Contact.vue'
import ContactProduct from '@/views/ContactProduct.vue'
import ContactComplete from '@/views/Contact/ContactComplete.vue'
import ContactRepairBoard from '@/views/Contact/RepairBoard.vue'
import ContactRepairBoardDetail from '@/views/Contact/RepairBoardDetail.vue'
import ContactRepairInstead from '@/views/Contact/RepairInstead.vue'
import ContactRepairInsteadDetail from '@/views/Contact/RepairInsteadDetail.vue'
import ContactPanelPurchase from '@/views/Contact/PanelPurchase.vue'
import ContactPanelPurchaseDetail from '@/views/Contact/PanelPurchaseDetail.vue'
import ContactRepairBoardCases from '@/views/Contact/RepairBoardCases.vue'

// Login
import Login from '@/views/Login/Login.vue'
import Logout from '@/views/Login/Logout.vue'
import PasswordReminder from '@/views/Login/PasswordReminder.vue'
import PasswordChange from '@/views/Login/PasswordChange.vue'
import RegisterComplete from '@/views/Login/RegisterComplete.vue'

// MyPage
import MyPage from '@/views/MyPage/MyPage.vue'

// Order
import Order from '@/views/Order/Order.vue'
import OrderComplete from '@/views/Order/Complete.vue'

// Product
import ProductList from '@/views/Product/List.vue'
import ProductDetail from '@/views/Product/Detail.vue'

// Other
import AgencyRecruitment from '@/views/AgencyRecruitment.vue'
import RepairBoard from '@/views/RepairBoard.vue'
import RepairInstead from '@/views/RepairInstead.vue'
import About from '@/views/About.vue'
import SpecifiedCommercialLaw from '@/views/SpecifiedCommercialLaw.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import DeferredPayment from '@/views/DeferredPayment.vue'


// Maintenance
import Maintenance from '@/views/Maintenance.vue'

// Deceit
import LKF from "../views/LKF";

// LP
import LP1 from "@/views/LP/LP1.vue";
import LP2 from "@/views/LP/LP2.vue";
import LP3 from "@/views/LP/LP3.vue";

Vue.use(VueRouter)

const routes = [
    // Root
    {
        path: '/',
        name: 'Home',
        component: Index
    },
    {
        path: '/campaign/:id/:preview',
        name: 'CampaignDetail',
        component: CampaignDetail,
    },
    {
        path: '/campaign/:id',
        name: 'CampaignDetail',
        component: CampaignDetail,
    },
    {
        path: '/campaign',
        name: 'Campaign',
        component: Campaign,
    },

    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
    },
    {
        path: '/category',
        name: 'Category',
        component: Category,
    },
    {
        path: '/category/list/:id',
        name: 'CategoryList',
        component: CategoryList,
    },
    {
        path: '/category/:id',
        name: 'CategoryById',
        component: CategoryById,
    },
    {
        path: '/commerce',
        name: 'Commerce',
        component: Commerce,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/contact-product',
        name: 'ContactProduct',
        component: ContactProduct,
    },
    {
        path: '/contact/complete',
        name: 'ContactComplete',
        component: ContactComplete,
    },
    {
        path: '/contact/repair-board',
        name: 'ContactRepairBoard',
        component: ContactRepairBoard,
    },
    {
        path: '/contact/repair-board-detail',
        name: 'ContactRepairBoardDetail',
        component: ContactRepairBoardDetail,
    },
    {
        path: '/contact/repair-board-cases',
        name: 'ContactRepairBoardCases',
        component: ContactRepairBoardCases,
    },
    {
        path: '/contact/repair-instead',
        name: 'ContactRepairInstead',
        component: ContactRepairInstead,
    },
    {
        path: '/contact/repair-instead-detail',
        name: 'ContactRepairInsteadDetail',
        component: ContactRepairInsteadDetail,
    },
    {
        path: '/contact/panel-purchase',
        name: 'ContactPanelPurchase',
        component: ContactPanelPurchase,
    },
    {
        path: '/contact/panel-purchase-detail',
        name: 'ContactPanelPurchaseDetail',
        component: ContactPanelPurchaseDetail,
    },
    {
        path: '/deferred-payment',
        name: 'DeferredPayment',
        component: DeferredPayment,
    },
    {
        path: '/guide',
        name: 'Guide',
        component: Guide,
    },
    {
        path: '/law',
        name: 'Law',
        component: Law,
    },
    {
        path: '/news',
        name: 'News',
        component: News,
    },
    {
        path: '/register/:email/:code',
        name: 'RegisterFromInvite',
        component: Register,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/register-complete',
        name: 'RegisterComplete',
        component: RegisterComplete,
    },
    // Login
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/login/:from_cart',
        name: 'LoginFromCart',
        component: Login,
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '/password-change/:code',
        name: 'PasswordChange',
        component: PasswordChange,
    },
    // MyPage
    {
        path: '/my-page',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/my-page/:customerCode/invite/:grade',
        name: 'MyPageInvite',
        component: RegisterFromInvite,
    },
    {
        path: '/my-page/:customerCode/invite/:grade/:verifiedEmail/:verificationCode',
        name: 'MyPageInviteByVerified',
        component: RegisterFromInvite,
    },
    {
        path: '/my-page/:active',
        name: 'MyPage',
        component: MyPage,
    },
    // Order
    {
        path: '/order',
        name: 'Order',
        component: Order,
    },
    {
        path: '/order/card/:cardEditResult',
        name: 'Order',
        component: Order,
    },
    {
        path: '/order/complete',
        name: 'OrderComplete',
        component: OrderComplete,
    },
    // Product
    {
        path: '/products/list',
        name: 'ProductList',
        component: ProductList,
    },
    {
        path: '/products/search/name/:productName',
        name: 'ProductListByName',
        component: ProductList,
    },
    {
        path: '/product/:id',
        name: 'ProductDetail',
        component: ProductDetail,
    },
    // Other
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/specified-commercial-law',
        name: 'SpecifiedCommercialLaw',
        component: SpecifiedCommercialLaw,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/agency-recruitment',
        name: 'AgencyRecruitment',
        component: AgencyRecruitment,
    },
    {
        path: '/repair/instead',
        name: 'RepairInstead',
        component: RepairInstead,
    },
    {
        path: '/repair/board',
        name: 'RepairBoard',
        component: RepairBoard,
    },
    // Maintenance
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
    },
    {
        path: '/LKF',
        name: 'LKF',
        component: LKF,
    },
    {
        path: '/lp/1',
        name: 'LP1',
        component: LP1,
    },
    {
        path: '/lp/2',
        name: 'LP2',
        component: LP2,
    },
    {
        path: '/lp/3',
        name: 'LP3',
        component: LP3,
    }
]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async function(to, from, next) {
    store.commit('setLoading', true)

    const env = process.env.VUE_APP_DEBUG
    if(env === 'true') {
        next();
    }

    window.scrollTo(0, 0)

    router['ref'] = from.path;

    const isLoggedIn = window.localStorage.getItem('isLoggedIn')
    const isLKF = window.localStorage.getItem('isLKF')
    if(isLKF == 'true') {
        next();
    }

    // RegisterFromInvite ||
    /*
    if(to.name === 'Maintenance' || isLKF || to.name === 'LKF' ||
        to.name === 'RegisterFromInvite' || to.name === 'Register' || to.name === 'Login' ||
        to.name === 'Logout' || to.name === 'PasswordChange' || to.name === 'MyPage' ||
        to.name === 'MyPageInvite' || to.name === 'MyPageInviteByVerified') {
        next()
    } else {
        // 同一ルート転送エラーを握りつぶす
        router.push({
            name: 'Maintenance'
        }).catch(err => {})
        console.log("MAINTENANCE PAGE")

        await store.commit('setLoading', false)
    }
    */
    next();

    // redirect to login page if not logged in and trying to access a restricted page
    /*
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    if (authRequired && !loggedIn) {
        return next({
            path: '/login',
            query: { returnUrl: to.path }
        });
    }
    */

    //next()
})
router.afterEach(() => {
    store.commit('setLoading', false)
})

export default router
