<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen"
           class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import store from "@/store";

export default {
  methods: {
    closeSidebarPanel() {
      store.dispatch('toggleNav');
    },
  },
  computed: {
    isPanelOpen() {
      return store.state.isNavOpen
    }
  }
}
</script>
<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s
}

.sidebar-backdrop {
  background-color: rgba(84, 84, 84, .6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 999;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: rgba(84, 84, 84, .4);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
}
</style>