<template>
  <div>
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="this.onCancel"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    <div v-show="isLoading" style="width: 100vh; height: 100vh;"></div>
    <div v-show="!isLoading">
      <div class="py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1"></i>パスワード再設定</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-5">
        <div class="container">
          <div class="row pt-4 pb-3">
            <div class="tab-pane fade show" role="tabpanel">
              <h3 class="mb-3 top_ttl">パスワード変更</h3>
              <p class="mb-4" >新しいパスワードを下のテキストボックスに半角英数字8～32文字以内で入力してください。</p>
              <div class="row">
                <ValidationObserver ref="validationObserver2" v-slot="{ handleSubmit, invalid }" class="col-12 col-md-8 mx-2 mx-md-4">
                  <form class="mb-4" @submit.prevent="handleSubmit(onSubmitPassword)">
                  <ValidationProvider
                      name="パスワード"
                      mode="aggressive"
                      rules="required|alpha_num|min:8|max:32|password:@confirm"
                      v-slot="validationContext"
                  >
                    <b-form-input
                        class="rounded-0 mb-2"
                        type="password"
                        name="password"
                        placeholder="半角英数字8～32文字"
                        :state="getValidationState(validationContext)"
                        v-model="password"
                    ></b-form-input>
                    <b-form-invalid-feedback class="mb-3">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </ValidationProvider >

                  <ValidationProvider
                      name="パスワード（確認）"
                      mode="aggressive"
                      rules="required|alpha_num|min:8|max:32"
                      vid="confirm"
                      v-slot="validationContext"
                  >
                    <b-form-input
                        class="rounded-0 mb-2"
                        type="password"
                        name="password"
                        placeholder="確認のためもう一度入力してください"
                        :state="getValidationState(validationContext)"
                        v-model="password_confirm"
                    ></b-form-input>
                    <b-form-invalid-feedback class="mb-3">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                    <div class="py-3 text-center">
                      <p v-if="onClickPasswordButton && invalid" class="fade show alert alert-danger">未入力の項目があるか、内容が適切でない項目があります。</p>
                      <button @click="onClickPasswordButton = true" :disabled="invalid || showSuccess" class="btn btn-primary rounded-0 py-2 px-4">パスワードを再設定する</button>
                      <p v-if="showSuccess" class="mt-3">パスワードを変更しました。<router-link :to="{ name: 'Login' }">ログインページ</router-link>から新しいパスワードでログインいただけます。</p>
                    </div>
                  </ValidationProvider >
                </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref="toast"></Toast>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import store from "@/store";
import Input from "@/components/Form/Input"
import Toast from "@/components/Toast"

export default {
  name: 'PasswordChange',
  components: { Input, Toast },
  data() {
    return {
      spinner: false,
      validationFailed: false,
      onClickPasswordButton: false,
      password: '',
      password_confirm: '',
      code: this.$route.params.code,
      showSuccess: false,
    }
  },
  methods: {
    ...mapActions('emailVerification',[
       'validateCode'
    ]),
    ...mapActions('myPage', [
      'passwordChange'
    ]),
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    async onSubmitPassword() {
      this.validationFailed = false
      const User = new FormData();
      User.append("code", this.code);
      User.append("password", this.password);

      this.passwordChange(User).then(res => {
        if (res === true) {
          this.showSuccess = true
          this.$refs.toast.makeToast('success', 'パスワード変更', 'パスワードを変更しました')
        }
      });
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>