import Vue from "vue";
import size from 'lodash/size'
import last from 'lodash/last'
import cloneDeep from 'lodash/cloneDeep'

const STORE_NAME = 'pickupProducts'

const state = {
    selectedItems: null,
    items: [],
    item: {
        id: 0,
        type: 0,
        base_product_id: 0,
        product_id: 0,
        rank: 0,
    },
    summary: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    prev: null,
    next: null,
    errors: [],
};

const initialState = {
    selectedItems: null,
    items: [],
    item: {
        id: 0,
        type: 0,
        base_product_id: 0,
        product_id: 0,
        rank: 0,
    },
    summary: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    prev: null,
    next: null,
    errors: [],
};

const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    updateSelectedItems(state, selectedItems) {
        state.selectedItems = selectedItems;
    },
    setSummary(state, summary) {
        state.summary = summary
    },
    setErrors(state, errors) {
        state.errors = errors
    },
    resetSelectedItems(state) {
        state.selectedItems = initialState.selectedItems
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    setPrev(state) {
        state.prev = cloneDeep(state)
    },
    setNext(state) {
        state.prev.next = cloneDeep(state)
        Vue.set(state.prev, 'next', cloneDeep(state))
    },
    undo(state) {
        const prevState = state.prev
        Vue.set(state, 'selectedItems', prevState.selectedItems)
    },
    redo(state) {
        const nextState = state.next
        Vue.set(state, 'selectedItems', nextState.selectedItems)
    },
};

const getters = {
    selectedItems: state => {
        return state.selectedItems;
    },
    pickupProducts: state => {
        return state.items;
    },
    summary: state => {
        return state.summary.data;
    },
};

const actions = {
    undo({commit}) {
        commit('undo')
    },
    redo({commit}) {
        commit('redo')
    },
    canUndo({state}) {
        return !!size(state.prev)
    },
    updateSelectedItems({state, commit}, value) {
        commit("updateSelectedItems", value);
    },
    async fetch({commit}) {
        await window.axios.get('/pickup-products').then(res => {
            commit('updateSelectedItems', res.data);

            console.log(STORE_NAME + '.fetch', res)

            return res.data;
        }).catch(error => {
            commit('setErrors', error)
            console.log(STORE_NAME + '.fetch.error', error)
        });
    },
    async fetchList({commit}) {
        await window.axios.get('/pickup-products/list').then(res => {
            commit('setItems', res.data);

            console.log(STORE_NAME + '.fetch', res)

            return res.data;
        }).catch(error => {
            commit('setErrors', error)
            console.log(STORE_NAME + '.fetch.error', error)
        });
    },
    async fetchSummary({commit}, params) {
        await window.axios.get('/products/summary', params).then(res => {
            commit('setSummary', res.data);

            console.log(STORE_NAME + '.summary', res)

            return res.data;
        }).catch(error => {
            commit('setErrors', error)
            console.log(STORE_NAME + '.summary.error', error)
        });
    },
    async save({state, commit}, productId) {

        let params = {};
        state.selectedItems.forEach(function(row, index) {
           state.selectedItems[index].base_product_id = productId
        });

        Object.assign(params, { base_product_id: productId })
        Object.assign(params, { items: state.selectedItems })

        return await window.axios.post('/pickup-products/save', params)
            .then(res => {
                console.log(STORE_NAME + '.save', res)

                return true;
            }).catch(error => {
                commit('setErrors', error)
                console.log(STORE_NAME + '.save.error', error)
            });
    },
    async resetState({commit}) {
        console.log(STORE_NAME + '.resetState')
        commit('resetState');
    },
    async resetSelectedItems({state, commit}) {
        console.log(STORE_NAME + '.resetSelectedItems')
        commit('resetSelectedItems');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};