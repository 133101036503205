<template>
    <div>
      <slot name="before" v-if="isBefore()"></slot>
      <slot name="running" v-if="isRunning()"></slot>
      <slot name="after" v-if="isAfter()"></slot>
      <template v-if="!isValid()">
        <span class="text-danger">(日付の形式が不正, from = {{ from }}, to = {{ to }})</span>
      </template>
    </div>
</template>

<script>
import moment from "moment";
export default {
  name: 'TimerBlock',
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    }
  },
  methods: {
    isRunning() {
      // エラー発生時は強制出力
      if(!this.isValid()) {
        return true
      }

      const currentTime = moment(new Date())
      const from = moment(this.from)
      const to = moment(this.to)

      return currentTime.isSameOrAfter(from) && currentTime.isSameOrBefore(to)
    },
    isBefore() {
      const currentTime = moment(new Date())
      const from = moment(this.from)
      return currentTime.isSameOrBefore(from)
    },
    isAfter() {
      const currentTime = moment(new Date())
      const to = moment(this.to)
      return currentTime.isSameOrAfter(to)
    },
    isValid() {
      const from = moment(this.from).isValid()
      const to = moment(this.to).isValid()

      if(!from || !to) {
        return false
      }

      return true
    }
  },
};
</script>
