import Vue from "vue";
import values from "lodash/values";

const state = {
    items: [],
    item: {
        root_customer_code: '',
        child_customer_code: '',
        root_customer_detail_id: '',
        child_customer_detail_id: '',
        child_customer: [],
        parent_agency_customer_code: '',
        root_is_parent_agency: '',
        root_grade: '',
        root_is_promotion_within_six_months: '',
        child_grade: '',
        child_generation: '',
        root_applied_from: '',
        root_applied_to: '',
        child_applied_from: '',
        child_applied_to: '',
        created_at: '',
        updated_at: '',
        customer_detail: {},
        customer: {},
        histories: {},
    },
    rootHistory: {

    },
    customer: {

    },
    tree: {

    },
    summary: {

    },
    search: {
        per_page: 25,
        page: 1,
        name: '',
        customer_code: '',
        email: '',
        date: '',
        grades: [],
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
};

const initialState = {
    items: [],
    item: {
        root_customer_code: '',
        child_customer_code: '',
        root_customer_detail_id: '',
        child_customer_detail_id: '',
        parent_agency_customer_code: '',
        root_is_parent_agency: '',
        root_grade: '',
        root_is_promotion_within_six_months: '',
        child_grade: '',
        child_generation: '',
        root_applied_from: '',
        root_applied_to: '',
        child_applied_from: '',
        child_applied_to: '',
        created_at: '',
        updated_at: '',
        customer_detail: {},
        customer: {},
        histories: {},
    },
    rootHistory: {

    },
    customer: {

    },
    tree: {

    },
    summary: {

    },
    search: {
        per_page: 25,
        page: 1,
        name: '',
        customer_code: '',
        email: '',
        date: '',
        grades: [],
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
};
const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setSearch(state, search) {
        state.search = search
    },
    setPagination(state, items) {
        Vue.set(state, 'pagination', items);
    },
    setCustomer(state, customer) {
        Vue.set(state, 'customer', customer);
    },
    setRootHistory(state, rootHistory) {
        state.rootHistory = rootHistory
    },
    setHistories(state, histories) {
        Vue.set(state, 'histories', histories);
    },
    setTree(state, tree) {
        Vue.set(state, 'tree', tree);
    },
    setSummary(state, summary) {
        Vue.set(state, 'summary', summary)
    },
    setSuggestList(state, items) {
        Vue.set(state, 'suggestList', items);
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    resetSearchState() {
        state.search.name = '';
        state.search.customer_code = '';
        state.search.email = '';
        state.search.date = '';
        state.search.grade = [];
    },
};

const getters = {
    histories(state) {
        return state.histories;
    },
    rootHistory(state) {
        return state.rootHistory
    },
    customer(state) {
        return state.customer;
    },
    tree(state) {
        return state.tree;
    },
    history(state) {
        return state.item;
    },
    summary(state) {
        return state.summary;
    },
    search(state) {
        return state.search;
    },
    pagination(state) {
        return state.pagination;
    },
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/aggregate-histories', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('aggregateHistories.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('aggregateHistories.fetch.error', error)
        });
    },
    async fetchOne({commit}, rootCustomerCode) {
        await window.axios.get('/aggregate-histories/' + rootCustomerCode).then(res => {

            commit('setRootHistory', res.data.histories.root_history);

            commit('setHistories', res.data.histories);

            console.log(res.data.histories)
            commit('setCustomer', res.data.customer);
            commit('setTree', res.data.tree);
            commit('setSummary', res.data.summary);
            commit('unsetErrors')
            console.log('aggregateHistories.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('aggregateHistories.fetchOne.error', error)
        });
    },
    async search({commit}, item) {
        return await window.axios.post('/aggregate-histories/search', item)
            .then(res => {
                commit('setItems', res.data);
                commit('unsetErrors')
                console.log('aggregateHistories.search', res, item)
            }).catch(error => {
                commit('setErrors', error)
                console.log('aggregateHistories.search.error', error, item)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('aggregateHistories.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};