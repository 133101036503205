<template>

</template>

<script>
import {mapMutations, mapState, mapActions, mapGetters} from "vuex";
import store from "@/store";
import findIndex from 'lodash/findIndex'

export default {
  name: 'AddCart',
  props: {
    quantity: {
      type: Number,
    },
    product: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('newCart', [
      'addProduct',
    ]),
    async addProductHandler(product, quantity) {
      const index = findIndex(product.details, { product_code: product.selected_product_code });

      // カートに商品を入れる
      if(index < 0) {
        console.log('商品をカートに追加できませんでした')
      }

      console.log("QUANTITY", quantity)

      console.log(product.details[index])
      console.log("CAMPAIGN", product.campaign)
      await this.addProduct(product, quantity)

      this.$bvModal.show('add-cart-modal')
    },
  },
}
</script>
