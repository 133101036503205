<template>
  <div>
    <b-link v-b-modal.modal-1>パスワードを忘れたとき</b-link>

    <b-modal id="modal-1" title="パスワードの再設定" hide-footer>
      <p class="my-4 text-center">ご登録のメールアドレスを入力してください</p>
      <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-form-row class="mt-3 mx-2 mx-md-4">
            <Input class="col-12" name="メールアドレス" type="text" v-model="form.email" rules="required|email" />
          </b-form-row>

          <b-form-row class="mt-3">
            <b-col class="text-center">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <template v-if="showSuccess">
                  <p class="my-4 text-center">送信完了しました。<br>届いたメールを確認してください。</p>
                </template>
                <button type="submit" class="btn btn-success px-4 rounded-0 mb-3">再設定用リンクを送信</button>
              </form>
            </b-col>
          </b-form-row>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import store from "@/store";
import router from "@/router";
import Input from "@/components/Form/Input"
export default {
  name: "PasswordReminder",
  components: {
    Input,
  },
  data() {
    return {
      form: {
        email: '',
      },
      email: '',
      showError: false,
      showSuccess: false
    };
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("authCustomer/resetState");
    await store.dispatch("customers/resetState");

    return next();
  },

  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
    }),
  },
  methods: {
    ...mapActions('authCustomer', [
      'passwordReminder'
    ]),
    async onSubmit() {
      const User = new FormData();
      User.append("email", this.form.email);

      try {
        await this.passwordReminder(User).then(res => {
          this.showSuccess = true
        });

      } catch (error) {
        console.log('login.error', error)
      }
    },
    setLoading(value) {
      store.commit('setLoading', value)
    },
  },
};
</script>
