<template>
  <div>
    <div class="py-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt-4">
            <h1 class="top_ttl mb-0 copy22">Other Services<span class="copy14 ml-2">代行修理のご依頼</span>
            </h1>
          </div>
          <div class="col-md-12 mt-3">
            <p>必須項目をご入力のうえ、画面下部の「送信ボタン」を押してください。</p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="nodeEnv === 'development'">
      <div>
        <button @click.prevent="setTestData">テストデータ</button>
      </div>
    </template>
    <div class="container mt-3 d-flex justify-content-center">
      <div class="col-md-9">
        <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <!-- 会社名 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">会社名</label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="株式会社リングライズ" name="会社名"
                         v-model="contact.company_name" rules="max:100"></Input>
                </div>
              </div>
            </div>

            <!-- ご担当者名 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">ご担当者名<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="日本花子" name="ご担当者名"
                         v-model="contact.staff_name" rules="required|max:50"></Input>
                </div>
              </div>
            </div>

            <!-- ご担当者名フリガナ -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">ご担当者名フリガナ<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="ニホンハナコ" name="ご担当者名フリガナ"
                         v-model="contact.staff_name_kana" rules="required|max:50|kana"></Input>
                </div>
              </div>
            </div>

            <!-- メールアドレス -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">メールアドレス<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="example@lingrise.jp" name="メールアドレス"
                         v-model="contact.email" rules="required|email"></Input>
                </div>
              </div>
            </div>

            <!-- 電話番号 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">電話番号</label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="number" class="rounded-0" placeholder="0311112222" name="電話番号"
                         v-model="contact.phone" rules="numeric|min:10|max:20"></Input>
                </div>
              </div>
            </div>

            <!-- 住所 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">住所</label>
              </div>
              <div class="form-group mb-0 col-md-8">
                <div class="form-inline col-md-12 mb-2" style="">
                  <label class="mr-2">〒</label>
                  <Input type="text" class="mb-2 mb-md-0" v-model="contact.zip" placeholder="1500000" name="郵便番号" rules="min:7|max:10"></Input>
                  <button @click.prevent="findAddress" class="btn btn-primary btn-sm ml-2 rounded-0">住所検索</button>
                </div>
                <div class="px-0 col-md-12">
                  <div class="form-group">
                    <ValidationProvider
                        name="都道府県"
                        mode="aggressive"
                        rules="required|min:1"
                        v-slot="validationContext"
                    >
                      <b-form-select
                          class="w-35 rounded-0"
                          v-model="contact.pref"
                          :options="prefs"
                          value-field="description"
                          text-field="description"
                          :state="getValidationState(validationContext)"
                      >
                        <template #first>
                          <b-form-select-option value="">都道府県を選択</b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback class="mb-3">{{
                          validationContext.errors[0]
                        }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group col-md-8 px-0">
                  <Input type="text" class="mb-2 mb-md-0" v-model="contact.city" name="市区町村名" rules="required|max:100"></Input>
                </div>
                <div class="form-group col-md-8 px-0">
                  <Input type="text" class="mb-2" v-model="contact.address1" name="番地・建物名・ビル名" rules="required|max:100"></Input>
                </div>
              </div>
            </div>

            <!-- 発送予定日 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">発送予定日<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-4 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <validation-provider
                      name="発送予定日"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                  >
                    <b-form-input id="delivery_date" type="date" :min="getDeliveryDateMin()" class="form-control rounded-0" v-model="contact.scheduled_shipping_date" />

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!-- 配送業者 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">配送業者<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="ヤマト運輸" name="配送業者"
                         v-model="contact.delivery_service_provider" rules="required|max:30"></Input>
                </div>
              </div>
            </div>

            <!-- 荷物番号 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">荷物番号<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="9999-5236-1122" name="荷物番号"
                         v-model="contact.shipping_number" rules="required|max:50"></Input>
                </div>
              </div>
            </div>

            <!-- ご依頼機種 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">ご依頼機種<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="iPhone XS" name="ご依頼機種"
                         v-model="contact.model_name" rules="required|max:50"></Input>
                </div>
              </div>
            </div>

            <!-- IMEI -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">IMEI</label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <Input type="text" class="rounded-0" placeholder="AA-BBBBBB-CCCCCC-D" name="IMEI"
                         v-model="contact.imei" rules="max:100"></Input>
                </div>
              </div>
            </div>

            <!-- パスコード -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">パスコード有無<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <validation-provider
                      name="パスコード"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                  >
                    <b-form-radio v-model="contact.has_password" value="パスコードロック無し">パスコードロック無し</b-form-radio>
                    <b-form-radio v-model="contact.has_password" value="パスコードロック有り">パスコードロック有り</b-form-radio>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <p class="alert alert-warning">「パスコードロック有り」を選択された場合は、パスコードをご入力ください。</p>
                  <Input type="text" class="rounded-0" placeholder="1234567" name="パスコード"
                         v-model="contact.password" rules="max:50" :disabled="contact.has_password !== 'パスコードロック有り'"></Input>
                </div>
              </div>
            </div>

            <!-- ご依頼修理内容 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">ご依頼修理内容<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <validation-provider
                      name="ご依頼修理内容"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                  >
                    <b-form-radio v-model="contact.failure_reason" value="画面割れ">画面割れ</b-form-radio>
                    <b-form-radio v-model="contact.failure_reason" value="液晶不良">液晶不良</b-form-radio>
                    <b-form-radio v-model="contact.failure_reason" value="バッテリー交換">バッテリー交換</b-form-radio>
                    <b-form-radio v-model="contact.failure_reason" value="充電不良">充電不良</b-form-radio>
                    <b-form-radio v-model="contact.failure_reason" value="その他">その他</b-form-radio>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <p class="alert alert-warning">「その他」を選択された場合は、修理内容に関する詳細をご入力ください。</p>
                  <Input type="text" class="rounded-0" placeholder="iOSが起動しない" name="修理内容詳細"
                         v-model="contact.failure_reason_detail" rules="max:200" :disabled="contact.failure_reason !== 'その他'"></Input>
                </div>
              </div>
            </div>

            <!-- 支払い方法 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">支払い方法<span class="text-danger">*</span></label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <validation-provider
                      name="支払い方法"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                  >
                    <b-form-radio v-model="contact.payment_type" value="銀行振込 (入金確認後に発送致します)">銀行振込 (入金確認後に発送致します)</b-form-radio>
                    <b-form-radio v-model="contact.payment_type" value="代金引換 (基板修理作業が終わり次第、発送致します)">代金引換 (基板修理作業が終わり次第、発送致します)</b-form-radio>
                    <b-form-radio v-model="contact.payment_type" value="後払い決済[Paid] (事前申請を完了されているお取引先様のみ)">後払い決済[Paid] (事前申請を完了されているお取引先様のみ)</b-form-radio>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!-- 備考欄 -->
            <div class="form-group row mb-0 mb-md-2">
              <div class="col-md-4 bg-light mb-sm-2 mb-2 mb-md-2">
                <label for="name" class="col-form-label">備考欄</label>
              </div>
              <div id="name" class="col-md-6 mb-2 mb-md-0 px-0 pl-md-2">
                <div class="form-group">
                  <div class="alert alert-info">
                    障害の経緯や詳細、発送時の希望配達時間帯、その他ご意見やご要望、またはご不明な点がございましたらご入力下さい。
                  </div>

                  <validation-provider
                      name="買取希望パネル入力欄"
                      :rules="{ required: false }"
                      v-slot="validationContext"
                  >
                    <b-form-textarea class="rounded-0"
                                     v-model="contact.message"
                                     :state="getValidationState(validationContext)"
                                     placeholder=""
                                     aria-describedby="input-1-live-feedback"
                                     rows="8"
                    ></b-form-textarea>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!--利用規約-->
            <div class="form-group">
              <div class="copy12 border-bottom mt-3 pb-2 mb-2">個人情報保護方針</div>
              <div class="copy09" style="height: 13rem; overflow:scroll;">
                ＜個人情報の取扱に関する方針＞<br/>
                当サイトを運営する株式会社リングライズでは、お客様のプライバシーを尊重し、個人情報に対して十分な配慮を行うと共に、お客様の個人情報を大切に保護し、適正な管理を行うことに努めています。<br/>
                <br/>
                【個人情報利用目的】<br/>
                お客様の個人情報は、原則として、当社のサービスに関する情報を提供する目的や当社に対するご意見、ご要望に対する今後の改善、および、ご依頼、お問い合わせに関する業務のために利用致します。<br/>
                上記以外の目的で利用する場合は、個人情報をご提供いただく際に、予め目的を明示しておりますので、ご確認ください。<br/>
                <br/>
                【第三者への情報提供】<br/>
                お客様の個人情報は、以下の場合を除き、第三者に開示、提供、譲渡することは致しません。<br/>
                ・法的拘束力がある第三者機関からの開示要求がある場合<br/>
                ・お客様本人の同意があった場合<br/>
                <br/>
              </div>
            </div>
            <div class="form-group pb-4">
              <div class="custom-control custom-checkbox text-center">
                <validation-provider
                    name="個人情報保護方針"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                  <b-form-checkbox
                      id="agreement"
                      v-model="contact.agreement"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      placeholder="個人情報保護方針"
                      name="agreement"
                      value="true"
                  >
                    上記の個人情報保護方針に同意し、送信する
                  </b-form-checkbox>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>
            <div class="py-1 text-center mb-5" style="">
              <p v-show="invalid" class="fade show alert alert-danger rounded-0 mb-3">
                未入力の項目があるか、内容が適切でない項目があります。
              </p>
              <button :disabled="!handleSubmit" type="submit" class="btn btn-primary rounded-0 py-2 px-4">送信する</button>
            </div>
            <!--/利用規約-->
          </form>
        </ValidationObserver>
      </div>
    </div><!-- /container -->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {localize} from 'vee-validate';
import Input from "@/components/Form/Input"

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'
import en from 'vee-validate/dist/locale/en.json';
import ja from 'vee-validate/dist/locale/ja.json';
import store from "@/store";
import moment from "moment/moment";

localize({
  en,
  ja
});
export default {
  name: 'ContactRepairInstead',
  props: {},
  components: {
    Input
  },
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      selected: [],
      spinner: false,
      contact: {
        mail_type: 'RepairInstead',
        company_name: '',
        staff_name: '',
        staff_name_kana: '',
        email: '',
        zip: '',
        pref: '',
        city: '',
        address1: '',
        phone: '',
        scheduled_shipping_date: '',
        delivery_service_provider: '',
        shipping_number: '',
        model_name: '',
        imei: '',
        has_password: '',
        password: '',
        failure_reason: '',
        failure_reason_detail: '',
        payment_type: '銀行振込',
        message: '',
        agreement: '',
        sendmail_flag: true,
        created_at: '',
        updated_at: '',
      },
      contactTest: {
        mail_type: 'RepairInstead',
        company_name: 'テスト会社',
        staff_name: 'テスト担当',
        staff_name_kana: 'テストタントウ',
        email: 'test@test.com',
        zip: '2320072',
        pref: '神奈川県',
        city: 'テスト市',
        address1: 'テスト町1-2-3',
        phone: '0312345678',
        scheduled_shipping_date: '2023-04-01',
        delivery_service_provider: 'ヤマト運輸',
        shipping_number: '1234-1234-1234',
        model_name: 'iPhone X',
        imei: '1234-1234-1234-1',
        has_password: 'パスコードロック有り',
        password: '1234567',
        failure_reason: 'その他',
        failure_reason_detail: 'iOSが起動しない',
        payment_type: '代金引換 (基板修理作業が終わり次第、発送致します)',
        message: '備考欄の入力データ',
        agreement: '',
        sendmail_flag: true,
        created_at: '',
        updated_at: '',
      },
      validationFailed: false,
    }
  },
  computed: {
    ...mapState({
      prefs: state => state.enums.items['pref'],
    }),
    ...mapGetters('enums', [
      'enums',
    ]),
  },
  methods: {
    ...mapActions('customers', [
      'fetchZipData',
    ]),
    ...mapActions('contacts', [
      'sendmail',
    ]),
    setTestData() {
      this.contact = this.contactTest
    },
    async findAddress() {
      this.spinnerFindAddress = true
      const target = await this.fetchZipData(this.contact.zip);
      console.log(target)

      this.contact.pref = target.pref;
      this.contact.city = target.city;
      this.contact.address1 = target.town;

      this.spinnerFindAddress = false
    },
    getDeliveryDateMin() {
      const now = moment();
      //console.log(now.format('YYYY-MM-DD'))
      return now.format('YYYY-MM-DD');
    },
    validateEmail() {
      if (this.contact.email === "") return 'メールアドレス'
      return this.contact.email
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        food: null
      };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    // 保存
    onSubmit() {
      this.handleSendmail(this.contact)
    },
    handleSendmail(contact) {
      this.validationFailed = false
      this.sendmail(contact).then(res => {
        if (res === true) {
          this.$router.push({path: '/contact/complete'})
          window.scrollTo(0, 0);
        }
      });
    },
  },
  async created() {
  },
  async beforeRouteUpdate(to, from, next) {
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('enums/fetch')

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    next();
  },
}
</script>