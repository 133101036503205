<template>
  <div class="container">
    <div class="py-5">
      <div class="text-center py-5 my-5">
        いつも モバイルリペアセンター をご利用いただきまして、誠にありがとうございます。<br>
        <br>
        ただいま、モバイルリペアセンター はメンテナンス作業を実施しております。<br />
        ご不便、ご迷惑をおかけいたしますが、何とぞご理解いただきますようお願い申し上げます。<br>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
  name: 'Maintenance',
  components: {},
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>