<template>
  <div>
    <div class="col-md-12">
      <div class="container">
        <div class="row">
          <div class="col-md-12 py-2 px-3 mx-2 px-md-2">
            <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i>ショッピングカート</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3 px-3 px-md-2">
            <div class="bg-lightgreen col-lg-5 mt-3 mt-md-0">
              <h1 class="contents-ttl copy22 mb-4 p-1">Profile<span class="copy12 ml-2">プロフィール入力</span></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-0 pb-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12 p-3 p-md-5">
            <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit, invalid }">
              <form @submit.prevent="handleSubmit(onSubmit)">
              <div class="form-row border-top py-3 border-light">
                <div class="col-md-2 pt-1">
                  <label>お名前</label>
                </div>
                <div class="form-group col-md-5 mb-0">
                  <Input type="text" class="mb-2 mb-md-0" v-model="customer.name" name="お名前" rules="required"></Input>
                </div>
              </div>
              <div class="form-row border-top py-3 border-light">
                <div class="col-md-2 pt-1">
                  <label>お名前（カナ）</label>
                </div>
                <div class="form-group col-md-5 mb-0">
                  <Input type="text" class="mb-2 mb-md-0" v-model="customer.name_kana" name="フリガナ" rules="required|kana"></Input>
                </div>
              </div>
              <div class="form-group form-row py-3 border-top border-light mb-0">
                <div class="pt-1 col-md-2">
                  <label>会社名</label>
                </div>
                <div class="col-md-10 mb-0">
                  <Input type="text" class="mb-2 mb-md-0" v-model="customer.company_name" name="会社名" rules=""></Input>
                </div>
              </div>
              <div class="form-row py-3 border-top border-light">
                <div class="pt-1 col-md-2">
                  <label>ご住所</label>
                </div>
                <div class="form-group mb-0 col-md-10">
                  <div class="form-inline mb-2" style="">
                    <label class="mr-2">〒</label>
                    <Input type="text" class="mb-2 mb-md-0" v-model="customer.zip" name="郵便番号" rules="required"></Input>
                    <button @click.prevent="findAddress" class="btn btn-info ml-2">検索</button>
                  </div>
                  <div class="px-0 col-lg-12">
                    <div class="form-group">
                      <ValidationProvider
                          name="都道府県"
                          mode="aggressive"
                          rules="required|min:1"
                          v-slot="validationContext"
                      >
                        <b-form-select
                            class="w-50 rounded-0"
                            v-model="customer.pref"
                            :options="enums['pref']"
                            value-field="value"
                            text-field="description"
                            :state="getValidationState(validationContext)"
                            autocomplete="address-level1"
                        >
                          <template #first>
                            <b-form-select-option value="" disabled>都道府県を選択</b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback class="mb-3">{{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-group px-0">
                    <Input type="text" class="mb-2 mb-md-0" v-model="customer.city" name="市区町村名（例：京都市中京区）" rules="required" autocomplete="address-level2"></Input>
                  </div>
                  <div class="form-group px-0">
                    <Input type="text" class="rounded-0 mb-2" v-model="customer.address1" name="番地・ビル名（例：○○町○丁目○-○）" rules="required" autocomplete="address-level3"></Input>
                  </div>
                </div>
              </div>
              <div class="form-group form-row py-3 border-top border-light mb-0">
                <div class="pt-1 col-md-2">
                  <label>メールアドレス</label>
                </div>
                <div class="col-md-10 mb-0">
                  <input v-if="emailVerification.email" type="text" class="form-control rounded-0" v-model="emailVerification.email" disabled autocomplete="username">
                  <input v-if="email" type="text" class="form-control rounded-0" v-model="email" disabled autocomplete="username">
                </div>
              </div>
              <div class="form-group form-row py-3 border-top border-light mb-0">
                <div class="pt-1 col-md-2">
                  <label>パスワード</label>
                </div>
                <div class="col-md-10 mb-0">
                  <!-- You can use vid or name to specify a targetable field -->
                  <ValidationProvider
                      name="パスワード"
                      mode="aggressive"
                      rules="required|alpha_num|min:8|max:32|password:@confirm"
                      v-slot="validationContext"
                  >
                    <b-form-input
                        class="rounded-0 mb-2"
                        type="password"
                        name="password"
                        placeholder="半角英数字8～32文字"
                        :state="getValidationState(validationContext)"
                        v-model="customer.password"
                        autocomplete="new-password"
                    ></b-form-input>
                    <b-form-invalid-feedback class="mb-3">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </ValidationProvider >

                  <ValidationProvider
                      name="パスワード（確認）"
                      mode="aggressive"
                      rules="required|alpha_num|min:8|max:32"
                      vid="confirm"
                      v-slot="validationContext"
                  >
                    <b-form-input
                        class="rounded-0 mb-2"
                        type="password"
                        name="password"
                        placeholder="確認のためもう一度入力してください"
                        :state="getValidationState(validationContext)"
                        v-model="customer.password_confirm"
                    ></b-form-input>
                    <b-form-invalid-feedback class="mb-3">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </ValidationProvider >

                </div>
              </div>
              <div class="form-group form-row py-3 border-light mb-0 border-top" style="">
                <div class="pt-1 col-md-2">
                  <label>電話番号</label>
                </div>
                <div class="col-md-10 mb-0">
                  <Input type="number" class="rounded-0" placeholder="00011112222" name="電話番号" v-model="customer.phone" rules="required|numeric|min:10|max:11" autocomplete="tel"></Input>
                </div>
              </div>
              <div class="form-group form-row py-3 border-light mb-0 border-top" style="">
                <div class="pt-1 col-md-2">
                  <label>携帯電話番号</label>
                </div>
                <div class="col-md-10 mb-0">
                  <Input type="number" class="rounded-0" placeholder="00011112222" name="携帯電話番号" v-model="customer.cell_phone" rules="numeric|min:10|max:11"></Input>
                </div>
              </div>

              <div class="form-group form-row py-3 border-light mb-0 border-top" style="">
                <div class="pt-1 col">
                  <p v-if="invalid" class="fade show alert alert-danger">
                    未入力の項目があるか、内容が適切でない項目があります。 <br>
                  </p>
                </div>
              </div>

              <div class="form-group form-check py-3 mb-0 border-bottom border-top border-light text-center">
                <ValidationProvider
                    name="利用規約に同意"
                    mode="aggressive"
                    rules="required"
                    v-slot="validationContext"
                >
                  <b-form-group>
                    <b-form-checkbox
                        class="form-check-input"
                        v-model="accept"
                        value="accepted"
                        unchecked-value=""
                        :state="getValidationState(validationContext)"
                    >
                      <a href="/#/law" target="_blank">利用規約</a><span class="text-dark">に同意する</span>
                    </b-form-checkbox>

                    <b-form-invalid-feedback :state="getValidationState(validationContext)" class="mb-3">{{
                        validationContext.errors[0]
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </div>
              <div class="py-3 text-center" style="">
                <button :disabled="!handleSubmit" type="submit" class="btn btn-primary rounded-0 py-2 px-4">本会員登録を完了する</button>
              </div>
            </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import Input from "@/components/Form/Input"
import router from "@/router";
import filter from "lodash/filter"


export default {
  name: 'Register',
  components: { Input },
  props: {
    emailVerified: {
      type: String,
    },
    fromInvite: Boolean,
  },
  data() {
    return {
      spinner: false,
      validationFailed: false,
      zipDisable: false,
      accept: false,
      plainPassword: '',
      parentCustomerCode: this.$route.params.customerCode,
      grade: (this.$route.params.grade) ? this.$route.params.grade : 1,
      fromFront: true,
      email: this.$route.params.email,
    }
  },
  methods: {
    ...mapActions('customers', [
      'new', 'store', 'fetchZip'
    ]),
    ...mapActions('authCustomer', [
      'Login'
    ]),
    async onSubmit() {
      await store.commit('setLoading', true)

      this.validationFailed = false
      this.customer.detail.grade = this.grade
      this.customer.detail.parent_customer_code = this.parentCustomerCode
      this.customer.from_invite = this.fromInvite
      this.customer.from_front = this.fromFront
      this.customer.is_enabled = false
      this.plainPassword = this.customer.password

      this.customer.email = this.email
      if(this.emailVerification.email) {
        this.customer.email = this.emailVerification.email
      }

      this.store(this.customer).then(res => {
        if (res) {
          router.push('/register-complete')
          // this.registerAfterLogin()
        }
      });
    },
    async registerAfterLogin() {
      const User = new FormData();
      User.append("email", this.customer.email);
      User.append("password", this.plainPassword);
      try {
        const res = await this.Login(User);
        console.log(res);

        router.push('/my-page')

      } catch (error) {
        this.setLoading(false)
        this.showError = true

        console.log('login.error', error)
      }
    },
    async findAddress() {
      await this.fetchZip(this.customer.zip);

      const prefName = this.customer.pref_name;

      const prefList = this.enums['pref']
      const targetPref = Object.values(prefList).filter((value, index) => {
        if(value.description === prefName) {
          return value.value
        }
      })

      this.customer.pref = targetPref[0].value
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
  },
  computed: {
    ...mapGetters('emailVerification', [
      'emailVerification'
    ]),
    ...mapGetters('customers', [
      'customer', 'parentCustomerName'
    ]),
    ...mapGetters('enums', [
      'enums'
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async mounted() {
    await this.$store.dispatch('customers/new')
    await this.$store.dispatch(
        'customers/fetchParent',
        this.parentCustomerCode
    );
  },
  async beforeRouteEnter(to, from, next) {
    // Common
    await store.dispatch(
        'enums/fetch'
    );

    await store.dispatch('customers/resetState');
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch('customers/resetState');

    // Common
    await store.dispatch(
        'enums/fetch'
    );

    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>