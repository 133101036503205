<template>

  <div class="container">
    <div class="row">
      <div class="col-md-12 py-2 px-3 mx-2 px-md-2">
        <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i><a href="category">カテゴリーから探す</a><i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i>子カテゴリー一覧</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3 px-3 px-md-2">
        <div class="bg-secondary col-lg-5 mt-3 mt-md-0">
          <h1 class="contents-ttl copy22 mb-4 p-1">Category<span class="copy12 ml-2">カテゴリーから探す</span></h1>
        </div>
      </div>
    </div>
    <div class="row pb-3">
      <CategoryLeftMenu></CategoryLeftMenu>

      <div class="col-lg-9">
        <!-- Child category list -->
        <section class="pb-4">
          <div class="container px-0">
            <div class="row px-2">
              <div class="col-md-6 pl-0">
                <h3 class="mb-3 category-title">小物部品</h3>
              </div>
              <div class="col-md-6 px-0">
                <form class="form-inline">
                  <input class="category-search form-control mr-sm-2" type="search" placeholder="商品名を入力" aria-label="Search"/>
                  <button class="btn btn-outline-primary my-2 my-sm-0 rounded-0" type="submit">探す</button>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="products-list.html" class="d-inline-block">13Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">XSMAX</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">XSMAX</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import CategoryLeftMenu from "@/components/CategoryLeftMenu"
export default {
  name: 'CategoryList',
  components: { CategoryLeftMenu },
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    ...mapActions('categories', [
      'fetchForFront'
    ]),
    searchProducts() {
      console.log('searchProducts')
    },
  },
  computed: {
    ...mapGetters('categories', [
      'categories',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
