import Vue from "vue";

const state = {
    items: [],
    item: {
        id: '',
        parent_id: '',
        name: '',
        description: '',
        header: '',
        footer: '',
        image: '',
        image_path: '',
        for_front: false,
        children: [],
    },
    list: [],
    errors: {},
    breadcrumb: {
        category_id: 0,
        category_name: '',
        parent_category_id: 0,
        parent_category_name: '',
    },
};

const initialState = {
    items: [],
    item: {
        id: '',
        parent_id: '',
        name: '',
        description: '',
        header: '',
        footer: '',
        image: '',
        image_path: '',
        for_front: false,
        children: [],
    },
    list: [],
    errors: {},
    breadcrumb: {
        category_id: 0,
        category_name: '',
        parent_category_id: 0,
        parent_category_name: '',
    },
};
const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setList(state, items) {
        let map = new Map()
        items.map(row => {
            map.set(row.id, row.name)
            console.log(row.id)
        })

        state.list = map
    },
    setBreadcrumb(state, item) {
        state.breadcrumb = item
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.items.splice(index, 1);
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    categories(state) {
        return state.items;
    },
    category(state) {
        return state.item;
    },
    list(state) {
        return state.list;
    },
    breadcrumb(state) {
        return state.breadcrumb
    },
    categoryName(state) {
        return state.breadcrumb.category_name
    },
    categoryId(state) {
        return state.breadcrumb.category_id
    },
    parentCategoryId(state) {
        return state.breadcrumb.parent_category_id
    },
    parentCategoryName(state) {
        return state.breadcrumb.parent_category_name
    },
    getCategoryName(categoryId) {
        return state.items.filter((item) => item.id === categoryId)
    }
};

const actions = {
    async fetch({state, commit}) {
        await window.axios.get('/categories').then(res => {
            console.log(res.data)
            commit('setItems', res.data);
            commit('unsetErrors')
            console.log('categories.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetch.error', error)
        });
    },
    async fetchList({commit}) {
        await window.axios.get('/categories/list').then(res => {
            commit('setList', res.data);
            commit('unsetErrors')
            console.log('categories.fetchList', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetchList.error', error)
        });
    },
    async fetchListForAdmin({commit}) {
        await window.axios.get('/categories/list-for-admin').then(res => {
            commit('setList', res.data);
            commit('unsetErrors')
            console.log('categories.fetchListForAdmin', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetchListForAdmin.error', error)
        });
    },
    async fetchForFront({commit}) {
        await window.axios.get('/categories/front').then(res => {
            commit('setItems', res.data);
            commit('unsetErrors')
            console.log('categories.fetchForFront', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetchForFront.error', error)
        });
    },
    async fetchBreadcrumb({commit}, id) {
        await window.axios.get('/categories/breadcrumb/' + id).then(res => {
            commit('setBreadcrumb', res.data);
            commit('unsetErrors')
            console.log('categories.setBreadcrumb', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetchForFront.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/categories/' + id).then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('categories.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetchOne.error', error)
        });
    },
    async new({commit}) {
        let item = initialState.item

        commit('setItem', item);
        console.log('categories.new')
    },
    async delete({state, commit}, item) {
        const index = state.items.indexOf(item);

        return await window.axios.post('/categories/delete', item)
            .then(index => {
                commit('delete', index);
                commit('unsetErrors')
                console.log('categories.delete', state.items[index])
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('categories.delete.error', error, state.items[index])
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/categories/store', item)
            .then(res => {
                commit('unsetErrors')
                console.log('categories.store', res, item)
                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('categories.store.error', error, item)
            });
    },
    async storeClone({commit}, item) {
        return await window.axios.post('/categories/clone', item)
            .then(res => {
                commit('unsetErrors')
                console.log('categories.storeClone', res, item)
                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('categories.storeClone.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/categories/update', newItem)
            .then(res => {
                commit('setItem', res.data)
                commit('unsetErrors')
                console.log('categories.update', res, newItem)
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('categories.update.error', error, newItem)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('categories.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
