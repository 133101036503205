<template>
  <div>
    <div class="py-5" style="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="mb-5">
              <h2 class="copy14 pb-3 border-bottom">お問い合わせを受け付けました</h2>
              <p class="mt-3 mb-4" style="">
              <p>お問い合わせありがとうございました。</p>
              <p>折り返し担当者よりご連絡致しますので、恐れ入りますがしばらくお待ち下さい。</p>
              <p></p>
              <p>なお、ご入力いただいたメールアドレス宛に受付完了メールを送信しております。</p>
              <p>受付完了のメールが届かない場合は、入力いただいたメールアドレスが間違っているか、スパムメール扱いになっているケースが想定されます。</p>
              <p>その場合は大変お手数ですが、再度お問い合わせの手続きをしていただくか、メール、またはお電話にてご連絡をお願い致します。</p>
              <br>
              <div class="">
                <a class="btn btn-outline-primary rounded-0 px-3 btn-sm" href="/">トップページに戻る</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: 'ContactComplete',
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('orders', [
      'fetch', 'store', 'update', 'delete'
    ]),
  },
  computed: {
  },
  async beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    return next();
  },
}
</script>