import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Tokyo');
const formatter = new Intl.NumberFormat('ja-JP');

export function numberFormat (value) {
    return formatter.format(value);
}

// TODO: Laravelのmodelでcastしたほうが漏れがなくて良い
export function toDatetime(value) {
    if (value) {
        return moment(String(value)).format('YYYY/MM/DD HH:mm')
    }
}

export function toDatetimeJp(value) {
    if (value) {
        return moment(String(value)).format('YYYY/MM/DD HH:mm')
    }
}

export function toDate(value) {
    if (value) {
        return moment(String(value)).format('YYYY/MM/DD')
    }
}

export function emptyToLabel(value) {
    if(value) {
        return value
    }

    return '未設定'
}

export function toPaymentLabel(value) {
    const paymentId = parseInt(value);
    if(paymentId === 1) {
        return 'クレジットカード'
    } else if(paymentId === 2) {
        return '代金引換'
    } else if(paymentId === 3) {
        return '銀行振込'
    } else if(paymentId === 4) {
        return '後払い'
    } else if(paymentId === 5) {
        return '売掛'
    }

    return '不明'
}
