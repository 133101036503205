<template>
  <div>
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    <div v-show="isLoading" style="width: 100vh; height: 100vh;"></div>
    <div v-show="!isLoading">
      <div class="pt-4 pb-5">
        <div class="container">
          <!-- キャンペーンヘッダー -->
          <div class="row">
            <div v-html="campaign.header"></div>
          </div>

          <!-- キャンペーンメインコンテンツ -->
          <div class="row">
            <div v-html="campaign.body"></div>
          </div>

          <!-- キャンペーン対象商品 -->
          <template v-if="campaign.products">
            <div class="row px-3 py-2">
              <h1 class="top_ttl mb-0 copy22">Product<span class="copy14 ml-2">キャンペーン対象商品</span></h1>
              <ComponentListProduct :key=$route.params.id :items="campaign.products" viewMode="CampaignProduct"></ComponentListProduct>
            </div>
          </template>

          <!-- キャンペーン対象商品 -->
          <template v-if="campaign.presents">
            <div class="row mt-2 bg-sandlight px-3 py-2">
              <h1 class="top_ttl mb-0 copy22">Present<span class="copy14 ml-2">プレゼント対象商品</span></h1>
              <ComponentListProduct :key=$route.params.id :items="campaign.presents" viewMode="CampaignPresent"></ComponentListProduct>
            </div>
          </template>

          <!-- キャンペーンフッター -->
          <div class="row">
            <div v-html="campaign.footer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import ComponentListProduct from "@/components/List/Product"
export default {
  name: 'CampaignDetail',
  components: {
    ComponentListProduct,
  },
  data() {
    return {
      spinner: false,
      slide: 0,
    }
  },
  computed: {
    ...mapGetters('newCampaigns', [
      'campaign',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id !== undefined) {
      if(to.params.preview !== undefined) {
        // 管理者向けプレビュー
        await store.dispatch(
            "newCampaigns/fetchOneByCampaignIdForAdmin",
            to.params.id
        );
      } else {
        // 一般向け
        await store.dispatch(
            "newCampaigns/fetchOneByCampaignId",
            to.params.id
        );
      }
    }
    // TODO: キャンペーンが存在しなかったとき、404に飛ばす

    await store.commit('setLoading', false)
    window.scrollTo(0, 0)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    if (to.params.id !== undefined) {
      if(to.params.preview !== undefined) {
        // 管理者向けプレビュー
        await store.dispatch(
            "newCampaigns/fetchOneByCampaignIdForAdmin",
            to.params.id
        );
      } else {
        // 一般向け
        await store.dispatch(
            "newCampaigns/fetchOneByCampaignId",
            to.params.id
        );
      }
    }
    // TODO: キャンペーンが存在しなかったとき、404に飛ばす

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
