import Vue from "vue";
import * as Cookies from 'js-cookie'

const state = {
    user: {
        id: '',
        name: '',
        email: '',
        email_verified_at: '',
        remember_token: '',
        api_token: '',
        permission: {
            view_orders: false,
            view_customers: false,
            view_products: false,
            view_campaigns: false,
            view_categories: false,
            view_series: false,
            view_news: false,
            view_users: false,
            view_configuration: false,
        },
        is_admin: false,
    },
    isAdmin: false,
    isVendor: false,
    isLoggedIn: false,
};

const initialState = {
    user: {
        id: '',
        name: '',
        email: '',
        email_verified_at: '',
        remember_token: '',
        api_token: '',
        permission: {
            view_orders: false,
            view_customers: false,
            view_products: false,
            view_campaigns: false,
            view_categories: false,
            view_series: false,
            view_news: false,
            view_users: false,
            view_configuration: false,
        },
        is_admin: false,
    },
    isAuthenticated: false,
    isAdmin: false,
    isLoggedIn: false,
};

const getters = {
    isAdmin: (state) => state.user.is_admin,
    isLoggedIn: (state) => state.isLoggedIn,
    StateUser: (state) => {
        console.log("stateUser", state.user)
        return state.user
    },
};


const mutations = {
    login(state, user) {
        state.user = user;
        state.isLoggedIn = true
    },
    logout(state) {
        state.user = initialState.user;
        state.isLoggedIn = false
    },
    setAdmin(state, value) {
        state.isAdmin = value
    },
    setAuthenticated(state, value) {
        state.isAuthenticated = value
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const actions = {
    async Login({commit}, user) {
        await window.axios.post("/login", user).then(res => {
            window.localStorage.setItem('api_token', res.data.api_token)

            if(res.data.permission) {
                window.localStorage.setItem('permission', JSON.stringify(res.data.permission))
            } else {
                window.localStorage.setItem('permission', JSON.stringify({
                    view_orders: false,
                    view_customers: false,
                    view_products: false,
                    view_campaigns: false,
                    view_categories: false,
                    view_series: false,
                    view_news: false,
                    view_users: false,
                    view_configuration: true,
                }))
            }
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('api_token')

            commit('login', res.data);
            commit('setAdmin', res.data.isAdmin);
            commit('setAuthenticated', true);
        });
    },
    async Logout({ commit }) {
        let user = null;
        commit("logout", user);
        commit('setAdmin', false);
        commit('setAuthenticated', false);
    },
    async IsLoggedIn({commit}) {
        window.axios.get('/logged-in').then(res => {
            console.log('auth.IsLoggedIn', res)

            return true
        }).catch(error => {
            commit('setErrors', error)
            console.log('auth.IsLoggedIn.error', error)

            return false
        });
    },
    async fetchCurrentUser({commit}) {
        await window.axios.get('/auth/current-user').then(res => {
            commit('login', res.data);
            console.log('users.fetchCurrentUser')
        }).catch(error => {
            commit('setErrors', error)
            console.log('users.fetchCurrentUser.error', error)
        });
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};