import Vue from "vue";

import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
const STORE_NAME = 'pickupProducts'

const state = {
    items: [],
    item: {
        id: '',
        is_present: '',
        name: '',
        title: '',
        description: '',
        header: '',
        body: '',
        footer: '',
        banner_image_path: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        announcement_image_path: '',
        announcement_body: '',
        discount_rule: '1',
        required_type: 1,
        required_quantity: 1,
        required_amount: 0,
        present_quantity: '',
        discount_of_agency: 1,
        discount_of_dealer: 1,
        discount_of_customer: 1,
        discount_of_web_customer: 1,
        discount_of_general: 1,
        started_at: '',
        ended_at: '',
        announcement_started_at: '',
        announcement_ended_at: '',
        created_at: '',
        updated_at: '',
        deleted_at: '',
        products: [],
        presents: [],
    },
    search: {
        per_page: 25,
        page: 1,
        name: "",
        title: "",
        product_id: '',
        product_name: '',
        started_at: '',
        ended_at: '',
        // 開催中のキャンペーンフィルター用
        is_all_campaigns: true,
        is_current_campaigns: null,
        is_next_month_campaigns: null,
        is_preview_month_campaigns: null,
        is_scheduled_campaigns: null,
        // クエリダンプ用
        sql_debug: true
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    summary: [],
    errors: {},
    suggestList: [],
};

const initialState = {
    items: [],
    item: {
        id: '',
        is_present: false,
        name: '',
        title: '',
        description: '',
        header: '',
        body: '',
        footer: '',
        banner_image_path: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        announcement_image_path: '',
        announcement_body: '',
        discount_rule: 1,
        required_type: 1,
        required_quantity: '',
        required_amount: '',
        present_quantity: '',
        discount_of_agency: 1,
        discount_of_dealer: 1,
        discount_of_customer: 1,
        discount_of_web_customer: 1,
        discount_of_general: 1,
        started_at: '',
        ended_at: '',
        announcement_started_at: '',
        announcement_ended_at: '',
        created_at: '',
        updated_at: '',
        deleted_at: '',
        products: [],
        presents: [],
    },
    search: {
        per_page: 25,
        page: 1,
        name: "",
        title: "",
        product_id: '',
        product_name: '',
        started_at: '',
        ended_at: '',
        // 開催中/翌月開催/前月開催/開催予定のキャンペーンフィルター用
        is_all_campaigns: true,
        is_current_campaigns: null,
        is_next_month_campaigns: null,
        is_preview_month_campaigns: null,
        is_scheduled_campaigns: null,
        // クエリダンプ用
        sql_debug: true
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    summary: [],
    errors: {},
    suggestList: [],
};
const mutations = {
    setItems(state, items) {
        state.items = items
        state.errors = cloneDeep(initialState.errors)
    },
    setItem(state, item) {
        state.item = item
        state.errors = cloneDeep(initialState.errors)
    },
    setSearch(state, search) {
        state.search = search
    },
    setPagination(state, items) {
        Vue.set(state, 'pagination', items);
    },
    setSuggestList(state, items) {
        Vue.set(state, 'suggestList', items);
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
        state.errors = cloneDeep(initialState.errors)
    },
    delete(state, item) {
        let index = state.pagination.data.findIndex(row => row.id === item.id);
        state.pagination.data.splice(index, 1);
        state.errors = cloneDeep(initialState.errors)
    },
    store(state, item) {
        state.item = item;
        state.errors = cloneDeep(initialState.errors)
    },
    setError(state, error) {
        state.errors.push(error);
    },
    unsetErrors(state) {
        state.errors = cloneDeep(initialState.errors)
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    resetSearchState() {
        state.search.name = null
        state.search.title = null
        state.search.product_id = null
        state.search.product_name = null
        state.search.started_at = null
        state.search.ended_at = null
        state.is_current_campaigns = null
    },
    resetDiscountValues(state) {
        // 掛率変更
        if(state.item.discount_rule === 1) {
            state.item.discount_of_agency = 1.0;
            state.item.discount_of_dealer = 1.0;
            state.item.discount_of_customer = 1.0;
            state.item.discount_of_web_customer = 1.0;
            state.item.discount_of_general = 1.0;
            // 値引
        } else if(state.item.discount_rule === 2) {
            state.item.discount_of_agency = 0;
            state.item.discount_of_dealer = 0;
            state.item.discount_of_customer = 0;
            state.item.discount_of_web_customer = 0;
            state.item.discount_of_general = 0;
            // プレゼント
        } else if(state.item.discount_rule === 3) {
            state.item.discount_of_agency = 1;
            state.item.discount_of_dealer = 1;
            state.item.discount_of_customer = 1;
            state.item.discount_of_web_customer = 1;
            state.item.discount_of_general = 1;
        }
    },
};

const getters = {
    campaigns(state) {
        return state.items;
    },
    campaign(state, id) {
        return state.item
    },
    pagination(state) {
        return state.pagination;
    },
    search(state) {
        return state.search;
    },
    suggestList(state) {
        return state.suggestList
    },
    summary: state => {
        return state.summary.data;
    },
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/campaigns', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('campaigns.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('campaigns.fetch.error', error)
        });
    },
    async fetchByPage({commit}, params) {
        await window.axios.get('/campaigns?page=' + params.page + '&per_page=' + params.perPage).then(res => {
            commit('setPagination', res.data);
            commit('unsetErrors')
            console.log('campaigns.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('campaigns.fetch.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/campaigns/' + id).then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('campaigns.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('campaigns.fetchOne.error', error)
        });
    },
    async fetchSuggest({state, commit}) {
        await window.axios.get('/campaigns/suggest-list').then(res => {
            commit('setSuggestList', res.data);
            commit('unsetErrors')
            console.log('campaigns.fetchSuggest', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('campaigns.fetchSuggest.error', error)
        });
    },
    async new({commit}) {
        let item = cloneDeep(initialState.item)

        commit('setItem', item);
        console.log('campaigns.new')
    },
    async delete({state, commit}, item) {

        return await window.axios.post('/campaigns/delete', item)
            .then(res => {
                commit('delete', item);
                commit('unsetErrors')
                console.log('campaigns.delete',item)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('campaigns.delete.error', error, item)
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/campaigns/store', item)
            .then(res => {
                commit('setItem', res)
                commit('unsetErrors')
                console.log('campaigns.store', res, item)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('campaigns.store.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/campaigns/update', newItem)
            .then(res => {
                commit('unsetErrors')
                console.log('campaigns.update', res, newItem)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('campaigns.update.error', error, newItem)

                return false
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('campaigns.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};