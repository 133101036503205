<template>
  <transition-group>
    <slot></slot>
  </transition-group>
</template>

<style scoped>
/* 追加, 削除時 */
.v-enter-active, .v-leave-active, .v-move {
  transition: transform 1s;
}

/* 追加前, 削除後の位置 */
.v-enter, .v-leave-to {
  transform: translateX(80%);
}

.v-leave-active {
  opacity: 0.2;
  position: absolute;
}
</style>

<script>
export default {
  name: 'NATransitionList',
  props: {

  },
}
</script>