var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-4"},[_vm._m(0),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered"},[_c('tbody',[_vm._m(1),_vm._m(2),_c('tr',[_c('td',{staticClass:"table-lightgray",attrs:{"width":"20%"}},[_vm._v("メールアドレス")]),_c('td',{},[(_vm.isMirror)?[_c('a',{attrs:{"href":"mailto:support@store.mobile-repair-center.jp"}},[_vm._v("support@store.mobile-repair-center.jp")])]:[_c('a',{attrs:{"href":"mailto:support@mobile-repair-center.jp"}},[_vm._v("support@mobile-repair-center.jp")])]],2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row px-2"},[_c('div',{staticClass:"col-md-12 p-2 my-3"},[_c('h3',{staticClass:"mb-0 font-weight-bold text-primary"},[_vm._v("会社概要")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray",attrs:{"width":"20%"}},[_vm._v("店名")]),_c('td',{},[_vm._v("モバイルリペアセンター")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray",attrs:{"width":"20%"}},[_vm._v("会社名")]),_c('td',{},[_vm._v("株式会社リングライズ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_vm._v("電話番号")]),_c('td',{},[_vm._v("03-5285-8019")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_vm._v("定休日")]),_c('td',[_vm._v("日曜日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_vm._v("営業時間")]),_c('td',[_vm._v("10:30～17:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_vm._v("取扱商品")]),_c('td',[_vm._v(" ・iPhone各種部品 "),_c('br'),_vm._v(" ・モバイル関連機器 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_vm._v("所在地")]),_c('td',[_vm._v(" 〒169-0075 "),_c('br'),_vm._v(" 東京都新宿区高田馬場1丁目33-16 KRビル 1F ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_c('a',{attrs:{"id":"access"}},[_vm._v("アクセス")])]),_c('td',[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.6206946368425!2d139.7038356!3d35.7109506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d3a2d5d23f7%3A0x88bb8a6766a9d43a!2z44CSMTY5LTAwNzUgVG9reW8sIFNoaW5qdWt1IENpdHksIFRha2FkYW5vYmFiYSwgMS1jaMWNbWXiiJIzM-KIkjE2IEtS44OT44OrIDFm!5e0!3m2!1sen!2sjp!4v1680325622461!5m2!1sen!2sjp","width":"600","height":"450","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"table-lightgray"},[_vm._v("お支払方法")]),_c('td',[_vm._v("現金・クレジットカード"),_c('br'),_c('br')])])
}]

export { render, staticRenderFns }