<template>
  <div class="py-3" style="">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
              <div class="row px-2">
                <div class="col-md-12 p-2 my-3">
                  <h3 class="mb-0 font-weight-bold text-primary">会社概要</h3>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                  <tr>
                    <td width="20%" class="table-lightgray">店名</td>
                    <td style="">モバイルリペアセンター</td>
                  </tr>
                  <tr>
                    <td width="20%" class="table-lightgray">会社名</td>
                    <td style="">株式会社リングライズ</td>
                  </tr>
                  <tr>
                    <td width="20%" class="table-lightgray">メールアドレス</td>
                    <td style="">
                      <template v-if="isMirror">
                        <a href="mailto:support@store.mobile-repair-center.jp">support@store.mobile-repair-center.jp</a>
                      </template>
                      <template v-else>
                        <a href="mailto:support@mobile-repair-center.jp">support@mobile-repair-center.jp</a>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">電話番号</td>
                    <td style="">03-5285-8019</td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">定休日</td>
                    <td>日曜日</td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">営業時間</td>
                    <td>10:30～17:00</td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">取扱商品</td>
                    <td>
                      ・iPhone各種部品 <br>
                      ・モバイル関連機器
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">所在地</td>
                    <td>
                      〒169-0075 <br>
                      東京都新宿区高田馬場1丁目33-16 KRビル 1F
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray"><a id="access">アクセス</a></td>
                    <td>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.6206946368425!2d139.7038356!3d35.7109506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d3a2d5d23f7%3A0x88bb8a6766a9d43a!2z44CSMTY5LTAwNzUgVG9reW8sIFNoaW5qdWt1IENpdHksIFRha2FkYW5vYmFiYSwgMS1jaMWNbWXiiJIzM-KIkjE2IEtS44OT44OrIDFm!5e0!3m2!1sen!2sjp!4v1680325622461!5m2!1sen!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-lightgray">お支払方法</td>
                    <td>現金・クレジットカード<br><br>
                      <!--
                      <div class="card text-center">
                        <div class="card-body">
                          <p>ご利用いただける現金以外のお支払い方法は、以下の通りです。</p>
                          <img class="img-fluid d-block border" src="images/data-recovery/card.png" style="">
                          <p class="card-text text-left my-2">
                            JCB・VISA・mastercard・ダイナースカード・アメリカンエクスプレス・UCカード・セゾンカード・DCカード・UFJカード・MUFGカード・ニコスカード・イオンカード・楽天カード</p>
                        </div>
                      </div>
                      -->
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      isMirror: (process.env.NODE_ENV === 'production-mirror' || process.env.NODE_ENV === 'local-mirror'),
    }
  },
}
</script>