import Vue from "vue";
import * as Cookies from "js-cookie";

const state = {
    user: {
        id: '',
        customer_code: '',
        parent_customer_code: '',
        grade: 6,
        ratio: 1.0,
        payment_timing: 1,
        name: '',
        email: '',
        remember_token: '',
        api_token: '',
    },
    errors: {},
    isLoggedIn: false,
};

const initialState = {
    user: {
        id: '',
        customer_code: '',
        parent_customer_code: '',
        grade: 6,
        ratio: 1.0,
        payment_timing: 1,
        name: '',
        email: '',
        remember_token: '',
        api_token: '',
    },
    errors: {},
    isLoggedIn: false,
};

const getters = {
    isLoggedIn: (state) => {
        return state.isLoggedIn
    },
    StateUser: (state) => {
        return state.user
    },
    ratio: (state) => {
        return state.user.ratio
    },
    grade: (state) => {
        return parseInt(state.user.grade)
    },
    parent_customer_code: (state) => {
        return state.user.parent_customer_code
    },
    payment_timing: (state) => {
        return parseInt(state.user.payment_timing)
    }
};


const mutations = {
    login(state, user) {
        console.log(user)
        state.user = user;
        state.isLoggedIn = true
    },
    logout(state) {
        state.user = {
            id: '',
            customer_code: '',
            parent_customer_code: '',
            grade: 5,
            ratio: 1.0,
            payment_timing: 1,
            name: '',
            email: '',
            remember_token: '',
            api_token: '',
        };
        state.isLoggedIn = false
    },
    setLoggedIn(state, flag) {
        state.isLoggedIn = flag
    },
    setError(state, error) {
        state.errors.push(error);
    },
    resetError(state) {
        state.errors = {}
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const actions = {
    async Login({commit}, user) {
        return await window.axios.post("/customer/login", user).then(res => {
            console.log("CustomerLogin: " , res)
            window.localStorage.setItem('front_api_token', res.data.api_token)
            window.localStorage.setItem('isLoggedIn', true)
            window.localStorage.setItem('cart_id', res.data.cart_id)


            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.localStorage.getItem('front_api_token')
            console.log(res.data);
            commit('setLoggedIn', true)
            commit('login', res.data);

            return true
        });
    },
    async Logout({ commit }) {
        return await window.axios.post("/customer/logout").then(res => {
            window.localStorage.setItem('front_api_token', '')
            window.localStorage.setItem('isLoggedIn', false)
            console.log(window.localStorage.getItem('isLoggedIn'))

            const crypto = require('crypto')
            const likeSHA1 = crypto.randomBytes(40).toString("hex").substr(0, 40);

            window.localStorage.setItem('cart_id', likeSHA1)

            commit('resetState');

            return true
        });
    },
    async passwordReminder({commit}, user) {
        return await window.axios.post("/my-page/password-reminder", user).then(res => {
            console.log('authCustomer.passwordReminder.param', user)
            console.log('authCustomer.passwordReminder.result', res)

            return true
        }).catch(error => {
            console.log('authCustomer.passwordReminder.error', error)
        });
    },
    async currentUser({state, commit}, user) {
        if(state.isLoggedIn === true) {
            return
        }

        return await window.axios.get("/customer/current-user", user).then(res => {
            console.log("currentUser.is_logged_in: ", res.data.is_logged_in, typeof res.data.is_logged_in)
            commit('setLoggedIn', res.data.is_logged_in)
            // ログインしていたユーザーなら再ログイン
            if(res.data.is_logged_in) {
                commit('login', res.data);
            }
        }).catch(error => {
            console.log('authCustomer.passwordReminder.error', error)
        });
    },
    resetState({ commit }) {
        commit('resetState');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};