<template>
  <div>
    <div class="py-4">
      <div class="container">
        <div class="row">
          <div class="contents mt-3 mb-3 col-md-12 d-flex justify-content-center">
            <h1 class="text-white copy16" style="font-family: &quot;M PLUS 1p&quot;, sans-serif; font-weight: 700;">基板修理事例</h1>
          </div>
          <div class="contents col-md-12 text-center d-flex justify-content-center mb-5">
            <div class="card text-center col-xl-10 p-0">
              <div class="card-header">
                <!-- case -->
                <div>
                  <b-card no-body>
                    <b-tabs pills card>
                      <!-- case01 -->
                      <b-tab title="修理事例１" active>
                        <!-- contants01 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/20200121_1_1_kiban.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/20200121_1_2_kiban.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone5S　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>破損による起動不可</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>液晶表示IC・バックライトICの交換により起動完了。
                                      経年劣化によりバッテリーの交換も行いました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/20200121_2_1_kiban.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/20200121_2_2_kiban.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8 Plus　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>腐食箇所周辺の洗浄及びメインオーディオIC・サブオーディオICの交換により、起動完了</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/20200121_3_1_kiban.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneSE　リンゴループ</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>起動後3分程でリンゴループ</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>電圧入力時に負荷がかかり、回路が短絡。
                                      充電管理ICの交換、回路の結線を行い、起動完了</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants02 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_010.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7 タッチ不良</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>タッチの反応がなく、液晶交換でも修復せず。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>タッチの反応がなく、液晶交換でも修復せず。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_011.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_012.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7 圏外病</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>左上のアンテナマーク部分が「検索中」と表示され、電波がつながらない</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>ベースバンドチップ（周波数チップ）の交換で修復</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_014.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_013.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneX リンゴループ</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>部品交換、更新・復元作業を行うも修復せず。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>水没断線修理、
                                      液晶パネル交換、
                                      電源ケーブル交換、
                                      基板腐敗クリーニングにて修復</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants03 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_015.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_016.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_017.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>電源を入れても起動せず、部品交換では修理不可</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>USB IC、充電ICチップ、通電回路ショート復元チップ4箇所交換にて起動</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_018.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_019.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_020.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7 水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による液晶異常</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>通常の水没修理では復旧せず、基板修理へ。表示ICや充電IC、腐食ショート箇所の部品を交換し修復</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_021.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">バックライト不良</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>画面が暗くなり、その後起動できなくなる</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>	バックライトICチップ交換・通電回路ショート復元チップ1ヶ所交換</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <!-- case02 -->
                      <b-tab title="修理事例２">
                        <!-- contants01 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_029.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_030.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_031.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPod touch5 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>リンゴマークはつくが、ホーム画面に進まない。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>電源ICチップ交換により修復</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_032.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_033.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_034.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>iPhone7起動不可。リンゴマーク点灯後、電源が切れてしまう。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>データ保存領域の再取り付けとデータ領域の回路の修復により起動。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_035.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_036.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_037.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8 再起動繰り返す</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>起動不可。コネクタ破損。再起動を繰り返す。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>ICチップの交換と回路修復により、修理完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants02 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_038.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneXS 水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没後、画面が映らなくなった</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>給電ICチップの交換により復旧。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>5日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_039.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_040.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_041.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6 水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没により電源が入らない状態。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>USBへの給電ICチップ交換を行い、修復。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_042.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_043.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障により電源が入らない状態。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>表示ICとブースター回路の修復を行い、起動確認OK。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants03 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_044.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_045.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_046.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneX 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>落下後、液晶不良。他店で修理を行って改善されたが、その後電源が入らない状態に。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>ICチップの交換・破損していたコネクタの修理により、修復。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_047.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_048.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone5S 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>落下後に電源が入らなくなった</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>記憶領域への回路が多数短絡。短絡修復により復旧。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>4日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_021.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">バックライト不良</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>画面が暗くなり、その後起動できなくなる</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>	バックライトICチップ交換・通電回路ショート復元チップ1ヶ所交換</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <!-- case03 -->
                      <b-tab title="修理事例３">
                        <!-- contants01 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_052.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>破損により、起動不可。水没反応も有り。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>複数のICの短絡修復を行い、起動。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_053.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_054.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_055.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6　操作不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>電源は入っているが画面操作が出来ない。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>バックライトICチップと時計を調整するICの故障・断線。ICの交換で修復完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_056.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_057.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障により電源が入らない状態。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>電源管理回路・表示給電IC短絡の修復により、起動しました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants02 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_058.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_059.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6s　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没により電源が入らない状態。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>USB用ICチップ・給電用ICチップの破損。チップの交換により修復完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>4日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_060.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_061.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7 リンゴループ</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障によるリンゴループ。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>ベースバンドIC、基板保護管ICの交換により修復完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_062.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_063.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_064.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneSE　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>基板表示IC、通路コンデンサの短絡及び断線。交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants03 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_065.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_066.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_067.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>経年劣化による熱暴走の可能性有。IC、NANDフラッシュの交換で修復完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>4日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_068.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_069.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPad mini4　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>いきなり充電が出来なくなり、起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>電源IC、バッテリーの交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>7日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_070.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_071.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_072.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneXS　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>電源チューブの短絡、バッテリーホルダーの修復により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <!-- case04 -->
                      <b-tab title="修理事例４">
                        <!-- contants01 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_076.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_077.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneXR　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>映像出力IC及びコネクタの短絡と、チップコンデンサの破損。交換を行い、起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_078.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_079.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>マザーボード保護IC、メイン電源ICの破損。液晶表示ICに水没の跡。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>ICの交換により起動完了。しかし、水没の影響で液晶パネルの交換が必要となりました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_080.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_081.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。夏の暑さの中で長時間ゲームなど電力を消費する状態が続いた為、熱暴走したと考えられました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>オーディオIC、充電ICの修復により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants02 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_082.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_083.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7Plus　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可→解析の結果、水没していました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>カメラ電源IC、ディスプレイICの破損。交換及び結線後に起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_084.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_085.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneX　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。パネル・バッテリーを交換したが改善されず。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>基板のブーストIC・パスの静電容量・シリアルデータ抵抗が破損。IC・コネクタの交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_086.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_087.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6s　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。過充電が原因と思われます。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>基板の短絡。チップコンデンサの破損確認。交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants03 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_088.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_089.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_090.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>経年劣化による短絡箇所あり。液晶表示ICとチップコンデンサの交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_091.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_092.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_093.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneX　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>オーディオIC・ワイヤレス充電用ICの破損。該当ICの交換および洗浄後、起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_094.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_095.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7　リンゴループ</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>リンゴループ。過度な電力が供給されたことが原因と考えられます。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>USB充電ICチップ・タッチICチップ・ブーストコイル交換および修復により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <!-- case05 -->
                      <b-tab title="修理事例５">
                        <!-- contants01 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_098.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_099.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneX　リンゴループ</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障によるリンゴループ。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>多数の短絡、充電ICに損傷有。該当ICの交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_100.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_101.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6s　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>自然故障による起動不可。加電圧によるショートと短絡欠損有。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>表示ICチップ/バックライトコンデンサの修復後、起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_102.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_103.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>カメラIC/NFCコントロールチップ/電源供給用コンデンサの腐食・破損。交換により起動。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants02 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_104.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_105.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneX　起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>多数の短絡と、バッテリーの膨張。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>充電ICの交換により起動完了。バッテリーの交換が別途必要となりました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_106.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_107.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneXSMax　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>電源センサーIC/C315コンデンサ/液晶表示ICの損傷。交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_108.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_107.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_108.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPad5　破損</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>破損により画面が映らない状態</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>漏電が数か所・短絡によるICチップの損傷・充電管理IC及び電源管理ICの短絡有。交換により起動。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>3日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- contants03 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_111.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_112.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_113.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone6s　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。タッチIC、映像出力用電源コントロールICの破損。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>該当ICの交換により起動完了。水没の影響からか画面に滲みが出ていた為、店舗様での画面交換が必要となりました。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_114.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_115.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8　バックライト不良</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>ディスプレイICチップ及びライトIC/ライトインダクターの破損。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>非純正ケーブルの使用または充電器から供給される電力が大きかった為にICが破損したと考えられます。ICとライトニングコネクタの交換により修復。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_116.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8　水没</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>水没による起動不可。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>液晶表示コネクタの破損。交換により起動完了。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <!-- case06 -->
                      <b-tab title="修理事例６">
                        <!-- contants01 -->
                        <div class="container d-flex justify-content-center">
                          <div class="row col-12 p-0">
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_023.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_024.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone7 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>iPhone7起動不可。手で持てないくらい端末が熱くなる。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>給電ICチップ交換、メモリチップ間の給電回路電感ICチップを修理し、修復。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>2日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants02 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_025.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_026.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhone8 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>iPhone8起動不可。リンゴマークも何も表示されない。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>バッテリーコネクタ修理交換し、表示ICチップを交換して修復</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- contants03 -->
                            <div class="card col-md-4 border-0 mb-3 p-0 p-md-2">
                              <b-carousel
                                  id="RepairCarousel"
                                  v-model="slide"
                                  :interval="4000"
                                  controls
                                  indicators
                                  background="#ababab"
                                  img-width="800"
                                  img-height="560"
                                  style="text-shadow: 1px 1px 2px #333;"
                                  ref="RepairCarousel"
                              >
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_027.jpg"></b-carousel-slide>
                                <b-carousel-slide img-src="/images/contact/kiban-syuri_028.jpg"></b-carousel-slide>
                              </b-carousel>
                              <div class="card-body p-0 mt-3">
                                <table class="table table-bordered table-contact">
                                  <thead>
                                  <tr>
                                    <th class="text-center bg-light" style="width: 25%"> </th>
                                    <th class="text-center bg-light">iPhoneSE 起動不可</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <th class="text-center bg-light">症状</th>
                                    <td>電源が切れてから、その後起動しなくなった。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理結果</th>
                                    <td>給電ＩＣの交換を行い、TouchIDも異常なし。</td>
                                  </tr>
                                  <tr>
                                    <th class="text-center bg-light">修理日数</th>
                                    <td>1日
                                      <table>
                                        <tbody>
                                        <tr>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- contact button -->
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 col-lg-6 mt-3">
            <router-link :to="{ name: 'ContactRepairBoard' }" class="btn_contact">お申し込み</router-link>
          </div>
        </div>

        <div class="mt-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center"
                     style="">
                  <i class="fas fa-thumbs-up fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">技術に自信があります</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">基板修理復旧率９０％</span><br><br>
                    <span class="font-weight-bold">iPhone修理復旧率９７％</span><br><br>
                    過去10数年の修理経験がある当社にまずはお任せください<br><br>
                  </p>
                </div>
              </div>
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <i class="fas fa-exclamation-triangle fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">
                    修理ミス</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">
                      ・バッテリー交換<br />
                      ・タッチパネル交換<br />
                      ・水没<br />
                      ・リンゴループ<br />
                      ・データ吸い出し<br />
                      ・基板修理<br />
                      ・ゲーム機の修理<br />
                    </span>
                    <br />

                    どのような修理でもまずはお問い合わせください。<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4 mt-3">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="col-md-12 pb-2 text-center">
          <p class="font-weight-bold">
            即日修理・即日郵送をモットーとしております。お気軽にご相談ください。
          </p>
          <!-- contact button ※リンク方法が違うので見てもらう -->
          <div class="row justify-content-center">
            <div class="col-11 col-md-9 col-lg-6 mt-3">
              <router-link :to="{ name: 'Contact' }" class="btn_inquiry">お問い合わせはこちらから</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>

<script>
export default {
  name: 'ContactRepairBoardCases',
  async beforeRouteEnter(to, from, next) {
    return next();
  },
}
</script>