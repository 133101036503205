import Vue from "vue";

const state = {
    items: [],
    item: {
        id: '',
        parent_id: '',
        name: '',
        description: '',
        header: '',
        footer: '',
        image: '',
        for_front: false,
    },
    errors: {},
};

const initialState = {
    items: [],
    item: {
        id: '',
        parent_id: '',
        name: '',
        description: '',
        header: '',
        footer: '',
        image: '',
        for_front: false,
    },
    errors: {},
};
const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setList(state, items) {
        let map = new Map()
        items.map(row => {
            map.set(row.id, row.name)
        })

        state.list = map;
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.items.splice(index, 1);
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    series(state) {
        return state.items;
    },
    seriesOne(state) {
        return state.item;
    },
    list(state) {
        return state.list;
    },
};

const actions = {
    async fetch({commit}) {
        await window.axios.get('/series').then(res => {
            commit('setItems', res.data);
            commit('unsetErrors')
            console.log('series.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('series.fetch.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/series/' + id).then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('series.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('series.fetchOne.error', error)
        });
    },
    async fetchList({commit}) {
        await window.axios.get('/series').then(res => {
            commit('setItems', res.data);
            commit('setList', res.data);
            commit('unsetErrors')
            console.log('categories.fetchList', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('categories.fetchList.error', error)
        });
    },
    async new({commit}) {
        let item = initialState.item

        commit('setItem', item);
        console.log('series.new')
    },
    async delete({state, commit}, item) {
        const index = state.items.indexOf(item);

        return await window.axios.post('/series/delete', item)
            .then(res => {
                commit('delete', index);
                commit('unsetErrors')
                console.log('series.delete', state.items[index])
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('series.delete.error', error, state.items[index])
            });
    },
    async hasChildren({state, commit}, item) {
        return await window.axios.get('/series/' + item.id + '/has-children/')
            .then(res => {
                return !!res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('series.hasChildren.error', error, item)
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/series/store', item)
            .then(res => {
                commit('setItem', res.data)
                commit('unsetErrors')
                console.log('series.store', res, item)
                return true;
            }).catch(error => {
                commit('setErrors', error)
                console.log('series.store.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/series/update', newItem)
            .then(res => {
                commit('setItem', res.data)
                commit('unsetErrors')
                console.log('series.update', res, newItem)
                return true;
            }).catch(error => {
                commit('setErrors', error)
                console.log('series.update.error', error, newItem)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('series.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};