<template>
  <b-form inline>
    <label for="na-per-page-select">表示件数 </label>
    <b-form-select id="na-per-page-select" class="ml-2" v-model="selected" :options="options" @change="$emit('perPageSelect', selected)"></b-form-select>
  </b-form>
</template>


<script>
export default {
  name: 'NAPerPageSelect',
  data() {
    return {
      selected: 25,
      options: [
        { value: 5, text: '5件' },
        { value: 10, text: '10件' },
        { value: 25, text: '25件' },
        { value: 50, text: '50件' },
        { value: 100, text: '100件' }
      ]
    }

  },
  methods: {

  }
}
</script>