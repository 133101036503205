<template>
  <div>
    <div class="py-4 deferred">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center d-flex justify-content-center">
            <h1 class="border-bottom pb-2 copy22 mt-3 mb-4 font-weight-bold">後払い決済サービス</h1>
          </div>
        </div>
        <div class="row justify-content-center mb-4">
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-8">
            <ul class="col-12 list-group list-group-flush copy15">
              <li class="list-group-item"><i class="fas fa-check-square text-primary"></i>　手数料なし<br class="pc_hid"><small>（商品代金以外は一切無料）</small></li>
              <li class="list-group-item"><i class="fas fa-check-square text-primary"></i>　弊社基準の簡易審査あり</li>
              <li class="list-group-item"><i class="fas fa-check-square text-primary"></i>　個人事業主様もご利用可！</li>
            </ul>
          </div>
        </div>
        <div class="card col-12 col-md-10 mb-4 mx-auto">
          <div class="card-header copy16 bg-white">
            1）概要
          </div>
          <div class="card-body">
              <p class="copy11">BtoBの取引は後払い決済が一般的ですが、ご請求にかかる手間や未回収リスクを考慮すると、簡単には実現できません。<br />
                しかし弊社ではこの度、部品卸業界のECサイトで「後払い決済」を初めて導入 (※)しました。</p>
              <div class="col-12 col-lg-10 mx-auto px-0">
                <img class="img-fruid w-100" src="images/deferred/pay_later_box1_bottom.jpg"/>
              </div>
          </div>
        </div>
        <div class="card col-12 col-md-10 mb-4 mx-auto">
          <div class="card-header copy16 bg-white">
            2）サービスご利用の対象
          </div>
          <div class="card-body">
            <ul class="col-12 list-group list-group-flush copy11">
              <li class="list-group-item">・ご利用対象は、法人・個人事業主様を問いません。</li>
              <li class="list-group-item">・修理店運営者以外はご利用いただけません。</li>
              <li class="list-group-item">・モバイルリペアセンターご利用額が10万円以上、且つ3回以上ご利用歴のあるお客様</li>
            </ul>
          </div>
        </div>
        <div class="card col-12 col-md-10 mb-4 mx-auto">
          <div class="card-header copy16 bg-white">
            3）弊社後払い決済の特徴
          </div>
          <div class="card-body">
            <p class="copy11 mb-4">卸会社が取引先(修理店)に対して「後払い決済」を提供する場合、決済データの管理や代金回収などのコストが必ず発生するため、</p>
            <p class="font-weight-bold">
              <span class="del"><span>1⃣商品代金にそのコストを上乗せ</span></span><br />
              <span class="del"><span>2⃣商品代金とは別に手数料を請求</span></span>
            </p>
            <p class="copy11 mt-3">という方向に向かいがちですが、モバイルリペアセンターでは、煩雑になりがちな受発注処理と請求管理などの情報処理を、ECサイトで効率的に管理し、運営コストを大幅に削減しています。 そのため、後払い決済を提供するときに生まれるコストをお客様にご負担いただく必要がございません。<br /><br />
              後払い決済ご利用時の与信審査は初回ご購入時のみで、審査期間は最短即日、長くても3営業日程。2回目以降のご利用からは審査なしで最短即日発送が可能です。</p>
          </div>
        </div>
        <!-- contact button -->
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 col-lg-6 mt-4 mb-5">
            <a href="/#/contact/" class="btn_contact">お問い合わせ・お申し込みはこちらから</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>

<script>
export default {
  name: 'DeferredPayment',
  async beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);

    return next();
  },
}
</script>