<template>
  <div id="app">
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    <div v-show="isLoading"></div>

    <Navbar></Navbar>

    <div v-show="!isLoading">
      <router-view></router-view>
    </div>
    <div v-show="isLoading">
      <div style="height: 45vh"></div>
    </div>

    <Toast ref="toast"></Toast>

    <Footer></Footer>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions } from "vuex";
import store from "@/store";
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import Toast from '@/components/Toast'
import Maintenance from '@/views/Maintenance.vue'
import router from "./router";

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Toast,
  },
  methods: {
    ...mapActions('auth', [
      'IsLoggedIn', 'Logout'
    ]),
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  mounted: async function() {
    await store.dispatch('authCustomer/currentUser')
    await store.dispatch(
        'categories/fetchForFront'
    );
  },
  async beforeRouteUpdate(to, from, next) {
    // このコンポーネントを描画するルートが変更されたときに呼び出されますが、
    // このコンポーネントは新しいルートで再利用されます。
    // たとえば、動的な引数 `/foo/:id` を持つルートの場合、`/foo/1` と `/foo/2` の間を移動すると、
    // 同じ `Foo` コンポーネントインスタンスが再利用され、そのときにこのフックが呼び出されます。
    // `this` でコンポーネントインスタンスにアクセスできます。

    // await store.commit('setLoading', true)


    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', true)


    return next();
  },
  async beforeRouteLeave(to, from, next) {
    // このコンポーネントを描画するルートが間もなく
    // ナビゲーションから離れていく時に呼ばれます。
    // `this` でのコンポーネントインスタンスへのアクセスができます。
    // await store.commit('setLoading', true)
    next();
  },
}
</script>