<template>
  <div>
    <template v-if="currentStep === 0">
      <div class="py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1"></i>会員登録<i class="fas fa-chevron-right fa-xs mx-1"></i>Step1</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4 pb-4">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1 class="top_ttl mb-0 copy22"><span class="copy14 ml-2">Step1. メールアドレス認証</span>
              </h1>
            </div>
          </div>
          <div class="row m-2">
            <div class="col-sm-12 col-lg-6 mt-2 mb-2">
              <table class="table border">
                <tr>
                  <td>紹介者ID</td>
                  <td>{{ this.$route.params.customerCode }}</td>
                </tr>
                <tr>
                  <td>紹介者氏名</td>
                  <td><strong>{{ parentCustomerName }}</strong> 様</td>
                </tr>
              </table>
            </div>

            <div class="col-sm-12">
              <div class="form-group pb-2">
                <p>上記の紹介者さまの氏名をご確認ください。<br>
                  問題が無ければ会員登録に使用するメールアドレスを入力してください。</p>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit }">
                <form class="mb-4" @submit.prevent="handleSubmit(onSubmitRegister)">
                  <div class="form-group">
                    <p v-show="errorMessage" class="fade show alert alert-danger">
                      入力されたメールアドレスはご利用いただけません。<br>
                      別のメールアドレスを入力してください。
                    </p>
                    <Input name="メールアドレス" type="text" v-model="form.email_register" rules="required|email"/>
                  </div>
                  <button type="submit" class="btn btn-primary px-4 rounded-0">送 信</button> <br>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="currentStep === 1">
      <div class="py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p class="mb-0 copy08">
                <a href="/#/">HOME</a>
                <i class="fas fa-chevron-right fa-xs mx-1"></i>
                会員登録
                <i class="fas fa-chevron-right fa-xs mx-1"></i>
                Step2
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4 pb-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <h1 class="top_ttl mb-0 copy22"><span class="copy14 ml-2">Step2. 認証コード入力</span>
              </h1>
            </div>
          </div>
          <div class="row mt-2 m-2">
            <div class="col-sm-12 col-md-8 pt-2 pb-2">
              <div class="form-group">
                <p class="text-small alert alert-success">
                  {{ form.email_register }} 宛に認証コードを送信しました。<br>
                  メール本文に認証用リンクが記載されているので、そちらを押していただくか、<br>
                  同じくメールに記載の認証コードを、下のフォームに入力してください。</p>
              </div>
            </div>

            <div class="col-sm-12 col-md-8">
              <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
                <b-form-row class="w-100 d-block mx-auto">
                  <b-col>
                    <form @submit.prevent="handleSubmit(onSubmitValidate)">
                      <b-row>
                        <b-col v-if="showError" class="mb-3" >
                          <fade-transition>
                            <p class="fade show alert alert-danger">入力された認証コードが間違っています</p>
                          </fade-transition>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="col-sm-8">
                          <Input name="認証コード" type="text" v-model="form.code" rules="required" class="mb-3"/>
                        </b-col>
                        <b-col>
                          <button type="submit" class="btn btn-primary px-4 rounded-0">送 信</button>
                        </b-col>
                      </b-row>

                    </form>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-4">
                  <b-col class="text-left">
                    <form @submit.prevent="handleSubmit(onSubmitReSend)">
                      <button type="submit" class="btn btn-success px-4 rounded-0">認証コードを再送</button>
                    </form>
                  </b-col>
                </b-form-row>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="currentStep === 2">
      <Register :emailVerified="this.emailVerified" :fromInvite="true"></Register>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import Input from "@/components/Form/Input"
import router from "@/router";
import Register from "@/views/Register"


export default {
  name: 'RegisterByInvite',
  components: { Input, Register },
  data() {
    return {
      spinner: false,
      validationFailed: false,
      zipDisable: false,
      accept: false,
      plainPassword: '',
      currentStep: 0,
      errorMessage: '',
      grade: this.$route.params.grade,
      verifiedEmail: this.$route.params.verifiedEmail,
      form: {
        email_register: (this.$route.params.verifiedEmail) ? this.$route.params.verifiedEmail : '',
        parent_customer_code: this.$route.params.customerCode,
        code: this.$route.params.verificationCode,
      },
    }
  },
  methods: {
    ...mapActions('customers', [
      'new', 'store', 'fetchZip'
    ]),
    ...mapActions('authCustomer', [
      'Login'
    ]),
    ...mapActions('emailVerification', [
      'register', 'reSend', 'validateCode'
    ]),
    async onSubmitRegister() {
      this.setLoading(true)
      this.showError = false

      const User = new FormData();
      User.append("email", this.form.email_register);
      User.append("parent_customer_code", this.$route.params.customerCode)
      User.append("grade", this.$route.params.grade)
      try {
        const res = await this.register(User);

        this.setLoading(false)
        console.log(res)

        if(res.error !== '') {
          this.errorMessage = res.error;
        } else {
          this.currentStep++;
        }

      } catch (error) {
        this.setLoading(false)

        console.log(error);
        this.showRegisterError = true
      }
    },
    async onSubmitValidate() {
      this.setLoading(true)
      this.showError = false

      const User = new FormData();
      User.append("email", this.form.email_register);
      User.append("code", this.form.code);
      User.append("parent_customer_code", this.form.parent_customer_code);

      await this.validateCode(User).then(res => {
        if (res === true) {
          this.currentStep = 2;
        } else {
          this.showError = true
        }
      }).catch(error => {
        console.log('RegisterFromInvite.validateCode.error', error)
        this.showError = true
      });

      this.setLoading(false)
    },
    async onSubmit() {
      await store.commit('setLoading', true)

      this.validationFailed = false
      this.customer.email = this.emailVerification.email
      this.plainPassword = this.customer.password

      this.store(this.customer).then(res => {
        if (res === true) {
          this.registerAfterLogin()
        }
      });
    },
    async registerAfterLogin() {
      const User = new FormData();
      User.append("email", this.customer.email);
      User.append("password", this.plainPassword);
      try {
        const res = await this.Login(User);
        console.log(res);

        await router.push('/my-page')

      } catch (error) {
        this.setLoading(false)
        this.showError = true

        console.log('login.error', error)
      }
    },
    async onSubmitReSend() {
      this.setLoading(true)
      this.showError = false
      this.showReSend = false;

      const User = new FormData();
      User.append("email", this.email_register);

      try {
        const res = await this.reSend(User);

        this.showReSend = true;

      } catch (error) {
        this.setLoading(false)

        this.showError = true
      }
    },
    findAddress() {
      this.fetchZip(this.customer.zip).then(res => {
        console.log(res)
      });
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    setLoading(value) {
      store.commit('setLoading', value)
    },
  },
  computed: {
    ...mapState({
      prefs: state => state.enums.items['pref'],
    }),
    ...mapGetters('emailVerification', [
      'emailVerification'
    ]),
    ...mapGetters('customers', [
      'customer', 'parentCustomerName'
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async created() {
  },
  async mounted() {
    if(this.$route.params.verifiedEmail !== undefined && this.$route.params.verificationCode !== undefined) {
      console.log("VERI", this.$route.params.verificationCode)
      this.onSubmitValidate();
    } else {
      console.log("ELSE", this.$route.params.verifiedEmail, this.$route.params.verificationCode)
      this.currentStep = 0;

      this.$store.dispatch(
          'customers/fetchParent',
          this.$route.params.customerCode
      );

      await store.commit('setLoading', false)
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', true)

    await store.dispatch('customers/resetState');

    await store.dispatch('enums/fetch')
    await store.dispatch('customers/new')

    // Common
    await store.dispatch(
        'enums/fetch'
    );


    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentStep = 0;

    await store.dispatch('enums/fetch')
    await store.dispatch('customers/new')

    await store.dispatch('customers/resetState');

    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>