<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3 class="px-3 px-md-0 top_ttl mt-2 mb-0">Category<span class="copy11 ml-2">商品カテゴリー</span>
        </h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <template v-for="(category, index) in categories">
        <template v-if="index < 8">
          <div class="p-3 col-sm-6 col-lg-3" :key="category.id">
              <router-link
                  class="text-dark"
                  :to="{ name: 'CategoryById', params: { id: category.id }}"
              >
                <div class="row mx-0">
                  <div class="col-3 p-0">
                    <img v-if="category.image" class="img-fluid d-block" :src="category.image">
                    <img v-else class="img-fluid d-block" :src="category.image_path">
                  </div>
                  <div class="col-9 pl-2 pr-0">
                    <p class="mb-1">
                      <b>{{ category.name }}</b>
                    </p>
                    <p class="mb-0 copy09">{{ category.description }}</p>
                  </div>
                </div>
              </router-link>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import store from "../../../admin/src/store";

export default {
  name: 'Category',
  components: {},
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    ...mapActions('categories', [
      'fetchForFront'
    ]),
  },
  computed: {
    ...mapGetters('categories', [
      'categories',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
