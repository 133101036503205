<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-4">
        <h1 class="top_ttl mb-0 copy22">Product<span class="copy14 ml-2">商品名から探す</span></h1>
      </div>
    </div>
    <div class="row py-3">
      <CategoryLeftMenu></CategoryLeftMenu>

      <div class="col-md-9 order-md-2">
        <div class="row px-3 px-md-0">
          <!--{ 検索結果有り }-->
          <template v-if="products">
            <ComponentListProduct :items="products"></ComponentListProduct>
          </template>
          <!--{ 検索結果無し }-->
          <template v-else>
            条件にマッチする商品が見つかりませんでした
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import CategoryLeftMenu from "@/components/CategoryLeftMenu"
import ComponentListProduct from "@/components/List/Product"

export default {
  name: 'CategoryById',
  components: {
    CategoryLeftMenu,
    ComponentListProduct
  },
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters('newProducts', [
      'products',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.productName !== undefined) {
      await store.dispatch(
          "newProducts/fetchByProductName",
          to.params.productName
      );
    } else {
      await store.dispatch(
          "newProducts/fetch"
      );
    }

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.productName !== undefined) {
      await store.dispatch(
          "newProducts/fetchByProductName",
          to.params.productName
      );
    } else {
      await store.dispatch(
          "newProducts/fetch"
      );
    }

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
