<template>

  <div class="container">
    <div class="row">
      <div class="col-md-12 py-2 px-3 mx-2 px-md-2">
        <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i>カテゴリーから探す</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3 px-3 px-md-2">
        <div class="bg-secondary col-lg-5 mt-3 mt-md-0">
          <h1 class="contents-ttl copy22 mb-4 p-1">Category<span class="copy12 ml-2">カテゴリーから探す</span></h1>
        </div>
      </div>
    </div>
    <div class="row">
      <CategoryLeftMenu></CategoryLeftMenu>

      <div class="col-md-9">
        <div class="row">
          <template v-for="(category) in categories">
            <div class="col-md-6 py-4 px-3">
              <div class="row mx-0">
                <div class="p-0 col-4">
                  <template v-if="category.children">
                    <router-link
                        :to="{ name: 'CategoryById', params: { id: category.id }}"
                    >
                      <img v-if="category.image" class="img-fluid d-block" :src="category.image">
                      <img v-else class="img-fluid d-block" :src="category.image_path">
                    </router-link>
                  </template>
                  <template v-else>
                    <router-link
                        :to="{ name: 'CategoryById', params: { id: category.id }}"
                    >
                      <img v-if="category.image" class="img-fluid d-block" :src="category.image">
                      <img v-else class="img-fluid d-block" :src="category.image_path">
                    </router-link>
                  </template>
                </div>
                <div class="pl-2 pr-0 col-8">
                  <template v-if="category.children.length > 0">
                    <router-link
                        :to="{ name: 'CategoryList', params: { id: category.id }}"
                    >
                      <p class="mb-1"><b>{{ category.name }}</b></p>
                    </router-link>
                    <p class="mb-0 copy09">{{ category.description }}</p>
                  </template>
                  <template v-else>
                    <router-link
                        :to="{ name: 'CategoryById', params: { id: category.id }}"
                    >
                      <p class="mb-1"><b>{{ category.name }}</b></p>
                    </router-link>
                    <p class="mb-0 copy09">{{ category.description }}</p>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Child category list -->
        <section class="pb-4">
          <div class="container px-0">
            <div class="row px-2">
              <div class="col-md-6 pl-0">
                <h3 class="mb-3 category-title">小物部品</h3>
              </div>
              <div class="col-md-6 px-0">
                <form class="form-inline">
                  <input class="category-search form-control mr-sm-2" type="search" placeholder="商品名を入力" aria-label="Search"/>
                  <button class="btn btn-outline-primary my-2 my-sm-0 rounded-0" type="submit">探す</button>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="products-list.html" class="d-inline-block">13Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">XSMAX</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">13</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12 mini</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">12</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11Pro Max</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11 Pro</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">11</a>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-3 col-md-4 p-2">
                <div class="border">
                  <div class="p-2 text-center">
                    <a href="#" class="d-inline-block">XSMAX</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- New item end -->
        <!-- Pickup item -->
        <!-- Pickup item end -->
      </div>


    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import CategoryLeftMenu from "@/components/CategoryLeftMenu"
export default {
  name: 'Category',
  components: { CategoryLeftMenu },
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    ...mapActions('categories', [
      'fetchForFront'
    ]),
    searchProducts() {
      console.log('searchProducts')
    },
  },
  computed: {
    ...mapGetters('categories', [
      'categories',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
