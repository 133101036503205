<template>
  <div id="toasts">
    <!-- { 保存 } -->
    <b-toast id="save-success-toast1" variant="success" solid no-close-button>
      保存しました
    </b-toast>

    <b-toast id="save-success-toast2" variant="success" solid no-close-button>
      保存しました
    </b-toast>

    <b-toast id="save-failed-toast1" variant="danger" solid no-close-button>
      保存に失敗しました
    </b-toast>

    <b-toast id="save-failed-toast2" variant="danger" solid no-close-button>
      保存に失敗しました
    </b-toast>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      counter: 0
    }
  },
  methods: {
    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        variant: variant,
        solid: true
      })
    }
  }
}
</script>