<template>
  <div>
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="this.onCancel"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    <div v-show="isLoading" style="width: 100vh; height: 100vh;"></div>
    <div v-show="!isLoading">
      <div class="py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1"></i>ご注文手続き</p>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 border-bottom" style="">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1 class="top_ttl mb-0 copy22 text">Order Processing<span
                  class="copy14 ml-0 ml-md-2 mt-2 mt-md-0 text">ご注文手続き</span>
              </h1>
            </div>
          </div>
          <div class="row bs-wizard justify-content-center py-5">
            <div class="col-xs-3 bs-wizard-step complete">
              <div class="text-center bs-wizard-stepnum text-primary">Step 1</div>
              <div class="progress">
                <div class="progress-bar"></div>
              </div>
              <a href="/#/cart" class="bs-wizard-dot"> </a>
              <div class="bs-wizard-info text-center px-3">カートの商品</div>
            </div>
            <div class="col-xs-3 bs-wizard-step complete">
              <!-- complete -->
              <div class="text-center bs-wizard-stepnum text-primary">Step 2</div>
              <div class="progress">
                <div class="progress-bar"></div>
              </div>
              <a href="/#/login" class="bs-wizard-dot"> </a>
              <div class="bs-wizard-info text-center px-3">ログイン</div>
            </div>
            <div class="col-xs-3 bs-wizard-step active">
              <!-- active -->
              <div class="text-center bs-wizard-stepnum text-primary">Step 3</div>
              <div class="progress">
                <div class="progress-bar"></div>
              </div>
              <a href="/#/order/confirm" class="bs-wizard-dot"> </a>
              <div class="bs-wizard-info text-center px-3">ご注文内容確認</div>
            </div>
            <div class="col-xs-3 bs-wizard-step disabled">
              <!-- active -->
              <div class="text-center bs-wizard-stepnum text-primary">Step 4</div>
              <div class="progress">
                <div class="progress-bar"></div>
              </div> <a href="#" class="bs-wizard-dot"> </a>
              <div class="bs-wizard-info text-center px-3">ご注文の完了</div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-5">
        <div class="container">
          <div class="row justify-content-center">
            <!-- お客様情報 -->
            <div class="col-md-10">
              <div class="mb-5">
                <h2 class="copy14 pb-3 border-bottom"><i class="fas fa-user text-primary mr-2"></i>お客様情報</h2>
                <p class="my-3">
                  会員ID: {{ order.customer_code }}<br>
                  <span class="font-weight-bold">{{ order.company_name }} {{ order.company_division }}</span><br>
                  <span class="font-weight-bold">{{ order.name }} ({{ order.name_kana }}) 様</span><br>
                  〒{{ order.zip }} {{ enums['pref'][order.pref].description }} {{ order.address1 }} {{ order.address2 }}<br><br>
                  TEL: {{ order.phone }}<br>
                  <template v-if="order.cell_phone != ''">
                    携帯: {{ order.cell_phone }}<br>
                  </template>
                  {{ order.email }}
                </p>
              </div>
              <div class="mb-5">
                <h2 class="copy14 pb-3 border-bottom"><i class="fas fa-truck text-primary mr-2"></i>お届け先情報</h2>
                <p class="my-3">
                  <span class="font-weight-bold">{{ order.delivery_company_name }} {{ order.delivery_company_division }}</span><br>
                  <span class="font-weight-bold">{{ order.delivery_name }} ({{ order.delivery_name_kana }}) 様</span><br>
                  〒{{ order.delivery_zip }} {{ enums['pref'][order.delivery_pref].description }}
                  {{ order.delivery_address1 }} {{ order.delivery_address2 }}<br><br>
                  TEL: {{ order.delivery_phone }}<br>
                  <template v-if="order.delivery_phone != ''">
                    携帯: {{ order.delivery_phone }}<br>
                  </template>
                </p>
                <div class="">
                  <b-button class="btn btn-secondary rounded-0 px-3 btn-sm" v-b-modal.change-delivery-modal>お届け先を変更する</b-button>
                  <b-modal
                      header-bg-variant="light"
                      id="change-delivery-modal"
                      title="お届け先を変更する"
                      size="lg"
                      hide-footer
                  >
                    <template #modal-header>
                      <span class="font-weight-bold text-primary">お届け先を変更する</span>
                    </template>
                    <b-container fluid>
                      <!-- お届け先追加 -->
                      <b-row class="mb-3 text-center">
                        <b-col>
                          <b-button class="rounded-0 btn btn-secondary" v-b-modal.add-delivery-modal>お届け先を新しく追加する</b-button>
                          <b-modal
                              id="add-delivery-modal"
                              title="お届け先を新しく追加する"
                              size="lg"
                              header-bg-variant="primary"
                              header-text-variant="white"
                              hide-footer
                          >
                            <AddDelivery :customer-code="order.customer_code" @addDeliveryComplete="this.fetchCustomer"></AddDelivery>
                          </b-modal>
                        </b-col>
                      </b-row>
                      <hr>
                      <!-- お届け先変更 -->
                      <b-row>
                          <template v-for="(row, index) in order.deliveries">
                            <b-col sm="6" xs="12">
                              <b-card
                                  :header="'お届け先' + parseInt(index + 1)"
                                  class="mb-2"
                              >
                                <b-card-text>
                                  <span class="font-weight-bold">法人名: {{ row.company_name | emptyToLabel }}</span><br>
                                  <span class="font-weight-bold">店舗名: {{ row.company_division | emptyToLabel }}</span><br>
                                  <br>
                                  {{ row.name }} ({{ row.name_kana }})<br>
                                  <br>
                                  〒{{ row.zip }} <br>
                                  {{ enums['pref'][row.pref].description }}{{ row.city }} {{ row.address1 }} <br>
                                  {{ row.address2 }} <br>
                                  <br>
                                  TEL: {{ row.phone }}<br>
                                </b-card-text>
                                <hr>

                                <div class="text-center">
                                  <b-button class="btn-sm rounded-0 text-center mr-2" variant="primary" @click.prevent="handleSetDelivery(row)">設定する</b-button>
                                  <b-button class="btn-sm rounded-0 text-center btn-gray" variant="danger" @click.prevent="handleDeleteDelivery(row)">削除する</b-button>
                                </div>
                              </b-card>
                            </b-col>
                          </template>
                      </b-row>
                    </b-container>
                  </b-modal>
                </div>
              </div>

              <!-- お届け日時指定 -->
              <div class="pb-3 mb-5">
                <h2 class="copy14 pb-3 border-bottom"><i class="fas fa-truck text-primary mr-2"></i>お届け日時指定</h2>
                <p class="my-3">
                  <b-input-group size="lg" prepend="お届け希望日">
                    <b-form-input id="delivery_date" type="date" :min="getDeliveryDateMin()" :max="30" class="form-control rounded-0" v-model="order.delivery_date" />
                  </b-input-group>
                  <br>

                  <b-input-group size="lg" prepend="時間指定">
                    <b-form-select class="form-control" id="delivery_time" v-model="order.delivery_time">
                      <option value="" :selected="true">指定なし</option>
                      <option v-for="(option, index) in enums['delivery_time']" v-bind:value="option.value" :key="index">
                        {{ option.description }}
                      </option>
                    </b-form-select>
                  </b-input-group>
                </p>
              </div>


              <!-- ポイント -->
              <div class="pb-3 mb-5">
                <h2 class="copy14 pb-3 border-bottom"><i class="fas fa-p text-primary mr-2"></i>保有ポイントを利用する
                </h2>

                <p class="alert alert-info rounded-0">
                  1pt = 1円としてご利用いただけます。 <br>
                  お客様の現在の保有ポイントは「<span class="font-weight-bold">{{ order.point | numberFormat }}pt</span>」です。
                </p>

                <b-input-group size="lg" prepend="利用ポイント" append="pt" class="rounded-0" >
                  <b-form-input class="text-right" v-model="order.usage_point" type="number" name="usage_point" min="0" :max="order.point" :disabled="order.point === 0" @change="handleChangeUsagePoint"></b-form-input>
                </b-input-group>
                <button class="mt-2 float-right btn btn-sm btn-secondary rounded-0" @click.prevent="useAllPoint()">保有ポイントを全て利用する</button>
              </div>

              <!-- お支払い方法 -->
              <div class="pb-3">
                <h2 class="copy14 pb-3 border-bottom"><i class="fas fa-money-check-alt text-primary mr-2"></i>お支払い方法
                </h2>

                <div class="form-check">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        @change="handleChangePaymentMethod"
                        v-model="order.payment_id"
                        :aria-describedby="ariaDescribedby"
                        stacked
                        plain
                    >
                      <b-form-radio v-if="!isMirror" v-model="order.payment_id" :aria-describedby="ariaDescribedby" name="payment_id" value="1" :disabled="onlyBank()">クレジットカード決済</b-form-radio>
                      <b-form-radio v-model="order.payment_id" :aria-describedby="ariaDescribedby" name="payment_id" value="2" :disabled="onlyBank()">代金引換</b-form-radio>
                      <b-form-radio v-model="order.payment_id" :aria-describedby="ariaDescribedby" name="payment_id" value="3">銀行振込</b-form-radio>
                      <b-form-radio v-model="order.payment_id" :aria-describedby="ariaDescribedby" name="payment_id" value="4" :disabled="onlyBank()">後払い</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>

              <p class="mb-5 p-2 border">
                クレジットカードをお選びの場合は、決済ページへ進むボタンを押すとクレジットカード決済画面に変わります。そちらでカード情報を入力して決済を完了してください。
              </p>

              <!-- カードの登録 -->
              <template v-if="order.payment_id == 1">
                <div>
                  <div class="pb-3">
                    <h2 class="copy14 pb-3 border-bottom">
                      <i class="fas fa-money-check-alt text-primary mr-2"></i>クレジットカード情報の登録 (任意)
                    </h2>
                  </div>

                  <!-- 登録画面から返ってきた場合 -->
                  <div class="alert alert-success text-center" role="alert" v-if="$route.params.cardEditResult">
                    カード情報を登録 / 編集しました。
                  </div>

                  <!-- 登録済みカード一覽 -->
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">カード番号</th>
                      <th scope="col">有効期限</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="registeredCards.length > 0">
                      <template v-for="(card, index) in registeredCards">
                        <tr :key="index">
                          <th scope="row">{{ index + 1 }}</th>
                          <td>{{ card.cardNo }}</td>
                          <td>{{ card.expire }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="3" class="text-center">
                          <div class="alert alert-lightgray">
                            登録されているカードはありません
                          </div>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>

                  <button
                      class="mx-auto w-50 btn btn-darkpink rounded-0 py-2 btn-block" @click.prevent="cardEditHandler()">カード情報を登録 / 編集する
                  </button>

                  <p class="mt-3 mb-5 p-2 border">
                    注1. 上のボタンを押してからご注文のときに利用されるクレジットカードを登録すると、カード情報を入力せずにご注文いただけます。 <br>
                    また、複数のカード情報を登録していただいた場合、決済画面にてどのカードを利用するか選択いただけます。 <br><br>
                    <span class="text-danger">ここで登録されるカード情報は決済代行業者(GMO-PG)側に保存され、当システムには一切保持しませんので、安全にご利用いただけます</span>
                    <br><br>
                    <span class="text-danger font-weight-bold">注2. カード情報を登録しなくてもご注文いただけますが、その場合カード情報は注文毎にご入力いただきます。</span><br>

                  </p>

                </div>
              </template>


              <!-- 備考欄 -->
              <div class="pb-3 mb-5">
                <h2 class="copy14 pb-3 border-bottom"><i class="fa fa-comment-o text-primary mr-2"></i>その他お問い合わせ
                </h2>
                <b-form-textarea
                    id="textarea"
                    v-model="order.message"
                    placeholder="その他お問い合わせ事項がございましたら、こちらにご入力ください。"
                    rows="5"
                    max-rows="6"
                ></b-form-textarea>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" style="">
            <div class="col-md-10" style="">
              <h3 class="copy14 mb-3"><i class="fas fa-shopping-cart fa-lg mr-2 text-primary"></i>ご注文明細</h3>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th></th>
                    <th>商品名</th>
                    <th>購入数</th>
                    <th class="text-center">小計</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(item,index) in orderDetails">
                    <tr :key="item.detail.product_code">
                      <!-- No -->
                      <td class="align-middle">{{ index + 1 }}</td>

                      <!-- 商品名 -->
                      <td class="p-1">
                        <div class="row mx-0">
                          <div class="p-0 order-1 order-lg-1 col-md-3 col-3 d-flex align-items-center"><img
                              class="img-fluid d-block" :src="item.product.main_image_path"></div>
                          <div
                              class="order-2 order-lg-2 d-flex flex-column justify-content-center col-md-9 col-9 p-2">
                            <router-link
                                class="text-dark mb-2"
                                :to="{ name: 'ProductDetail', params: { id: item.product.id }}"
                            >
                              <p class="mb-0">{{ item.product.product_name }}</p>
                            </router-link>
                            <span v-if="item.detail.product_class_value1"
                                  class="text-small">{{ item.product.product_class_type_id1_label }}: {{ item.detail.product_class_value1 }}</span>
                            <span v-if="item.detail.product_class_value2"
                                  class="text-small">{{ item.product.product_class_type_id2_label }}: {{ item.detail.product_class_value2 }}</span>
                            <span v-if="item.detail.model_name"
                                  class="text-small mt-2">モデル番号: {{ item.detail.model_name }}</span>
                          </div>
                        </div>
                      </td>
                      <!-- 数量 -->
                      <td class="align-middle text-right pr-0" style="width: 15%">
                        {{ item.quantity | numberFormat }}点
                      </td>
                      <!-- 小計(販売価格) -->
                      <td class="align-middle text-right pr-0" style="width: 20%">
                        {{
                          Math.floor((item.detail.price.sales_price * item.quantity) * (1 + item.detail.tax_rate)) | numberFormat
                        }}円<br><small
                          class="ml-1">税込</small>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div class="row mr-2 pt-4 border-top">
                <div class="col-md-9" style="">
                  <p class="text-right font-weight-bold">商品購入合計</p>
                </div>
                <div class="col-md-3" style="">
                  <p class="text-right">
                    <span class="copy12">{{ orderTotal | numberFormat }}円</span>
                    <br>
                    <small class="ml-1">税込</small>
                  </p>
                </div>
              </div>
              <div class="row mr-2" v-if="islandShippingFee < 1">
                <div class="col-md-9">
                  <p class="text-right font-weight-bold">送料</p>
                </div>
                <div class="col-md-3">
                  <p class="text-right">
                    <template v-if="orderTotal < 33000">
                      <span class="copy12">{{ getShippingFee(order) | numberFormat }}円</span><br><small class="ml-1">税込</small>
                    </template>
                    <template v-else>
                      <span class="copy12">無料</span>
                      <span class="d-none">{{ shippingFee = 0 }}</span>
                    </template>
                  </p>
                </div>
              </div>
              <div v-if="order.charge > 0" class="row mr-2">
                <div class="col-md-9">
                  <p class="text-right font-weight-bold">代引手数料</p>
                </div>
                <div class="col-md-3">
                  <p class="text-right">
                    <span class="copy12">{{ getCharge(order) }}円</span><br><small class="ml-1">税込</small>
                  </p>
                </div>
              </div>
              <div class="row pt-3  mr-2 border-top">
                <div class="col-md-9">
                  <p class="text-right font-weight-bold">ポイント値引</p>
                </div>
                <div class="col-md-3">
                  <p class="text-right">
                    <span
                      class="copy12">-{{
                      order.usage_point | numberFormat
                    }}円
                    </span>
                  </p>
                </div>
              </div>
              <div class="row pt-3  mr-2 border-top">
                <div class="col-md-9">
                  <p class="text-right font-weight-bold" style="position: relative; top: 7px">ご請求額</p>
                </div>
                <div class="col-md-3">
                  <p class="text-right"><span
                      class="copy16">
                    {{ orderTotal + shippingFee + charge + islandShippingFee - order.usage_point | numberFormat }}円
                  <br><small class="copy11 ml-1">税込</small></span></p>
                </div>
              </div>
              <div class="row pt-3  mr-2 border-top">
                <div class="col-md-9">
                  <p class="text-right font-weight-bold">この注文で付与されるポイント</p>
                </div>
                <div class="col-md-3">
                  <p class="text-right"><span
                      class="copy12">{{
                      Math.floor((orderTotal + shippingFee + charge + islandShippingFee - order.usage_point) * order.point_rate) | numberFormat
                    }}pt
                  </span></p>
                </div>
              </div>
              <div class="row">
                <div class="mt-3 mb-2 text-center col-md-6" style=""><a
                    class="btn btn-middlegray rounded-0 py-2 btn-block" href="/#/cart">カートに戻る</a></div>
                <template v-if="order.payment_id == 1">
                  <template v-if="spinner">
                    <div class="mt-3 mb-2 text-center col-md-6" style="">
                      <button class="btn btn-darkpink rounded-0 py-2 btn-block" :disabled="spinner">
                        <b-spinner
                            small
                            variant="light"
                            key="info"
                            class="mr-1"
                        ></b-spinner>
                        処理中
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mt-3 mb-2 text-center col-md-6" style="">
                      <button
                          class="btn btn-darkpink rounded-0 py-2 btn-block"
                          @click.prevent="paymentHandler(order)">
                        決済ページへ進む
                      </button>
                    </div>
                  </template>
                </template>
                <!-- 代引 -->
                <template v-else>
                  <div class="mt-3 mb-2 text-center col-md-6" style="">
                    <button
                        class="btn btn-darkpink rounded-0 py-2 btn-block" @click.prevent="paymentHandler()">注文を完了する
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import store from "@/store";
import router from "@/router";
import size from 'lodash/size'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import AddDelivery from "@/components/AddDelivery"

momentTimezone.tz.setDefault('Asia/Tokyo');

export default {
  name: 'Order',
  components: { AddDelivery },
  data() {
    return {
      spinner: false,
      currentStep: 0,
      shippingFee: 0,
      charge: 0,
      selected: '2',
      options: [
        {text: 'クレジットカード決済', value: 1},
        {text: '代金引換', value: 2},
        {text: '銀行振込', value: 3},
        {text: '後払い', value: 4},
      ],
      orderSubtotal: 0,
      presentFlat: [],
      validateStatus: true,
      toastCount: 0,
      isMirror: (process.env.NODE_ENV === 'production-mirror' || process.env.NODE_ENV === 'local-mirror'),
    }
  },
  methods: {
    ...mapActions('myPage', [
      'fetchCustomer',
      'fetchCardEditUrl',
      'deleteDelivery',
    ]),
    ...mapActions('newCart', [
      'checkout',
      'getIslandShippingFee',
      'storeFrontByCredit',
      'storeFront',
      'getPresentList',
      'fetchCustomer',
      'fetchRegisteredCards',
      'fetchCardEditUrl',
      'setCustomerDelivery',
    ]),
    ...mapMutations('newCart', [
      'addShippingFee', 'setCustomer'
    ]),
    makeToast(variant, title, description) {
      this.toastCount++
      this.$bvToast.toast(description, {
        title: title,
        variant: variant,
      })
    },
    onlyBank() {
      let paymentTotal = this.orderTotal + this.shippingFee + this.order.charge + this.islandShippingFee
      let usagePoint = this.order.usage_point

      console.log("onlyBank", paymentTotal, usagePoint)
      if(paymentTotal > usagePoint) {
        return false
      }

      this.charge = 0;
      this.order.charge = 0;
      return true
    },
    handleChangeUsagePoint() {
      if(this.usage_point > this.order.point) {
        this.order.usage_point = this.order.point
      }

      let paymentTotal = this.orderTotal + this.shippingFee + this.order.charge + this.islandShippingFee
      let usagePoint = this.order.usage_point

      if(usagePoint > paymentTotal) {
        this.order.usage_point = paymentTotal
      }

      if(paymentTotal == usagePoint) {
        this.useAllPoint()
      }
    },
    handleChangePaymentMethod() {
      if(this.order.payment_id != 2) {
        this.order.charge = 0
      }

      if(this.order.payment_id == 2) {
        this.order.charge = 330
      }
      console.log(this.order.charge)
    },
    useAllPoint() {
      let point = this.order.point
      let max = this.orderTotal + this.shippingFee + this.order.charge + this.islandShippingFee

      console.log(point, max)

      if(max > point) {
        this.order.usage_point = point
      } else {
        this.order.payment_id = 3
        this.handleChangePaymentMethod()
        max = this.orderTotal + this.shippingFee + this.order.charge + this.islandShippingFee

        this.order.usage_point = max
        this.makeToast('info', '支払い方法を変更しました', '全額ポイント払いの場合、各種決済は不要となるため支払い方法を銀行振込に変更しました')
      }
    },
    checkUsagePoint(value) {

    },
    getDeliveryDateMin() {
      const now = moment();
      //console.log(now.format('YYYY-MM-DD'))
      return now.add(this.configurations.data.desired_delivery_date_margin, "d").format('YYYY-MM-DD');
    },
    async handleSetDelivery(delivery) {
      this.isLoading = true

      await this.setCustomerDelivery(delivery);
      this.$bvModal.hide('change-delivery-modal');

      this.isLoading = false
    },
    async handleDeleteDelivery(delivery) {
      this.isLoading = true
      await this.deleteDelivery(delivery)
      await this.fetchCustomer();
      this.isLoading = false
    },
    async setSelectedPresent(present) {
      if (present.product_id > 0) {
        this.selectedList.push(present)
      }
    },
    getShippingFee(order) {
      const customerPref = order.pref;
      const deliveryPref = order.delivery_pref;
      const targetPref = (deliveryPref > 0) ? deliveryPref : customerPref

      let shippingFee = this.shippingFees.filter(function(row, index) {
        if(targetPref > 0 && row.pref == targetPref) {
          return true;
        }
      })
      shippingFee = shippingFee[0]

      console.log("customerPref", customerPref)
      console.log("targetPref", targetPref);
      console.log("shippingFee",shippingFee)

      if (this.orderTotal <= 33000) {
        this.shippingFee = parseInt(shippingFee.shipping_fee) + parseInt(shippingFee.tax)
        this.order.shipping_fee = parseInt(shippingFee.shipping_fee) + parseInt(shippingFee.tax)
        return parseInt(shippingFee.shipping_fee) + parseInt(shippingFee.tax)
      } else {
        return 0
      }
    },
    getCharge(order) {
      // 代引以外
      if (order.payment_id != 2) {
        this.charge = 0;
        this.order.charge = 0;
        return this.charge;
      }

      if (this.orderTotal <= 33000) {
        this.charge = 330;
        this.order.charge = 330;
      } else if(this.orderTotal >= 33000 && this.orderTotal > 55000) {
        this.charge = 550;
        this.order.charge = 550;
      } else if(this.orderTotal >= 55000 && this.orderTotal > 110000) {
        this.charge = 880;
        this.order.charge = 880;
      }

      return this.charge;
    },
    async changeQuantity(item) {
      await this.changeQuantity(item)
    },
    async paymentHandler() {
      this.spinner = true

      const order = this.order

      if (order.payment_id == 1) {
        await this.storeFrontByCredit(order).then(function (url) {
          if (url !== '') {

            let paymentLink = document.createElement('a');

            paymentLink.href = url;
            document.body.appendChild(paymentLink);

            paymentLink.click();
          }
        })
      } else if (order.payment_id > 1) {
        const res = await this.storeFront(order).then((res) => {
          // 在庫なし
          if(!res) {
            this.$router.push({path: '/cart'})
          } else {
            window.localStorage.setItem('order_id', res);
            this.$router.push({path: '/order/complete'})
          }
        }).catch(error => console.log(error))
      }

      this.spinner = false
    },
    async cardEditHandler() {
      let cardEditLink = document.createElement('a');

      cardEditLink.href = this.cardEditUrl;
      document.body.appendChild(cardEditLink);

      cardEditLink.click();
    },
    handlerUpdatePresent(present) {
      this.setPresent(present)
    },
    // プレゼント上限
    getLimit(campaignId) {
      let limit = Object.entries(this.presentLimit).find(function (row) {
        if (row[0] === campaignId) {
          return row[1]
        }
      })

      return limit[1]
    },
    // 選択可能な数量
    getRemaining(campaignId) {
      let limit = this.getLimit(campaignId);

      // 選択済み数量合計
      let sumQuantity = this.presentList[campaignId].reduce((partialSum, a) => partialSum + a.selected_quantity, 0)

      return limit - sumQuantity

    },
    // 超過しているか、Limitに達していないプレゼントがあるかチェックする
    validatePresents() {
      let length = Object.entries(this.presentLimit).length
      if (length > 0) {
        for (const row of Object.entries(this.presentLimit)) {
          if (this.getRemaining(row[0]) > 0 || this.getRemaining(row[0]) < 0) {
            return false
          }
        }
      }

      return true
    },
    updatePresent(present) {
      console.log("updatePresent", present)
      this.validatePresents()
      store.commit('newCart/setPresent', present)
    },
    count(arrayOrObject) {
      return size(arrayOrObject)
    }
  },
  computed: {
    ...mapMutations('newCart', [
      'setSelectedQuantity', 'setPresent', 'setDelivery',
    ]),
    ...mapGetters('newCart', [
      'order',
      'orderDetails',
      'orderTotal',
      'islandShippingFee',
      'presentList',
      'presentQuantityTotal',
      'notValidated',
      'presentLimit',
      'presentContiton',
      'selectableLimit',
      'paymentId',
      'registeredCards',
      'cardEditUrl',
      'shippingFees',
    ]),
    ...mapGetters('myPage', [
      'customer',
    ]),
    ...mapGetters('authCustomer', [
      'StateUser'
    ]),
    ...mapGetters('enums', [
      'enums'
    ]),
    ...mapGetters('configurations', [
      'configurations'
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  created: async function () {
    // await store.commit('setLoading', true)
  },
  mounted: async function () {
    if(this.isMirror) {
      this.order.payment_id = 2;
    }

    await store.commit('setLoading', false)
  },
  async beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter")

    await store.commit('setLoading', true)


    await store.dispatch(
        'newCart/getCart',
        { cart_id: window.localStorage.getItem('cart_id') }
    ).then((res) => {
      if (size(res) === 0) {
        router.push('/cart').catch(function (err) {
        })
      }
    });

    await store.dispatch('newCart/fetchRegisteredCards');
    await store.dispatch('newCart/fetchCardEditUrl');

    await store.dispatch(
        'newCart/fetchCustomer'
    );

    await store.dispatch('newCart/fetchDelivery');
    await store.dispatch('newCart/fetchShippingFees')


    await store.dispatch(
        'enums/fetch'
    );
    await store.dispatch(
        'configurations/fetchOne'
    );
    /*
    await store.dispatch(
        'newCart/getIslandShippingFee',
        store.state.customers.item.zip
    )

     */

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate")

    await store.commit('setLoading', true)

    /*
    await store.dispatch(
        'newCart/getIslandShippingFee',
        store.state.customers.item.zip
    )

     */
    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
