<template>
  <div>
    <div class="py-4">
      <div class="container">
        <div class="row">
          <div class="contents mt-3 mb-3 col-md-12 d-flex justify-content-center">
            <h1 class="text-white copy16" style="font-family: &quot;M PLUS 1p&quot;, sans-serif; font-weight: 700;">修理代行サービス</h1>
          </div>
          <div class="contents col-md-12 text-center d-flex justify-content-center">
            <h2 class="copy23 pb-4 mb-5 font-weight-bold">部品販売のプロだからこそ<br>どこよりも丁寧に</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-9 bg-light">
            <p class="mb-0 p-2 copy12">
              パーツマスターでは基板修理のエンジニアが代⾏修理を承ります。<br>
              ご依頼フォームを入力いただいた後、担当者よりメールまたはお電話で具体的な料金と納期をご案内いたします。
            </p>
          </div>
        </div>
        <!-- contact button -->
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 col-lg-6 mt-5">
            <router-link :to="{ name: 'ContactRepairInstead' }" class="btn_contact">お申し込み</router-link>
          </div>
        </div>

        <!-- title01 -->
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="d-flex justify-content-center text-center contents">
            <h3 class="copy14 font-weight-bold">修理の流れ</h3>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contants01 -->
            <div>
              <div class="mb-5">
                <h4 class="copy12"><span>01</span>お客様情報のご入力</h4>
                <p>お送りいただく前に、申込フォームを入力頂きお申し込みください。<br>
                  ご入力いただきました後に、お電話（もしくはメール）で、お見積り（重要事項など含む）をご案内いたします。<br>
                  ※営業時間外のお申込みですと、翌営業日以降のご連絡になります。</p>
              </div>
            </div>

            <!-- contants02 -->
            <div>
              <div class="mb-5">
                <h4 class="copy12"><span>02</span>梱包・発送</h4>
                <p>ご案内にご同意いただけましたら、下記＜修理サービス依頼書＞をご記入、同封して端末をお送りください。</p>
                <div class="container my-4">
                  <div class="row col-12 justify-content-center">
                    <a class="btn btn-lg btn-navy px-5" href="/images/contact/requestform20220728.zip">依頼書ダウンロード</a>
                  </div>
                </div>
                <p>お送りの際は、<span class="marker">緩衝材で端末を保護し「ワレモノ扱い」</span>で元払いにてお送りください。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  送り先
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row">
                    <p class="mb-0 p-4">
                      〒169-0075<br>
                      東京都新宿区高田馬場1丁目33-16 KRビル 1F
                      モバイルリペアセンター宛<br>
                      ※送料はお客様負担とさせて頂きます。
                    </p>
                  </div>
                </div>
              </div>
              <div class="p-4 border rounded mb-5">
                <h5 class="pb-2 border-bottom border-danger">発送前のご注意</h5>
                <p class="mb-0">※配送料は<span class="text-red">お客様ご負担</span>とさせていただいております。<span class="text-red">元払い</span>にてお送りください。<br>
                  着払いでお送りいただいた場合には買取価格より差し引かせて頂きますので、ご了承くださいませ。<br><br>
                  ※機種名・IMEI・状態を記載した<span class="marker">簡易メモをご一緒に同梱</span>いただけますようお願い致します。</p>
              </div>
            </div>

            <!-- contants03 -->
            <div>
              <div class="mb-4">
                <h4 class="copy12"><span>03</span>端末到着後</h4>
                <p>端末が届きましたら、お電話（もしくはメール）で、端末が到着した旨をご連絡いたします。<br>
                  ※混雑状況やご依頼によってはご連絡が遅延する場合がございます。予めご了承ください。</p>
              </div>
              <div class="card mb-5">
                <div class="copy12 card-header font-weight-bold text-center">
                  作業について
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row p-4">
                    <p class="mb-0 px-2">作業費は<span class="marker">2,000円(税込 2,200円)</span>でございますが、修理金額に含まれていますので、別途頂戴することはございません。<br>
                      修復できなかった場合は<span class="text-red">送料+2,000円(税込 2,200円)のみご負担</span>となります。<br>
                      端末が弊社に到着してから約２～５日後にお届け致しますが、<span class="text-red">破損が激しいもの</span>は時間がかかる場合がございます。
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- contants04 -->
            <div>
              <div class="mb-4">
                <h4 class="copy12"><span>04</span>修理完了後</h4>
                <p>端末の修理作業が終わりましたら、お電話（もしくはメール）でご連絡させていただき、その後、発送いたします。<br>
                  ※銀行振込をご選択いただきました際は、ご入金確認後に発送いたします。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  手数料について
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row p-4 lineh20">
                    <ul>
                      <li>代金引換:ご請求金額に<span class="marker">代引手数料（金額により<span class="text-red">¥330～¥550</span>）</span>を<span class="text-red">加算</span></li>
                      <li>銀行振込:振込手数料を<span class="text-red">お客様ご負担</span></li>
                      <li>後払い決済[Paid]:手数料は<span class="text-red">お客様ご負担</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- title02 -->
            <div class="row mt-5 mb-4 d-flex justify-content-center">
              <div class="text-center contents">
                <h3 class="copy14 font-weight-bold">注意事項</h3>
              </div>
            </div>
            <div class="mb-4 lineh20">
              <p>※作業途中でのキャンセルは受け付けできかねます。<br>
                ※作業途中でのキャンセルは受け付けできかねます<br>
                ※破損状況によっては、症状が悪化する可能性があります。<br>
                ※元々のパネル・バッテリー・その他の部品が使用できない場合は、別途部品代を請求する場合がございます。（価格は当店での販売価格となります。）<br>
                ※リンゴループ、リカバリーモードの端末につきましては修理前に既にデータが損傷している可能性がございます。<br>
                万が一、修理完了後にデータが無く起動した場合(初期化状態)は修理には成功致しております為、通常の修理金額の半額をご請求させて頂きます。<br>
                ※端末の状態によっては、データ無し端末復旧となる場合がございます(データ初期化状態or初期化しないと正常起動しない状態)。<br>
                この場合でも修理完了とみなしますが(正常起動しない状態から正常起動する状態に回復しているため)、ご請求金額は半額とさせて頂きます。<br>
                ※パスコードがある場合は必ずご記載お願い致します。原則としましてパスコード記載が無い場合は復旧後の動作確認ができない為、修理ご対応は致しかねます。<br>
                ※万が一、復旧後に記載頂いたパスコードで解除ができない場合やパスコードが不明なものに関しましては【保証なし】【全額ご請求】とさせていただきます。</p>
            </div>

            <!-- contact button -->
            <div class="row justify-content-center">
              <div class="col-11 col-md-9 col-lg-6 mt-3">
                <router-link :to="{ name: 'ContactRepairInstead' }" class="btn_contact">お申し込み</router-link>
              </div>
            </div>

          </div>
        </div>

        <!-- title04 -->
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contants01 -->
            <div>
              <div class="p-4 border rounded mb-3 bg-lightgray">
                <h5 class="font-weight-bold">
                  梱包方法
                </h5>
                <p class="mb-4">
                  梱包には衝撃緩衝材を 使用してください。
                  ご協力よろしくお願い致します。<br>
                </p>
                <h5 class="font-weight-bold">
                  送料について
                </h5>
                <p class="mb-4">
                  配送料はお客様ご負担とさせていただいております。元払いにてお送りください。<br>
                  ※着払いでお送りいただいた場合には修理価格に加算させて頂きますので、ご了承くださいませ。
                </p>
                <h5 class="font-weight-bold">
                  代行修理端末お送り先
                </h5>
                <p class="mb-0">
                  〒169-0075<br>
                  東京都新宿区高田馬場1-33−16 KRビル 1F モバイルリペアセンター宛<br><br>
                  ※送料はお客様負担とさせて頂きます。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center"
                     style="">
                  <i class="fas fa-thumbs-up fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">技術に自信があります</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">基板修理復旧率９０％</span><br><br>
                    <span class="font-weight-bold">iPhone修理復旧率９７％</span><br><br>
                    過去10数年の修理経験がある当社にまずはお任せください<br><br>
                  </p>
                </div>
              </div>
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <i class="fas fa-exclamation-triangle fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">
                    修理ミス</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">
                      ・バッテリー交換<br />
                      ・タッチパネル交換<br />
                      ・水没<br />
                      ・リンゴループ<br />
                      ・データ吸い出し<br />
                      ・基板修理<br />
                      ・ゲーム機の修理<br />
                    </span>
                    <br />

                    どのような修理でもまずはお問い合わせください。<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4 mt-3">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="col-md-12 pb-2 text-center">
          <p class="font-weight-bold">
            即日修理・即日郵送をモットーとしております。お気軽にご相談ください。
          </p>
          <!-- contact button -->
          <div class="row justify-content-center">
            <div class="col-11 col-md-9 col-lg-6 mt-3">
              <router-link :to="{ name: 'Contact' }" class="btn_inquiry">お問い合わせはこちらから</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>

<script>
export default {
  name: 'ContactRepairBoardDetail',
  async beforeRouteEnter(to, from, next) {
    return next();
  },
}
</script>