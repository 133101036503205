import Vue from "vue";

const state = {
    items: [],
    item: {
        id: '',
        layout_id: '',
        slug_prefix: '',
        slug_suffix: '',
        name: '',
        title: '',
        description: '',
        seo_keyword: '',
        seo_description: '',
        header: '',
        body: '',
        footer: '',
        redirect_url: '',
        image_file_path: '',
        thumbnail_file_path: '',
        started_at: '',
        ended_at: '',
        is_login_required: Boolean,
        created_at: '',
        updated_at: '',
        deleted_at: '',
    },
    errors: {},
};

const initialState = {
    items: [],
    item: {
        id: '',
        layout_id: '',
        slug_prefix: '',
        slug_suffix: '',
        name: '',
        title: '',
        description: '',
        seo_keyword: '',
        seo_description: '',
        header: '',
        body: '',
        footer: '',
        redirect_url: '',
        image_file_path: '',
        thumbnail_file_path: '',
        started_at: '',
        ended_at: '',
        is_login_required: Boolean,
        created_at: '',
        updated_at: '',
        deleted_at: '',
    },
    errors: {},
};
const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.items.splice(index, 1);
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    pages(state) {
        return state.items;
    },
    page(state, id) {
        return state.items.filter((item) => item.id === id)
    }
};

const actions = {
    async fetch({commit}) {
        await window.axios.get('/pages').then(res => {
            commit('setItems', res.data);
            commit('pages.unsetErrors')
            console.log('pages.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('pages.fetch.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/pages/' + id).then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('pages.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('pages.fetchOne.error', error)
        });
    },
    async new({commit}) {
        let item = initialState.item

        commit('setItem', item);
        console.log('pages.new')
    },
    async delete({state, commit}, item) {
        const index = state.items.indexOf(item);

        return await window.axios.post('/pages/delete', item)
            .then(index => {
                commit('delete', index);
                commit('unsetErrors')
                console.log('pages.delete', state.items[index])
            }).catch(error => {
                commit('setErrors', error)
                console.log('pages.delete.error', error, state.items[index])
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/pages/store', item)
            .then(res => {
                commit('unsetErrors')
                console.log('pages.store', res, item)
            }).catch(error => {
                commit('setErrors', error)
                console.log('pages.error', error, item)
            });
    },
    async storeClone({commit}, item) {
        return await window.axios.post('/pages/clone', item)
            .then(res => {
                commit('unsetErrors')
                console.log('pages.storeClone', res, item)
            }).catch(error => {
                commit('setErrors', error)
                console.log('pages.storeClone.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/pages/update', newItem)
            .then(res => {
                commit('unsetErrors')
                console.log('pages.update', res, newItem)
            }).catch(error => {
                commit('setErrors', error)
                console.log('pages.update.error', error, newItem)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('pages.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};