import Vue from "vue";

const state = {
    items: [],
    item: {
        id: '',
        name: '',
        title: '',
        description: '',
        redirect_url: '',
        image: '',
        image_path: '',
        started_at: '',
        ended_at: '',
        created_at: '',
        updated_at: '',
    },
    search: {
        per_page: 25,
        page: 1,
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    errors: {},
};

const initialState = {
    items: [],
    item: {
        id: '',
        name: '',
        title: '',
        description: '',
        redirect_url: '',
        image: '',
        image_path: '',
        started_at: '',
        ended_at: '',
        created_at: '',
        updated_at: '',
    },
    search: {
        per_page: 25,
        page: 1,
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    errors: {},
};
const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setPagination(state, items) {
        Vue.set(state, 'pagination', items);
    },
    setSearch(state, search) {
        state.search = search
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.pagination.data.splice(index, 1);
        state.pagination.total--;
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    news(state) {
        return state.items;
    },
    newsOne(state) {
        return state.item;
    },
    pagination(state) {
        return state.pagination;
    },
    search(state) {
        return state.search;
    },
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/news', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('news.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetch.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/news/' + id).then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('news.fetchOne', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('news.fetchOne.error', error)
        });
    },
    async fetchLatest({commit}) {
        await window.axios.get('/news/latest').then(res => {
            commit('setItems', res.data);
            commit('unsetErrors')
            console.log('news.fetchLatest', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('news.fetchOne.error', error)
        });
    },
    async new({commit}) {
        let item = {
            id: '',
            name: '',
            title: '',
            description: '',
            redirect_url: '',
            image: '',
            image_path: '',
            started_at: '',
            ended_at: '',
        }

        console.log("NEW",item)

        commit('setItem', item);
        console.log('news.new')
    },
    async delete({state, commit}, item) {
        return await window.axios.post('/news/delete', item)
            .then(res => {
                const index = state.pagination.data.indexOf(item);

                commit('delete', index);
                commit('unsetErrors')
                console.log('news.delete', state.pagination.data[index])
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('news.delete.error', error, state.pagination.data[index])
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/news/store', item)
            .then(res => {
                console.log("STORE",res.data)
                commit('setItem', res.data)
                commit('unsetErrors')
                console.log('news.store', res, item)
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('news.store.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/news/update', newItem)
            .then(res => {
                commit('unsetErrors')
                console.log('news.update', res, newItem)
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('news.update.error', error, newItem)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('news.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};