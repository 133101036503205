<template>
  <div class="pt-4 pb-5">
    <div class="container">
      <div class="row col-12">
        <div class="copy09 mb-4 pb-1">
          <router-link
              :to="{ name: 'Home' }"
          >
            トップ
          </router-link>
          > お知らせ
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h1 class="top_ttl mb-0 copy22">News<span class="copy14 ml-2">お知らせ</span>
          </h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="py-3 px-4 border-bottom col-lg-12">
          <div class="row px-md-0">
            <template v-for="(item, index) in pagination.data">
              <div class="py-3 px-4 col-lg-12 border-bottom">
                <div class="row px-md-0">
                  <!-- 画像あり -->
                  <template v-if="typeof item.image_path === 'string'">
                    <div class="col-3 pl-3 pr-0">
                      <img class="img-fluid d-block" v-lazy="item.image_path">
                    </div>
                    <div class="col-9 pl-3 pr-0">
                      <p class="mb-1 text-primary"><strong>{{ item.created_at | toDate }}</strong></p>
                      <p class="mb-2 copy12" style=""><strong>{{ item.title }}</strong></p>
                      <p class="mb-0" v-html="item.description"></p>
                    </div>
                  </template>

                  <!-- 画像無し -->
                  <template v-else>
                    <div class="col-9 pl-3 pr-0">
                      <p class="mb-1 text-primary"><strong>{{ item.created_at | toDate }}</strong></p>
                      <p class="mb-2 copy12" style=""><strong>{{ item.title }}</strong></p>
                      <p class="mb-0" v-html="item.description"></p>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
  name: 'News',
  components: {},
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    ...mapActions('news', [
      'fetch', 'delete'
    ]),
  },
  computed: {
    ...mapGetters('news', [
      'pagination',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(
        "news/fetch",
    );

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    await store.dispatch(
        "news/fetch",
    );

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>