<template>
  <div>
    <div class="py-4">
      <div class="container">
        <div class="row">
          <div class="contents mt-3 mb-3 col-md-12 d-flex justify-content-center">
            <h1 class="text-white copy16" style="font-family: &quot;M PLUS 1p&quot;, sans-serif; font-weight: 700;">基板修理サービスについて</h1>
          </div>
          <div class="contents col-md-12 text-center d-flex justify-content-center">
            <h2 class="copy21 pb-4 mb-5 font-weight-bold">修復率が「劇的」に向上しました。<br>リンゴループもほぼ解決できます。</h2>
          </div>
          <div class="container mb-5">
            <div class="row text-center">
              <div class="col-lg-12 mb-5">
                <img src="images/contact/substrate_top_text02.png" class="img-fluid"/>
              </div>
              <div class="col-lg-12">
                <img src="images/contact/substrate_top_text03.png" class="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-9 bg-light">
            <p class="mb-0 p-2 copy12">
              iPhoneの修理作業後の状態悪化(画面や電源が入らなくなった)など重大なトラブルが発生した際に非常に有効で、ご利用によってトラブルを未然に防いだり、トラブルの規模を小さくすることが可能です。<br><br>
              2019年3月から修復率が飛躍的に向上し、修復が難しいとされていた「リンゴループ端末」「水没端末」も、他サービスとは比較にならないほどの高い確率で修復可能です。
            </p>
          </div>
        </div>
        <!-- contact button -->
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 col-lg-6 mt-3">
            <router-link :to="{ name: 'ContactRepairBoard' }" class="btn_contact">お申し込み</router-link>
          </div>
        </div>

        <!-- title01 -->
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="text-center contents">
            <h3 class="copy14 font-weight-bold">基板修理事例</h3>
          </div>
        </div>
        <div class="container d-flex justify-content-center">
          <div class="row col-12 col-xl-10">
            <!-- contents01 -->
            <div class="card col-md-4 border-0 mb-3">
              <b-carousel
                  id="HomeCarousel"
                  v-model="slide"
                  :interval="4000"
                  controls
                  indicators
                  background="#ababab"
                  img-width="800"
                  img-height="560"
                  style="text-shadow: 1px 1px 2px #333;"
                  ref="HomeCarousel"
              >
                <b-carousel-slide img-src="/images/contact/kiban-syuri_001.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/images/contact/kiban-syuri_002.jpg"></b-carousel-slide>
              </b-carousel>
              <div class="card-body p-0 mt-3">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th class="text-center bg-light" style="width: 25%"> </th>
                    <th class="text-center bg-light">iPhone7 タッチNG</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th class="text-center bg-light">症状</th>
                    <td>落下の衝撃により、タッチが効かなくなる。パネルを交換しても修理不可</td>
                  </tr>
                  <tr>
                    <th class="text-center bg-light">修理結果</th>
                    <td>表示IC部分の部品を交換し、動作可能に。</td>
                  </tr>
                  <tr>
                    <th class="text-center bg-light">修理日数</th>
                    <td>1日
                      <table>
                        <tbody>
                        <tr>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card col-md-4 border-0 mb-3">
              <b-carousel
                  id="HomeCarousel"
                  v-model="slide"
                  :interval="4000"
                  controls
                  indicators
                  background="#ababab"
                  img-width="800"
                  img-height="560"
                  style="text-shadow: 1px 1px 2px #333;"
                  ref="HomeCarousel"
              >
                <b-carousel-slide img-src="/images/contact/kiban-syuri_003.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/images/contact/kiban-syuri_004.jpg"></b-carousel-slide>
              </b-carousel>
              <div class="card-body p-0 mt-3">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th class="text-center bg-light" style="width: 25%"> </th>
                    <th class="text-center bg-light">iPhone7 リンゴループ</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th class="text-center bg-light">症状</th>
                    <td>朝起きたらリンゴループに。復元・更新などをしても直らない</td>
                  </tr>
                  <tr>
                    <th class="text-center bg-light">修理結果</th>
                    <td>ベースバンドを交換し修復</td>
                  </tr>
                  <tr>
                    <th class="text-center bg-light">修理日数</th>
                    <td>3日
                      <table>
                        <tbody>
                        <tr>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card col-md-4 border-0 mb-3">
              <b-carousel
                  id="HomeCarousel"
                  v-model="slide"
                  :interval="4000"
                  controls
                  indicators
                  background="#ababab"
                  img-width="800"
                  img-height="560"
                  style="text-shadow: 1px 1px 2px #333;"
                  ref="HomeCarousel"
              >
                <b-carousel-slide img-src="/images/contact/kiban-syuri_005.jpg"></b-carousel-slide>
                <b-carousel-slide img-src="/images/contact/kiban-syuri_006.jpg"></b-carousel-slide>
              </b-carousel>
              <div class="card-body p-0 mt-3">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th class="text-center bg-light" style="width: 25%"> </th>
                    <th class="text-center bg-light">iPhoneSE コネクタ破損</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th class="text-center bg-light">症状</th>
                    <td>バッテリー交換時、コネクタを破損。ドックコネクタを差し、反応はあるが％が増えない（溜まらない）状態に</td>
                  </tr>
                  <tr>
                    <th class="text-center bg-light">修理結果</th>
                    <td>部品の交換で修復</td>
                  </tr>
                  <tr>
                    <th class="text-center bg-light">修理日数</th>
                    <td>1日
                      <table>
                        <tbody>
                        <tr>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- go to repair-board-cases button -->
        <div class="container mb-5">
          <div class="row col-12 justify-content-center">
            <router-link :to="{ name: 'ContactRepairBoardCases' }" class="btn btn-lg btn-success px-5">事例紹介の続きを見る</router-link>
          </div>
        </div>

        <!-- title02 -->
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="text-center contents">
            <h3 class="copy14 font-weight-bold">修理の流れ</h3>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-md-9 px-2 px-md-0">
            <!-- contents01 -->
            <div>
              <div class="mb-5">
                <h4 class="copy12"><span>01</span>お客様情報のご入力</h4>
                <p>申込フォームよりお客様の情報をご入力ください。<br>
                  ご入力いただきました後に、お電話（もしくはメール）で、お見積り（重要事項など含む）をご案内いたします。<br>
                  ※営業時間外のお申込みですと、翌営業日以降のご連絡になります。</p>
              </div>
            </div>

            <!-- contents02 -->
            <div>
              <div class="mb-4">
                <h4 class="copy12"><span>02</span>梱包・発送</h4>
                <p>
                  ご案内にご同意いただけましたら、下記＜修理サービス依頼書＞をご記入、同封して端末をお送りください。
                </p>
                  <div class="container my-4">
                    <div class="row col-12 justify-content-center">
                      <a class="btn btn-lg btn-navy px-5" href="/images/contact/requestform20220728.zip" _target="blank">依頼書ダウンロード</a>
                    </div>
                  </div>
                <p>端末をお送りの際は、<span class="marker">緩衝材で端末を保護し「ワレモノ扱い」</span>で<span class="text-red">元払い</span>にてお送りください。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  送り先
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row">
                    <p class="mb-0 p-4">
                      〒169-0075<br />
                      東京都新宿区高田馬場1丁目33-16 KRビル 1F
                      モバイルリペアセンター宛<br />
                      ※送料はお客様負担とさせて頂きます。
                    </p>
                  </div>
                </div>
              </div>
              <div class="p-4 border rounded mb-5">
                <h5 class="pb-2 border-bottom border-danger">発送前のご注意</h5>
                <p class="mb-0">※配送料は<span class="text-red">お客様ご負担</span>とさせていただいております。<span class="text-red">元払い</span>にてお送りください。<br />
                  着払いでお送りいただいた場合には<span class="text-red">基板修理代金に別途加算</span>させて頂きますので、ご了承くださいませ。<br>
                  ※<span class="text-red">基板修理端末のみお預かり</span>とさせていただきます。その為、不良品は同梱せずお送り頂きます様お願い致します。<br>
                  ※機種名・IMEI・状態を記載した<span class="marker">簡易メモをご一緒に同梱</span>いただけますようお願い致します。</p>
              </div>
            </div>

            <!-- contents03 -->
            <div class="mb-5">
              <div class="mb-4">
                <h4 class="copy12"><span>03</span>端末到着後</h4>
                <p>端末が届きましたら、お電話（もしくはメール）で、端末が到着した旨をご連絡いたします。<br>
                  ※混雑状況やご依頼によってはご連絡が遅延する場合がございます。予めご了承ください。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  作業について
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row p-4">
                    <p class="mb-0 px-2">作業費は<span class="marker">2,000円(税込 2,200円)</span>でございますが、修理金額に含まれていますので、別途頂戴することはございません。<br>
                      修復できなかった場合は<span class="text-red">送料+2,000円(税込 2,200円)のみご負担</span>となります。<br>
                      端末が弊社に到着してから約２～５日後にお届け致しますが、<span class="text-red">破損が激しいもの</span>は時間がかかる場合がございます。
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- contents04 -->
            <div class="mb-4">
              <div class="mb-4">
                <h4 class="copy12"><span>03</span>修理完了後</h4>
                <p>端末の修理作業が終わりましたら、お電話（もしくはメール）でご連絡させていただき、その後、発送いたします。<br>
                  ※銀行振込をご選択いただきました際は、ご入金確認後に発送いたします。</p>
              </div>
              <div class="card mb-3">
                <div class="copy12 card-header font-weight-bold text-center">
                  手数料について
                </div>
                <div class="contents-card-body">
                  <div class="justify-content-center row p-4">
                    <ul>
                      <li>代金引換:ご請求金額に<span class="marker">代引手数料（金額により<span class="text-red">¥330～¥550</span>）</span>を加算</li>
                      <li>銀行振込:振込手数料をお客様ご負担<br>銀行振込:振込手数料を<span class="text-red">お客様ご負担</span></li>
                      <li>後払い決済[Paid]:手数料は<span class="text-red">お客様ご負担</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- contact button -->
            <div class="row justify-content-center">
              <div class="col-11 col-md-9 col-lg-6 mt-3">
                <router-link :to="{ name: 'ContactRepairBoard' }" class="btn_contact">お申し込み</router-link>
              </div>
            </div>

            <!-- title03 -->
            <div class="row mt-5 mb-4 d-flex justify-content-center">
              <div class="text-center contents">
                <h3 class="copy14 font-weight-bold">注意事項</h3>
              </div>
            </div>
            <div class="mb-4 lineh20">
              <p>※作業途中でのキャンセルは受け付けできかねます。<br>
                ※破損状況によっては、症状が悪化する可能性があります。<br>
                ※元々のパネル・バッテリー・その他の部品が使用できない場合は、別途部品代を請求する場合がございます。（価格は当店での販売価格となります。）<br>
                ※基板修理後に起動不安定な場合に限り、USBメモリにバックアップデータを保存し、ご返却することも可能です。ご要望がございましたら備考欄にご記載くださいませ。(USBメモリの容量により1,000円～4,000円の実費負担を頂きます。)<br>
                ※USBメモリ保管の際、原則暗号化をかけてバックアップを行っておりません。暗号化ロックをした状態のまま保存をご希望される際は備考欄またはお電話などでご連絡くださいませ。<br>
                ※既に端末本体に暗号化ロックがかけられている場合も基板修理完了とし、ご請求とさせていただきます。<br>
                ※リンゴループ、リカバリーモードの端末につきましては修理前に既にデータが損傷している可能性がございます。万が一、基板修復完了後にデータが無く起動した場合(初期化状態)は基板の修復には成功致しております為、通常の修理金額の半額をご請求させて頂きます。<br>
                ※端末の状態によっては、データ無し端末復旧となる場合がございます(データ初期化状態or初期化しないと正常起動しない状態)。この場合でも基板修理完了とみなしますが(正常起動しない状態から正常起動する状態に回復しているため)、ご請求金額は半額とさせて頂きます。<br>
                ※パスコードがある場合は必ずご記載お願い致します。原則としましてパスコード記載が無い場合は復旧後の動作確認ができない為、修理ご対応は致しかねます。<br>
                ※万が一、復旧後に記載頂いたパスコードで解除ができない場合やパスコードが不明なものに関しましては【保証なし】【全額ご請求】とさせていただきます。<br>
                ※復旧後、保管期間は1ヶ月となります。1ヶ月を超えた際は所有権放棄との判断の上、依頼品を処分させていただきます。</p>
            </div>

          </div>
        </div>


        <div class="mt-5 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center"
                     style="">
                  <i class="fas fa-thumbs-up fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">技術に自信があります</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">基板修理復旧率９０％</span><br><br>
                    <span class="font-weight-bold">iPhone修理復旧率９７％</span><br><br>
                    過去10数年の修理経験がある当社にまずはお任せください<br><br>
                  </p>
                </div>
              </div>
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <i class="fas fa-exclamation-triangle fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">
                    修理ミス</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">
                      ・バッテリー交換<br />
                      ・タッチパネル交換<br />
                      ・水没<br />
                      ・リンゴループ<br />
                      ・データ吸い出し<br />
                      ・基板修理<br />
                      ・ゲーム機の修理<br />
                    </span>
                    <br />

                    どのような修理でもまずはお問い合わせください。<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4 mt-3">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="col-md-12 pb-2 text-center">
          <p class="font-weight-bold">
            即日修理・即日郵送をモットーとしております。お気軽にご相談ください。
          </p>
          <!-- contact button ※リンク方法が違うので見てもらう -->
          <div class="row justify-content-center">
            <div class="col-11 col-md-9 col-lg-6 mt-3">
              <router-link :to="{ name: 'Contact' }" class="btn_inquiry">お問い合わせはこちらから</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>

<script>
export default {
  name: 'ContactRepairBoardDetail',
  async beforeRouteEnter(to, from, next) {
    return next();
  },
}
</script>