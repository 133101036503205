import Vue from "vue";
import axios from 'axios'

const state = {
    items: [],
    item: {
        detail: {
            customer_code: '',
            parent_customer_code: '',
            grade: 1,
            payment_timing: 1,
            ratio: 0,
            is_vip: false,
            is_trained: false,
            is_leaders_fee2: false,
            is_supervisor: false,
            is_friendship_kenbikai: false,
            is_fixed_bonus: false,
            is_ifa: false,
            has_leaders_fee_0_or_more: false,
            has_leaders_fee_1_or_more: false,
            has_pc_bonus_0_or_more: false,
            has_pc_bonus_1_or_more: false,
            has_masters_club_bonus_0_or_more: false,
            has_masters_club_bonus_1_or_more: false,
            has_grand_masters_club_bonus_0_or_more: false,
            has_grand_masters_club_bonus_1_or_more: false,
            applied_from: '',
            applied_to: '',
        },
        id: '',
        is_enabled: true,
        point_rate: 0.01,
        customer_code: '',
        first_name: '',
        last_name: '',
        name: '',
        first_name_kana: '',
        last_name_kana: '',
        name_kana: '',
        pca_customer_name1: '',
        pca_customer_name2: '',
        pca_index_kana: '',
        email: '',
        email_verified_at: '',
        password: '',
        password_confirm: '',
        login_failed: '',
        logged_in_at: '',
        blocked_at: '',
        zip: '',
        pref: '',
        city: '',
        address1: '',
        address2: '',
        phone: '',
        fax: '',
        cell_phone: '',
        company_zip: '',
        company_pref: '',
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_name: '',
        company_name_kana: '',
        company_division: '',
        company_position: '',
        company_title: '',
        company_staff_title: '',
        company_email: '',
        company_phone: '',
        company_fax: '',
        company_cell_phone: '',
        pca_customer_division1: 0,
        pca_customer_division2: 0,
        pca_customer_division3: 0,
        pca_customer_division4: 0,
        pca_customer_division5: 0,
        pca_main_staff_code: 0,
        pca_applied_sales_price_number: 0,
        pca_ratio: 100.0,
        pca_managed_results: 0,
        pca_print_sales_date: 0,
        pca_tax_rule: 0,
        pca_sales_amount_rule: 0,
        pca_company_shop_code: '',
        pca_type_code: '',
        pca_voucher_division: '',
        pca_business_partner_code: '',
        pca_statement_of_delivery_paper: 1,
        pca_statement_of_delivery_company: 0,
        pca_billing_code: 0,
        pca_settlement_day: 99,
        pca_collect_type1: 0,
        pca_type_boundary_value: 0,
        pca_collect_type2: 0,
        pca_collect_date: 0,
        pca_collect_rule: 0,
        pca_credit_limit: 0,
        pca_opening_balance: 0,
        pca_fraction_amount: 0,
        pca_tax_amount: 0,
        pca_tax_print_rule: 2,
        pca_print_paper_type: 1,
        pca_print_company_name: 0,
        pca_print_company_type: 0,
        pca_company_code1: '',
        pca_company_code2: '',
        pca_bank_account_code: '',
        pca_print_invoice_type: 1,
        pca_holiday_type: 0,
        pca_holiday_calender_code: '',
        pca_comment: '',
        pca_company_number: '',
        is_enable_credit_receivable: false,
        is_tester: false,
    },
    deliveries: [],
    search: {
        per_page: 25,
        page: 1,
        grades: [],
        customer_code: '',
        name: '',
        phone: '',
        pref: '',
        address: '',
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    parent_customer_name: '',
    errors: {},
    customer_deliveries: {

    },
    emailIsDouble: 2,
    customerCodeIsDouble: 2,
    suggestCustomerName: [],
};

const initialState = {
    items: [],
    item: {
        detail: {
            customer_code: '',
            parent_customer_code: '',
            grade: 1,
            payment_timing: 1,
            ratio: 0,
            is_vip: false,
            is_trained: false,
            is_leaders_fee2: false,
            is_supervisor: false,
            is_friendship_kenbikai: false,
            is_fixed_bonus: false,
            is_ifa: false,
            has_leaders_fee_0_or_more: false,
            has_leaders_fee_1_or_more: false,
            has_pc_bonus_0_or_more: false,
            has_pc_bonus_1_or_more: false,
            has_masters_club_bonus_0_or_more: false,
            has_masters_club_bonus_1_or_more: false,
            has_grand_masters_club_bonus_0_or_more: false,
            has_grand_masters_club_bonus_1_or_more: false,
            applied_from: '',
            applied_to: '',
        },
        // customer
        id: '',
        point_rate: 0.01,
        is_enabled: true,
        customer_code: '',
        first_name: '',
        last_name: '',
        name: '',
        first_name_kana: '',
        last_name_kana: '',
        name_kana: '',
        pca_customer_name1: '',
        pca_customer_name2: '',
        pca_index_kana: '',
        email: '',
        email_verified_at: '',
        password: '',
        password_confirm: '',
        login_failed: '',
        logged_in_at: '',
        blocked_at: '',
        zip: '',
        pref: '',
        city: '',
        address1: '',
        address2: '',
        phone: '',
        fax: '',
        cell_phone: '',
        company_zip: '',
        company_pref: '',
        company_city: '',
        company_address1: '',
        company_address2: '',
        company_name: '',
        company_name_kana: '',
        company_division: '',
        company_position: '',
        company_title: '',
        company_staff_title: '',
        company_email: '',
        company_phone: '',
        company_fax: '',
        company_cell_phone: '',
        pca_customer_division1: 0,
        pca_customer_division2: 0,
        pca_customer_division3: 0,
        pca_customer_division4: 0,
        pca_customer_division5: 0,
        pca_main_staff_code: 0,
        pca_applied_sales_price_number: 0,
        pca_ratio: 100.0,
        pca_managed_results: 0,
        pca_print_sales_date: 0,
        pca_tax_rule: 0,
        pca_sales_amount_rule: 0,
        pca_company_shop_code: '',
        pca_type_code: '',
        pca_voucher_division: '',
        pca_business_partner_code: '',
        pca_statement_of_delivery_paper: 1,
        pca_statement_of_delivery_company: 0,
        pca_billing_code: 0,
        pca_settlement_day: 99,
        pca_collect_type1: 0,
        pca_type_boundary_value: 0,
        pca_collect_type2: 0,
        pca_collect_date: 0,
        pca_collect_rule: 0,
        pca_credit_limit: 0,
        pca_opening_balance: 0,
        pca_fraction_amount: 0,
        pca_tax_amount: 0,
        pca_tax_print_rule: 2,
        pca_print_paper_type: 1,
        pca_print_company_name: 0,
        pca_print_company_type: 0,
        pca_company_code1: '',
        pca_company_code2: '',
        pca_bank_account_code: '',
        pca_print_invoice_type: 1,
        pca_holiday_type: 0,
        pca_holiday_calender_code: '',
        pca_comment: '',
        pca_company_number: '',
        is_enable_credit_receivable: false,
        is_tester: false,
    },
    deliveries: [],
    search: {
        per_page: 25,
        page: 1,
        grades: [],
        customer_code: '',
        name: '',
        phone: '',
        pref: '',
        address: '',
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    parent_customer_name: '',
    errors: {},
    emailIsDouble: 2,
    customerCodeIsDouble: 2,
    suggestCustomerName: [],
};

const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setDeliveries(state, deliveries) {
        state.deliveries = deliveries
    },
    setSuggestCustomerName(state, items) {
        Vue.set(state, 'suggestCustomerName', items);
    },
    setEmailIsDouble(state, res) {
        state.emailIsDouble = res
    },
    setCustomerCodeIsDouble(state, res) {
        state.customerCodeIsDouble = res
    },
    setParent(state, parent) {
        state.parent_customer_name = parent.name
    },
    setSearch(state, search) {
        state.search = search
    },
    setPagination(state, items) {
        state.pagination = items
    },
    setAddress(state, item) {
        state.item.pref_name = item.pref
        state.item.city = item.city
        state.item.address1 = item.town
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.pagination.data.splice(index, 1);
    },
    deleteDelivery(state, index) {
        state.deliveries.splice(index, 1);
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            console.log(f)
            Vue.set(state, f, initialState[f]);
        }
    },
    resetSearchState() {
        state.search = {
            per_page: 25,
            page: 1,
            grades: [],
            customer_code: '',
            name: '',
            phone: '',
            pref: '',
            address: '',
        }
    },
};


const getters = {
    customers(state) {
        return state.items;
    },
    customer(state) {
        return state.item;
    },
    customerDeliveries(state) {
        return state.deliveries;
    },
    emailIsDouble(state) {
        return state.emailIsDouble
    },
    customerCodeIsDouble(state) {
        return state.customerCodeIsDouble
    },
    pagination(state) {
        return state.pagination;
    },
    search(state) {
        return state.search;
    },
    parentCustomerName(state) {
        return state.parent_customer_name;
    },
    suggestCustomerName() {
        return state.suggestCustomerName;
    },
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/customers', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('customers.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetch.error', error)
        });
    },
    async fetchDeliveries({state, commit, rootActions}, customerCode) {
        await window.axios.get('/customer-deliveries/' + customerCode).then(res => {
            commit('setDeliveries', res.data);
            commit('unsetErrors')
            console.log('customers.fetchDeliveries', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchDeliveries.error', error)
        });
    },
    async fetchOne({commit}, id) {
        await window.axios.get('/customers/' + id).then(res => {
            res.data.password = 'default1password'

            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('customers.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchOne.error', error)
        });
    },
    async fetchParent({commit}, parentCustomerCode) {
        await window.axios.get('/customers/parent/' + parentCustomerCode).then(res => {
            commit('setParent', res.data);
            commit('unsetErrors')
            console.log('customers.fetchParent')
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchParent.error', error)
        });
    },
    async fetchZip({commit}, zip) {
        await axios.get('https://api.zipaddress.net/?zipcode=' + zip).then(res => {
            commit('setAddress', res.data.data);
            commit('unsetErrors')
            console.log('customers.fetchZip', res.data.data)

            return true
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchZip.error', error)

            return false
        });
    },
    async fetchZipData({commit}, zip) {
        return await axios.get('https://api.zipaddress.net/?zipcode=' + zip).then(res => {
            console.log('customers.fetchZipData', res.data.data)
            return res.data.data
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchZipData.error', error)

            return false
        });
    },
    async new({state, commit}) {
        state.deliveries = []
        let item = {
                detail: {
                    customer_code: '',
                    parent_customer_code: '',
                    grade: 1,
                    payment_timing: 1,
                    ratio: 0,
                    is_vip: false,
                    is_trained: false,
                    is_leaders_fee2: false,
                    is_supervisor: false,
                    is_friendship_kenbikai: false,
                    is_fixed_bonus: false,
                    is_ifa: false,
                    has_leaders_fee_0_or_more: false,
                    has_leaders_fee_1_or_more: false,
                    has_pc_bonus_0_or_more: false,
                    has_pc_bonus_1_or_more: false,
                    has_masters_club_bonus_0_or_more: false,
                    has_masters_club_bonus_1_or_more: false,
                    has_grand_masters_club_bonus_0_or_more: false,
                    has_grand_masters_club_bonus_1_or_more: false,
                    applied_from: '',
                    applied_to: '',
                },
                // customer
                id: '',
                is_enabled: true,
                point_rate: 0.01,
                customer_code: '',
                name: '',
                name_kana: '',
                email: '',
                email_verified_at: '',
                password: '',
                password_confirm: '',
                login_failed: '',
                logged_in_at: '',
                zip: '',
                pref: '',
                city: '',
                address1: '',
                address2: '',
                phone: '',
                fax: '',
                cell_phone: '',
                company_zip: '',
                company_pref: '',
                company_city: '',
                company_address1: '',
                company_address2: '',
                company_name: '',
                company_name_kana: '',
                company_division: '',
                company_position: '',
                company_title: '',
                company_staff_title: '',
                company_email: '',
                company_phone: '',
                company_fax: '',
                company_cell_phone: '',
                is_enable_credit_receivable: false,
                is_tester: false,
        };

        commit('setItem', item);
        console.log('customers.new')
    },
    async delete({state, commit}, item) {
        const index = state.pagination.data.indexOf(item);

        return await window.axios.post('/customers/delete', item)
            .then(res => {
                commit('delete', index);
                commit('unsetErrors')
                console.log('customers.delete', state.pagination.data[index])
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.delete.error', error, state.pagination.data[index])
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/customers/store', item)
            .then(res => {
                commit('unsetErrors')
                commit('setItem', res.data)
                console.log('customers.store', res, item)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.store.error', error, item)
            });
    },
    async storeDelivery({commit}, item) {
        return await window.axios.post('/customer-deliveries/store', item)
            .then(res => {
                commit('unsetErrors')
                commit('setDeliveries', res.data)
                console.log('customers.storeDelivery', res, item)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.storeDelivery.error', error, item)
            });
    },
    async updateDelivery({commit}, item) {
        return await window.axios.post('/customer-deliveries/update', item)
            .then(res => {
                commit('unsetErrors')
                commit('setDeliveries', res.data)
                console.log('customers.updateDelivery', res, item)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.updateDelivery.error', error, item)
            });
    },
    async deleteDelivery({commit}, item) {
        const index = state.deliveries.indexOf(item);

        return await window.axios.post('/customer-deliveries/delete', item)
            .then(res => {
                commit('deleteDelivery', index);
                commit('unsetErrors')
                console.log('customers.deleteDelivery', state.deliveries[index])
                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.deleteDelivery.error', error, state.deliveries[index])
            });
    },
    async storeClone({commit}, item) {
        return await window.axios.post('/customers/clone', item)
            .then(res => {
                commit('unsetErrors')
                console.log('customers.storeClone', res, item)
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.storeClone.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/customers/update', newItem)
            .then(res => {
                commit('unsetErrors')
                console.log('customers.update', res, newItem)

                return res.data
            }).catch(error => {
                commit('setErrors', error)
                console.log('customers.update.error', error)
            });
    },
    async exportCsv({state, commit}) {
        return await window.axios.get('/customers/export')
            .then(res => {
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                let now = new Date();
                let Y = now.getFullYear();
                let M = (now.getMonth()+1).toString().padStart(2, '0');
                let D = now.getDate().toString().padStart(2, '0');
                let h = now.getHours().toString().padStart(2, '0');
                let m = now.getMinutes().toString().padStart(2, '0');
                let unixTime = new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16)

                fileLink.href = fileURL;
                fileLink.setAttribute('download', "customers_" + Y + M + D + "_" + h + m + "_" + unixTime + ".csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            });
    },
    async exportCsvTest({state, commit}) {
        return await window.axios.get('/customers/export/test')
            .then(res => {
                let fileURL = window.URL.createObjectURL(new Blob([res.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'customers_test.csv');
                document.body.appendChild(fileLink);

                fileLink.click();
            });
    },
    async isExistsEmail({state, commit}) {
        let customer_code = null
        if(state.item.customer_code === null || state.item.customer_code === 0 || state.item.customer_code === '') {
            customer_code = 0
        } else {
            customer_code = state.item.customer_code
        }

        let email = state.item.email
        await window.axios.get("/customers/is-exists/" + state.item.id + "/email/" + email).then(res => {
            // 重複
            console.log(res.data)
            commit('setEmailIsDouble', res.data)

            return res.data
        }).catch(error => {
            console.log(error);
        });
    },
    async isExistsCustomerCode({state, commit}) {
        let customer_code = null
        if(state.item.customer_code === null || state.item.customer_code === 0 || state.item.customer_code === '') {
            customer_code = 0
        } else {
            customer_code = state.item.customer_code
        }
        console.log(state.item.customer_code)

        await window.axios.get("/customers/is-exists/" + state.item.id + "/customer-code/" + customer_code).then(res => {
            // 重複
            console.log(res.data)
            commit('setCustomerCodeIsDouble', res.data)

            return res.data
        }).catch(error => {
            console.log(error);
        });
    },
    async fetchSuggestCustomerName({state, commit}, param) {
        return await window.axios.get('/customers/suggest/customer-name/' + param).then(res => {
            commit('setSuggestCustomerName', res.data);
            commit('unsetErrors')
            console.log('customers.fetchSuggestCustomerName', res)

            return res.data
        }).catch(error => {
            commit('setErrors', error)
            console.log('customers.fetchSuggestCustomerName.error', error)
        });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('customers.resetState')
    },
    resetSearchState({commit}) {
        commit('resetSearchState')
        console.log('customers.resetSearchState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};