import Vue from "vue";
import size from 'lodash/size'
import last from 'lodash/last'
import cloneDeep from 'lodash/cloneDeep'

const STORE_NAME = 'campaignProducts'

const state = {
    selectedItems: null,
    items: [],
    item: {
        id: 0,
        campaign_id: 0,
        product_id: 0,
    },
    summary: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    errors: [],
};

const initialState = {
    selectedItems: null,
    items: [],
    item: {
        id: 0,
        campaign_id: 0,
        product_id: 0,
    },
    summary: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    errors: [],
};

const mutations = {
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    updateSelectedItems(state, selectedItems) {
        state.selectedItems = selectedItems;
    },
    setSummary(state, summary) {
        state.summary = summary
    },
    setErrors(state, errors) {
        state.errors = errors
    },
    resetSelectedItems(state) {
        state.selectedItems = initialState.selectedItems
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    selectedItems: state => {
        return state.selectedItems;
    },
    products: state => {
        return state.items;
    },
    summary: state => {
        return state.summary.data;
    },
};

const actions = {
    updateSelectedItems({state, commit}, value) {
        commit("updateSelectedItems", value);
    },
    async fetch({commit}, campaignId) {
        await window.axios.get('/campaigns/' + campaignId + '/products').then(res => {
            commit('updateSelectedItems', res.data);

            console.log(STORE_NAME + '.fetch', res)

            return res.data;
        }).catch(error => {
            commit('setErrors', error)
            console.log(STORE_NAME + '.fetch.error', error)
        });
    },
    async fetchSummary({commit}, params) {
        await window.axios.get('/campaigns/summary', params).then(res => {
            commit('setSummary', res.data);

            console.log(STORE_NAME + '.summary', res)

            return res.data;
        }).catch(error => {
            commit('setErrors', error)
            console.log(STORE_NAME + '.summary.error', error)
        });
    },
    async save({state, commit}, campaignId) {

        let params = {
            campaign_id: campaignId,
            items: state.selectedItems,
        };
        console.log(params)

        return await window.axios.post('/campaigns/products/save', params)
            .then(res => {
                console.log(STORE_NAME + '.save', res)

                return true;
            }).catch(error => {
                commit('setErrors', error)
                console.log(STORE_NAME + '.save.error', error)
            });
    },
    async resetState({commit}) {
        console.log(STORE_NAME + '.resetState')
        commit('resetState');
    },
    async resetSelectedItems({state, commit}) {
        console.log(STORE_NAME + '.resetSelectedItems')
        commit('resetSelectedItems');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};