<template>
  <div>
      <div class="row">
        <template v-for="(item,index) in pickupProducts">
          <div class="py-2 col-md-6 col-lg-3" :key="item.id">
            <a :href="'/#/product/' + item.product_id" class="text-dark">
              <img class="img-fluid d-block mb-2"
                   v-lazy="item.main_image_path">
              <p class="mb-0">{{ item.product_name }}</p></a>
          </div>
        </template>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "../../../admin/src/store";

export default {
  name: 'Pickup',
  components: {},
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    ...mapActions('pickupProducts', [
      'fetchList',
    ]),
  },
  computed: {
    ...mapGetters('pickupProducts', [
      'pickupProducts',
    ]),
    isLoading() {
      return this.$store.state.isLoading
    }
  },
  created() {
    this.fetchList()
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(
        "pickupProducts/fetchList",
    );

    await store.commit('setLoading', false)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>