<template>
  <transition>
    <slot></slot>
  </transition>
</template>

<style scoped>
.v-enter-active, .v-leave-active {
  transition: opacity 2s;
}

.v-enter, .v-leave-to {
  opacity: 0.0;
}
</style>

<script>
export default {
  name: 'NATransitionFade',
  props: {
  },
}
</script>