<template>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td>
      <td>the Bird</td>
      <td>@twitter</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "NAEditableList",
  props: {
    headers: {
      type: Object,
      require: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    items: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    }
  },
}
</script>

<style scoped>

</style>