import Vue from "vue";

import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
const STORE_NAME = 'newCampaign'

const state = {
    items: [],
    item: {
        id: Number,
        name: '',
        title: '',
        description: '',
        header: '',
        body: '',
        footer: '',
        started_at: '',
        ended_at: '',
        discount_rule: Number,
        required_type: Number,
        required_quantity: Number,
        required_amount: Number,
        present_quantity: Number,
        discount_of_agency: Number,
        discount_of_dealer: Number,
        discount_of_customer: Number,
        discount_of_web_customer: Number,
        discount_of_general: Number,
        banner_image_path: '',
        main_image_path: '',
        announcement_image_path: '',
        announcement_body: '',
        announcement_started_at: '',
        announcement_ended_at: '',
        products: [],
        presents: [],
    },
};

const initialState = {
    items: [],
    item: {
        id: Number,
        name: '',
        title: '',
        description: '',
        header: '',
        body: '',
        footer: '',
        started_at: '',
        ended_at: '',
        discount_rule: Number,
        required_type: Number,
        required_quantity: Number,
        required_amount: Number,
        present_quantity: Number,
        discount_of_agency: Number,
        discount_of_dealer: Number,
        discount_of_customer: Number,
        discount_of_web_customer: Number,
        discount_of_general: Number,
        banner_image_path: '',
        main_image_path: '',
        announcement_image_path: '',
        announcement_body: '',
        announcement_started_at: '',
        announcement_ended_at: '',
        products: [],
        presents: [],
    },
};
const mutations = {
    setItem(state, item) {
        state.item = item
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
};

const getters = {
    campaign(state, id) {
        return state.item
    },
};

const actions = {
    async fetchOneByCampaignId({commit}, campaignId) {
        await window.axios.get('/campaigns/by/campaign/' + campaignId).then(res => {
            commit('setItem', res.data);
            console.log(STORE_NAME + '.fetchOne')
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOne.error', error)
        });
    },
    async fetchOneByCampaignIdForAdmin({commit}, campaignId) {
        await window.axios.get('/campaigns/by/campaign/' + campaignId + '/preview').then(res => {
            commit('setItem', res.data);
            console.log(STORE_NAME + '.fetchOne')
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOne.error', error)
        });
    },
    async fetchOneByProductId({commit}, productId) {
        await window.axios.get('/campaigns/by/product/' + productId).then(res => {
            commit('setItem', res.data);
            console.log(STORE_NAME + '.fetchOne')
        }).catch(error => {
            console.log(STORE_NAME + '.fetchOne.error', error)
        });
    },
    resetState({commit}) {
        commit('resetState');
        console.log(STORE_NAME + '.resetState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};