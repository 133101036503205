<template>

  <div class="pt-0 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="copy08 my-2 pb-3 d-none d-md-block">
            <router-link
              :to="{ name: 'CategoryById', params: { id: product.category_id }}"
            >
              {{ product.category_name }}
            </router-link>
            <i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i>
            {{ product.product_name }}
          </div>
          <div class="bg-secondary col-lg-5 mt-4 mt-md-0">
            <h1 class="contents-ttl copy22 mb-4 p-1">Products<span class="copy14 ml-2">商品詳細</span></h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-9 order-md-2 product px-3">
          <div class="row">
            <div class="col-md-6">
              <b-carousel
                  id="productCarousel"
                  v-model="slide"
                  :interval="8000"
                  :no-touch="false"
                  controls
                  indicators
                  background="#ababab"
                  img-width="800"
                  img-height="560"
                  style="text-shadow: 1px 1px 2px #333;"
                  ref="productCarousel"
              >
                <template v-if="product.main_image_path">
                  <b-carousel-slide :img-src="product.main_image_path"></b-carousel-slide>
                </template>
                <template v-if="product.detail_image1">
                  <b-carousel-slide :img-src="product.detail_image1"></b-carousel-slide>
                </template>
                <template v-if="product.detail_image2">
                  <b-carousel-slide :img-src="product.detail_image2"></b-carousel-slide>
                </template>
                <template v-if="product.detail_image3">
                  <b-carousel-slide :img-src="product.detail_image3"></b-carousel-slide>
                </template>
                <template v-if="product.detail_image4">
                  <b-carousel-slide :img-src="product.detail_image4"></b-carousel-slide>
                </template>
                <template v-if="product.detail_image5">
                  <b-carousel-slide :img-src="product.detail_image5"></b-carousel-slide>
                </template>
              </b-carousel>
              <div class="row mx-0">
                <!--{
                <template v-if="product.detail_image_paths">
                  <template v-for="(image, index) in product.detail_image_paths">
                    <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-2">
                      <img class="img-fluid d-block" :src="image" @click="$refs.productCarousel.setSlide(index)" role="button">
                    </div>
                  </template>
                </template>
                }-->
                <template v-if="product.main_image_path">
                  <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-4">
                    <img class="img-fluid d-block" :src="product.main_image_path" @click="$refs.productCarousel.setSlide(0)" role="button">
                  </div>
                </template>
                <template v-if="product.detail_image1">
                  <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-4">
                    <img class="img-fluid d-block" :src="product.detail_image1" @click="$refs.productCarousel.setSlide(1)" role="button">
                  </div>
                </template>
                <template v-if="product.detail_image2 !== ''">
                  <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-4">
                    <img class="img-fluid d-block" :src="product.detail_image2" @click="$refs.productCarousel.setSlide(2)" role="button">
                  </div>
                </template>
                <template v-if="product.detail_image3">
                  <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-4">
                    <img class="img-fluid d-block" :src="product.detail_image3" @click="$refs.productCarousel.setSlide(3)" role="button">
                  </div>
                </template>
                <template v-if="product.detail_image4">
                  <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-4">
                    <img class="img-fluid d-block" :src="product.detail_image4" @click="$refs.productCarousel.setSlide(4)" role="button">
                  </div>
                </template>
                <template v-if="product.detail_image5">
                  <div class="py-2 pl-0 pr-2 col-4 col-md-4 col-lg-4">
                    <img class="img-fluid d-block" :src="product.detail_image5" @click="$refs.productCarousel.setSlide(5)" role="button">
                  </div>
                </template>
              </div>
              <!-- ヘッダー -->
              <template v-if="product.header">
                <div class="row pb-2 border-bottom mx-0">
                  <div class="col-md-12 align-items-center d-flex justify-content-center my-0 pl-0 pt-2 py-5">
                    <!--{ product.footer }-->
                    <div class="w-100" v-html="product.header">
                    </div>
                  </div>
                </div>
              </template>

              <!-- メインコンテンツ -->
              <template v-if="product.body">
                <div class="row pb-2 border-bottom mx-0">
                  <div class="col-md-12 align-items-center d-flex justify-content-center my-0 pl-0 pt-2 py-5">
                    <!--{ product.footer }-->
                    <div class="w-100" v-html="product.body">
                    </div>
                  </div>
                </div>
              </template>

              <!-- フッター -->
              <template v-if="product.footer">
                <div class="row pb-2 border-bottom mx-0">
                  <div class="col-md-12 align-items-center d-flex justify-content-center my-0 pl-0 pt-2 py-5">
                    <!--{ product.footer }-->
                    <div class="w-100" v-html="product.footer">
                    </div>
                  </div>
                </div>
              </template>

            </div>
            <div class="col-md-6">
              <h1 class="border border-bottom border-left-0 border-right-0 copy14 py-3 top_ttl mb-3 font-weight-bold">{{ product.product_name }}</h1>
              <p class="lineh16 mx-0 mt-3 pb-3 border-bottom" v-html="product.description"></p>
            </div>
          </div>

          <div class="row px-2 pb-0">
            <div class="px-0 col-lg-12">
              <table class="table table-bordered">
                <thead class="thead-dark text-center copy09">
                <tr>
                  <th class="align-middle">内訳</th>
                  <th class="align-middle">販売価格<small>（単価 ✕ 入数）</small></th>
                  <th class="align-middle" style="width:20%;">注文数</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(detail, index) in product.details">
                  <tr :key="detail.product_code + '1'">
                    <td class="bg-paleblue">
                      <span class="font-weight-bold">
                        {{ detail.description }}
                      </span>
                      <span class="float-right">
                        <template v-if="product.product_class_type_id1_label">
                          {{ product.product_class_type_id1_label }}: {{ detail.product_class_value_id1_label}}
                        </template>
                        <template v-if="product.product_class_type_id2_label">
                          {{ product.product_class_type_id2_label }}: {{ detail.product_class_value_id2_label}}
                        </template>
                      </span>
                    </td>
                    <td rowspan="3" class="align-middle text-right">
                      <!--{ ログイン時 }-->
                      <template v-if="computedIsLoggedIn === 'true' || computedIsLoggedIn === true">
                        <div class="copy09 font-weight-bold mb-2">小計: {{ detail.price.sales_price_tax * detail.quantity | numberFormat }}円(税込)</div>
                        {{ detail.price.sales_price_tax | numberFormat }}円(税抜{{ detail.price.sales_price | numberFormat }}円) × {{ product.contained | numberFormat }}
                      </template>

                      <!--{ 非ログイン時 }-->
                      <template v-else>
                        非公開
                      </template>
                    </td>
                    <td rowspan="3" class="align-middle">
                      <span class="float-right mr-3 mb-2">
                        <span v-if="detail.is_unlimited_stock == 1">
                          在庫数: 無制限
                        </span>
                        <span v-else>
                          在庫数: {{ detail.stock }}
                        </span>
                      </span>
                      <input type="number" class="form-control text-right" v-model="detail.quantity" min="0" :max="(detail.is_unlimited_stock) ? 9999 : detail.stock" :disabled="!computedIsLoggedIn === 'true' || !computedIsLoggedIn === true"/>
                    </td>
                  </tr>
                  <tr :key="detail.product_code + '2'">
                    <td class="bg-light">モデル番号：{{ detail.model_name }} <span class="float-right">品番：{{ detail.product_code }}</span></td>
                  </tr>
                  <tr :key="detail.product_code + '3'">
                    <td class="py-0"></td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-12 order-md-2 px-0">
            <!--template v-if="product.campaign">
              <span class="badge badge-danger mt-3 mb-2 px-2">キャンペーン商品</span>
            </template>
            <template-- v-else>
              <p></p>
            </template-->

            <!--カートに入れるボタン -->
            <div class="row pb-2 border-bottom mx-0">
              <div class="col-md-12 pt-2 pb-4 px-0">
                <div>
                  <!--{
                  <div class="alert alert-info">
                    ■ 選択中の商品 <br>
                    <template v-for="(detail, index) in product.details">
                      {{ detail.description }} x {{ detail.quantity }}<br>
                    </template>
                  </div>
                  }-->
                  <div class="form-group mb-0 text-white text-center">
                    <template v-if="salesPrice > 0">
                      <button
                          :disabled="countQuantity === 0"
                          class="btn btn-darkpink rounded-0 py-2 px-5"
                          @click="addProductHandler(product)">
                        カートに入れる ({{ countQuantity | numberFormat }}個)
                      </button>
                    </template>
                  </div>

                  <!-- カート追加時モーダル -->
                  <b-modal id="add-cart-modal" title="BootstrapVue" hide-footer>
                    <!--{ ヘッダー }-->
                    <template #modal-header>
                      <h5 class="modal-title"><i class="fas fa-shopping-cart fa-lg mr-2 text-primary"></i>カートに追加しました</h5>
                    </template>

                    <!--{ 本文 }-->
                    <template #default class="modal-footer">
                      <b-row class="text-center justify-content-center">
                        <b-col>
                          <b-button
                              class="btn btn-middlegray rounded-0 px-3"
                              @click="$bvModal.hide('add-cart-modal')"
                          >
                            お買い物を続ける
                          </b-button>
                        </b-col>
                        <b-col>
                          <router-link :to="{ name: 'Cart' }" class="btn btn-primary rounded-0 px-3">
                            カートへ進む
                          </router-link>
                        </b-col>
                      </b-row>
                    </template>
                  </b-modal>
                </div>
              </div>
            </div>

            <!--{ 関連商品 }-->
            <template v-if="product.relative_products">
              <h3 class="copy12 mt-3 top_ttl">関連商品</h3>
              <div class="row pb-2 border-bottom mx-0">
                <ComponentListProduct :items="product.relative_products"></ComponentListProduct>
              </div>
            </template>
          </div>
        </div>
        <CategoryLeftMenu></CategoryLeftMenu>
      </div>
    </div>
  </div>
</template>


<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import store from "@/store";
import CategoryLeftMenu from "@/components/CategoryLeftMenu"
import ComponentListProduct from "@/components/List/Product"
import findIndex from "lodash/findIndex";


export default {
  name: 'ProductDetail',
  components: { CategoryLeftMenu, ComponentListProduct },
  data() {
    return {
      spinner: false,
      slide: 0,
      sizeList: null,
      selectedProductDetailsFields: [
          ''
      ],
      options: [
        { text: '白', value: '3' },
        { text: '黒', value: '4' },
        { text: 'ピンク', value: '5' },
        { text: 'レッド', value: '6' },
        { text: 'コーラルピンク', value: '24' }
      ]
    }
  },
  methods: {
    getSelectedProductDetails() {
      return this.product.details.filter(function(row) {
        return row.quantity > 0
      });
    },
    incrementQuantity() {
      this.quantity++
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--
      }
    },
    setSizeList(event) {
      const valueId1 = event.target.value

      if(valueId1 > 0) {
        this.sizeList = this.getSizeList(valueId1)
        this.product.selected_value_id2 = ''
        this.product.selected_product_code = ''
      }
    },
    getSizeList(valueId1) {
      return this.oldProduct.classes[valueId1].values
    },
    async getProductCode() {
        return await this.fetchProductCode({
          product_id: this.product.id,
          value_id1: this.product.selected_value_id1,
          value_id2: this.product.selected_value_id2,
        }).then(res => res)
    },
    ...mapMutations('newCart', [
      'changeSelectedValueId1',
      'changeSelectedValueId2',
    ]),
    ...mapActions('newCart', [
      'addProduct', 'fetchProductCode',
    ]),
    ...mapActions('newProducts', [
      'setSelectedValue', 'setSelectedProductCode', 'fetchProductCode'
    ]),
    async setProductCode(event) {
      const hasValueId1 = this.oldProduct.is_enable_value_id1
      const hasValueId2 = this.oldProduct.is_enable_value_id2

      this.$nextTick(async function () {
        if(hasValueId1 || hasValueId2) {
          await this.getProductCode().then(res => {
            console.log(res)
            this.product.selected_product_code = res
          })
        }
      })
    },
    async addProductHandler(product) {
      for (const row of product.details) {
        // 数量1以上の商品をカートに入れる
        if(row.quantity > 0) {
          this.product.selected_product_code = row.product_code
          this.product.selected_value_id1 = row.product_class_value_id1
          this.product.selected_value_id2 = row.product_class_value_id2
          this.product.quantity = parseInt(row.quantity)

          await this.addProduct(product, parseInt(row.quantity));
        }
      }

      this.$bvModal.show('add-cart-modal')
    },
  },
  computed: {
    ...mapGetters('newProducts', [
      'product',
      'details',
      "retailPrice",
      "retailPriceTax",
      "salesPriceTax",
      "salesPrice",
      "campaignPriceTax",
      "campaignPrice",
      "oldProduct",
      "quantity",
      "selectedValueId1",
      "selectedValueId2",
      "selectedProductCode",
      "countQuantity",
    ]),
    ...mapGetters('newCampaigns', [
      'campaign',
    ]),
    ...mapGetters('authCustomer', [
      'isLoggedIn',
    ]),
    computedQuantity() {
      return store.state.newProducts.quantity
    },
    computedIsLoggedIn() {
      return this.$store.state.authCustomer.isLoggedIn
    },
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  created: async function() {
    await store.commit('setLoading', true)

    await store.dispatch(
        "products/resetState",
    )

    await store.dispatch(
        "newProducts/fetchOneForFront",
        this.$route.params.id
    );

    this.product.selected_product_code = ''
    this.product.selected_value_id1 = ''
    this.product.selected_value_id2 = ''

    await store.commit('setLoading', false)
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    await store.dispatch(
        "products/resetState",
    )

    await store.dispatch(
        "newProducts/fetchOneForFront",
        to.params.id
    );

    return next();
  },
}
</script>
