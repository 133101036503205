<template>
  <div>
    <!-- Carousel -->
    <div>
      <div class="container">
        <div class="row mx-0">
          <div v-if="!isMirror" class="col-md-12 px-0">
            <b-carousel
                id="HomeCarousel"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                img-width="800"
                img-height="560"
                style="text-shadow: 1px 1px 2px #333;"
                ref="HomeCarousel"
            >
              <b-carousel-slide img-src="/images/top/visual_01_pc.jpg"></b-carousel-slide>
              <b-carousel-slide img-src="/images/top/visual_02_pc.jpg"></b-carousel-slide>
              <b-carousel-slide img-src="/images/top/visual_03_pc.jpg"></b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container py-4">
        <div class="row">
          <!-- Left menu -->
          <CategoryLeftMenu></CategoryLeftMenu>
          <!-- Left menu end -->
          <div class="col-lg-9">
            <!-- banner -->
            <section>
              <div v-if="!isMirror" class="container">
                <div class="justify-content-center row">
                  <div class="col-12 col-lg-6 pb-3 px-0">
                    <a href="/#/contact/panel-purchase-detail"><img class="img-fruid w-100 px-2" src="images/top/banner_img01.jpg"/></a>
                  </div>
                  <div class="col-12 col-lg-6 pb-3 px-0">
                    <a href="/#/contact/repair-board-detail"><img class="img-fruid w-100 px-2" src="images/top/banner_img02.jpg"/></a>
                  </div>
                </div>
                <div class="justify-content-center row">
                  <div class="col-12 col-lg-6 pb-3 px-0">
                    <a href="/#/contact/repair-instead-detail"><img class="img-fruid w-100 px-2" src="images/top/banner_img03.jpg"/></a>
                  </div>
                  <div class="col-12 col-lg-6 pb-3 px-0">
                    <a href="/#/deferred-payment"><img class="img-fruid w-100 px-2" src="images/top/banner_img04.jpg"/></a>
                  </div>
                </div>
              </div>
            </section>
            <!-- banner end -->
            <!-- News -->
            <News></News>
            <!-- News end -->

            <!-- New item -->
            <section class="py-4">
              <div class="container px-0">
                <h2 class="mb-4 top-title col-lg-7 pl-2 bg-info">NEW ITEM<span class="copy12 ml-2">新着商品</span></h2>
                  <NewItem></NewItem>
              </div>
            </section>
            <!-- New item end -->

            <!-- Pickup item -->
            <section class="py-4">
              <div class="container px-0">
                <h2 class="mb-4 top-title col-lg-7 pl-2 bg-orange">Pickup item <span class="copy12 ml-2">ピックアップ商品</span>
                </h2>
                  <Pickup></Pickup>
              </div>
            </section>
            <!-- Pickup item end -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style>
</style>

<script>
import News from '@/components/News'
import NewItem from '@/components/NewItem'
import Category from '@/components/Category'
import CategoryLeftMenu from '@/components/CategoryLeftMenu'
import Pickup from '@/components/Pickup'
import store from '@/store'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'app',
  components: {
    News,
    NewItem,
    Category,
    Pickup,
    CategoryLeftMenu
  },
  data() {
    return {
      slide: 0,
      isMirror: (process.env.NODE_ENV === 'production-mirror' || process.env.NODE_ENV === 'local-mirror'),
    }
  },
  computed: {
    ...mapGetters('authCustomer', [
      'ratio', 'grade'
    ]),
    ...mapGetters('newItems', [
      'newItems'
    ]),
  },
  async mounted() {
    await store.dispatch('newItems/fetch');
  },
  async beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);

    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);

    await store.commit('setLoading', true)

    return next();
  },
}
</script>