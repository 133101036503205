<template>
  <div>
  <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="this.onCancel"
      color="#007bff"
      :is-full-page="fullPage"></loading>
  <div v-show="isLoading" style="width: 100vh; height: 100vh;"></div>
  <div v-show="!isLoading">
    <div class="col-md-12">
      <div class="container">
        <div class="row">
          <div class="col-md-12 py-2 px-3 mx-2 px-md-2">
            <p class="mb-0 copy08"><a href="/#/">HOME</a><i class="fas fa-chevron-right fa-xs mx-1" aria-hidden="true"></i>ショッピングカート</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3 px-3 px-md-2">
            <div class="bg-info col-lg-5 mt-3 mt-md-0">
              <h1 class="contents-ttl copy22 mb-4 p-1">Shopping Cart<span class="copy12 ml-2">ショッピングカート</span></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 border-bottom">
      <div class="container">
        <div class="row bs-wizard justify-content-center py-5" style="border-bottom:0;">
          <div class="col-xs-3 bs-wizard-step complete">
            <div class="text-center bs-wizard-stepnum text-primary">Step 1</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="/#/cart" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">カートの商品</div>
          </div>
          <div class="col-xs-3 bs-wizard-step active">
            <!-- complete -->
            <div class="text-center bs-wizard-stepnum text-primary">Step 2</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="/#/login" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">ログイン</div>
          </div>
          <div class="col-xs-3 bs-wizard-step disabled">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum text-primary">Step 3</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="/#/order/" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">注文内容確認</div>
          </div>
          <div class="col-xs-3 bs-wizard-step disabled">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum text-primary">Step 4</div>
            <div class="progress">
              <div class="progress-bar"></div>
            </div> <a href="#" class="bs-wizard-dot"> </a>
            <div class="bs-wizard-info text-center px-3">ご注文の完了</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isValidStock()" class="col-12 col-md-8 mx-auto mt-3">
      <div class="alert alert-danger text-center" role="alert">
        カート内の商品に、在庫が不足している商品が含まれているためご注文いただけません。 <br>
        大変恐れ入りますが、対象商品の数量を在庫数以下の値に調整いただくか、
        当該商品をカートから削除してください。<br>
      </div>
    </div>

    <div class="py-5">
      <div class="container">
        <div class="row">
          <!-- SP -->
          <div class="px-3 d-sm-block d-md-none col-12">
            <template v-if="orderDetails.length > 0">
              <template v-for="item in orderDetails">
                <div>
                  <div class="p-2">
                    <div class="row d-flex align-items-center">
                      <div class="p-0 order-1 order-lg-1 col-6">
                        <a :href="'/#/product/' + item.product.id" class="text-dark">
                          <img class="img-fluid d-block" :src="item.product.main_image_path">
                        </a>
                      </div>
                      <div class="order-2 order-lg-2 col-6 d-flex flex-column align-items-start justify-content-center pl-3 pr-0">
                        <a :href="'/#/product/' + item.product.id" class="text-dark">
                          <p class="mb-0" style="">{{ item.product.product_name }}</p>
                        </a>
                        <template v-if="item.detail.product_class_value1">
                          <p class="mb-0"><small>{{ item.detail.product_class_value1 }}</small></p>
                        </template>
                        <template v-if="item.detail.product_class_value2">
                          <p class="mb-0"><small>{{ item.detail.product_class_value2 }}</small></p>
                        </template>
                        <template v-if="item.detail.model_name">
                          <p class="mb-0 mt-2"><small>モデル番号: {{ item.detail.model_name }}</small></p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="p-2">
                    <div class="row d-flex align-items-center">
                      <div class="order-2 order-lg-2 col-6 d-flex flex-column align-items-start justify-content-center col-md-6">
                        <p class="mb-0" style="">価格（税抜）</p>
                      </div>
                      <div class="order-2 order-lg-2 col-6 col-md-6 text-right">
                        <p class="mb-0">{{ item.detail.price.sales_price | numberFormat }}円</p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2">
                    <div class="row d-flex align-items-center">
                      <div class="order-2 order-lg-2 col-6 d-flex flex-column align-items-start justify-content-center col-md-6">
                        <p class="mb-0" style="">消費税</p>
                      </div>
                      <div class="order-2 order-lg-2 col-6 col-md-6 text-right">
                        <p class="mb-0">{{ Math.floor((item.detail.price.sales_price * item.quantity) * (item.detail.tax_rate)) | numberFormat }}円</p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 border-bottom">
                    <div class="row d-flex align-items-center">
                      <div class="order-2 order-lg-2 col-6 d-flex flex-column align-items-start justify-content-center col-md-6">
                        <p class="mb-0">小計（税込）</p>
                      </div>
                      <div class="order-2 order-lg-2 col-6 col-md-6 text-right">
                        <p class="mb-0">{{ Math.floor((item.detail.price.sales_price * item.quantity) * (1 + item.detail.tax_rate)) | numberFormat }}円</p>
                      </div>
                    </div>
                    <div>
                      <div class="mt-3">
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="form-group form-inline mb-0 mr-auto">
                            <button class="btn btn-sm btn-danger text-white" type="button" @click.prevent="handleDelete(item)">削除</button>
                          </div>
                          <div class="form-group form-inline mb-0">
                            <span class="d-block mr-3">
                              <template v-if="item.detail.is_unlimited_stock">
                                在庫数: -
                              </template>
                              <template v-else>
                                在庫数: {{ item.detail.stock | numberFormat }}個
                              </template>
                            </span>
                            <input type="number" class="form-control text-center rounded-0"
                                   style="min-width: 6rem; max-width: 6rem;"
                                   id="quantity"
                                   min="1"
                                   maxlength="3"
                                   v-model="item.quantity"
                            >
                            <button class="btn btn-sm btn-primary text-center ml-2" style="position: relative;"
                                    @click.prevent="changeQuantityHandler(item)"
                            >注文数変更</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row text-right">
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div>
                <div class="p-2">
                  <div class="row text-center">
                    <p class="col-12 text-center">お客様のカートに商品はありません</p>
                  </div>
                </div>
              </div>
            </template>

            <!-- SP Cart footer -->
            <div class="p-2 border-top border-bottom">
              <div class="row d-flex align-items-center">
                <div class="p-2 order-2 order-lg-2 col-12 text-right">
                  <a href="product.html" class="text-dark">
                    <p class="mb-0"><span>商品購入合計</span><span class="ml-3 copy12 font-weight-bold">{{ orderTotal | numberFormat }}円（税込）</span></p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="d-none d-sm-block col-md-12">
            <div class="table-responsive">
              <table class="table table-borderless">
                <thead class="thead-light border-bottom">
                <tr>
                  <td class="" style="width: 35%">ご注文商品</td>
                  <td class="text-center" style="width: 15%;">価格</td>
                  <td class="text-center" style="width: 15%;">注文数</td>
                  <td class="text-center" style="width: 15%;">消費税</td>
                  <td class="text-center" style="width: 20%;">小計</td>
                  <td class="text-center" style="width: 15%;"></td>
                </tr>
                </thead>
                <tbody>
                <template v-if="!orderDetails.length > 0">
                  <tr class="border-bottom">
                    <td class="py-3 text-center" colspan="5">
                      お客様のカートに商品はありません
                    </td>
                  </tr>
                </template>

                <template v-else>
                  <template v-for="item in orderDetails">
                    <tr :key="item.detail.product_code" class="border-bottom">
                      <td class="py-3">
                        <div class="row d-flex align-items-center">
                          <div class="p-0 order-1 order-lg-1 col-md-3 col-12"> <img class="img-fluid d-block" v-lazy="item.product.main_image_path"> </div>
                          <div class="p-3 order-2 order-lg-2 d-flex flex-column justify-content-center col-md-9 col-12">
                            <router-link
                                :to="{ name: 'ProductDetail', params: { id: item.detail.product_id }}"
                            >
                              {{ item.product.product_name}}
                            </router-link>
                            <p class="mb-0">
                              <small>
                               <template v-if="item.detail.product_class_value1">{{ item.product.product_class_type_id1_label }}: {{ item.detail.product_class_value1 }}</template>
                              </small>
                            </p>
                            <p class="mb-0">
                              <small>
                                <template v-if="item.detail.product_class_value2">{{ item.product.product_class_type_id2_label }}: {{ item.detail.product_class_value2 }}</template>
                              </small>
                            </p>
                            <p class="mb-0">
                              <small>
                                <template v-if="item.detail.model_name">モデル番号: {{ item.detail.model_name }}</template>
                              </small>
                            </p>
                          </div>
                        </div>
                      </td>

                      <td class="align-middle py-3 text-center">
                        {{ item.detail.price.sales_price | numberFormat }}円（税抜）
                      </td>


                      <td class="align-middle py-3">
                        <div class="d-flex justify-content-center">
                          <div class="mx-2">
                            <span class="d-block mb-2">
                              <template v-if="item.detail.is_unlimited_stock">
                                在庫数: -
                              </template>
                              <template v-else>
                                在庫数: {{ item.detail.stock | numberFormat }}個
                              </template>
                            </span>
                            <b-form-input type="number" class="form-control text-center rounded-0 mb-3"
                                   style="min-width: 6rem; max-width: 6rem;"
                                   id="quantity"
                                   min="1"
                                   maxlength="3"
                                   v-model="item.quantity"
                                   :aria-describedby="'input-live-help' + item.detail.product_code + ' ' + 'input-live-feedback' + item.detail.product_code"
                                   :state="(item.detail.is_unlimited_stock || item.detail.stock > item.quantity)"
                                   @change="changeQuantityHandler(item)"
                            />

                            <button v-if="!item.product.quantity_spinner"
                                    class="btn btn-sm btn-primary text-center w-100 py-1" style="height: 38px; position: relative;"
                                    @click.prevent="changeQuantityHandler(item)"
                            >注文数変更</button>
                            <button v-else
                                    class="btn btn-sm btn-primary text-center w-100 py-0" style="height: 39px; position: relative; top: 1px;"
                                    disabled
                            >
                              <vue-loaders-ball-clip-rotate-multiple scale="0.75"/>
                            </button>
                          </div>
                          <div class="ml-2">

                          </div>
                        </div>
                      </td>

                      <td class="align-middle py-3 text-center">
                        {{ Math.floor((item.detail.price.sales_price * item.quantity) * (item.detail.tax_rate)) | numberFormat }}円
                      </td>

                      <td class="align-middle py-3 text-center">
                        {{ Math.floor((item.detail.price.sales_price * item.quantity) * (1 + item.detail.tax_rate)) | numberFormat }}円（税込）
                      </td>
                      <td class="align-middle py-3 text-center">
                        <button v-if="!item.product.delete_spinner" class="btn btn-primary w-100" type="button" @click.prevent="handleDelete(item)">削除</button>
                        <button v-else class="btn btn-primary py-0 w-100" style="height: 39px;" type="button" disabled><vue-loaders-ball-clip-rotate-multiple scale="0.75"/></button>
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div>
            <div v-if="orderTotal > 0" class="row">
              <!-- 購入合計 -->
              <div class="col-12">
                <p class="text-right"><span>商品購入合計</span><span class="ml-3 copy12 font-weight-bold">{{  orderTotal | numberFormat }}円（税込）</span></p>
              </div>
            </div>
          </div>

        </div>

        <div v-if="orderTotal < 33000" class="col-12 col-md-8 mx-auto mt-3">
          <div class="alert alert-info text-center" role="alert">
            33,000円(税込)のご購入で送料無料となります。<br>
            <br>
            あと {{ 33000 - orderTotal | numberFormat }}円(税込) お買い上げいただくと、送料無料です！
          </div>
        </div>
        <div v-if="!isValidAmount()" class="col-12 col-md-8 mx-auto mt-3">
          <div class="alert alert-warning text-center" role="alert">
            当サイトは商品購入合計金額が{{ configurations.data.minimum_purchase_amount | numberFormat }}円(税込) 以上からご利用いただけます。<br>
            <br>
            あと {{ configurations.data.minimum_purchase_amount - orderTotal | numberFormat }}円(税込) 以上お買い上げいただくと、ご注文手続きに進めます。
          </div>
        </div>
        <div class="row border-bottom py-3 mx-auto">
          <div class="col-md-6 col-lg-6 py-4 px-3">
            <div class="form-group mb-0 text-white">
              <a class="btn btn-block rounded-0 py-2 btn-middlegray" href="/#/">ショッピングを続ける</a>
            </div>
          </div>


          <div class="col-md-6 col-lg-6 py-4 px-3">
            <div class="form-group mb-0 text-white">
              <template v-if="this.$store.state.authCustomer.isLoggedIn">
                <button class="btn btn-darkpink btn-block rounded-0 py-2" @click.prevent="$router.push({path: '/order' })" :disabled="!isValidStock() || !isValidAmount()">ご注文手続きへ進む</button>
              </template>
              <template v-else>
                <button class="btn btn-darkpink btn-block rounded-0 py-2" @click.prevent="$router.push({path: '/login/from_cart' })">ログイン / 新規会員登録</button>
              </template>
            </div>
          </div>
        </div>
        <Toast ref="toast"></Toast>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import store from "@/store";
import Toast from "@/components/Toast"
export default {
  name: 'Cart',
  data() {
    return {
      spinner: false,
      quantityList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      options: [
        { text: 'クリア', value: '3' },
        { text: 'ナチュラルピンク', value: '4' },
        { text: 'コーラルピンク', value: '5' },
        { text: '大人ベージュ', value: '6' },
        { text: 'レッド', value: '24' },
      ],
      changeQuantityFlag: false,
      previewPaymentTotal: 0,
      isLKF: window.localStorage.getItem('isLKF') ? window.localStorage.getItem('isLKF') : false,
    }
  },
  components: {
    Toast
  },
  methods: {
    ...mapActions('authCustomer', [
      'isLoggedIn',
    ]),
    ...mapActions('newCart', [
       'deleteCartItem', 'changeQuantity', 'getCart'
    ]),
    async handleDelete(item) {
      console.log("typeof:" + typeof item)

      item.product.delete_spinner = true
      if(await this.deleteCartItem(item)) {
        await this.getCart();
        this.$refs.toast.makeToast('success', 'カート', 'カートから商品を削除しました')
        item.product.delete_spinner = false
      }
    },
    async changeQuantityHandler(item) {
      item.product.quantity_spinner = true
      await this.changeQuantity(item).then(() => {
        this.$refs.toast.makeToast('success', 'カート', 'カート内商品の数量を変更しました')
        item.product.quantity_spinner = false
      })
    },
    async handleQuantity(item) {
      item.product.is_updated = true
    },
    isValidStock() {
      const invalidItem = this.orderDetails.filter(function(row, index) {
        if(row.detail.is_unlimited_stock) {
          return false;
        }

        if(row.detail.stock < row.quantity) {
          return true;
        } else {
          return false;
        }
      })

      console.log(invalidItem)
      if(invalidItem.length > 0) {
        return false
      }

      return true
    },
    isValidAmount() {
      if(this.orderTotal >= this.configurations.data.minimum_purchase_amount) {
        console.log("閾値超過してるのでOK")
        return true
      }

      console.log("閾値超過してないのでNG")
      return false
    }
  },
  computed: {
    ...mapGetters('newCart', [
      'order', 'orderTotal', 'orderDetails'
    ]),
    ...mapGetters('configurations', [
      'configurations'
    ]),
    isLoading() {
      return this.$store.state.isLoading
    },
  },
  created: async function() {
    await store.commit('setLoading', true)

    await store.dispatch(
        "newCart/getCart",
        window.localStorage.getItem('cart_id')
    );
    await store.dispatch('authCustomer/currentUser');

    await store.dispatch(
        'configurations/fetchOne'
    );

    await store.commit('setLoading', false)
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
  async beforeRouteLeave(to, from, next) {
    await store.commit('setLoading', true)

    return next();
  },
}
</script>
