import Vue from "vue";

import values from 'lodash/values'
const state = {
    classMaster: {
        sizes: [],
        colors: [],
    },
    grade: 0,
    campaignRatio: 1.0,
    items: [],
    item: {
        id: '',
        product_name: '',
        model_name: '',
        product_class_type_id1: '',
        product_class_type_id2: '',
        tax_rate: '',
        header: '',
        description: '',
        body: '',
        footer: '',
        attention: '',
        unit_name: '',
        contained: '',
        agency_ratio: '',
        dealer_ratio: '',
        customer_ratio: '',
        web_customer_ratio: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        detail_image_paths: '',
        detail_thumbnail_image_paths: '',
        main_image: null,
        detail_image1: null,
        detail_image2: null,
        detail_image3: null,
        detail_image4: null,
        detail_image5: null,
        is_pickup: Boolean,
        is_regular: Boolean,
        is_sales_at_retail_price: Boolean,
        is_cosmetic: Boolean,
        is_kogen: Boolean,
        is_mebius: Boolean,
        is_chaga: Boolean,
        is_inconsistent: Boolean,
        is_broken: Boolean,
        is_not_exists_detail: Boolean,
        is_enable_value_id1: 0,
        is_enable_value_id2: 0,
        has_campaign: false,
        selected_value_id1: '',
        selected_value_id2: '',
        selected_product_code: '',
        for_front: Boolean,
        started_at: '',
        ended_at: '',
        details: [],
        classes: {},
        campaign: {
            id: Number,
            is_present: Boolean,
            product_codes: JSON,
            product_codes_for_present: JSON,
            name: String,
            title: String,
            description: String,
            header: String,
            body: String,
            footer: String,
            discount_rule: String,
            required_quantity: String,
            required_amount: String,
            present_quantity: String,
            discount_of_agency: Number,
            discount_of_dealer: Number,
            discount_of_customer: Number,
            discount_of_general: Number,
            started_at: Number,
            ended_at: Number,
        },
        types1: null,
        types2: null,
        relative_products: {},
    },
    selectedProduct: {
        id: Number,
        ratio: 0.9,
        product_code: String,
        product_name: String,
        product_class_type_id1: 0,
        product_class_type_id2: 0,
        product_class_value_id1: 0,
        product_class_value_id2: 0,
        amount: Number,
        quantity: 1,
        price: Number,
        discount_rate: Number,
        tax: Number,
        tax_rate: Number,
    },
    // 管理画面 検索条件
    search: {
        per_page: 25,
        page: 1,
        id: '',
        product_name: '',
        model_name: '',
        category_id: '',
        series_id: '',
        for_front: [],
        exclude_categories: [9999],
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    main_image: null,
    detail_image1: null,
    detail_image2: null,
    detail_image3: null,
    detail_image4: null,
    detail_image5: null,
    productClasses: [],
    productClassTypes: [],
    productClassValues: [],
    errors: [],
    types1: null,
    types2: null,
    suggestList: [],
    classLabel1: [],
    classLabel2: [],
};

const initialState = {
    classMaster: {
        sizes: [],
        colors: [],
    },
    grade: 0,
    campaignRatio: 1.0,
    items: [],
    item: {
        id: '',
        product_name: '',
        model_name: '',
        product_class_type_id1: '',
        product_class_type_id2: '',
        tax_rate: '',
        header: '',
        description: '',
        body: '',
        footer: '',
        attention: '',
        unit_name: '',
        contained: '',
        agency_ratio: '',
        dealer_ratio: '',
        customer_ratio: '',
        web_customer_ratio: '',
        main_image_path: '',
        main_thumbnail_image_path: '',
        detail_image_paths: '',
        detail_thumbnail_image_paths: '',
        main_image: null,
        detail_image1: null,
        detail_image2: null,
        detail_image3: null,
        detail_image4: null,
        detail_image5: null,
        is_pickup: Boolean,
        is_regular: Boolean,
        is_sales_at_retail_price: Boolean,
        is_cosmetic: Boolean,
        is_kogen: Boolean,
        is_mebius: Boolean,
        is_chaga: Boolean,
        is_inconsistent: Boolean,
        is_broken: Boolean,
        is_not_exists_detail: Boolean,
        is_enable_value_id1: 0,
        is_enable_value_id2: 0,
        has_campaign: false,
        selected_value_id1: '',
        selected_value_id2: '',
        selected_product_code: '',
        for_front: Boolean,
        started_at: '',
        ended_at: '',
        master: [],
        details: {
            id: Number,
            product_id: Number,
            product_code: String,
            product_class_value_id1: Number,
            product_class_value_id2: Number,
            original_price: Number,
            original_price_tax: Number,
            retail_price: Number,
            retail_price_tax: Number,
            sales_price: Number,
            sales_price_tax: Number,
            is_out_of_stock: 0,
        },
        classes: {},
        campaign: {
            id: Number,
            is_present: Boolean,
            product_codes: JSON,
            product_codes_for_present: JSON,
            name: String,
            title: String,
            description: String,
            header: String,
            body: String,
            footer: String,
            discount_rule: String,
            required_quantity: String,
            required_amount: String,
            present_quantity: String,
            discount_of_agency: Number,
            discount_of_dealer: Number,
            discount_of_customer: Number,
            discount_of_general: Number,
            started_at: Number,
            ended_at: Number,
        },
        relative_products: {},
    },
    // 管理画面 検索条件
    search: {
        per_page: 25,
        page: 1,
        product_name: '',
        category_id: '',
        series_id: '',
        for_front: [],
        exclude_categories: [9999],
    },
    pagination: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: null,
        to: 0,
        total: 0,
    },
    targetCampaign: {
        id: Number,
        is_present: Boolean,
        product_codes: JSON,
        product_codes_for_present: JSON,
        name: String,
        title: String,
        description: String,
        header: String,
        body: String,
        footer: String,
        discount_rule: String,
        required_quantity: String,
        required_amount: String,
        present_quantity: String,
        discount_of_agency: Number,
        discount_of_dealer: Number,
        discount_of_customer: Number,
        discount_of_general: Number,
        started_at: Number,
        ended_at: Number,
    },
    selectedProduct: {
        id: Number,
        product_code: String,
        product_name: String,
        ratio: 0.9,
        product_class_type_id1: Number,
        product_class_type_id2: Number,
        product_class_value_id1: Number,
        product_class_value_id2: Number,
        amount: Number,
        quantity: Number,
        price: Number,
        discount_rate: Number,
        tax: Number,
        tax_rate: Number,
    },
    productClasses: [],
    productClassTypes: [],
    productClassValues: [],
    errors: [],
    suggestList: [],
    classLabel1: [],
    classLabel2: [],
};

const mutations = {
    setClassMaster(state, classMaster) {
        state.classMaster = classMaster
    },
    setProductClasses(state, productClasses) {
        state.productClasses = productClasses;
    },
    setProductClassTypes(state, productClassTypes) {
        state.productClassTypes = productClassTypes;
    },
    setProductClassValues(state, productClassValues) {
        state.productClassValues = productClassValues;
    },
    setItems(state, items) {
        state.items = items
    },
    setItem(state, item) {
        state.item = item
    },
    setSearch(state, search) {
        state.search = search
    },
    setPagination(state, items) {
        Vue.set(state, 'pagination', items);
    },
    setSuggestList(state, items) {
        Vue.set(state, 'suggestList', items);
    },
    setSelectedProduct(state, selectedProduct) {
        state.selectedProduct = selectedProduct;
    },
    update(state, {item, newItem}) {
        Object.assign(item, newItem);
    },
    delete(state, index) {
        state.pagination.data.splice(index, 1);
    },
    deleteDetail(state, index) {
        let detail = state.items.details[index]
        if(detail.id === '') {
            state.items.splice(index, 1);
        } else {
            detail.deleted_at = Date.now()
        }
    },
    recoverDetail(state, index) {
        let detail = state.items.details[index]
            detail.deleted_at = ''
    },
    store(state, item) {
        state.item = item;
    },
    setErrors(state, error) {
        state.errors = error;
    },
    unsetErrors(state) {
        state.errors = initialState.errors
    },
    resetState() {
        for (let f in state) {
            Vue.set(state, f, initialState[f]);
        }
    },
    resetSearchState() {
        state.search.id = "";
        state.search.product_name = "";
        state.search.product_code = "";
        state.search.category_id = '';
        state.search.series_id = '';
        state.search.for_front = [];
    },
    setClassLabel(typeId, classLabels) {
        if(typeId === 1) {
            state.classLabel1 = classLabels
        } else if(typeId === 2) {
            state.classLabel2 = classLabels
        }
    },
    setGrade(state, grade) {
        state.grade = parseInt(grade)
    },
    setCampaignRatio(state, campaign) {
        const grade = window.localStorage.getItem('grade');
        const agency = campaign.discount_of_agency
        const dealer = campaign.discount_of_dealer
        const customer = campaign.discount_of_customer
        const web_customer = campaign.discount_of_web_customer
        // Customer
        if(grade == 1) {
            state.campaignRatio = customer
            window.localStorage.setItem('campaignRatio', customer)
        } else if(grade == 2) {
            state.campaignRatio = dealer
            window.localStorage.setItem('campaignRatio', dealer)
        } else if(grade == 3) {
            state.campaignRatio = agency
            window.localStorage.setItem('campaignRatio', agency)
        } else if(grade == 5) {
            state.campaignRatio = web_customer
            window.localStorage.setItem('campaignRatio', web_customer)
        } else {
            state.campaignRatio = 1.0;
            window.localStorage.setItem('campaignRatio', 1.0)
        }
    }
};

const getters = {
    products(state) {
        return state.items;
    },
    product(state) {
        return state.item;
    },
    pagination(state) {
        return state.pagination;
    },
    search(state) {
        return state.search;
    },
    suggestList(state) {
        return state.suggestList
    },
    selectedProduct(state) {
        return state.selectedProduct;
    },
    productDetails(state) {
        return state.item.details;
    },
    productClasses(state) {
        return state.productClasses;
    },
    productClassTypes(state) {
        return state.productClassTypes;
    },
    productClassValues(state) {
        return state.productClassValues;
    },
    getClassMaster(state) {
        return state.classMaster;
    },
    getColorName(valueId) {
        return state.classMaster['colors'][valueId]
    },
    getSizeName(valueId) {
        return state.classMaster['sizes'][valueId]
    },
    getRatio(state, getters, rootState) {
        const customerRatio = (rootState.customers.item.detail.ratio) ? rootState.customers.item.detail.ratio : 0;
        const productRatio = (state.item.ratio) ? state.item.ratio : 0;

        if(productRatio > 0) {
            return productRatio
        }

        return customerRatio
    },
    grade(state) {
        return state.grade
    },
    campaignRatio(state) {
        return state.campaignRatio
    },
    getClassLabel(typeId, valueId) {
        console.log(state.pagination.classLabel1)
        return 'testy'
        if(typeId === 1) {
            return state.classLabel1[valueId]
        } else if(typeId === 2) {
            return state.classLabel2[valueId]
        }
    },
};

const actions = {
    async fetch({state, commit, rootActions}, params) {
        await window.axios.get('/products', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('products.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetch.error', error)
        });
    },
    async fetchWithClasses({state, commit, rootActions}, params) {
        await window.axios.get('/products/with/classes', { params }).then(res => {
            commit('setPagination', res.data);
            commit('setClassLabel', 1, values(res.data.classLabel1))
            commit('setClassLabel', 2, values(res.data.classLabel2))
            commit('setSearch', params);
            commit('unsetErrors')
            console.log('products.fetchWithClasses', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchWithClasses.error', error)
        });
    },
    async fetchByPage({commit}, params) {
        await window.axios.get('/products?page=' + params.page + '&per_page=' + params.perPage).then(res => {
            commit('setPagination', res.data);
            commit('unsetErrors')
            console.log('products.fetch', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetch.error', error)
        });
    },
    async fetchSuggest({state, commit}) {
        await window.axios.get('/products/suggest-list').then(res => {
            commit('setSuggestList', res.data);
            commit('unsetErrors')
            console.log('products.fetchSuggest', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchSuggest.error', error)
        });
    },
    async fetchOne({dispatch, commit}, id) {
        await window.axios.get('/products/' + id).then(res => {
            commit('setItem', res.data);
            commit('unsetErrors')
            console.log('products.fetchOne')
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchOne.error', error)
        });
    },
    async fetchOneForFront({dispatch, commit}, id) {
        await window.axios.get('/products/' + id + '/detail').then(res => {
            commit('setItem', res.data);
            if(res.data.campaign) {
                commit('setCampaignRatio', res.data.campaign)
            }

            commit('unsetErrors')
            console.log('products.fetchOneForFront')
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchOneForFront.error', error)
        });
    },
    async fetchClassMaster({state, commit}) {
        await window.axios.get('/products/class-master').then(res => {
            commit('setClassMaster', res.data);
            console.log('products.fetchClassMaster', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchClassMaster.error', error)
        });
    },
    async fetchProductClassValues({commit}) {
        await window.axios.get('/products/class/values').then(res => {
            commit('setProductClassValues', res.data);
            commit('unsetErrors')
            console.log('products.fetchProductClassValues', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchProductClassValues.error', error)
        });
    },
    async fetchProductClassTypes({commit}) {
        await window.axios.get('/products/class/types').then(res => {
            commit('setProductClassTypes', res.data);
            commit('unsetErrors')
            console.log('products.fetchProductClassTypes', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchProductClassTypes.error', error)
        });
    },
    async fetchByCategoryId({commit}, id) {
        await window.axios.get('/products/search/category/' + id).then(res => {
            commit('setItems', res.data);
            commit('unsetErrors')
            console.log('products.fetchByCategoryId', res)

            return true
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchByCategoryId.error', error)
        });
    },
    async fetchByProductName({commit}, productName) {
        await window.axios.get('/products/search/name/' + productName).then(res => {
            commit('setItems', res.data);
            commit('unsetErrors')
            console.log('products.fetchByProductName', res)
        }).catch(error => {
            commit('setErrors', error)
            console.log('products.fetchByProductName.error', error)
        });
    },
    async new({commit}) {
        let item = initialState.item

        commit('setItem', item);
        console.log('products.new')
    },
    async delete({state, commit}, item) {
        const index = state.pagination.data.indexOf(item);

        return await window.axios.post('/products/delete', item)
            .then(res => {
                commit('delete', index);
                commit('unsetErrors')
                console.log('products.delete', state.pagination.data[index])

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('products.delete.error', error, item)
            });
    },
    async store({commit}, item) {
        return await window.axios.post('/products/store', item)
            .then(res => {
                commit('unsetErrors')
                console.log('products.store', res, item)
            }).catch(error => {
                commit('setErrors', error)
                console.log('products.store.error', error, item)
            });
    },
    async storeClone({commit}, item) {
        return await window.axios.post('/products/clone', item)
            .then(res => {
                commit('unsetErrors')
                console.log('products.storeClone', res, item)
            }).catch(error => {
                commit('setErrors', error)
                console.log('products.storeClone.error', error, item)
            });
    },
    async update({state, commit}, newItem) {
        return await window.axios.post('/products/update', newItem)
            .then(res => {
                commit('unsetErrors')
                console.log('products.update', res, newItem)

                return true
            }).catch(error => {
                commit('setErrors', error)
                console.log('products.update.error', error, newItem)
            });
    },
    resetState({commit}) {
        commit('resetState');
        console.log('products.resetState')
    },
    resetSearchState({commit}) {
        commit('resetSearchState')
        console.log('products.resetSearchState')
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};