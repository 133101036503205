<template>
  <div class="progress">
    <div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
  </div>
</template>

<script>
export default {
  name: "NAProgress.vue"
}
</script>

<style scoped>

</style>