<template>
    <ValidationProvider
        :name="name"
        :rules="rules"
        mode="aggressive"
        v-slot="validationContext"
    >
    <b-input-group>
      <template #prepend>
        <template v-if="prepend">
          <b-input-group-text>
            {{ prepend }}
          </b-input-group-text>
        </template>
      </template>
      <b-form-input
          class="rounded-0"
          :type="type"
          :name="name"
          :placeholder="placeholder ? placeholder : name"
          :state="getValidationState(validationContext)"
          :autocomplete="autocomplete"
          :disabled="disabled"
          v-model="internalValue"
      ></b-form-input>
      <template #append>
        <template v-if="append">
          <b-input-group-text>
            {{ append }}
          </b-input-group-text>
        </template>
      </template>

      <b-form-invalid-feedback>{{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-input-group>
  </ValidationProvider >
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    value: {
      required: String,
    },
    validate: {
      type: String,
      default: null
    },
    rules: {
      Type: String,
      required: true
    },
    autocomplete: {
      Type: String,
      required: false
    },
    prepend: {
      Type: String,
      required: false,
    },
    append: {
      Type: String,
      required: false,
    },
    disabled: {
      Type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
  },
  destroyed() {
    this.$off();
  }
};
</script>
