<template>
  <div>
    <div class="py-4" style="">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center d-flex justify-content-center" style="">
            <h1 class="border-bottom pb-3 text-primary" style="font-family: &quot;M PLUS 1p&quot;, sans-serif; font-weight: 700;">修理代行について</h1>
          </div>
        </div>
        <div class="row justify-content-center" style="">
          <div class="px-md-3 p-3 d-flex flex-column align-items-start justify-content-center col-md-9" style="">
            <p class="mb-0">
              修理店では対応していない機種や症状、基板修理などを弊社が代わりに修理を行います。<br />
              現在数十店舗様とお取引があり大手フランチャイズ数社からもご依頼をいただいております。 <br />
              まずはお気軽にお問い合わせください！ <br />
            </p>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="row justify-content-center" style="">
          <div class="col-md-9">
            <div class="h5">
                <span class="check-text">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <span class="check-text">自店舗では修理できない機種</span>
                </span>
            </div>
            <div class="h5">
                <span class="check-text">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <span class="check-text">自店舗では修理できない症例</span>
                </span>
            </div>
            <div class="h5">
                <span class="check-text">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <span class="check-text">自店舗にて修理ミスによる故障</span>
                </span>
            </div>
            <div class="h5">
                <span class="check-text">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                  <span class="check-text">iPhone修理・基板修理をサービスのメニューとして増やしたい</span>
                </span>
            </div>
          </div>
          <div class="col-md-9 mt-3 bg-light pt-2 pb-2 pr-3 pl-3">
            <div class="row">
              <!-- vision 01 -->
              <div class="col-md-4 bg-light pt-2 pb-2">
                <div class="row col-md-12 bg-white m-0 vision-height">
                  <div class="row col-md-4 col-sm-4 bg-gray mx-auto">
                    <span class="vision-text-small">VISION</span>
                    <span class="vision-text-large">01</span>
                  </div>
                  <div class="row col-md-12 d-flex justify-content-center text-center p-0">
                  <span class="d-block">
                    復旧率９０％以上の<br />
                    技術があります<br />
                    <br />
                  </span>
                  </div>
                </div>
              </div>
              <!-- vision 02 -->
              <div class="col-md-4 bg-light pt-2 pb-2">
                <div class="row col-md-12 bg-white m-0 vision-height">
                  <div class="row col-md-4 bg-gray mx-auto">
                    <span class="vision-text-small">VISION</span>
                    <span class="vision-text-large">02</span>
                  </div>
                  <div class="row col-md-12 d-flex justify-content-center text-center m-auto p-0">
                  <span class="d-block mt-3">
                  専門のスタッフが常駐しているため、iPhone、iPad、Android、ゲーム機、基板修理が可能です
                  <br />
                  </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 bg-light pt-2 pb-2">
                <div class="row col-md-12 bg-white mx-auto vision-height">
                  <div class="row col-md-4 bg-gray mx-auto">
                    <span class="vision-text-small">VISION</span>
                    <span class="vision-text-large">03</span>
                  </div>
                  <div class="row col-md-12 d-flex justify-content-center text-center m-auto p-0">
                  <span class="d-block mt-3">
                    技術に自信があるので修理不可の場合は料金を一切頂きません<br />
                    <br />
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="py-0 bg-light">
          <div class="container">
            <div class="row">
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center"
                     style="">
                  <i class="fas fa-thumbs-up fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">技術に自信があります</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">基板修理復旧率９０％</span>　<br /><br />
                    <span class="font-weight-bold">iPhone修理復旧率９７％</span>　<br /><br />
                    過去10数年の修理経験がある当社にまずはお任せください　<br /><br />
                  </p>
                </div>
              </div>
              <div class="col-md-6 p-3">
                <div class="px-md-3 p-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                  <i class="fas fa-exclamation-triangle fa-4x text-secondary mb-3"></i>
                  <h2 style="font-family: 'M PLUS 1p', sans-serif; font-weight: 700;" class="text-primary">
                    修理ミス</h2>
                  <p class="mt-2 mb-3">
                    <span class="font-weight-bold">
                      ・バッテリー交換<br />
                      ・タッチパネル交換<br />
                      ・水没<br />
                      ・リンゴループ<br />
                      ・データ吸い出し<br />
                      ・基板修理<br />
                      ・ゲーム機の修理<br />
                    </span>
                    <br />

                    どのような修理でもまずはお問い合わせください。<br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4 mt-3">
          <div class="col-md-12 text-center">
            <i class="fas fa-wrench text-secondary fa-3x text-pink"></i>
          </div>
        </div>
        <div class="col-md-12 pb-2 text-center">
          <p class="font-weight-bold">
            即日修理・即日郵送をモットーとしております。お気軽にご相談ください。
          </p>
          <router-link class="btn btn-secondary mt-2 py-2 px-2" to="/contact">
            <i class="fas fa-envelope pr-2"></i>お問い合わせはこちら
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.vision-text-small {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  top: 0.9rem;
  left: -0.2rem;
}
.vision-text-large {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: -0.2rem;
}
.bg-gray {
  background-color: #aaaaaa;
  height: 4rem;
  padding-left: 1.4rem;
}
.check-image {
  display: inline-block;
  width: 2.4rem;
  vertical-align: middle;
}
.check-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}
.vision-height {
  min-height: 100%;
  padding-bottom: 2rem;
}
</style>

<script>
export default {
  name: 'RepairInstead',
  async beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);

    return next();
  },
}
</script>